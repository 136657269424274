import React, { ComponentType, FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
  component: ComponentType;
  allowed: boolean;
  redirectTo: any;
}

const ProtectedRoute: FC<any> = ({
  component: RouteComponent,
  redirectTo,
  allowed,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (allowed) {
          return <RouteComponent {...rest} {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: redirectTo,
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
