import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ChartFormatToggle, { CHART_FORMATS } from './ChartFormatToggle';
import Spacer from '../common/Spacer';
import TargetsChart from '../charts/TargetsChart';
import TargetTrends from './TargetTrends';
import { PERIODS } from './TrackerPeriodSelector';

const styles = {
  controls: {
    display: 'flex',
    padding: '0 12px 3px 0',
  },
};

const calcRatio = (num1, num2) => {
  return num2 === 0 ? 0 : Math.floor((num1 * 100) / num2);
};

const TargetsTracker = (props) => {
  const {
    targets,
    targetsData,
    onUpdateTargetValue,
    period,
    chartFormat,
    onChangeChartFormat,
    classes,
  } = props;

  const handleTargetValueUpdate = (targetId, value) => {
    if (chartFormat === CHART_FORMATS.Percentages) {
      value = convertPercentagesToNumbers(targetId, value);
    }

    const target = targets.find((t) => t.id === targetId);
    if (!target) {
      return;
    }

    const targetCopy = { ...target };
    updateTargetValueByPeriod(targetCopy, value);

    onUpdateTargetValue(targetCopy);
  };

  const updateTargetValueByPeriod = (target, value) => {
    switch (period) {
      case PERIODS.Month:
        target.monthValue = value;
        break;
      case PERIODS.Quarter:
        target.quarterValue = value;
        break;
      case PERIODS.Year:
        target.yearValue = value;
        break;
    }
  };

  const convertPercentagesToNumbers = (targetId, value) => {
    const targetDataItem = targetsData.find((t) => t.id === targetId);
    const previous = getDataByPeriod(targetDataItem.previous);
    return Math.floor((previous * value) / 100);
  };

  const getDataByPeriod = (data) => {
    switch (period) {
      case PERIODS.Month:
        return data.month;
      case PERIODS.Quarter:
        return data.quarter;
      case PERIODS.Year:
        return data.year;
      default:
        return 0;
    }
  };

  const targetsDataByPeriod = targetsData.map((target) => {
    return {
      ...target,
      value: getDataByPeriod(target.goal),
      current: getDataByPeriod(target.current),
      previous: getDataByPeriod(target.previous),
    };
  });

  const formattedTargetsData =
    chartFormat == CHART_FORMATS.TotalCounts
      ? targetsDataByPeriod
      : targetsDataByPeriod.map((target) => {
          return {
            ...target,
            value: calcRatio(target.value, target.previous),
            current: calcRatio(target.current, target.previous),
            previous: calcRatio(target.previous, target.previous),
          };
        });

  return (
    <>
      <div className={classes.controls}>
        <Spacer />
        <ChartFormatToggle value={chartFormat} onChange={onChangeChartFormat} />
      </div>
      <TargetsChart
        targetsData={formattedTargetsData}
        onUpdateTargetValue={handleTargetValueUpdate}
        isPercentages={chartFormat === CHART_FORMATS.Percentages}
      />
      <TargetTrends targetsData={targetsDataByPeriod} />
    </>
  );
};

TargetsTracker.propTypes = {
  targetsData: PropTypes.array.isRequired,
  onUpdateTargetValue: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  chartFormat: PropTypes.string.isRequired,
  onChangeChartFormat: PropTypes.func.isRequired,
};

export default withStyles(styles)(TargetsTracker);
