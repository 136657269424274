import { CSSProperties } from "react";

export const ChartStyles = {
  tooltipContentStyle: {
    color: '#fff',
    background: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    borderRadius: 4
  } as CSSProperties,
  tooltipItemStyle: {
    background: 'rgba(0, 0, 0, 0.87) !important'
  },
  tooltipWrapperStyle: {
    top: -50
  },
  areaChartMargin: {
    top: 0,
    right: 10,
    bottom: 0,
    left: 0,
  },
  targetsChartMargin: {
    top: 34,
    right: 0,
    bottom: 0,
    left: 15
  },
  barChartMargin: {
    top: 5,
    right: 30,
    bottom: 5,
    left: 20
  }
};

export const ChartSettings = {
  tick: {
    fill: '#5E6366',
    fontSize: '12px',
    lineHeight: 16
  },
  xAxisLabel: {
    angle: -90,
    position: 'center',
    dx: -15,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 1,
    lineHeight: 14
  },
};
