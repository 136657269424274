//@ts-nocheck
import React, { Component, FC, useEffect, useState } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { ZoomOutMap as ZoomOutMapIcon } from '@material-ui/icons';
import DashboardModal from './DashboardModal';
import AssociatedReportsCostChart from '../charts/AssociatedReportsCostChart';
import PdfCreator from '../PdfCreator';
import IDService from '../../services/IdService';
import AssociatedReportsDateCostChartData from '../../services/chart-data-shapes/AssociatedReportsDateValueChartData';
import { getPdfFileName, getSettings } from '../../helpers/data-helper';
import AssociatedReportsCostTimeseriesChart from '../charts/AssociatedReportsCostTimeseriesChart';
import WidgetTitle from '../help-button/widget-title';
import TimeServiceProvider from '../../services/TimeService';
import { WidgetCode } from '../widgets/widgetCodes';
import { getAssociatedReportWidgetData } from '../../services/WidgetDataService';
import CircularProgressCentral from '../common/CircularProgressCentral';
import { AssociatedReportDto, WidgetSettingDto } from '../../api/apiDtos';
import { AssociatedReportDtoSchema } from '../../api/zodSchemas';
import { logDataFetchError, logResponseParseError } from '../../services/utils';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { useWidgetTranslations } from '../widgets/useTranslatedWidgetsDefaultSettings';

const styles = createStyles({
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
  cardActions: {
    padding: 16,
    flex: 1,
  },
  actionText: {
    flexGrow: 1,
    textAlign: 'center',
  },
  modalWrap: {
    height: 400,
  },
});

interface Props extends WithStyles<typeof styles> {
  domain: any[];
  settings: WidgetSettingDto;
}

const AssociatedReportsCostCard: FC<Props> = ({
  classes,
  domain,
  settings,
}) => {
  const { AssociatedReportsCostDefaultSettings } = useWidgetTranslations();
  const timeService = TimeServiceProvider();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [chartType, setChartType] = useState('bar');
  const [associatedReports, setAssociatedReports] = useState<
    AssociatedReportDto[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  const getDataFromAPI = () => {
    setIsLoading(true);
    getAssociatedReportWidgetData(WidgetCode.costOverTime)
      .then((res) => {
        //here zod is used to do a runtime check whether the parsed res from api is the
        //correct format
        const schemaMatch = z.array(AssociatedReportDtoSchema).safeParse(res);
        if (schemaMatch.success) {
          setAssociatedReports(schemaMatch.data);
        } else {
          logResponseParseError(schemaMatch.error);
        }
      })
      .catch((err) => logDataFetchError(err, 'RootCauseBreakdowns'))
      .finally(() => setIsLoading(false));
  };

  const setModal = (showModal: boolean) => () => setShowModal(showModal);

  useEffect(() => {
    timeService.subscribe(getDataFromAPI);

    getDataFromAPI();

    return () => {
      timeService.unsubscribe(getDataFromAPI);
    };
  }, []);

  const ModalView = ({ chartData, associatedReports, fileName, axisY }) => {
    var handleChartChange = (event: any) => {
      if (event.target.value != null) setChartType(event.target.value);
    };

    const elementId = IDService.Generate();

    return (
      <div id={elementId} className={classes.modalWrap}>
        <div>
          <RadioGroup value={chartType} onClick={handleChartChange} row>
            <FormControlLabel
              value="bar"
              control={<Radio color="primary" />}
              label={t('dashboard.AssociatedReportsCostCard.Bar')}
              labelPlacement="end"
            />
            <FormControlLabel
              value="timeseries"
              control={<Radio color="primary" />}
              label={t('dashboard.AssociatedReportsCostCard.Time Series')}
              labelPlacement="end"
            />
            <PdfCreator targetElementId={elementId} fileName={fileName} />
          </RadioGroup>
        </div>
        {chartType === 'bar' ? (
          <AssociatedReportsCostChart data={chartData} tooltip axisY={axisY} />
        ) : (
          <AssociatedReportsCostTimeseriesChart
            data={associatedReports}
            domain={domain}
          />
        )}
      </div>
    );
  };

  const chartData = AssociatedReportsDateCostChartData(
    associatedReports,
    domain
  );
  const currentTotal = chartData.reduce(
    (total, point) => total + point.cost,
    0
  );
  const currentMax = Math.max(...chartData.map((p) => p.cost));

  const elementId = IDService.Generate();
  const { title, currency, totalItemsLabel, axisY, info } = getSettings(
    settings,
    AssociatedReportsCostDefaultSettings
  );
  const fileName = getPdfFileName(title);

  return isLoading ? (
    <CircularProgressCentral />
  ) : (
    <>
      <Card className={classes.card}>
        <CardHeader
          title={<WidgetTitle title={title} info={info} />}
          titleTypographyProps={{ variant: 'h6' }}
          action={
            <>
              <PdfCreator targetElementId={elementId} fileName={fileName} />
              <IconButton onClick={setModal(true)}>
                <ZoomOutMapIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
        <CardContent id={elementId} className={classes.cardContent}>
          <AssociatedReportsCostChart data={chartData} axisY={axisY} />
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Typography variant="subtitle2" className={classes.actionText}>
            {t('dashboard.AssociatedReportsCostCard.Total')}: {currency}
            {currentTotal.toLocaleString()}
          </Typography>
          <Typography variant="subtitle2" className={classes.actionText}>
            {t('dashboard.AssociatedReportsCostCard.Highest')}: {currency}
            {currentMax.toLocaleString()}
          </Typography>
          <Typography variant="subtitle2" className={classes.actionText}>
            {totalItemsLabel}: {associatedReports.length}
          </Typography>
        </CardActions>
      </Card>
      <DashboardModal
        handleClose={setModal(false)}
        title={title}
        open={showModal}
      >
        {ModalView({ chartData, associatedReports, fileName, axisY })}
      </DashboardModal>
    </>
  );
};

export default withStyles(styles)(AssociatedReportsCostCard);
