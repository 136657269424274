// @ts-nocheck
import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import GeoPyLicence from './GeoPy';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '0 20px 0 20px',
  },
}));

const Licences = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">
            {t('licensing.Licences.Third Party Licence Details')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="h6">GeoPy</Typography>
          <GeoPyLicence />
        </Grid>
      </Grid>
    </div>
  );
};

export default Licences;
