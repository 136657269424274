import { useTranslation } from 'react-i18next';

export const useWidgetTranslations = () => {
  const { t } = useTranslation();

  const noDataLabel = t(
    'widgets.useTranslatedWidgetsDefaultSettings.No data for this time range'
  );

  return {
    noDataLabel,
    ReportsByDatesDefaultSettings: {
      title: '',
      axisY: t('widgets.useTranslatedWidgetsDefaultSettings.TOTAL REPORTED'),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.info about Report Dates By Type widget'
      ),
      noDataLabel: noDataLabel,
      typesLabel: t('widgets.useTranslatedWidgetsDefaultSettings.Totals'),
      organisationsLabel: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Organisations'
      ),
      reportTypeAggregationThreshold: '5',
      organisationAggregationThreshold: '5',
    },
    ReportAveragesByClassificationDefaultSettings: {
      title: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Report Averages By Classification'
      ),
      axisX: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Reports per Facilities per Month'
      ),
      axisY: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Classification data'
      ),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.info about Report Averages By Classification widget'
      ),
      noDataLabel: noDataLabel,
      typesLabel: t('widgets.useTranslatedWidgetsDefaultSettings.Categories'),
      organisationsLabel: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Organisations'
      ),
      percentageItemsPostfix: t(
        'widgets.useTranslatedWidgetsDefaultSettings.reports'
      ),
      fractionalItemsPostfix: t(
        'widgets.useTranslatedWidgetsDefaultSettings.per facilities / months'
      ),
    },
    ReportHistoryDefaultSettings: {
      title: t('widgets.useTranslatedWidgetsDefaultSettings.Report History'),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Additional information about the Report History widget'
      ),
      totalItemsLabel: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Total Reports'
      ),
      reportTypeAggregationThreshold: '5',
    },
    ReportTableDefaultSettings: {
      title: t('widgets.useTranslatedWidgetsDefaultSettings.Report Table'),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Additional information about the Report Table widget'
      ),
      noDataLabel: noDataLabel,
    },
    AssociatedReportTableDefaultSettings: {
      title: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Associated Report Table'
      ),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Additional information about the Associated Report Table widget'
      ),
      noDataLabel: noDataLabel,
    },
    TopicTrendsDefaultSettings: {
      title: t('widgets.useTranslatedWidgetsDefaultSettings.Latest Trends'),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Additional information about Latest Topics Trends widget'
      ),
      topic: t('widgets.useTranslatedWidgetsDefaultSettings.Topic'),
      reportsPercent: t('widgets.useTranslatedWidgetsDefaultSettings.Report %'),
      previousPeriodReportsPercent: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Previous Period'
      ),
      currentPeriodReportsPercent: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Current Period'
      ),
      noDataLabel: noDataLabel,
      defaultTopicsSource: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Default Topics Source'
      ),
    },
    ReportsByOrganisationsDefaultSettings: {
      title: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Reports by organisation'
      ),
      axisX: t('widgets.useTranslatedWidgetsDefaultSettings.organisation'),
      axisY: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Reports by organisation'
      ),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Additional information about the Reports by Organisations widget'
      ),
    },
    ReportsByTypesDefaultSettings: {
      title: t('widgets.useTranslatedWidgetsDefaultSettings.Reports by type'),
      axisY: t('widgets.useTranslatedWidgetsDefaultSettings.Reports by type'),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Additional information about the Reports by Types widget'
      ),
      itemsLabel: t('widgets.useTranslatedWidgetsDefaultSettings.incidents'),
    },
    AssociatedReportsCostDefaultSettings: {
      title: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Claim Costs Over Time'
      ),
      currency: '£',
      totalItemsLabel: t(
        'widgets.useTranslatedWidgetsDefaultSettings.No. claims'
      ),
      axisY: t('widgets.useTranslatedWidgetsDefaultSettings.GBP COST'),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Additional information about the Associated Reports Costs widget'
      ),
    },
    MapWidgetDefaultSettings: {
      title: t('widgets.useTranslatedWidgetsDefaultSettings.Reports Locations'),
      typesLabel: t('widgets.useTranslatedWidgetsDefaultSettings.Report'),
      reportedTooltip: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Reported'
      ),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Additional information about the Reports Locations widget'
      ),
    },
    RootCauseBreakdownsDefaultSettings: {
      title: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Root Cause Breakdowns'
      ),
      axisY: t(
        'widgets.useTranslatedWidgetsDefaultSettings.ROOT CAUSE DISTRIBUTION'
      ),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Additional information about Root Cause Breakdowns widget'
      ),
      noDataLabel: noDataLabel,
      organisationsLabel: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Organisation'
      ),
      typesLabel: t('widgets.useTranslatedWidgetsDefaultSettings.Root Cause'),
    },
    PriorityRootCauseClustersDefaultSettings: {
      title: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Priority Root Cause clusters'
      ),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Additional information about the Priority Root Cause clusters widget'
      ),
      rootCauseType: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Root Cause Type'
      ),
      reportsPercent: t('widgets.useTranslatedWidgetsDefaultSettings.Report %'),
      previousPeriodReportsPercent: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Previous Period'
      ),
      currentPeriodReportsPercent: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Current Period'
      ),
      noDataLabel: noDataLabel,
    },
    PreventiveActionsGuidanceDefaultSettings: {
      title: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Preventive Actions Guidance'
      ),
      subtitle: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Recommended preventive actions to reduce risk from priority Root Causes'
      ),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Additional information about Preventive Actions Guidance widget'
      ),
      noDataLabel: noDataLabel,
      rootCauseType: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Root Cause Type'
      ),
      shortTermRecommendedActivity: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Short Term Recommended Activity'
      ),
      longTermRecommendedActivity: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Long Term Recommended Activity'
      ),
      objectiveLabel: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Objective'
      ),
    },
    RootCauseToTopicCorrelationsDefaultSettings: {
      title: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Root Cause & Topic Correlations'
      ),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Additional information about the Root Cause to Topic correlations widget'
      ),
      rootCauseType: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Root Cause'
      ),
      topic: t('widgets.useTranslatedWidgetsDefaultSettings.Root Cause Topics'),
      noDataLabel: noDataLabel,
    },
    PriorityHazardClustersDefaultSettings: {
      title: t('widgets.useTranslatedWidgetsDefaultSettings.Priority Hazards'),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Additional information about the Priority Hazards widget'
      ),
      hazard: t('widgets.useTranslatedWidgetsDefaultSettings.Hazard Type'),
      reportsPercent: t('widgets.useTranslatedWidgetsDefaultSettings.Report %'),
      previousPeriodReportsPercent: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Previous Period'
      ),
      currentPeriodReportsPercent: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Current Period'
      ),
      noDataLabel: noDataLabel,
    },
    LifeSavingRulesDefaultSettings: {
      title: t('widgets.useTranslatedWidgetsDefaultSettings.Life Saving Rules'),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Additional information about the Life Saving Rules widget'
      ),
      axisY: t('widgets.useTranslatedWidgetsDefaultSettings.Total reported'),
      noDataLabel: noDataLabel,
    },
    ReportsWordcloudDefaultSettings: {
      title: t('widgets.useTranslatedWidgetsDefaultSettings.Wordcloud'),
      info: t(
        'widgets.useTranslatedWidgetsDefaultSettings.Prominent keywords that appeared across all specified reports'
      ),
    },
  };
};

export type ReportsByDatesDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['ReportsByDatesDefaultSettings'];
export type ReportAveragesByClassificationDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['ReportAveragesByClassificationDefaultSettings'];
export type ReportHistoryDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['ReportHistoryDefaultSettings'];
export type ReportTableDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['ReportTableDefaultSettings'];
export type AssociatedReportTableDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['AssociatedReportTableDefaultSettings'];
export type TopicTrendsDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['TopicTrendsDefaultSettings'];
export type ReportsByOrganisationsDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['ReportsByOrganisationsDefaultSettings'];
export type ReportsByTypesDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['ReportsByTypesDefaultSettings'];
export type AssociatedReportsCostDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['AssociatedReportsCostDefaultSettings'];
export type MapWidgetDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['MapWidgetDefaultSettings'];
export type RootCauseBreakdownsDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['RootCauseBreakdownsDefaultSettings'];
export type PriorityRootCauseClustersDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['PriorityRootCauseClustersDefaultSettings'];
export type PreventiveActionsGuidanceDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['PreventiveActionsGuidanceDefaultSettings'];
export type RootCauseToTopicCorrelationsDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['RootCauseToTopicCorrelationsDefaultSettings'];
export type PriorityHazardClustersDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['PriorityHazardClustersDefaultSettings'];
export type LifeSavingRulesDefaultSettingsType = ReturnType<
  typeof useWidgetTranslations
>['LifeSavingRulesDefaultSettings'];

export type WidgetSettings =
  | ReportsByDatesDefaultSettingsType
  | ReportAveragesByClassificationDefaultSettingsType
  | ReportHistoryDefaultSettingsType
  | ReportTableDefaultSettingsType
  | AssociatedReportTableDefaultSettingsType
  | TopicTrendsDefaultSettingsType
  | ReportsByOrganisationsDefaultSettingsType
  | ReportsByTypesDefaultSettingsType
  | AssociatedReportsCostDefaultSettingsType
  | MapWidgetDefaultSettingsType
  | RootCauseBreakdownsDefaultSettingsType
  | PriorityRootCauseClustersDefaultSettingsType
  | PreventiveActionsGuidanceDefaultSettingsType
  | RootCauseToTopicCorrelationsDefaultSettingsType
  | PriorityHazardClustersDefaultSettingsType
  | LifeSavingRulesDefaultSettingsType;
