import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('selectedLanguage', lng);
  };

  return (
    <div>
      <Button onClick={() => changeLanguage('en')}>English</Button>
      <Button onClick={() => changeLanguage('ko')}>Korean</Button>

      {/* these buttons don't exist yet nor do we have the json files to support them, just allows for expansion to other languages if requested  */}

      {/* <Button onClick={() => changeLanguage('es')}>Spanish</Button> */}
      {/*<Button onClick={() => changeLanguage('fr')}>French</Button> */}
    </div>
  );
};

export default LanguageSwitcher;
