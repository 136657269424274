import ApiRoutes from './ApiRoutes';
import API from './ApiInstance';
import TimeServiceProvider from './TimeService';
import moment from 'moment';
import { isObjectEmpty } from '../helpers/data-helper';
import { appendObject } from '../helpers/form-data-helper';
import { PossibleReportWidgetDataDto } from '../api/originalMappings';
import { WidgetCodeType } from '../components/widgets/widgetCodes';
import { PaginationDtoOfReportDataDto, ReportsWidgetDataRequest } from '../api/apiDtos';
import { FilterTypes, GenerateFilterParams } from '../signals/filterService';

const timeService = TimeServiceProvider();

export const getReportWidgetData = (widgetCode: WidgetCodeType) => {
  const [dateFrom, dateTo] = timeService.domain;
  const prevDomainStart = timeService.getPrevDomain()[0];
  const filterParams = GenerateFilterParams(FilterTypes.report);

  const requestData: ReportsWidgetDataRequest = {
    dateFrom: moment(dateFrom).toJSON(),
    dateTo: moment(dateTo).toJSON(),
    dateFromExtended: prevDomainStart.toJSON(),
    widgetCode: widgetCode,
    filters: isObjectEmpty(filterParams) ? {} : filterParams,
  };

  return API.post<ReportsWidgetDataRequest, PossibleReportWidgetDataDto>(ApiRoutes.reportsWidgetData, requestData);
};

export const getAssociatedReportWidgetData = (widgetCode: string | Blob) => {
  const [dateFrom, dateTo] = timeService.domain;
  const filters = GenerateFilterParams(
    FilterTypes.associatedReport
  );

  const formData = new FormData();
  formData.append('dateFrom', moment(dateFrom).toJSON());
  formData.append('dateTo', moment(dateTo).toJSON());
  formData.append('widgetCode', widgetCode);

  if (isObjectEmpty(filters)) {
    formData.append('filters', {}.toString());
  } else {
    appendObject(formData, 'filters', filters);
  }

  return API.post(ApiRoutes.associatedReportsWidgetData, formData);
};

export const getReportsWithPagination = (
  page,
  rowsPerPage,
  sortBy,
  sortDesc,
  filters = {}
) => {
  const [dateFrom, dateTo] = timeService.domain;
  let reportFilters = GenerateFilterParams(FilterTypes.report);

  const formData = new FormData();
  formData.append('dateFrom', moment(dateFrom).toJSON());
  formData.append('dateTo', moment(dateTo).toJSON());
  formData.append('page', page);
  formData.append('rowsPerPage', rowsPerPage);
  formData.append('sortDesc', sortDesc);

  if (sortBy) {
    formData.append('sortBy', sortBy);
  }

  if (isObjectEmpty(reportFilters)) {
    formData.append('filters', {}.toString());
  } else {
    appendObject(formData, 'filters', reportFilters);
  }

  return API.post<any, PaginationDtoOfReportDataDto>(ApiRoutes.reportsWithPagination, formData);
};

export const getAssociatedReportsWithPagination = (
  page,
  rowsPerPage,
  sortBy,
  sortDesc
) => {
  const [dateFrom, dateTo] = timeService.domain;
  const filters = GenerateFilterParams(
    FilterTypes.associatedReport
  );

  const formData = new FormData();
  formData.append('dateFrom', moment(dateFrom).toJSON());
  formData.append('dateTo', moment(dateTo).toJSON());
  formData.append('page', page);
  formData.append('rowsPerPage', rowsPerPage);
  formData.append('sortDesc', sortDesc);

  if (sortBy) {
    formData.append('sortBy', sortBy);
  }

  if (isObjectEmpty(filters)) {
    formData.append('filters', {}.toString());
  } else {
    appendObject(formData, 'filters', filters);
  }

  return API.post(ApiRoutes.associatedReportsWithPagination, formData);
};
