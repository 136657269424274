// @ts-nocheck
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';

export async function submitData(url, data, onSuccess) {
  api.post(url, data).then(
    (response) => {
      if (response) {
        if (onSuccess) {
          onSuccess();
        }
      }
    },
    (err) => {
      console.log('error encountered: ' + err);
    }
  );
}

export async function submitWidgetsConfiguration(data, onSuccess) {
  return submitData(ApiRoutes.saveWidgetsConfiguration, data, onSuccess);
}
