import React, { useState, useContext, FC, useEffect } from 'react';
import {
  Button,
  TextField,
  makeStyles,
  Switch,
  FormControlLabel,
  Grid,
  Checkbox,
  Chip,
  DialogActions,
  Theme,
  CardContent,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  AlertPeriod,
  AlertTypeOfChange,
  NamedDto,
  UserDto,
  AlertTypeOfReport,
} from '../../api/originalMappings';

import { AlertCreateDto } from '../../api/apiDtos';
import { AlertsConfigContext } from '../../context/AlertConfigContextProvider';
import { AlertTypes } from '../../constants';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import LocalComboBox from '../combobox/local-combo-box';
import { toast } from 'react-toastify';
import { alertActionType, selectedAlert } from '../../signals/selectedAlert';
import CircularProgressCentral from '../../components/common/CircularProgressCentral';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    marginBottom: theme.spacing(2),
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmationCard: {
    maxWidth: 600,
    width: 600,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
  },
}));

interface Props {
  Type: string;
}

type AlertTypeObject = {
  id: AlertTypeOfReport;
  label: string;
};

const AlertsForm: FC<Props> = ({ Type }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { alertsConfig, setAlertsConfig, CallingAlerts } =
    useContext(AlertsConfigContext);

  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [usersOptions, setUsersOptions] = useState<UserDto[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserDto[]>([]);

  const [selectedAlertTypeOfReport, setSelectedAlertTypeOfReport] = useState<
    AlertTypeObject[]
  >([]);

  const [showConfirmPopUp, setShowConfirmPopUp] = useState<boolean>(false);

  const [minimumValue, setMinimumValue] = useState(0);
  const [maximumValue, setMaximumValue] = useState(0);
  const [percentageValue, setPercentageValue] = useState(0);

  const [alertName, setAlertName] = useState('');
  const [isActive, setIsActive] = useState(true);

  const [period, setPeriod] = useState<AlertPeriod | null>(AlertPeriod.Year);

  const [typeOfChange, setTypeOfChange] = useState<AlertTypeOfChange | null>(
    null
  );

  const [parentClassificationOption, setParentClassificationOption] = useState<
    NamedDto[]
  >([]);
  const [subClassificationOption, setSubClassificationOption] = useState<
    NamedDto[]
  >([]);
  const [level2ClassificationOption, setLevel2ClassificationOption] = useState<
    NamedDto[]
  >([]);

  const [parentClassification, setParentClassification] =
    useState<NamedDto>(null);

  const [subClassification, setSubClassification] = useState<NamedDto>(null);

  const [level2Classification, setLevel2Classification] =
    useState<NamedDto>(null);

  const typeOfChangeOptions = Object.entries(AlertTypeOfChange)
    .filter(([key, value]) => !isNaN(Number(value)))
    .map(([key, value]) => ({
      label: key,
      value: value,
    }));

  const periodOptions = Object.entries(AlertPeriod)
    .filter(([key, value]) => !isNaN(Number(value)))
    .map(([key, value]) => ({
      label: key,
      value: value,
    }));

  const alertTypeOfReportOptions = Object.entries(AlertTypeOfReport)
    .filter(([key]) => isNaN(Number(key)))
    .map(([label, id]) => ({ id: Number(id), label }));

  function getAlertTypeofReportOptionLabel(option) {
    return option.label;
  }

  const handleTypeOfChange = (
    e: any,
    newValue: { label: string; value: any }
  ) => {
    setTypeOfChange(newValue ? newValue?.value : null);
  };

  const handlePeriod = (e: any, newValue: { label: string; value: any }) => {
    setPeriod(newValue ? newValue?.value : null);
  };

  const hasCorrectMin =
    minimumValue <= maximumValue &&
    minimumValue >= 0 &&
    minimumValue <= 100 &&
    !isNaN(minimumValue)
      ? true
      : typeOfChange === AlertTypeOfChange['Percentage Change']
      ? true
      : false;

  const hasCorrectMax =
    maximumValue >= minimumValue &&
    maximumValue >= 0 &&
    maximumValue <= 100 &&
    !isNaN(maximumValue)
      ? true
      : typeOfChange === AlertTypeOfChange['Percentage Change']
      ? true
      : false;

  const minAndMaxAreEqual = maximumValue === minimumValue ? true : false;

  const hasCorrectPercentage =
    percentageValue >= 0 && percentageValue <= 100
      ? true
      : typeOfChange === AlertTypeOfChange['Range Change']
      ? true
      : false;

  const checkboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkboxCheckedIcon = <CheckBoxIcon fontSize="small" />;

  function isFormValid() {
    let validClassification = parentClassification && subClassification;

    let validatesChangeType = false;
    switch (typeOfChange) {
      case AlertTypeOfChange['Percentage Change']:
        validatesChangeType =
          !isNaN(percentageValue) &&
          percentageValue !== 0 &&
          percentageValue > 0
            ? true
            : false;
        break;
      case AlertTypeOfChange['Range Change']:
        validatesChangeType =
          hasCorrectMax && hasCorrectMin && !minAndMaxAreEqual ? true : false;
        break;
    }
    return (
      validClassification &&
      hasCorrectMax &&
      typeOfChange &&
      period &&
      alertName &&
      validatesChangeType
    );
  }

  function HandleClose(): void {
    setAlertsConfig('');
  }

  useEffect(() => {
    async function onInit() {
      await getUsers();
      if (alertsConfig === AlertTypes.create) {
        await getClassificationById(null, 'parent');
      }
      await getInitialEditClassifications();
    }
    onInit();
  }, []);

  useEffect(
    function settingSelectedAlertUsers() {
      if (
        alertsConfig === AlertTypes.edit ||
        (alertsConfig === AlertTypes.delete && usersOptions.length > 0)
      ) {
        const selectedUserIds = [];
        selectedAlert.value.alertUsers.forEach((user) => {
          selectedUserIds.push(user?.userId);
        });
        const foundSelectedUsers = usersOptions.filter((user) =>
          selectedUserIds.includes(user.id)
        );
        setSelectedUsers(foundSelectedUsers);
      }
    },
    [usersOptions]
  );

  //calling new classification with every click
  async function getClassificationById(Id: string, type: string) {
    const result = await api.get<string, NamedDto[]>(
      `${ApiRoutes.getSubClassification}/${Id ?? ''}`
    );
    switch (type) {
      case 'parent':
        setParentClassificationOption(result);
        break;
      case 'sub':
        setSubClassificationOption(result);
        break;
      case 'level2':
        setLevel2ClassificationOption(result);
        break;
    }
  }

  //setting edit states on selected Alert Model
  async function getInitialEditClassifications() {
    if (
      alertsConfig === AlertTypes.edit ||
      alertsConfig === AlertTypes.delete
    ) {
      const result = await api.get<string, NamedDto[]>(
        `${ApiRoutes.getSubClassification}/${''}`
      );
      const subClassificationResult = await api.get<string, NamedDto[]>(
        `${ApiRoutes.getSubClassification}/${
          selectedAlert.value.parentClassificationId ?? ''
        }`
      );
      const level2ClassificationResult = await api.get<string, NamedDto[]>(
        `${ApiRoutes.getSubClassification}/${
          selectedAlert.value.subClassificationId ?? ''
        }`
      );
      const selectedAlertTypeOfReportEnums = [];
      selectedAlert.value.alertReportTypes.forEach((selectedReport) => {
        selectedAlertTypeOfReportEnums.push(selectedReport.typeOfReport);
      });

      const foundSelectedAlertReportTypes = alertTypeOfReportOptions.filter(
        (e) => selectedAlertTypeOfReportEnums.includes(e.id)
      );
      setSelectedAlertTypeOfReport(foundSelectedAlertReportTypes);

      setAlertName(selectedAlert.value.name);
      setTypeOfChange(selectedAlert.value.typeOfChange);
      if (
        selectedAlert.value.typeOfChange ===
        AlertTypeOfChange['Percentage Change']
      ) {
        setMaximumValue(0);
        setMinimumValue(0);
        setPercentageValue(selectedAlert.value.percentageValue);
      } else if (
        selectedAlert.value.typeOfChange === AlertTypeOfChange['Range Change']
      ) {
        setMaximumValue(selectedAlert.value.maximumValue);
        setMinimumValue(selectedAlert.value.minimumValue);
        setPercentageValue(0);
      }
      setPeriod(selectedAlert.value.period);
      setIsActive(selectedAlert.value.isActive);
      setParentClassificationOption(result);
      setParentClassification({
        id: selectedAlert.value.parentClassificationId,
        name: selectedAlert.value.parentClassification.label,
      });
      setSubClassificationOption(subClassificationResult);
      setSubClassification({
        id: selectedAlert.value.subClassificationId,
        name: selectedAlert.value.subClassification.label,
      });
      setLevel2ClassificationOption(level2ClassificationResult);
      setLevel2Classification({
        id: selectedAlert.value.level2ClassificationId,
        name: selectedAlert.value.level2Classification?.label,
      });
      setIsLoading(false);
    }
  }

  async function getUsers() {
    try {
      const result: UserDto[] = await api.get<string, UserDto[]>(
        ApiRoutes.getAllUsersByOrg
      );
      if (result) {
        setUsersOptions(result);
        if (alertsConfig === AlertTypes.create) {
          setIsLoading(false);
        }
      } else {
        setUsersOptions([]);
      }
    } catch (error) {
      console.error('Failed to fetch classification by ID:', error);
    }
  }

  async function EditAlert() {
    alertActionType.value = 'edit';
    const selectedUserIds = [];
    selectedUsers.forEach((user) => {
      selectedUserIds.push(user?.id);
    });
    const selectedAlertTypeEnums: number[] = [];
    selectedAlertTypeOfReport.forEach((reportType) => {
      const id = (reportType as any).id as number;
      selectedAlertTypeEnums.push(id);
    });

    let max;
    let min;
    let percentage;
    if (typeOfChange === AlertTypeOfChange['Percentage Change']) {
      max = 0;
      min = 0;
      percentage = percentageValue;
    } else if (typeOfChange === AlertTypeOfChange['Range Change']) {
      max = maximumValue;
      min = minimumValue;
      percentage = 0;
    }

    const alert: AlertCreateDto = {
      alertUsersIds: selectedUserIds,
      isActive: isActive,
      parentClassificationId: parentClassification?.id,
      subClassificationId: subClassification?.id,
      level2ClassificationId: level2Classification?.id,
      maximumValue: max,
      minimumValue: min,
      percentageValue: percentage,
      name: alertName,
      period: period,
      typeOfChange: typeOfChange,
      alertTypeOfReports: selectedAlertTypeEnums,
      subDomain: window.location.hostname.split('.')[0],
    };
    api
      .put<AlertCreateDto, Array<NamedDto>>(
        `${ApiRoutes.updateAlert}/${selectedAlert.value.id}`,
        alert
      )
      .then(() => {
        CallingAlerts();
        toast.success(t('Alerts.AlertsForm.Alert Updated Successfully'));
        selectedAlert.value = null;
        setAlertsConfig('');
      });
  }

  async function DeleteAlert() {
    alertActionType.value = 'delete';
    await api
      .del(`${ApiRoutes.deleteAlert}/${selectedAlert.value.id}`)
      .then(() => {
        CallingAlerts();
        toast.success(t('Alerts.AlertsForm.Alert Successfully Deleted'));
        selectedAlert.value = null;
        setAlertsConfig('');
      });
  }

  function CreateAlert(): void {
    alertActionType.value = 'create';
    const selectedUserIds = [];
    selectedUsers.forEach((user) => {
      selectedUserIds.push(user?.id);
    });

    const selectedAlertTypeEnums: number[] = [];
    selectedAlertTypeOfReport.forEach((reportType) => {
      const id = (reportType as any).id as number;
      selectedAlertTypeEnums.push(id);
    });

    const alert: AlertCreateDto = {
      alertUsersIds: selectedUserIds,
      isActive: isActive,
      parentClassificationId: parentClassification?.id,
      subClassificationId: subClassification?.id,
      level2ClassificationId: level2Classification?.id,
      maximumValue: maximumValue,
      minimumValue: minimumValue,
      percentageValue: percentageValue,
      name: alertName,
      period: period,
      typeOfChange: typeOfChange,
      alertTypeOfReports: selectedAlertTypeEnums,
      subDomain: window.location.hostname.split('.')[0],
    };
    api
      .post<AlertCreateDto, Array<NamedDto>>(ApiRoutes.createAlert, alert)
      .then(() => {
        CallingAlerts();
        toast.success(t('Alerts.AlertsForm.Alert Created Successfully'));
        selectedAlert.value = null;
        setAlertsConfig('');
      });
  }

  const handlePercentageChange = (evt) => {
    const input = evt.target.value.replace('%', '');
    const value = parseFloat(input);
    if (!isNaN(value)) {
      setPercentageValue(value);
    } else {
      setPercentageValue(parseFloat(''));
    }
  };

  function getOptionLabel(option) {
    if (option) {
      return option?.email;
    } else {
      return `${option?.email}`;
    }
  }

  function RenderForm() {
    return (
      <>
        {!isLoading ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={alertsConfig === AlertTypes.delete}
                  value={alertName}
                  variant="outlined"
                  label={t('Alerts.AlertsForm.Name')}
                  type="string"
                  onChange={(evt) => {
                    const value = evt?.target.value;
                    setAlertName(value);
                  }}
                  fullWidth
                  className={classes.field}
                />
                {parentClassificationOption.length > 0 && (
                  <LocalComboBox
                    disabled={alertsConfig === AlertTypes.delete}
                    disableClearable
                    options={parentClassificationOption}
                    value={parentClassification}
                    getOptionLabel={(option) =>
                      option && option?.name
                        ? option?.name
                        : t('Alerts.AlertsForm.Select Option')
                    }
                    getOptionSelected={(option, value) =>
                      option?.id === value?.id
                    }
                    onChange={(e, value) => {
                      if (value) {
                        setSubClassificationOption([]);
                        setLevel2ClassificationOption([]);
                        setSubClassification(null);
                        setLevel2Classification(null);
                        getClassificationById(value.id, 'sub');
                        setParentClassification({
                          id: value.id,
                          name: value.name,
                        });
                      }
                    }}
                    fullWidth
                    customLabel={t('Alerts.AlertsForm.Parent Classification')}
                    className={classes.field}
                  />
                )}

                {subClassificationOption?.length > 0 ||
                (subClassificationOption?.length > 0 &&
                  (alertsConfig === AlertTypes.edit ||
                    alertsConfig === AlertTypes.delete)) ? (
                  <LocalComboBox
                    disabled={alertsConfig === AlertTypes.delete}
                    disableClearable
                    options={subClassificationOption}
                    value={subClassification}
                    getOptionLabel={(option) =>
                      option && option?.name
                        ? option?.name
                        : t('Alerts.AlertsForm.Select Option')
                    }
                    getOptionSelected={(option, value) =>
                      option?.id === value?.id
                    }
                    onChange={(e, value) => {
                      if (value) {
                        setSubClassification(null);
                        setLevel2Classification(null);
                        setLevel2ClassificationOption([]);
                        getClassificationById(value.id, 'level2');
                        setSubClassification({
                          id: value.id,
                          name: value.name,
                        });
                      }
                    }}
                    fullWidth
                    customLabel={t('Alerts.AlertsForm.Classification')}
                    className={classes.field}
                  />
                ) : (
                  <></>
                )}
                {level2ClassificationOption?.length > 0 ||
                (level2ClassificationOption?.length > 0 &&
                  (alertsConfig === AlertTypes.edit ||
                    alertsConfig === AlertTypes.delete)) ? (
                  <LocalComboBox
                    disabled={alertsConfig === AlertTypes.delete}
                    disableClearable
                    options={level2ClassificationOption}
                    value={level2Classification}
                    getOptionLabel={(option) =>
                      option && option?.name
                        ? option?.name
                        : t('Alerts.AlertsForm.Select Option')
                    }
                    getOptionSelected={(option, value) =>
                      option?.id === value?.id
                    }
                    onChange={(e, value) => {
                      if (value) {
                        setLevel2Classification({
                          id: value.id,
                          name: value.name,
                        });
                      }
                    }}
                    fullWidth
                    customLabel={t('Alerts.AlertsForm.Classification')}
                    className={classes.field}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12} md={6} container spacing={2}>
                <Grid item xs={12}>
                  <LocalComboBox
                    disabled={alertsConfig === AlertTypes.delete}
                    disableClearable
                    options={typeOfChangeOptions}
                    value={
                      typeOfChangeOptions.find(
                        (option) => option?.value === typeOfChange
                      ) ?? null
                    }
                    getOptionLabel={(option) => (option ? option?.label : '')}
                    onChange={handleTypeOfChange}
                    fullWidth
                    customLabel={t('Alerts.AlertsForm.Type of Change')}
                    className={classes.field}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('Alerts.AlertsForm.Type of Change')}
                        placeholder={t('Alerts.AlertsForm.Select Option')}
                      />
                    )}
                  />

                  {typeOfChange === AlertTypeOfChange['Percentage Change'] ? (
                    <TextField
                      disabled={alertsConfig === AlertTypes.delete}
                      value={
                        isNaN(percentageValue) ? '' : `${percentageValue}%`
                      }
                      variant="outlined"
                      label={t('Alerts.AlertsForm.+/- % Change')}
                      type="text"
                      onChange={handlePercentageChange}
                      fullWidth
                      className={classes.field}
                      error={!hasCorrectPercentage}
                      helperText={
                        !hasCorrectPercentage
                          ? t('Alerts.AlertsForm.Value must be between 0-100')
                          : ''
                      }
                    />
                  ) : typeOfChange === AlertTypeOfChange['Range Change'] ? (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          disabled={alertsConfig === AlertTypes.delete}
                          value={minimumValue}
                          variant="outlined"
                          label={t('Alerts.AlertsForm.Minimum')}
                          type="number"
                          onChange={(evt) => {
                            const value = parseFloat(evt?.target.value);
                            setMinimumValue(value);
                          }}
                          fullWidth
                          className={classes.field}
                          error={!hasCorrectMax || minAndMaxAreEqual}
                          helperText={
                            isNaN(maximumValue)
                              ? ''
                              : isNaN(minimumValue)
                              ? t('Alerts.AlertsForm.Please input value')
                              : !hasCorrectMin
                              ? t('Alerts.AlertsForm.ValueSmallerThanMax', {
                                  maxVal: maximumValue,
                                })
                              : minAndMaxAreEqual
                              ? t(
                                  'Alerts.AlertsForm.min and max cannot be the same'
                                )
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          disabled={alertsConfig === AlertTypes.delete}
                          value={maximumValue}
                          variant="outlined"
                          label={t('Alerts.AlertsForm.Maximum')}
                          type="number"
                          onChange={(evt) => {
                            const value = parseFloat(evt?.target.value);
                            setMaximumValue(value);
                          }}
                          fullWidth
                          className={classes.field}
                          error={!hasCorrectMax || minAndMaxAreEqual}
                          helperText={
                            isNaN(minimumValue)
                              ? ''
                              : isNaN(maximumValue)
                              ? t('Alerts.AlertsForm.Please input value')
                              : !hasCorrectMax
                              ? t('Alerts.AlertsForm.ValueBiggerThanMin', {
                                  minVal: minimumValue,
                                })
                              : minAndMaxAreEqual
                              ? t(
                                  'Alerts.AlertsForm.min and max cannot be the same'
                                )
                              : ''
                          }
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Autocomplete
                    disabled={alertsConfig === AlertTypes.delete}
                    disableCloseOnSelect
                    multiple
                    options={alertTypeOfReportOptions}
                    value={selectedAlertTypeOfReport}
                    getOptionSelected={(option, value) =>
                      option?.id === value?.id
                    }
                    getOptionLabel={getAlertTypeofReportOptionLabel}
                    renderTags={(value, alertTypes) =>
                      value.map((alertType, index) => (
                        <Chip
                          key={alertType.id}
                          label={alertType.label}
                          {...alertTypes({ index })}
                        />
                      ))
                    }
                    limitTags={2}
                    style={{ display: 'flex', margin: '0 0 15px 0' }}
                    renderInput={(params) => (
                      <TextField
                        disabled={alertsConfig === AlertTypes.delete}
                        {...params}
                        label={t('Alerts.AlertsForm.Select Report Types')}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    renderOption={(option, { selected }) => (
                      <>
                        <Checkbox
                          disabled={alertsConfig === AlertTypes.delete}
                          color="primary"
                          icon={checkboxIcon}
                          checkedIcon={checkboxCheckedIcon}
                          style={{ marginRight: 2 }}
                          checked={selected}
                        />
                        {getAlertTypeofReportOptionLabel(option)}
                      </>
                    )}
                    onChange={(event, newValue) => {
                      setSelectedAlertTypeOfReport(newValue);
                    }}
                  />
                  <LocalComboBox
                    disabled={alertsConfig === AlertTypes.delete}
                    disableClearable
                    options={periodOptions}
                    value={
                      periodOptions.find(
                        (option) => option?.value === period
                      ) ?? null
                    }
                    getOptionLabel={(option) => (option ? option?.label : '')}
                    onChange={handlePeriod}
                    fullWidth
                    customLabel={t('Alerts.AlertsForm.Periods')}
                    className={classes.field}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('Alerts.AlertsForm.Periods')}
                        placeholder={t('Alerts.AlertsForm.Select Option')}
                      />
                    )}
                  />
                  {usersOptions?.length > 0 && (
                    <Autocomplete
                      disabled={alertsConfig === AlertTypes.delete}
                      disableCloseOnSelect
                      multiple
                      options={usersOptions.sort((a, b) =>
                        a.email.localeCompare(b.email)
                      )}
                      value={selectedUsers}
                      getOptionSelected={(option, value) =>
                        option?.id === value?.id
                      }
                      getOptionLabel={getOptionLabel}
                      renderTags={(value, users) =>
                        value.map((user, index) => (
                          <Chip
                            key={user.id}
                            label={user.email}
                            {...users({ index })}
                          />
                        ))
                      }
                      limitTags={2}
                      style={{ display: 'flex', margin: '10px 0 10px 0' }}
                      renderInput={(params) => (
                        <TextField
                          disabled={alertsConfig === AlertTypes.delete}
                          {...params}
                          label={t('Alerts.AlertsForm.Set Users')}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      renderOption={(option, { selected }) => (
                        <>
                          <Checkbox
                            disabled={alertsConfig === AlertTypes.delete}
                            color="primary"
                            icon={checkboxIcon}
                            checkedIcon={checkboxCheckedIcon}
                            style={{ marginRight: 2 }}
                            checked={selected}
                          />
                          {getOptionLabel(option)}
                        </>
                      )}
                      onChange={(event, newValue) => {
                        setSelectedUsers(newValue);
                      }}
                    />
                  )}
                  <FormControlLabel
                    disabled={alertsConfig === AlertTypes.delete}
                    value="filterByCluster"
                    control={
                      <Switch
                        color="primary"
                        checked={isActive}
                        onChange={() => setIsActive(!isActive)}
                      />
                    }
                    label={t('Alerts.AlertsForm.Set Users')}
                    labelPlacement="start"
                    className={classes.field}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <CircularProgressCentral />
        )}
      </>
    );
  }
  function RenderConfirmationPopUp() {
    return (
      <div className={classes.backdrop}>
        <div className={classes.confirmationCard}>
          <CardContent>
            {t('Alerts.AlertsForm.Are you sure you want to delete')}{' '}
            <strong style={{ fontWeight: 'bold' }}>
              {selectedAlert.value?.name}
            </strong>
            ?
          </CardContent>
          <DialogActions>
            <Button onClick={DeleteAlert} color="primary" variant="outlined">
              {t('Global.Confirm')}
            </Button>
            <Button
              onClick={() => setShowConfirmPopUp(false)}
              color="primary"
              variant="contained"
            >
              {t('Global.Cancel')}
            </Button>
          </DialogActions>
        </div>
      </div>
    );
  }
  return (
    <>
      {showConfirmPopUp && RenderConfirmationPopUp()}
      {Type === AlertTypes.create && RenderForm()}
      {Type === AlertTypes.edit && RenderForm()}
      {Type === AlertTypes.delete && RenderForm()}
      {Type === AlertTypes.create && (
        <DialogActions>
          <Button
            disabled={!isFormValid()}
            onClick={CreateAlert}
            color="primary"
            variant="outlined"
          >
            {t('Alerts.AlertsForm.Create Alert')}
          </Button>
          <Button onClick={HandleClose} color="primary" variant="contained">
            {t('Global.Cancel')}
          </Button>
        </DialogActions>
      )}
      {Type === AlertTypes.edit && (
        <DialogActions>
          <Button
            disabled={!isFormValid()}
            onClick={EditAlert}
            color="primary"
            variant="outlined"
          >
            {t('Global.Save')}
          </Button>
          <Button onClick={HandleClose} color="primary" variant="contained">
            {t('Global.Cancel')}
          </Button>
        </DialogActions>
      )}
      {Type === AlertTypes.delete && (
        <DialogActions>
          <Button
            onClick={() => setShowConfirmPopUp(true)}
            color="primary"
            variant="outlined"
          >
            {t('Global.Delete')}
          </Button>
          <Button onClick={HandleClose} color="primary" variant="contained">
            {t('Global.Cancel')}
          </Button>
        </DialogActions>
      )}
    </>
  );
};
export default AlertsForm;
