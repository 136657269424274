import { useTranslation } from 'react-i18next';

const useValidationError = () => {
  const { t } = useTranslation();

  const VALIDATION_ERROR_TEXT = {
    Username: t(
      'services.authentication.useValidationService.Username can contain letters (a-z), numbers (0-9), hyphens (-), underscores (_) and periods (.)'
    ),
  };

  return VALIDATION_ERROR_TEXT;
};

export default useValidationError;
