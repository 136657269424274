// @ts-nocheck
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ColumnChart from '../charts/ColumnChart';
import { widgetPropOptions } from '../widgets-manager-page/widgets-management-constants';
import FilteredReportsViewer from './FilteredReportsViewer';

function ReportsByTypeCard({ data, settings, brandId }) {
  const [selectedItem, setSelectedItem] = useState(null);

  const { axisY, organisationOrFacility } = settings;

  const source =
    organisationOrFacility === widgetPropOptions.organisation
      ? data.organisationChartData
      : data.facilityChartData;

  const brandChartData = source.find((o) => o.id === brandId);
  if (!brandChartData) {
    return null;
  }

  const chartData = brandChartData.reportTypes.map((rt) => ({
    id: rt.id,
    [rt.name]: rt.count,
    name: rt.name,
  }));

  const createReportFilters = () => {
    if (!selectedItem) {
      return {};
    }
    const filterTag =
      organisationOrFacility === widgetPropOptions.organisation
        ? FilterTags.report.organisation
        : FilterTags.report.facility;
    return {
      [FilterTags.report.reportType]: selectedItem.name,
      [filterTag]: brandId,
    };
  };
  const reportFilters = createReportFilters();

  return (
    <>
      <ColumnChart
        chartShape={data.chartShape}
        chartData={chartData}
        header={axisY}
        label={brandChartData.name}
        onSelectItem={(item) => setSelectedItem(item)}
      />
      {selectedItem && (
        <FilteredReportsViewer
          open={true}
          handleClose={() => setSelectedItem(null)}
          filters={reportFilters}
          title={selectedItem.name}
        />
      )}
    </>
  );
}

ReportsByTypeCard.propTypes = {
  data: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  brandId: PropTypes.string.isRequired,
};

export default ReportsByTypeCard;
