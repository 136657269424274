// @ts-nocheck
import React from 'react';
import { Grid, Typography, IconButton, withStyles } from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import PropTypes from 'prop-types';
import { formatDateCommon } from '../../../helpers/date-helper';

const styles = {
  title: {
    overflowWrap: 'break-word',
  },
  description: {
    marginLeft: '1em',
    overflowWrap: 'break-word',
  },
  actionRow: {
    marginTop: '0.5em',
    border: '#ccc solid 1px',
    borderRadius: 5,
    alignItems: 'center',
    paddingLeft: '1em',
  },
  right: {
    float: 'right',
  },
};

const ActionViewer = ({ action, onRemove, classes }) => {
  const { date, title, description } = action;
  return (
    <Grid container className={classes.actionRow}>
      <Grid item xs={2}>
        <Typography variant="body1">{formatDateCommon(date)}</Typography>
      </Grid>
      <Grid item xs={3} className={classes.title}>
        <Typography variant="body1">{title}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" className={classes.description}>
          {description}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          color="primary"
          className={classes.right}
          onClick={onRemove}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

ActionViewer.propTypes = {
  action: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActionViewer);
