// @ts-nocheck
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { trackAction } from '../../services/analytics/AnalyticsService';

const styles = (theme) => ({
  content: {
    paddingTop: 50,
    paddingBottom: 50,
  },
  closeButton: {
    position: 'absolute !important',
    right: theme.spacing(1),
    top: 0,
    color: theme.palette.grey[500],
  },
  title: {
    background: '#000',
    color: '#fff',
    padding: '12.5px 24px',
  },
});

class DashboardModal extends Component {
  componentDidUpdate(prevProps) {
    const { open, title } = this.props;
    if (!prevProps.open && open) {
      trackAction(title, 'modal');
    }
  }

  render() {
    const { classes, open, children, title, handleClose, width } = this.props;
    const maxWidth = width || 'xl';
    return (
      <Dialog fullWidth maxWidth={maxWidth} onClose={handleClose} open={open}>
        <DialogTitle classes={{ root: classes.title }} disableTypography>
          <Typography variant="h6">{title}</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers classes={{ root: classes.content }}>
          {children}
        </DialogContent>
      </Dialog>
    );
  }
}

DashboardModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.any.isRequired,
  width: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default withStyles(styles)(DashboardModal);
