// @ts-nocheck
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton, Typography, withStyles } from '@material-ui/core';
import ConfirmDialog from '../dialog/ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const styles = {
  disabledIcon: {
    padding: 12,
    margin: '0 5px',
  },
  icon: {
    margin: '0 5px',
  },
};

const DeleteFacilityDialog = ({ facility, onDelete, classes }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const deleteFacility = () => {
    const url = `${ApiRoutes.deleteFacility}?id=${facility.id}`;
    api.del(url).then(() => {
      toast.success(t('facilities.DeleteFacilityDialog.Facility deleted'));
      onDelete();
      setOpen(false);
    });
  };

  const DELETION_ALLOWED_TOOLTIP = t(
    'facilities.DeleteFacilityDialog.DELETION_ALLOWED_TOOLTIP'
  );
  const DELETION_NOT_ALLOWED_TOOLTIP = t(
    'facilities.DeleteFacilityDialog.DELETION_NOT_ALLOWED_TOOLTIP'
  );

  return (
    <>
      <Tooltip
        title={
          facility.hasDependentReports
            ? DELETION_NOT_ALLOWED_TOOLTIP
            : DELETION_ALLOWED_TOOLTIP
        }
      >
        {facility.hasDependentReports ? (
          <DeleteIcon color="disabled" className={classes.disabledIcon} />
        ) : (
          <IconButton
            color="primary"
            className={classes.icon}
            onClick={() => setOpen(true)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Tooltip>
      <ConfirmDialog
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={deleteFacility}
        title={t('facilities.DeleteFacilityDialog.DELETION_ALLOWED_TOOLTIP')}
        confirm="Delete"
      >
        <Typography variant="body1">
          {t('facilities.DeleteFacilityDialog.DELETION_ALLOWED_TOOLTIP', {
            facilityName: facility.name,
          })}
          <br />
          {t('facilities.DeleteFacilityDialog.Do you wish to continue?')}
        </Typography>
      </ConfirmDialog>
    </>
  );
};

DeleteFacilityDialog.propTypes = {
  facility: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeleteFacilityDialog);
