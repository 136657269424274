import React, { FC, useEffect, useState } from 'react';
import {
  withStyles,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  createStyles,
  WithStyles,
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterChipsStatic from '../filters/FilterChipsStatic';
import { trackAction } from '../../services/analytics/AnalyticsService';
import ApiRoutes from '../../services/ApiRoutes';
import API from '../../services/ApiInstance';
import { Filter, SetFilters } from '../../signals/filterService';
import { FilterConfigDto } from '../../api/apiDtos';
import { useTranslation } from 'react-i18next';

const styles = createStyles({
  root: {
    minHeight: 500,
  },
  denseCell: {
    padding: '4px 0',
  },
  button: {
    fontSize: 13,
  },
});

interface Props extends WithStyles<typeof styles> {
  searches: any;
  onDelete: Function;
}

const SavedSearchCard: FC<Props> = ({ classes, searches, onDelete }) => {
  const [filterSettings, setFilterSettings] = useState([]);
  const { t } = useTranslation();
  const loadFilterSettings = async () => {
    const response = await API.get<any, FilterConfigDto>(
      ApiRoutes.getOwnFilterConfig
    );
    const filterConfig = response;
    if (filterConfig) {
      const filterSettings = filterConfig.sections.reduce(
        (settings, section) => {
          settings.push(...section.settings);
          return settings;
        },
        []
      );
      setFilterSettings(filterSettings);
    }
  };

  const getFilterSetting = (filterTag) =>
    filterSettings.find((s) => s.typeCode === filterTag);

  const convert = () => {
    return searches.map((s) => {
      const filters: Filter[] = [];
      s.searchFilters.forEach((f) => {
        if (f.selectValue) {
          Object.keys(f.selectValue).forEach((id) => {
            const name = f.selectValue[id];
            filters.push({ name, property: f.property, value: id });
          });
        } else {
          const filterSetting = getFilterSetting(f.property);
          const name = filterSetting ? filterSetting.title : f.textValue;
          filters.push({ name, property: f.property, value: f.textValue });
        }
      });
      return { id: s.id, filters };
    });
  };

  const ApplySavedSearch = (filters: Filter[]) => {
    SetFilters(filters);
    trackAction('saved search', 'run');
  };

  useEffect(() => {
    loadFilterSettings();
  }, []);

  const convertedSearches = convert();

  return (
    <Card variant="outlined" className={classes.root}>
      <CardHeader
        title={t('dashboard.SavedSearchCard.Saved Searches')}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent>
        <Table>
          <TableBody>
            {convertedSearches.map((s: any) => (
              <TableRow key={s.id}>
                <TableCell className={classes.denseCell}>
                  {' '}
                  <FilterChipsStatic
                    onMoreClick={() => {}}
                    filters={s.filters}
                  />
                </TableCell>
                <TableCell className={classes.denseCell}>
                  <Button
                    className={classes.button}
                    onClick={() => onDelete(s)}
                  >
                    <DeleteIcon />
                  </Button>
                </TableCell>
                <TableCell className={classes.denseCell}>
                  <Button
                    className={classes.button}
                    onClick={() => ApplySavedSearch(s.filters)}
                  >
                    {t('dashboard.SavedSearchCard.Run')} <PlayArrowIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(SavedSearchCard);
