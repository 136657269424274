import React, { useState, useEffect } from 'react';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  ReferenceLine,
  Bar,
  Cell,
  ComposedChart,
  Scatter,
} from 'recharts';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';

const AssociatedReportsCostTimeseriesChart = ({ data, domain }) => {
  const [chartData, setChartData] = useState([]);
  const [axisTicks, setAxisTicks] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    updateTimeseriesData(data);
  }, [data, domain]);

  const updateTimeseriesData = (data) => {
    const sortedData = data.map((d) => d).sort((a, b) => a.time - b.time);
    const ticksArr = getTicks(sortedData);
    setChartData(sortedData);
    setAxisTicks(ticksArr);
  };

  const DataCell = (value, index) => {
    return <Cell key={`cell-${index}`} fill="#FF5722" width={1} />;
  };

  const getTicks = (data) => {
    if (!data || !data.length) return [];

    const scale = d3.scaleTime().domain(domain).range([0, 1]);
    const ticks = scale.ticks(d3.timeMonth, 1);

    return ticks.map((entry) => +entry);
  };

  const associatedReportsData =
    chartData &&
    chartData
      .filter((d) => d.value1)
      .map((d) => {
        const dCopy = Object.assign({}, d);
        dCopy.date = moment(d.date).valueOf();
        return dCopy;
      });

  const tickStyle = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
  };

  return chartData ? (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart data={associatedReportsData}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <Tooltip
          labelFormatter={(label) => `${moment(label).format('DD MMM YYYY')}`}
          formatter={(value) => [
            `£${value.toLocaleString()}`,
            t('charts.AssociatedReportsCostTimeSeriesChart.Settlement Amount'),
          ]}
          cursor={{ stroke: '#7f8c8d', strokeWidth: 1 }}
        />
        <XAxis
          dataKey="date"
          type="number"
          scale="time"
          domain={['auto', 'auto']}
          tickFormatter={(timestamp) => moment(timestamp).format('MMM YYYY')}
          tick={tickStyle}
          allowDataOverflow
          ticks={axisTicks}
        />
        <YAxis dataKey="value1" allowDataOverflow tick={tickStyle} />
        <ReferenceLine y={0} stroke="#000" />
        <Bar
          dataKey="value1"
          minPointSize={3}
          background={false}
          isAnimationActive={false}
          maxBarSize={1}
        >
          {chartData.map((value, index) => DataCell(value, index))}
        </Bar>
        <Scatter data={associatedReportsData} isAnimationActive={false}>
          {chartData.map((point, index) => DataCell(point, index))}
        </Scatter>
      </ComposedChart>
    </ResponsiveContainer>
  ) : null;
};

AssociatedReportsCostTimeseriesChart.propTypes = {
  data: PropTypes.array,
  domain: PropTypes.array.isRequired,
};

export default AssociatedReportsCostTimeseriesChart;
