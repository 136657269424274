import {
  ENTITY_TYPES,
  UNMAPPED_ITEMS_POOL,
  EXTRA_ITEMS_POOL,
} from './constants';
import { useTranslation } from 'react-i18next';

export const useTranslatedMapperData = () => {
  const { t } = useTranslation();

  const MapperCards = [
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Report ID'),
      name: 'ReportId',
      requiredFor: [ENTITY_TYPES.Reports, ENTITY_TYPES.AssociatedReports],
      categories: [ENTITY_TYPES.Reports, ENTITY_TYPES.AssociatedReports],
      itemised: false,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Facility ID'),
      name: 'FacilityId',
      requiredFor: [
        ENTITY_TYPES.Reports,
        ENTITY_TYPES.Locations,
        ENTITY_TYPES.Facilities,
      ],
      categories: [
        ENTITY_TYPES.Reports,
        ENTITY_TYPES.Locations,
        ENTITY_TYPES.Facilities,
      ],
      itemised: false,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Date occurred'),
      name: 'DateOccurred',
      requiredFor: [ENTITY_TYPES.Reports],
      categories: [ENTITY_TYPES.Reports],
      itemised: false,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Report type'),
      name: 'ReportType',
      requiredFor: [],
      categories: [ENTITY_TYPES.Reports],
      itemised: true,
    },
    {
      title: t(
        'upload.upload_mapper.useTranslatedMapperData.Report description'
      ),
      name: 'Description',
      requiredFor: [ENTITY_TYPES.Reports],
      categories: [ENTITY_TYPES.Reports],
      itemised: false,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Date reported'),
      name: 'DateReported',
      requiredFor: [],
      categories: [ENTITY_TYPES.Reports],
      itemised: false,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Affected party'),
      name: 'AffectedParty',
      requiredFor: [],
      categories: [ENTITY_TYPES.Reports],
      itemised: true,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Area'),
      name: 'Area',
      requiredFor: [],
      categories: [ENTITY_TYPES.Reports],
      itemised: true,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Event type'),
      name: 'EventType',
      requiredFor: [],
      categories: [ENTITY_TYPES.Reports],
      itemised: true,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Facility name'),
      name: 'Facility',
      requiredFor: [ENTITY_TYPES.Facilities],
      categories: [ENTITY_TYPES.Facilities],
      itemised: true,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Organisation'),
      name: 'Organisation',
      requiredFor: [ENTITY_TYPES.Facilities, ENTITY_TYPES.Organisations],
      categories: [ENTITY_TYPES.Facilities, ENTITY_TYPES.Organisations],
      itemised: true,
    },
    {
      title: t(
        'upload.upload_mapper.useTranslatedMapperData.Organisation parent'
      ),
      name: 'OrganisationParent',
      requiredFor: [ENTITY_TYPES.Organisations],
      categories: [ENTITY_TYPES.Organisations],
      itemised: true,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Postcode'),
      name: 'Postcode',
      requiredFor: [],
      categories: [ENTITY_TYPES.Facilities],
      itemised: false,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Longitude'),
      name: 'Longitude',
      requiredFor: [ENTITY_TYPES.Locations],
      categories: [ENTITY_TYPES.Locations],
      itemised: false,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Latitude'),
      name: 'Latitude',
      requiredFor: [ENTITY_TYPES.Locations],
      categories: [ENTITY_TYPES.Locations],
      itemised: false,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Location date'),
      name: 'LocationDate',
      requiredFor: [ENTITY_TYPES.Locations],
      categories: [ENTITY_TYPES.Locations],
      itemised: false,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Report latitude'),
      name: 'ReportLatitude',
      requiredFor: [],
      categories: [ENTITY_TYPES.Reports],
      itemised: false,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Report longitude'),
      name: 'ReportLongitude',
      requiredFor: [],
      categories: [ENTITY_TYPES.Reports],
      itemised: false,
    },
    {
      title: t(
        'upload.upload_mapper.useTranslatedMapperData.Additional field 1'
      ),
      name: 'AdditionalField1',
      requiredFor: [],
      categories: [ENTITY_TYPES.Reports],
      itemised: false,
    },
    {
      title: t(
        'upload.upload_mapper.useTranslatedMapperData.Additional field 2'
      ),
      name: 'AdditionalField2',
      requiredFor: [],
      categories: [ENTITY_TYPES.Reports],
      itemised: false,
    },
    {
      title: t(
        'upload.upload_mapper.useTranslatedMapperData.Additional field 3'
      ),
      name: 'AdditionalField3',
      requiredFor: [],
      categories: [ENTITY_TYPES.Reports],
      itemised: false,
    },
    {
      title: t(
        'upload.upload_mapper.useTranslatedMapperData.Additional field 4'
      ),
      name: 'AdditionalField4',
      requiredFor: [],
      categories: [ENTITY_TYPES.Reports],
      itemised: false,
    },
    {
      title: t(
        'upload.upload_mapper.useTranslatedMapperData.Associated report ID'
      ),
      name: 'AssociatedReportId',
      requiredFor: [ENTITY_TYPES.AssociatedReports],
      categories: [ENTITY_TYPES.AssociatedReports],
      itemised: false,
    },
    {
      title: t(
        'upload.upload_mapper.useTranslatedMapperData.Associated report title'
      ),
      name: 'AssociatedReportTitle',
      requiredFor: [ENTITY_TYPES.AssociatedReports],
      categories: [ENTITY_TYPES.AssociatedReports],
      itemised: false,
    },
    {
      title: t(
        'upload.upload_mapper.useTranslatedMapperData.Associated report description'
      ),
      name: 'AssociatedReportDescription',
      requiredFor: [ENTITY_TYPES.AssociatedReports],
      categories: [ENTITY_TYPES.AssociatedReports],
      itemised: false,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Value 1'),
      name: 'Value1',
      requiredFor: [ENTITY_TYPES.AssociatedReports],
      categories: [ENTITY_TYPES.AssociatedReports],
      itemised: false,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Value 2'),
      name: 'Value2',
      requiredFor: [ENTITY_TYPES.AssociatedReports],
      categories: [ENTITY_TYPES.AssociatedReports],
      itemised: false,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Date of incident'),
      name: 'AssociatedReportDate',
      requiredFor: [ENTITY_TYPES.AssociatedReports],
      categories: [ENTITY_TYPES.AssociatedReports],
      itemised: false,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Value 1 Unit'),
      name: 'Value1Unit',
      requiredFor: [],
      categories: [ENTITY_TYPES.AssociatedReports],
      itemised: false,
    },
    {
      title: t('upload.upload_mapper.useTranslatedMapperData.Value 2 Unit'),
      name: 'Value2Unit',
      requiredFor: [],
      categories: [ENTITY_TYPES.AssociatedReports],
      itemised: false,
    },
  ];

  function IsCardNameValid(name) {
    return (
      MapperCards.map((i) => i.name).includes(name) ||
      name === UNMAPPED_ITEMS_POOL ||
      name === EXTRA_ITEMS_POOL
    );
  }

  return {
    MapperCards,
    IsCardNameValid,
  };
};
