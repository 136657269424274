import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';

const defaultLabels = {
  title: 'Confirm the action',
  confirm: 'Confirm',
  cancel: 'Cancel',
};

const ConfirmDialog = (props) => {
  const { children, open, title, confirm, cancel, onConfirm, onCancel } = props;
  const labels = { title, confirm, cancel };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{labels.title}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={onCancel}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          {labels.confirm}
        </Button>
        <Button color="primary" variant="outlined" onClick={onCancel} autoFocus>
          {labels.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  children: PropTypes.object,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  confirm: PropTypes.string,
  cancel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

ConfirmDialog.defaultProps = defaultLabels;

export default ConfirmDialog;
