import React, { useEffect, useState, FC } from 'react';
import {
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import CheckBoxGroup from '../common/CheckBoxGroup';
import {
  CLASSIFICATION_TYPES,
  CLASSIFICATION_TYPE_OPTIONS,
} from '../../constants';
import { add } from 'ol/coordinate';
import { ClassificationType } from '../../api/originalMappings';
import {
  OrganisationAdditionalTopicsDto,
  OrganisationDto,
} from '../../api/apiDtos';
import { useTranslation } from 'react-i18next';

const styles = {
  container: {
    marginBottom: 12,
  },
};

interface ClassificationTypesSelectorProps extends WithStyles<typeof styles> {
  orgs: OrganisationDto[];
  setSelectedClassificationTypes: (value: any[]) => void;
  selectedClassificationTypes: any[];
  setSelectedAdditionalTopics: (value: Map<any, any>) => void;
}

const ClassificationTypesSelector: FC<ClassificationTypesSelectorProps> = ({
  orgs,
  setSelectedClassificationTypes,
  selectedClassificationTypes,
  setSelectedAdditionalTopics,
  classes,
}) => {
  const { t } = useTranslation();
  const [selectedAdditionalTopics, _setSelectedAdditionalTopics] = useState(
    new Map()
  );
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    const prevSelectedOrgs = selectedAdditionalTopics
      ? Array.from(selectedAdditionalTopics.keys())
      : [];
    const orgNames = orgs ? orgs.map((org) => org.name) : [];
    const diffOrgs1 = orgNames.filter(
      (orgName) => !prevSelectedOrgs.includes(orgName)
    );
    const diffOrgs2 = prevSelectedOrgs.filter(
      (orgName) => !orgNames.includes(orgName)
    );
    const diffOrgs = [...diffOrgs1, ...diffOrgs2];

    let _selectedAdditionalTopics = new Map(selectedAdditionalTopics || []);

    if (diffOrgs.length === 0) {
      _setSelectedAdditionalTopics(_selectedAdditionalTopics);
      return;
    }

    const diffOrg = diffOrgs[0];

    const diffOrgObj = orgs.find((o) => o.name === diffOrg);
    if (prevSelectedOrgs.includes(diffOrg)) {
      for (const org of orgs) {
        if (org.additionalTopics) {
          setSelectedOption(org.name);
        }
      }
      _selectedAdditionalTopics.delete(diffOrg);
    } else {
      if (diffOrgObj?.additionalTopics) {
        setSelectedOption(diffOrg);
      }
      _selectedAdditionalTopics.set(diffOrg, []);
    }

    _setSelectedAdditionalTopics(_selectedAdditionalTopics);
  }, [orgs]);

  const handleOrgSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedOption(event.target.value as string);
  };

  useEffect(() => {
    setSelectedAdditionalTopics(selectedAdditionalTopics);
  }, [selectedAdditionalTopics]);

  const constructAdditionalTopicsOpts = (selectedOption: string) => {
    const addTopicsOpts = orgs
      .at(
        orgs.findIndex(
          (o) => o.name === selectedOption && o.additionalTopics.length !== 0
        )
      )
      .additionalTopics.map((aT: OrganisationAdditionalTopicsDto) => {
        return {
          key: aT.additionalTopicName,
          value: ClassificationType.Topic,
          name: aT.customLabel != '' ? aT.customLabel : aT.additionalTopicName,
          id: aT.id,
        };
      });
    return addTopicsOpts;
  };

  const orgsWithAdditionalTopics = () => {
    return orgs.filter((o) => o.additionalTopics).map((o) => o.name);
  };

  const handleAdditionalTopicsAssignment = (selectedTypes: any[]) => {
    let _selectedAdditionalTopicsCopy = new Map(selectedAdditionalTopics);
    _selectedAdditionalTopicsCopy.set(selectedOption, selectedTypes);
    _setSelectedAdditionalTopics(_selectedAdditionalTopicsCopy);
  };

  return (
    <FormControl className={classes.container}>
      <FormLabel>
        {t(
          'super_user.ClassificationTypesSelector.Select classification types'
        )}
      </FormLabel>
      <CheckBoxGroup
        value={selectedClassificationTypes}
        onChange={setSelectedClassificationTypes}
        options={CLASSIFICATION_TYPE_OPTIONS}
        nameSelector={(option) => option.name}
        valueSelector={(option) => option.value}
      />
      {orgsWithAdditionalTopics().length !== 0 &&
        selectedClassificationTypes?.some(
          (x) => x === CLASSIFICATION_TYPES.Topic
        ) && (
          <FormControl>
            <FormLabel>
              {t(
                'super_user.ClassificationTypesSelector.Select additional topics'
              )}
            </FormLabel>
            <Select
              placeholder={'Organisation'}
              value={selectedOption}
              onChange={handleOrgSelect}
            >
              {orgsWithAdditionalTopics().map((orgName: string) => {
                return (
                  <MenuItem key={orgName} value={orgName}>
                    {orgName}
                  </MenuItem>
                );
              })}
            </Select>
            <CheckBoxGroup
              value={
                selectedAdditionalTopics &&
                selectedAdditionalTopics.get(selectedOption)
                  ? selectedAdditionalTopics.get(selectedOption)
                  : []
              }
              onChange={(values: any[]) =>
                handleAdditionalTopicsAssignment(values)
              }
              options={
                selectedOption !== '' && orgs.length !== 0
                  ? constructAdditionalTopicsOpts(selectedOption)
                  : []
              }
              nameSelector={(option: any) => option.name}
              valueSelector={(option: any) => option.id}
            />
          </FormControl>
        )}
    </FormControl>
  );
};

export default withStyles(styles)(ClassificationTypesSelector);
