// @ts-nocheck
import React from 'react';
import { withStyles, Typography } from '@material-ui/core';

const styles = {
  noData: {
    height: 289,
  },
  noDataText: {
    color: '#aaa',
    textAlign: 'center',
  },
};

function NoDataText({ label, classes }) {
  return (
    <div className={classes.noData}>
      <Typography className={classes.noDataText} variant="h4">
        {label}
      </Typography>
    </div>
  );
}

export default withStyles(styles)(NoDataText);
