import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const SELECT_ITEM = 'Select item';

export default function LocalComboBox(props) {
  const { customLabel, ...childProps } = props;
  return (
    <Autocomplete
      {...childProps}
      renderInput={(params) => (
        <TextField
          {...params}
          label={customLabel || SELECT_ITEM}
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
}
