import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type initialState = [
  topicFilters: any[],
  setTopicFilters: Dispatch<SetStateAction<any[]>>,
];

export const TopicFilterContext = createContext<null | initialState>(null);

export const useTopicFilters = () => {
  const context = useContext(TopicFilterContext);
  if (context === null) {
    throw new Error(
      'useTopicFilters must be encapsulated under a <TopicFilterProvider>'
    );
  }
  return context;
};

const TopicFilterProvider = ({ children }) => {
  const [topicFilters, setTopicFilters] = useState<any[]>([]);

  return (
    <TopicFilterContext.Provider value={[topicFilters, setTopicFilters]}>
      {children}
    </TopicFilterContext.Provider>
  );
};

export default TopicFilterProvider;
