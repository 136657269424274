// @ts-nocheck
import React from 'react';
import DataTableWidget from './DataTableWidget';
import API from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import PropTypes from 'prop-types';
import { PERMISSIONS } from '../../constants';
import { toast } from 'react-toastify';
import { getReportsWithPagination } from '../../services/WidgetDataService';
import { useTableColumns } from '../../context/TableColumnsContext';
import useAuthorization from '../../hooks/useAuthorisationManager';
import { useTranslation } from 'react-i18next';
import { useWidgetTranslations } from './useTranslatedWidgetsDefaultSettings';

function ReportsTableWidget({ settings }) {
  const { ReportTableDefaultSettings } = useWidgetTranslations();
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();
  const { reportTableColumns, reportTableColumnsExpanded } = useTableColumns();

  const canDeactivateReport = hasPermission(PERMISSIONS.Report.Update);

  const deactivateReport = async (report) => {
    if (report) {
      const url = `${ApiRoutes.deactivateReport}?id=${report.id}`;
      await API.deleteReport(url);
      toast.success(t('widgets.ReportsTableWidget.Report deleted'));
    }
  };

  const getAIData = async (recordId) => {
    const params = new URLSearchParams({
      recordId: recordId,
    });
    try {
      const response = await API.get(
        `${ApiRoutes.aiOutPutsByReport}?${params.toString()}`
      );
      return response;
    } catch (error) {
      console.error('getAiOutPutHazards error:', error);
      throw error;
    }
  };

  return (
    <DataTableWidget
      columns={reportTableColumns}
      columnsExpanded={reportTableColumnsExpanded}
      settings={settings}
      defaultSettings={ReportTableDefaultSettings}
      url={ApiRoutes.getReportExtras}
      dataFetchFunc={getReportsWithPagination}
      canDeleteRecord={canDeactivateReport}
      deleteFunc={deactivateReport}
      getAiOutPutFunc={getAIData}
      entityName="report"
    />
  );
}

ReportsTableWidget.propTypes = {
  settings: PropTypes.object,
};

export default ReportsTableWidget;
