const ApiRoutes = {
  login: '/api/user/login',
  refresh: '/api/user/auth/token/refresh',
  userList: '/api/user/users',
  roles: '/api/user/roles',
  org: 'api/organisation/get-org',
  orgs: 'api/organisation/get-orgs',
  orgsTopLevel: 'api/organisation/get-top-level',
  orgsForAdminView: 'api/organisation/get-for-admin-view',
  addUser: 'api/user/add',
  userPersonalDetails: 'api/user/personal-details',
  updateUser: '/api/user/update',
  updateUserSelf: '/api/user/update-self',
  fileProcessMapped: '/api/file/processMapped',
  fileParseHeaders: '/api/file/parseHeaders',
  getFileValueMappingRules: 'api/file/getValueMappingRules',
  downloadFileUploadReport: 'api/file/downloadFileUploadReport',
  logout: '/api/user/logout',

  organisations: '/api/data/organisations',
  reportTypes: '/api/data/report-types',
  affectedParties: '/api/data/affected-parties',
  topics: '/api/data/topics',
  associatedReports: '/api/data/associated-reports',
  associatedReportStatuses: '/api/data/associated-report-statuses',
  reports: '/api/data/reports',
  reportColumns: '/api/data/report-columns',
  latestDateOccurredReport: 'api/data/latest-date-occurred-reports',

  saveSearch: '/api/search/save',
  getSavedSearches: '/api/search/get',
  deleteSearch: '/api/search/delete',

  getDashboardConfiguration: 'api/dashboard/get',
  getOwnDashboardConfiguration: 'api/dashboard/get-own',
  saveDashboardConfiguration: 'api/dashboard/save',

  getWidgetsConfiguration: 'api/widgets/get',
  getOwnWidgetsConfiguration: 'api/widgets/get-own',
  saveWidgetsConfiguration: 'api/widgets/save',

  getReportExtras: 'api/data/report-extras',
  getAssociatedReportExtras: 'api/data/associated-report-extras',

  getTrackers: 'api/tracker/get',
  getTrackerFilters: 'api/tracker/filters',
  saveTracker: 'api/tracker/save',
  updateTracker: 'api/tracker/update',
  deleteTracker: 'api/tracker/delete',
  getChartData: 'api/tracker/get-chart-data',
  getTrackerPreventiveActions: 'api/tracker/get-preventive-actions',
  exportTrackerReports: 'api/tracker/export-tracker-reports',

  getReportFilters: 'api/filter/report',
  getAssociatedReportFilters: 'api/filter/associated-report',
  getFilterConfig: 'api/filter/get-config',
  getOwnFilterConfig: 'api/filter/get-own-config',
  saveFilterConfig: 'api/filter/save-config',

  addOrganisation: 'api/organisation/add',
  updateOrganisation: 'api/organisation/update',
  editOrganisationLogo: 'api/organisation/edit-logo',
  industrySectors: 'api/organisation/industry-sectors',
  globalLocations: 'api/organisation/global-locations',

  aiOutPutsByReport: 'api/data/ai-outputs-by-report',
  getreportbyid: 'api/data/report-by-id',
  reportsWithPagination: 'api/data/reports-with-pagination',
  associatedReportsWithPagination:
    'api/data/associated-reports-with-pagination',
  reportsWidgetData: 'api/data/reports-widget-data',
  associatedReportsWidgetData: 'api/data/associated-reports-widget-data',
  locationReports: 'api/data/location-reports',
  wordcloud: 'api/data/get-wordcloud',
  getSubClassification: 'api/data/sub-classifications',

  deactivateReport: 'api/report/deactivate',

  createAction: 'api/action/create',
  getActions: 'api/action/get',
  deleteAction: 'api/action/delete',

  createAlert: 'api/alert/create',
  updateAlert: 'api/alert/update',
  getAlerts: 'api/alert/getAll',
  deleteAlert: 'api/alert/delete',
  interactWithAlert: 'api/alert/hit',
  alertToggle: 'api/alert/alert-toggle',
  getAllUsersByOrg: '/api/user/users-By-Org',

  getAlertNotifications: 'api/alertnotification/all',
  markAllAlertNotificationRead: 'api/alertnotification/mark-all-read',
  markAlertNotificationRead: 'api/alertnotification/mark-read',
  markAlertNotificationUnread: 'api/alertnotification/mark-unread',

  getAllFacilities: '/api/facility/get-all',
  getFacilitiesWithPagination: 'api/facility/get-with-pagination',
  createFacility: 'api/facility/create',
  updateFacility: 'api/facility/update',
  deleteFacility: 'api/facility/delete',

  getAliases: 'api/alias/get',
  saveAlias: 'api/alias/save',
  deleteAlias: 'api/alias/delete',
  getNamesForAlias: 'api/alias/getNames',

  runClassification: 'api/superUser/runClassification',

  getThemeConfig: 'api/theme/getConfig',
  saveThemeConfig: 'api/theme/saveConfig',
  getOwnThemeConfig: 'api/theme/getOwnConfig',

  getActivityLogs: 'api/activity/get',

  logo: 'api/general/logo',
  version: 'api/general/version',

  getTopics: 'api/configureTopics/get-all',
  deleteTopic: 'api/configureTopics/delete',
  addTopic: 'api/configureTopics/add',
  updateTopic: 'api/configureTopics/update',
};

export default ApiRoutes;
