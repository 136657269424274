import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type initialState = [
  dateUpdate: any[],
  updateFilterCountByNewDate: Dispatch<SetStateAction<any[]>>,
];

export const DateUpdateContext = createContext<null | initialState>(null);

export const useDateUpdate = () => {
  const context = useContext(DateUpdateContext);
  if (context === null) {
    throw new Error(
      'useDateUpdate must be encapsulated under a <DateUpdateProvider>'
    );
  }
  return context;
};

const DateUpdateProvider = ({ children }) => {
  const [dateUpdate, updateFilterCountByNewDate] = useState<any[]>([]);

  return (
    <DateUpdateContext.Provider value={[dateUpdate, updateFilterCountByNewDate]}>
      {children}
    </DateUpdateContext.Provider>
  );
};

export default DateUpdateProvider;
