import moment from 'moment';

const isDate = (date: Date) => date instanceof Date && !isNaN(date.valueOf());
const daysInWeek = 7;
const weekDaysOffset = -1;

const _getWeekStart = (date: Date) => {
    date = new Date(date);
    const day = date.getDay();
    const diff =
        date.getDate() -
        day +
        (day === 0 ? (daysInWeek + weekDaysOffset) * -1 : 1); // adjust when day is sunday
    return new Date(date.setDate(diff));
};

const _getWeekEnd = (date: Date) => {
    var day = date.getDay();
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + (day === 0 ? 0 : daysInWeek) - day
    );
};

//for unit test needed
const _getLang = () => {
    if (navigator.languages !== undefined) return navigator.languages[0];
    else return navigator.language;
};

export default class DateService {
    static ParseMMDYYYYhhmmss = (date: Date) => {
        return isDate(date)
            ? `${date.getMonth() +
                  1}${date.getDate()}${date.getFullYear()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`
            : null;
    };

    static ParseMMMMdd = (date: Date) => {
        return isDate(date)
            ? date.toLocaleDateString(_getLang(), {
                  year: '2-digit',
                  month: 'short',
              })
            : null;
    };

    static ParseMMMMYY = (date: Date) =>
        isDate(date)
            ? date.toLocaleDateString(_getLang(), {
                  month: 'short',
                  year: 'numeric',
              })
            : null;

    static ParseddMMYYYY = (date:Date) =>
        isDate(date)
            ? date.toLocaleDateString(_getLang(), {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
              })
            : null;

    static ParseToWeekRange = (date: Date) => {
        let result = null;
        if (isDate(date)) {
            const startOfWeek = _getWeekStart(date);
            const endOfWeek = _getWeekEnd(date);
            result = `${startOfWeek.toLocaleDateString(_getLang(), {
                year: '2-digit',
                month: 'short',
                day: '2-digit',
            })}
        - ${endOfWeek.toLocaleDateString(_getLang(), {
            year: '2-digit',
            month: 'short',
            day: '2-digit',
        })}`;
        }
        return result;
    };

    static ParseDDMMYYHHMM = (date: Date) => {
        let result = null;
        if (isDate(date)) {
            result = moment(date).format('dd/MM/yy hh:mm');
        }
        return result;
    };

    static GetLastDayOfPreviousMonth = (date: Date) => {
        if (date === undefined) {
            date = new Date();
        }
        return new Date(date.getFullYear(), date.getMonth() - 1, 0);
    };
}
