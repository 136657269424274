/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, FC, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Grid,
  Paper,
  Typography,
  TextField,
  IconButton,
} from '@material-ui/core';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TrackerViewer from './TrackerViewer';
import { TrackerItem } from './TrackerItem';
import { AlertItem } from './AlertItem';
import { useTrackerStyles } from './styles';
import ConfirmDialog from '../dialog/ConfirmDialog';
import { toast } from 'react-toastify';
import { AlertDto, AlertUserDto, TrackerDto } from '../../api/apiDtos';
import { AlertsConfigContext } from '../../context/AlertConfigContextProvider';
import { selectedAlert } from '../../signals/selectedAlert';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { listOfTrackers } from '../../components/widgets/NotificationTable';
import { listOfAlerts } from '../../components/widgets/NotificationTable';
import { useEffectAsync } from '../../hooks/useEffectAsync';
import { formatDateCommon } from '../../helpers/date-helper';
import AddAlertOutlined from '@material-ui/icons/AddAlertOutlined';
import { AlertTypes } from '../../constants';
import { Props } from 'recharts/types/container/Surface';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const TrackerPage: FC<Props> = ({}) => {
  const { t } = useTranslation();
  //setAlertConfig takes 3 parameters - Create, Delete, Edit => will show respective pop up based on selected parameter
  const { callAlerts, setAlertsConfig } = useContext(AlertsConfigContext);
  const { alertId }: any = useParams();
  const classes = useTrackerStyles();

  const [selectedTrackerIndex, setSelectedTrackerIndex] = useState(null);
  const [selectedAlertIndex, setSelectedAlertIndex] = useState(null);

  //selected alert comes from this selectedAlert.value which is imported from signals
  const [currentTracker, setCurrentTracker] = useState<TrackerDto>(null);

  const [tabValue, setTabValue] = useState<string>('1');
  const [graphData, setGraphData] = useState<TrackerDto | AlertDto>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const handleTabChange = async (
    e: React.ChangeEvent<{}>,
    newValue: string
  ) => {
    setTabValue(newValue);
    if (!listOfTrackers.value?.length) {
      await loadTrackers();
    }
    if (!listOfAlerts.value?.length) {
      await loadAlerts();
    }
    if (newValue === '1') {
      setSelectedTrackerIndex(0);
      setCurrentTracker(listOfTrackers.value[0]);
      setSelectedAlertIndex(null);
      selectedAlert.value = null;
    } else if (newValue === '1' && !listOfTrackers.value?.length) {
      setSelectedTrackerIndex(null);
      setCurrentTracker(null);
      setSelectedAlertIndex(null);
      selectedAlert.value = null;
      return;
    }
    if (newValue === '2' && listOfAlerts.value) {
      setSelectedAlertIndex(0);
      selectedAlert.value = listOfAlerts.value[0];
      setSelectedTrackerIndex(null);
      setCurrentTracker(null);
    } else if (newValue === '2' && !listOfAlerts.value?.length) {
      selectedAlert.value = null;
      setSelectedAlertIndex(null);
      setSelectedTrackerIndex(null);
      setCurrentTracker(null);
    }
  };

  useEffectAsync(async () => {
    if (!listOfAlerts?.value.length) {
      await loadAlerts();
    }
    if (!listOfTrackers?.value.length) {
      await loadTrackers();
    }
    if (alertId) {
      let foundTracker;
      let foundAlert;

      foundAlert = listOfAlerts.value.find((x) => x.id === alertId);
      if (foundAlert) {
        selectedAlert.value = foundAlert;
        const foundAlertIdIndex = listOfAlerts.value?.findIndex(
          (x) => x.id === alertId
        );
        if (foundAlertIdIndex > -1) {
          setSelectedAlertIndex(foundAlertIdIndex);
          setTabValue('2');
          setSelectedTrackerIndex(null);
          setCurrentTracker(null);
          return;
        } else {
          selectedAlert.value = listOfAlerts.value[0];
          setSelectedAlertIndex(0);
          setTabValue('2');
          toast.error(t('tracker.Trackers.Alert not found'));
          return;
        }
      }
      foundTracker = listOfTrackers.value.find((x) => x.id === alertId);
      if (foundTracker) {
        setCurrentTracker(foundTracker);
        const foundTrackerIndex = listOfTrackers.value.findIndex(
          (x) => x.id === alertId
        );
        if (foundTrackerIndex > -1) {
          setSelectedTrackerIndex(foundTrackerIndex);
          setTabValue('1');
          setSelectedAlertIndex(null);
          selectedAlert.value = null;
          return;
        } else {
          setCurrentTracker(listOfTrackers.value[0]);
          setSelectedTrackerIndex(0);
          setTabValue('1');
          toast.error(t('tracker.Trackers.Tracker not found'));
          return;
        }
      }
    }
    if (listOfTrackers.value.length) {
      setCurrentTracker(listOfTrackers.value[0]);
      setSelectedTrackerIndex(0);
      setTabValue('1');
      return;
    }
    if (listOfAlerts.value?.length) {
      selectedAlert.value = listOfAlerts.value[0];
      setSelectedAlertIndex(0);
      setTabValue('2');
      return;
    } else {
      setTabValue('1');
      selectedAlert.value = null;
      setCurrentTracker(null);
      listOfAlerts.value = null;
      listOfTrackers.value = null;
    }
  }, []);

  useEffectAsync(async () => {
    if (callAlerts) {
      await loadAlerts();
    }
  }, [callAlerts]);

  async function loadAlerts() {
    await api
      .get<unknown, AlertDto[]>(ApiRoutes.getAlerts)
      .then((_listOfAlerts) => {
        if (_listOfAlerts?.length) {
          listOfAlerts.value = _listOfAlerts;
          selectedAlert.value = listOfAlerts.value[0];
          setSelectedAlertIndex(0);
        } else {
          listOfAlerts.value = null;
          selectedAlert.value = null;
        }
      });
  }

  async function loadTrackers() {
    await api
      .get<unknown, TrackerDto[]>(ApiRoutes.getTrackers)
      .then((_listOfTrackers) => {
        if (_listOfTrackers?.length) {
          listOfTrackers.value = _listOfTrackers;
        }
      });
  }

  useEffect(() => {
    if (selectedAlert.value !== null) {
      setGraphData(selectedAlert.value);
    } else if (currentTracker !== null) {
      setGraphData(currentTracker);
    } else setGraphData(null);
  }, [selectedAlert.value, currentTracker]);
  useEffect(() => {
    console.log(graphData);
  }, [graphData]);

  const handleCheckedAlertClick = (event, alertIndex, item: AlertDto) => {
    api
      .put<string, AlertUserDto>(`${ApiRoutes.alertToggle}/${item.id}`, item.id)
      .then((res: AlertUserDto) => {
        if (!res.muted) {
          toast.success(t('tracker.Trackers.Notification Turned On'));
        } else {
          toast.success(t('tracker.Trackers.Notification Turned Off'));
        }
        loadAlerts();
      });
  };

  const deleteTrackerConfirmation = (event, item) => {
    setSelectedTrackerIndex(
      listOfTrackers.value?.findIndex((x) => x.id === item.id)
    );
    setCurrentTracker(item);
    setDeleteDialogOpen(true);
  };

  const editTrackerConfirmation = (event, item) => {
    setSelectedTrackerIndex(
      listOfTrackers.value?.findIndex((x) => x.id === item.id)
    );
    setCurrentTracker(item);
    setEditDialogOpen(true);
  };

  const editTracker = () => {
    setEditDialogOpen(false);
    const tracker: TrackerDto = {
      //only thing getting updated here
      name: currentTracker.name,
      //these can delete the rest but it allows for expansion if necessary
      id: currentTracker?.id,
      actions: currentTracker?.actions,
      dateCreated: currentTracker?.dateCreated,
      dateUpdated: currentTracker?.dateUpdated,
      endDate: currentTracker?.endDate,
      filters: currentTracker?.filters,
      hasAlert: currentTracker?.hasAlert,
      startDate: currentTracker?.startDate,
      targets: currentTracker?.targets,
    };
    api.post<TrackerDto, TrackerDto>(ApiRoutes.updateTracker, tracker).then(
      (response) => {
        if (response) {
          toast.success(t('tracker.Trackers.Tracker updated'));
        }
        setCurrentTracker(response);
        loadTrackers();
      },
      (err) => {
        toast.error(t('tracker.Trackers.Tracker could not be updated'));
      }
    );
  };

  const deleteTracker = () => {
    setDeleteDialogOpen(false);
    api
      .post<TrackerDto, TrackerDto>(ApiRoutes.deleteTracker, currentTracker)
      .then(
        (response) => {
          if (response) {
            const selectedTrackerIndex = listOfTrackers.value?.findIndex(
              (x) => x.id === currentTracker.id
            );
            const items = [...listOfTrackers.value];
            items.splice(selectedTrackerIndex, 1);
            listOfTrackers.value = items;
            setSelectedTrackerIndex(0);
            setCurrentTracker(listOfTrackers.value[0]);
            toast.success(t('tracker.Trackers.Tracker deleted successfully'));
          }
          loadTrackers();
        },
        (err) => {
          toast.error(t('tracker.Trackers.Tracker could not be deleted'));
        }
      );
  };

  async function handleCheckedTrackerNotificationClick(
    event,
    alertIndex,
    item: TrackerDto
  ) {
    const tracker: TrackerDto = {
      //only thing getting updated here
      hasAlert: item.hasAlert === true ? false : true,
      //these can delete the rest but it allows for expansion if necessary
      id: item?.id,
      actions: item.actions,
      dateCreated: item.dateCreated,
      dateUpdated: item.dateUpdated,
      endDate: item.endDate,
      filters: item.filters,
      name: item.name,
      startDate: item.startDate,
      targets: item.targets,
    };
    await api
      .post<TrackerDto, TrackerDto>(ApiRoutes.updateTracker, tracker)
      .then(
        (response) => {
          if (response.hasAlert) {
            toast.success(t('tracker.Trackers.Notification Turned On'));
            setCurrentTracker(response);
          } else {
            toast.success(t('tracker.Trackers.Notification Turned Off'));
          }
          loadTrackers();
        },
        (err) => {
          toast.error(t('tracker.Trackers.Could not update notification'));
        }
      );
  }

  const handleAlertItemSelected = (alert: AlertDto) => {
    selectedAlert.value = alert;
    setSelectedAlertIndex(
      listOfAlerts.value?.findIndex((x) => x.id === alert.id)
    );
  };

  function CreateAlert() {
    setAlertsConfig(AlertTypes.create);
  }

  return (
    <>
      {currentTracker || selectedAlert.value ? (
        <div className={classes.trackerHeaderTitle}>
          <div className={classes.trackerHeader}>
            <Typography
              variant="h5"
              className={classes.mr05}
              style={{ fontWeight: 500 }}
            >
              {!selectedAlert.value
                ? t('tracker.Trackers.Performance tracker')
                : t('tracker.Trackers.Alert')}
              :
            </Typography>
            {selectedAlert.value || currentTracker ? (
              <Typography variant="h5" style={{ fontWeight: 500 }}>
                {selectedAlert.value
                  ? ` - ${
                      selectedAlert.value?.level2Classification?.label ??
                      selectedAlert.value?.subClassification.label
                    }`
                  : currentTracker?.name}
              </Typography>
            ) : (
              <TextField
                value={currentTracker?.name || selectedAlert.value?.name}
                variant="outlined"
                size="small"
              />
            )}
          </div>
          <Typography variant="body2" style={{ fontWeight: 500 }}>
            {t('tracker.Trackers.Created')}:{' '}
            {selectedAlert.value
              ? formatDateCommon(selectedAlert.value?.createdDate)
              : formatDateCommon(currentTracker?.dateCreated)}
            | {t('tracker.Trackers.Last updated')}:{' '}
            {selectedAlert.value
              ? formatDateCommon(selectedAlert.value?.updatedDate)
              : formatDateCommon(
                  currentTracker?.dateUpdated || currentTracker?.dateCreated
                )}
          </Typography>
        </div>
      ) : null}
      <Grid container style={{ width: '99%', paddingTop: 40 }}>
        <Grid item style={{ width: 352, position: 'fixed' }}>
          <Paper className={classes.root}>
            <TabContext value={tabValue}>
              <div style={{ position: 'static' }}>
                <TabList
                  onChange={handleTabChange}
                  style={{ background: '#fff' }}
                >
                  <Tab
                    label={t('tracker.Trackers.Trackers')}
                    value="1"
                    style={{ color: tabValue === '1' ? '#D94936' : 'black' }}
                  />
                  <Tab
                    label={t('tracker.Trackers.Alerts')}
                    value="2"
                    style={{ color: tabValue === '2' ? '#D94936' : 'black' }}
                  />
                </TabList>
              </div>
              <TabPanel value="1" style={{ padding: '0' }}>
                <List component="nav" aria-label="created trackers">
                  {listOfTrackers.value?.length ? (
                    listOfTrackers.value.map((item, index) => (
                      <ListItem
                        key={`li-${index}`}
                        button
                        selected={selectedTrackerIndex === index}
                        onClick={(event) => {
                          setCurrentTracker(item);
                          setSelectedTrackerIndex(index);
                          setSelectedAlertIndex(null);
                          selectedAlert.value = null;
                        }}
                      >
                        <TrackerItem
                          key={`ti-${index}`}
                          item={item}
                          trackerDeleteConfitmation={(event) =>
                            deleteTrackerConfirmation(event, item)
                          }
                          handleTrackerEditConfirmation={(event) =>
                            editTrackerConfirmation(event, item)
                          }
                          notifiationClick={(event) =>
                            handleCheckedTrackerNotificationClick(
                              event,
                              index,
                              item
                            )
                          }
                        />
                      </ListItem>
                    ))
                  ) : (
                    <Typography
                      className={`${classes.redText} ${classes.alignCenter}`}
                    >
                      {t('tracker.Trackers.No trackers found')}
                    </Typography>
                  )}
                </List>
              </TabPanel>
              <TabPanel value="2" style={{ padding: '0' }}>
                {listOfAlerts.value?.length ? (
                  <List component="nav" aria-label="created alerts">
                    {listOfAlerts.value.map((item, index) => (
                      <ListItem
                        key={`li-${index}`}
                        button
                        selected={selectedAlertIndex === index}
                        onClick={(event) => {
                          selectedAlert.value = item;
                          setSelectedAlertIndex(index);
                          setSelectedTrackerIndex(null);
                          setCurrentTracker(null);
                          api.get(`${ApiRoutes.interactWithAlert}/${item.id}`);
                        }}
                      >
                        <AlertItem
                          key={`ti-${index}`}
                          item={item}
                          onClick={(event) =>
                            handleCheckedAlertClick(event, index, item)
                          }
                          itemSelected={handleAlertItemSelected}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography
                    className={`${classes.redText} ${classes.alignCenter}`}
                  >
                    {t('tracker.Trackers.No alerts found')}
                  </Typography>
                )}
              </TabPanel>
            </TabContext>
          </Paper>
        </Grid>
        <Grid xs={10} style={{ paddingLeft: 360 }}>
          {graphData ? (
            <div style={{ paddingTop: 15 }}>
              <TrackerViewer
                tabValue={tabValue}
                item={graphData}
                disabled={true}
              />
            </div>
          ) : !listOfAlerts.value?.length && tabValue === '2' ? (
            <div className={classes.gridButtonLayout}>
              <Tooltip title={t('tracker.Trackers.New Alert')}>
                <IconButton
                  className={classes.iconCircleButton}
                  onClick={CreateAlert}
                >
                  <AddAlertOutlined className={classes.iconStyle} />
                </IconButton>
              </Tooltip>
            </div>
          ) : null}
        </Grid>
      </Grid>
      <ConfirmDialog
        open={deleteDialogOpen}
        onCancel={() => setDeleteDialogOpen(false)}
        onConfirm={deleteTracker}
        title={t('tracker.Trackers.Delete Tracker')}
        confirm={t('tracker.Trackers.Delete')}
      >
        <Typography variant="body1">
          {t('tracker.Trackers.Tracker')}: {''}
          <strong style={{ fontWeight: 'bold' }}>
            {currentTracker && currentTracker.name}
          </strong>
          {''} {t('tracker.Trackers.will be deleted.')}
          <br />
          {t('tracker.Trackers.Do you wish to continue?')}
        </Typography>
      </ConfirmDialog>
      <ConfirmDialog
        open={editDialogOpen}
        onCancel={() => setEditDialogOpen(false)}
        onConfirm={editTracker}
        title={t('tracker.Trackers.Do you wish to continue?', {
          currentTrackerName: currentTracker?.name,
        })}
        confirm={t('tracker.Trackers.Save')}
      ></ConfirmDialog>
    </>
  );
};

export default TrackerPage;
