import React from 'react';
import { Chip, Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormatFilter } from '../../signals/filterService';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: '3px',
  },
  base: {
    display: 'inline-block',
    alignItems: 'center',
  },
}));

export default function FilterChipsStatic({ onMoreClick, filters }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.base}>
      {filters.slice(0, 3).map((filter, idx) => (
        <Chip
          key={`${filter.parameters}-${idx}`}
          label={FormatFilter(filter)}
          color="primary"
          size="small"
          className={classes.chip}
        />
      ))}
      {filters.length > 3 ? (
        <Button onClick={onMoreClick} color="primary" size="small">
          + {filters.length - 3} {t('filters.FilterChipsStatic.more filters')}
        </Button>
      ) : null}
    </div>
  );
}

FilterChipsStatic.propTypes = {
  onMoreClick: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
};
