import React, { FC } from 'react';
import {
  BarChart,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
} from 'recharts';

const defaultAxisY = 'GBP COST';

interface Props {
  axisY: string;
  tooltip?: boolean;
  data: any[];
}

const AssociatedReportsCostChart: FC<Props> = ({ data, tooltip, axisY }) => {
  const maxVal = data.map((d) => d.cost).reduce((p, c) => (c > p ? c : p), 0);
  const domain =
    maxVal <= 10000
      ? [0, 10000]
      : maxVal <= 40000
      ? [0, 40000]
      : maxVal <= 100000
      ? [0, 100000]
      : [0, 500000];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          top: 0,
          right: 10,
          bottom: 0,
          left: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" fill="#F1F3F4" />
        {tooltip && (
          <Tooltip
            isAnimationActive={false}
            formatter={(value) => `£${value.toLocaleString()}`}
          />
        )}
        <Bar dataKey="cost" fill="#FF5722" />
        <XAxis
          dataKey="date"
          tick={{
            height: '16px',
            fill: '#5E6366',
            fontSize: '12px',
          }}
          domain={['auto', 'auto']}
        />
        <YAxis
          tick={{
            fill: '#5E6366',
            fontSize: '12px',
            height: 16,
            dy: 4,
          }}
          domain={domain}
          tickFormatter={(t) => `${t / 1000}K`}
          label={{
            value: axisY || defaultAxisY,
            angle: -90,
            position: 'center',
            dx: -15,
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: 1,
            lineHeight: 14,
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AssociatedReportsCostChart;
