import { AxiosError } from "axios";

export const toPercent = (val: number) => {
  return `${(val * 100).toFixed(2)}%`;
};

//type guard
export const isServerError = (error: any): error is AxiosError => {
  return (error as AxiosError).message !== undefined;
}

export const logDataFetchError = (err: any, identifier: string): void => {
  if (isServerError(err)) {
    console.error(`API error occured when fetching '${identifier}' data:`, err.message)
  } else {
    console.error(`Something went wrong on the client when fetching '${identifier}':`, err.message)
  }
}

export const logResponseParseError = (zodErr: any): void => {
  console.error(`Error in parsing response data, contract dont match\: ${zodErr}`)
}


