// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Divider,
  Switch,
} from '@material-ui/core';
import ClientComboBox from '../../combobox/client-combo-box';
import api from '../../../services/ApiInstance';
import ApiRoutes from '../../../services/ApiRoutes';
import { widgetManagementStyles } from '../widgets-management-styles';
import UserPreferenceService from '../../../services/UserPreferenceService';
import { toast } from 'react-toastify';
import { WidthProvider, Responsive } from 'react-grid-layout';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { useTranslation } from 'react-i18next';

const ResponsiveGridLayout = WidthProvider(Responsive);
const userPreferenceService = new UserPreferenceService();
const useStyles = makeStyles(widgetManagementStyles);
const defaultConfig = { sections: [] };

//to avoid lost focus after render use external component function
//https://github.com/mui-org/material-ui/issues/783
//https://codesandbox.io/s/material-demo-msbxl?fontsize=14&hidenavigation=1&theme=dark&file=/Good.js:430-445
const FilterItem = ({ setting, onTitleChange, onToggle }) => {
  const { typeCode, typeName, canBeDisabled, isEnabled, title } = setting;
  const classes = useStyles();
  return (
    <div className={classes.filterRow}>
      <DragIndicatorIcon color="action" className={classes.dragIcon} />
      <TextField
        variant="outlined"
        fullWidth
        id={`rti-${typeCode}`}
        label={typeName}
        name={typeCode}
        value={title}
        onChange={onTitleChange}
        className={classes.filterTitle}
      />
      <Switch
        disabled={!canBeDisabled}
        checked={isEnabled}
        onChange={onToggle}
        color="primary"
        className={classes.filterToggle}
      />
    </div>
  );
};

const FilterSectionItem = ({ section, onTitleChange, onToggle }) => {
  const { typeId, typeName, canBeDisabled, isEnabled, title } = section;
  const classes = useStyles();
  return (
    <div className={classes.filterSectionHeader}>
      <TextField
        variant="outlined"
        fullWidth
        id={typeId}
        label={typeName}
        name={typeName}
        value={title}
        onChange={onTitleChange}
        className={classes.filterTitle}
      />
      <Switch
        disabled={!canBeDisabled}
        checked={isEnabled}
        onChange={onToggle}
        color="primary"
        className={classes.filterToggle}
      />
    </div>
  );
};

export default function FiltersConfigurator() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [client, setClient] = useState(null);
  const [defaultClient, setDefaultClient] = useState(null);
  const [modified, setModified] = useState(false);
  const [filterConfig, setFilterConfig] = useState(defaultConfig);

  useEffect(() => {
    const savedClient = userPreferenceService.get('FiltersConfiguratorClient');
    if (savedClient !== null) {
      setDefaultClient(savedClient);
    }
  }, []);

  useEffect(() => {
    setModified(false);
    if (!client) {
      setFilterConfig(defaultConfig);
      return;
    }
    loadFilterConfiguration(client);
  }, [client]);

  const changeFilterSetting = (typeCode, fieldName, value) => {
    setFilterConfig((prevState) => {
      const updatedState = {
        ...prevState,
        sections: prevState.sections.map((sec) => ({
          ...sec,
          settings: sec.settings.map((setting) => {
            return setting.typeCode === typeCode
              ? { ...setting, [fieldName]: value }
              : setting;
          }),
        })),
      };
      return updatedState;
    });
    setModified(true);
  };

  const changeFilterOrders = (newLayout, sectionTypeId) => {
    setFilterConfig((prevState) => {
      const updatedState = {
        ...prevState,
        sections: prevState.sections.map((sec) => {
          return sec.typeId === sectionTypeId
            ? {
                ...sec,
                settings: sec.settings.map((setting) => {
                  const layoutItem = newLayout.find(
                    (item) => item.i === setting.typeCode
                  );
                  return {
                    ...setting,
                    order: layoutItem ? layoutItem.y : setting.order,
                  };
                }),
              }
            : sec;
        }),
      };
      return updatedState;
    });
    setModified(true);
  };

  const changeFilterSection = (typeId, fieldName, value) => {
    setFilterConfig((prevState) => {
      const updatedState = {
        ...prevState,
        sections: prevState.sections.map((sec) => {
          return sec.typeId === typeId ? { ...sec, [fieldName]: value } : sec;
        }),
      };
      return updatedState;
    });
    setModified(true);
  };

  const onClientChange = (event, value) => {
    userPreferenceService.save('FiltersConfiguratorClient', value);
    setClient(value);
  };

  const loadFilterConfiguration = (client) => {
    if (!client) return;

    api
      .get(ApiRoutes.getFilterConfig, { params: { clientId: client.id } })
      .then(
        (response) => {
          if (response) {
            setFilterConfig(response);
          }
        },
        (err) => {
          console.log('failed getting filter config: ' + err);
        }
      );
  };

  const saveFilterConfiguration = () => {
    if (!client) return;

    const config = { ...filterConfig, clientId: client.id };
    api.post(ApiRoutes.saveFilterConfig, config).then(
      () => {
        toast.success(
          t(
            'widgets_manager_page.filters_configurator.filters_configurator.Saved successfully'
          )
        );
        setModified(false);
      },
      (err) => {
        console.log('failed saving filter config: ' + err);
      }
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.combo}>
        <ClientComboBox
          name="client"
          onChange={onClientChange}
          value={client}
          defaultValue={defaultClient}
          style={{ maxWidth: 300 }}
        />
      </div>
      <Paper className={classes.paper}>
        <Grid container justifyContent="center">
          <Grid item container xs={6} direction="column">
            <div className={classes.gridColumn}>
              {filterConfig.sections.map((sec) => (
                <Paper key={sec.typeId} className={classes.filterSection}>
                  <FilterSectionItem
                    section={sec}
                    onTitleChange={(e) =>
                      changeFilterSection(sec.typeId, 'title', e.target.value)
                    }
                    onToggle={(e) =>
                      changeFilterSection(
                        sec.typeId,
                        'isEnabled',
                        e.target.checked
                      )
                    }
                  />
                  <Divider />
                  <div className={classes.filterSectionContent}>
                    <ResponsiveGridLayout
                      cols={{ lg: 1, md: 1, sm: 1, xs: 1, xxs: 1 }}
                      rowHeight={72}
                      onLayoutChange={(newLayout) =>
                        changeFilterOrders(newLayout, sec.typeId)
                      }
                    >
                      {sec.settings.map((setting) => (
                        <Paper
                          key={setting.typeCode}
                          data-grid={{ x: 0, y: setting.order, w: 1, h: 1 }}
                          className={classes.draggableFilter}
                        >
                          <FilterItem
                            setting={setting}
                            onTitleChange={(e) =>
                              changeFilterSetting(
                                setting.typeCode,
                                'title',
                                e.target.value
                              )
                            }
                            onToggle={(e) =>
                              changeFilterSetting(
                                setting.typeCode,
                                'isEnabled',
                                e.target.checked
                              )
                            }
                          />
                        </Paper>
                      ))}
                    </ResponsiveGridLayout>
                  </div>
                </Paper>
              ))}
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Button
        variant="contained"
        color="primary"
        onClick={saveFilterConfiguration}
        disabled={!modified || !client}
      >
        {t(
          'widgets_manager_page.filters_configurator.filters_configurator.Save configuration'
        )}
      </Button>
    </div>
  );
}
