export const CARD_TYPE = 'DnDCard';
export const UNMAPPED_ITEMS_POOL = 'Parsed Headers';
export const EXTRA_ITEMS_POOL = 'EXTRAS';

export const ENTITY_TYPES = {
  Reports: 1,
  Locations: 2,
  Facilities: 4,
  AssociatedReports: 8,
  Organisations: 16,
  ReportType: 32,
} as const;

type EntityKey = keyof typeof ENTITY_TYPES;
export type EntityEnums = (typeof ENTITY_TYPES)[EntityKey] & number;
