import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const GeoPyLicense = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="body1">
        <strong>
          {t('licensing.GeoPy.Copyright')} (c){' '}
          {t('licensing.GeoPy.2006-2018 geopy authors (see AUTHORS)')}
        </strong>
        {t('licensing.GeoPy.permission')}
      </Typography>
      <Typography variant="body1">{t('licensing.GeoPy.theAbove')}</Typography>
      <Typography variant="body1">
        {t('licensing.GeoPy.TheSoftware')}
      </Typography>
    </>
  );
};
export default GeoPyLicense;
