// @ts-nocheck
import React, { useState, useMemo, useEffect } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  Select,
  InputLabel,
  MenuItem,
  withStyles,
  Button,
  TextField,
  Tooltip,
  IconButton,
  FormControl,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ClientComboBox from '../combobox/client-combo-box';
import { fetchAvailableOrganisationsList } from '../../services/OrganisationService';
import API from '../../services/ApiInstance';
import apiRoutes from '../../services/ApiRoutes';
import ValidationService from '../../services/authentication/ValidationService';
import InfoDialog from '../dialog/InfoDialog';
import useLoggedInUser from '../../useLoggedInUser';
import { useTranslation } from 'react-i18next';
import useValidationError from '../../services/authentication/useValidationService';

const styles = {
  roleSelect: {
    minWidth: 250,
  },
  formControl: {
    margin: '0 0 10px 0',
  },
};

const EditUserDialog = ({ classes, user, roles, onSuccess }) => {
  const { currentUser, setCurrentUser } = useLoggedInUser();
  const { t } = useTranslation();

  const validator = new ValidationService();
  const { VALIDATION_ERROR_TEXT } = useValidationError();
  const [isOwnRecord] = useState(currentUser.id === user.id);
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState(user.roleName);
  const [email, setEmail] = useState(user.email ? user.email : '');
  const [username, setUsername] = useState(user.username ? user.username : '');
  const [isActive, setIsActive] = useState(user.isActive);
  const [formErrors, setFormErrors] = useState(false);
  const [primaryOrganisation, setPrimaryOrganisation] = useState(
    user.primaryOrganisation ?? {}
  );
  const [organisations, setOrganisations] = useState(
    user.organisations
      ? user.organisations.filter(
          (x) => x.name != user.primaryOrganisation.name
        )
      : []
  );
  const [availableOrganisations, setAvailableOrganisations] = useState([]);

  const FORM_INVALID_TEXT = t(
    'user_management.EditUserDialog.The form could not be submitted - please review the details you have entered.'
  );

  useEffect(() => {
    fetchAvailableOrganisationsList().then((orgs) =>
      setAvailableOrganisations(orgs)
    );
  }, []);

  useEffect(() => {
    setOrganisations(
      organisations.filter((x) => x.id != primaryOrganisation.id)
    );
  }, [primaryOrganisation]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const cancel = () => {
    setOpen(false);
  };

  const handleChange = (prop) => (e) => {
    switch (prop) {
      case 'email':
        setEmail(e.target.value);
        break;
      case 'role':
        setRole(e.target.value);
        break;
      case 'username':
        setUsername(e.target.value);
        break;
      case 'primaryOrganisation':
        break;
      case 'isActive':
        break;
      case 'organisations':
        break;
    }
  };

  const changeIsActive = (value) => {
    setIsActive(value);
  };

  const onTagsChange = (event, values) => {
    setOrganisations(values.filter((x) => x.id != primaryOrganisation.id));
  };

  const isUsernameValid = () => {
    return (
      !username ||
      validator.validate(ValidationService.validators.username(username))
    );
  };

  async function updateUser() {
    const updateUser = {
      id: user.id,
      username,
      email,
      isActive,
      role,
      primaryOrganisation,
      organisations,
    };

    const isValid = validator.validate(
      ValidationService.validators.required(updateUser.email),
      ValidationService.validators.email(updateUser.email),
      ValidationService.validators.username(updateUser.username),
      ValidationService.validators.required(updateUser.role),
      ValidationService.validators.required(updateUser.primaryOrganisation),
      ValidationService.validators.required(updateUser.organisations)
    );

    if (isValid) {
      await API.post(apiRoutes.updateUser, updateUser);
      setOpen(false);
      onSuccess();
    } else {
      setFormErrors(true);
    }
  }

  return (
    <>
      <Tooltip title={t('user_management.EditUserDialog.Edit User')}>
        <IconButton
          aria-label="EditUser"
          color="primary"
          onClick={handleClickOpen}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={cancel}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {t('user_management.EditUserDialog.Edit User')}
        </DialogTitle>
        <DialogContent>
          {
            //Admin can not change its own role
            isOwnRecord ? (
              <TextField
                value={role}
                label={t('user_management.EditUserDialog.Role')}
                variant="outlined"
                margin="dense"
                className={classes.formControl}
                disabled
              />
            ) : (
              <FormControl
                variant="outlined"
                className={`${classes.roleSelect} ${classes.formControl}`}
              >
                <InputLabel id="user-role-label">
                  {t('user_management.EditUserDialog.Role')} *
                </InputLabel>
                <Select
                  labelId="user-role-label"
                  label={t('user_management.EditUserDialog.Role')}
                  value={role}
                  onChange={handleChange('role')}
                >
                  {roles &&
                    roles.map((role) => (
                      <MenuItem key={role} value={role.name}>
                        {role.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )
          }
          <TextField
            required
            margin="dense"
            id="username"
            label={t('user_management.EditUserDialog.Username')}
            type="text"
            value={username}
            fullWidth
            onChange={handleChange('username')}
            error={!isUsernameValid()}
            helperText={
              isUsernameValid() ? null : VALIDATION_ERROR_TEXT.Username
            }
            variant="outlined"
            className={classes.formControl}
          />
          <Autocomplete
            id="primary-org-dropdown"
            value={primaryOrganisation}
            onChange={(evt, newVal) => setPrimaryOrganisation(newVal)}
            options={availableOrganisations}
            getOptionLabel={(option) => option.name ?? ''}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  t('user_management.EditUserDialog.Primary Organisation') +
                  ' *'
                }
                variant="outlined"
              />
            )}
            className={classes.formControl}
          />
          <ClientComboBox
            value={organisations}
            options={availableOrganisations}
            onChange={onTagsChange}
            multiple
            id="tags-outlined"
            filterSelectedOptions
            customLabel={
              t('user_management.EditUserDialog.Organisations') + '*'
            }
            primaryOrganisation={primaryOrganisation}
            className={classes.formControl}
          />
          <TextField
            required
            error={
              !!email &&
              !validator.validate(ValidationService.validators.email(email))
            }
            margin="dense"
            id="name"
            label={t('user_management.EditUserDialog.Email Address')}
            type="email"
            fullWidth
            value={email}
            onChange={handleChange('email')}
            variant="outlined"
          />
          <FormControlLabel
            control={
              <Switch
                checked={isActive}
                onChange={(e) => changeIsActive(e.target.checked)}
                color="primary"
              />
            }
            label={t('user_management.EditUserDialog.Is Active')}
          />
          {formErrors && (
            <InfoDialog
              open={formErrors}
              onClose={() => setFormErrors(false)}
              text={FORM_INVALID_TEXT}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => updateUser()}
            color="primary"
            variant="contained"
          >
            {t('user_management.EditUserDialog.Update')}
          </Button>
          <Button onClick={cancel} color="primary" variant="outlined">
            {t('Global.Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(EditUserDialog);
