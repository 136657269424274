import React, { FC, useRef } from 'react';
import PropTypes from 'prop-types';
import DateService from '../../services/date-service';
// import { ReportsByDatesDefaultSettings } from '../widgets/useTranslatedWidgetsDefaultSettings';
import NoDataText from '../common/NoDataText';
import { OTHERS } from '../../constants';
import { ReportDateAggregationDto } from '../../api/originalMappings';
import HighchartsReact, {
  HighchartsReactProps,
} from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useTranslation } from 'react-i18next';
import { useWidgetTranslations } from '../widgets/useTranslatedWidgetsDefaultSettings';

// const createChartShape = (data: ReportDateAggregationDto[]) => {
//   //create a unique array of organisation names
//   const chartShape = [];
//   data.forEach((item) => {
//     item.organisationCounts.forEach((orgCount) => {
//       if (!chartShape.includes(orgCount.organisation)) {
//         chartShape.push(orgCount.organisation);
//       }
//     });
//   });
//   return chartShape;
// };

const aggregateMinorOrgs = (
  data: ReportDateAggregationDto[],
  aggregationThreshold: number
) => {
  const topOrgs = getTopOrganisations(data, aggregationThreshold);

  return data.map((item) => {
    const processed: ReportDateAggregationDto = { date: item.date };

    const othersCount = item.organisationCounts
      .filter((i) => !topOrgs.includes(i.organisation))
      .reduce((sum, current) => sum + current.count, 0);

    processed.organisationCounts = item.organisationCounts.filter((i) =>
      topOrgs.includes(i.organisation)
    );
    processed.organisationCounts.push({
      organisation: OTHERS,
      count: othersCount,
    });

    return processed;
  });
};

const getTopOrganisations = (
  data: ReportDateAggregationDto[],
  limit: number
) => {
  const orgTotalCounts = {};
  data.forEach((item) => {
    item.organisationCounts.forEach((orgCount) => {
      const orgTotalCount = orgTotalCounts[orgCount.organisation];
      if (orgTotalCount) {
        orgTotalCounts[orgCount.organisation] = orgTotalCount + orgCount.count;
      } else {
        orgTotalCounts[orgCount.organisation] = orgCount.count;
      }
    });
  });

  return Object.keys(orgTotalCounts)
    .sort((orgA, orgB) => orgTotalCounts[orgB] - orgTotalCounts[orgA])
    .slice(0, limit);
};

const createChartData = (data: ReportDateAggregationDto[]) => {
  return data.map((point) => {
    const chartPoint = {
      date: DateService.ParseMMMMYY(new Date(point.date)),
    };

    if (point.organisationCounts) {
      point.organisationCounts.forEach((item) => {
        const key = item.organisation;
        chartPoint[key] = item.count;
        chartPoint[getLineKey(key)] = item.count;
      });
    }

    return chartPoint;
  });
};

const getLineKey = (key) => `line-${key}`;

interface Props extends HighchartsReactProps {
  data: ReportDateAggregationDto[];
  noDataLabel: boolean;
  aggregationEnabled: boolean;
  aggregationThreshold: number;
}

const ReportDatesByOrganisationChart: FC<Props> = ({
  data,
  aggregationEnabled,
  aggregationThreshold,
  ...rest
}) => {
  const { ReportsByDatesDefaultSettings } = useWidgetTranslations();
  const { t } = useTranslation();
  const createHighchartsSeriesData = (
    chartData: ReportDateAggregationDto[]
  ): Highcharts.SeriesAreaOptions[] => {
    const orgNames = chartData[0].organisationCounts.map((x) => x.organisation); //only need to extract the keys apart from 'date'

    // Map over the keys array to create a new array of objects.
    const seriesDataKeys = orgNames.map((orgName) => ({
      name: orgName,
    }));

    const formatReportTypeCounts = (
      chartData: ReportDateAggregationDto[],
      key: string
    ): number[] => {
      return chartData.map((x) =>
        x.organisationCounts
          ? x.organisationCounts.find((x) => x.organisation === key)?.count ?? 0
          : 0
      );
    };

    return seriesDataKeys.map((x) => ({
      ...x,
      type: 'area',
      data: formatReportTypeCounts(chartData, x.name),
    }));
  };

  const textNoData = ReportsByDatesDefaultSettings.noDataLabel;

  const processedData = aggregationEnabled
    ? aggregateMinorOrgs(data, aggregationThreshold)
    : data;

  const chartData = createChartData(processedData);

  const hasData = data && data.length > 0;

  const minDate = new Date(chartData.at(0)?.date);
  // const maxDate = new Date(chartData.at(-1)?.date);

  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'area',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: t('charts.ReportDatesByOrganisationChart.Reports By Organisation'),
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%b %Y', //ex- 01 Jan 2016
      },
    },
    yAxis: {
      title: {
        text: t('charts.ReportDatesByOrganisationChart.Number of Reports'),
      },
      type: 'linear',
    },
    plotOptions: {
      area: {
        pointStart: Date.UTC(
          minDate.getFullYear(),
          minDate.getMonth(),
          minDate.getDate()
        ),
        pointInterval: 1,
        pointIntervalUnit: 'month',
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series: createHighchartsSeriesData(processedData),
  };

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  return hasData ? (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      ref={chartComponentRef}
      {...rest}
    />
  ) : (
    <NoDataText label={textNoData} />
  );
};

ReportDatesByOrganisationChart.propTypes = {
  data: PropTypes.array.isRequired,
  aggregationEnabled: PropTypes.bool.isRequired,
  aggregationThreshold: PropTypes.number.isRequired,
};

export default ReportDatesByOrganisationChart;
