import React, { FC, useState } from 'react';
import {
  Paper,
  Grid,
  Tooltip,
  IconButton,
  withStyles,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  WithStyles,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import { toast } from 'react-toastify';
import AliasItem from './AliasItem';
import { AliasMapDto } from '../../api/apiDtos';
import { useTranslation } from 'react-i18next';

const styles = {
  paper: {
    padding: 5,
    marginBottom: 5,
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'end',
    padding: '10px 0',
  },
  cancelButton: {
    marginLeft: 10,
  },
  addButton: {
    height: '100%',
    marginLeft: 5,
  },
};

interface Props extends WithStyles<typeof styles> {
  aliasMap: AliasMapDto;
  onEdit: () => void;
}

const EditAliasDialog: FC<Props> = ({ aliasMap, onEdit, classes }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [aliases, setAliases] = useState<string[]>(aliasMap.aliases || []);
  const [newAlias, setNewAlias] = useState('');

  const submit = async () => {
    if (aliases.length === 0) {
      return;
    }
    const editedAliasMap = {
      entityType: aliasMap.entityType,
      name: aliasMap.name,
      aliases,
    };
    await api.post(ApiRoutes.saveAlias, editedAliasMap);
    toast.success(t('Alias.EditAliasDialog.Aliases edited'));
    setOpen(false);
    onEdit();
  };

  const add = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!newAlias) {
      return;
    }
    setAliases((prevState) => {
      return prevState.some((a) => a.toLowerCase() === newAlias.toLowerCase())
        ? prevState
        : prevState.concat([newAlias]);
    });
    setNewAlias('');
  };

  const remove = (alias: string) => {
    setAliases((prevState) => prevState.filter((a) => a !== alias));
  };

  const onOpen = () => {
    setAliases(aliasMap.aliases || []);
    setOpen(true);
  };

  return (
    <>
      <Tooltip title={t('Alias.EditAliasDialog.Edit aliases')}>
        <IconButton color="primary" onClick={onOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle>
          {t('Alias.EditAliasDialog.Edit aliases for', { name: aliasMap.name })}
        </DialogTitle>
        <DialogContent>
          <Paper className={classes.paper}>
            <form onSubmit={add}>
              <Grid container>
                <Grid item xs={10}>
                  <TextField
                    value={newAlias}
                    onChange={(e) => setNewAlias(e.target.value.trim())}
                    variant="outlined"
                    label={t('Alias.EditAliasDialog.New alias')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={add}
                    disabled={!newAlias}
                    startIcon={<AddCircleOutlineIcon />}
                    className={classes.addButton}
                  >
                    {t('Alias.EditAliasDialog.Add')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
          {aliases.length > 0 && (
            <Paper className={classes.paper}>
              {aliases.map((alias) => (
                <AliasItem
                  key={alias}
                  alias={alias}
                  onRemove={() => remove(alias)}
                />
              ))}
            </Paper>
          )}
          <div className={classes.dialogActions}>
            <Button
              type="submit"
              onClick={submit}
              color="primary"
              variant="contained"
              disabled={aliases.length === 0}
            >
              {t('Alias.EditAliasDialog.Submit')}
            </Button>
            <Button
              onClick={() => setOpen(false)}
              color="primary"
              variant="outlined"
              className={classes.cancelButton}
            >
              {t('Global.Cancel')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(EditAliasDialog);
