import React from 'react';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { AlertButton } from './AlertButton';
import { useTrackerStyles } from './styles';
import { formatDateCommon } from '../../helpers/date-helper';
import {
  NotificationsNone as NotificationsNoneIcon,
  NotificationsOff as NotificationsOffIcon,
  EditOutlined,
  DeleteOutline,
} from '@material-ui/icons';
import { TrackerDto } from '../../api/apiDtos';
import { useTranslation } from 'react-i18next';

interface TrackerItemProps {
  item: TrackerDto;
  notifiationClick: (event: any) => void;
  trackerDeleteConfitmation: (item: TrackerDto) => void;
  handleTrackerEditConfirmation: (item: TrackerDto) => void;
}

export const TrackerItem: React.FC<TrackerItemProps> = ({
  notifiationClick,
  item,
  trackerDeleteConfitmation,
  handleTrackerEditConfirmation,
}) => {
  const classes = useTrackerStyles();
  const { t } = useTranslation();
  if (!item) {
    return <></>;
  }

  const { name, dateCreated, dateUpdated, hasAlert } = item;
  const className = classes.itemWrapper;

  const handleTrackerAlertClick = (e) => {
    e.stopPropagation();
    notifiationClick(e);
  };

  const handleTrackerDeleteClick = (e, item: TrackerDto) => {
    e.stopPropagation();
    trackerDeleteConfitmation(item);
  };

  const handleTrackerEditClick = (e, item: TrackerDto) => {
    e.stopPropagation();
    handleTrackerEditConfirmation(item);
  };

  return (
    <div className={className}>
      <Grid direction="row" container>
        <Grid item xs={11}>
          <Typography variant="subtitle1">{name}</Typography>
          <Typography variant="body1">
            {t('tracker.TrackerItem.Created')}: {formatDateCommon(dateCreated)}
          </Typography>
          <Typography variant="body1">
            {t('tracker.TrackerItem.Last updated')}:{' '}
            {formatDateCommon(dateUpdated || dateCreated)}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {hasAlert ? (
            <Tooltip title={t('tracker.TrackerItem.Mute Notification')}>
              <NotificationsNoneIcon
                color="secondary"
                onClick={handleTrackerAlertClick}
              />
            </Tooltip>
          ) : (
            <Tooltip title={t('tracker.TrackerItem.Unmute Notification')}>
              <NotificationsOffIcon
                color="secondary"
                onClick={handleTrackerAlertClick}
              />
            </Tooltip>
          )}
          <Grid
            style={{ cursor: 'pointer' }}
            item
            xs={12}
            container
            justifyContent="flex-end"
          ></Grid>
        </Grid>
        <Grid
          style={{ cursor: 'pointer' }}
          item
          xs={12}
          container
          justifyContent="flex-end"
        >
          <Tooltip title={t('tracker.TrackerItem.Edit Tracker')}>
            <EditOutlined
              color="primary"
              onClick={(event) => {
                handleTrackerEditClick(event, item);
              }}
            />
          </Tooltip>
          <Tooltip title={t('tracker.TrackerItem.Delete Tracker')}>
            <DeleteOutline
              color="primary"
              onClick={(event) => {
                handleTrackerDeleteClick(event, item);
              }}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
};
