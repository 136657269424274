import { User } from '@auth0/auth0-react'
import { create } from 'zustand'

interface appStore {
  currentUser: User | null
  setCurrentUser: (loggedInUser: User) => void;
}

const useLoggedInUser = create<appStore>()((set) => ({
  currentUser: null,
  setCurrentUser: (newUser) => set({ currentUser: newUser}) 
}));

export default useLoggedInUser;