import React from 'react';
import {
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';

const styles = {
  root: {
    margin: '0 !important',
    boxShadow: 'none !important',
    background: 'transparent',
  },
  summaryRoot: {
    minHeight: 'unset !important',
    padding: '0 0 0 14px',
    background: 'transparent',
  },
  summaryContent: {
    margin: '0 !important',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#fff',
    fontSize: 14,
    fontWeight: 500,
  },
  summaryExpandIcon: {
    marginRight: '4px !important',
  },
  detailsRoot: {
    padding: 0,
  },
};

const FilterDrawerSection = ({
  isExpanded,
  onChange,
  classes,
  title,
  children,
}) => {
  return (
    <Accordion
      square
      expanded={isExpanded}
      onChange={onChange}
      classes={{
        root: classes.root,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        classes={{
          root: classes.summaryRoot,
          content: classes.summaryContent,
          expandIcon: classes.summaryExpandIcon,
        }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.detailsRoot,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

FilterDrawerSection.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};

export default withStyles(styles)(FilterDrawerSection);
