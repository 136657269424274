import React, { useState, useEffect } from 'react';
import LocalComboBox from './local-combo-box';
import API from '../../services/ApiInstance';
import apiRoutes from '../../services/ApiRoutes';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { OrganisationDto } from '../../api/apiDtos';
import { useTranslation } from 'react-i18next';

const sortByLevel = (a, b) => a.level - b.level;

const sortByParentLevelOne = (orgs) => {
  const grouped = orgs.reduce((grouped, org) => {
    const group = grouped[org.parentLevelOne];
    if (group) {
      group.push(org);
    } else {
      grouped[org.parentLevelOne] = [org];
    }
    return grouped;
  }, {});
  const result = [];
  Object.keys(grouped).forEach((parentLevelOne) => {
    result.push(...grouped[parentLevelOne].sort(sortByLevel));
  });
  return result;
};

const renderOrgOption = (org) => {
  return org.level === 0 || org.level === 1 ? (
    <Typography variant="h6">{org.name}</Typography>
  ) : (
    <span style={{ paddingLeft: 5 }}>{org.name}</span>
  );
};

function ClientComboBox(props) {
  const {
    customLabel,
    defaultValue,
    onChange,
    excludeSuperOrganisation,
    primaryOrganisation = null,
    ...rest
  } = props;
  const { t } = useTranslation();
  const [clients, setClients] = useState([]);
  const [clientsWithoutPrimary, setClientsWithoutPrimary] = useState([]);
  const [loading, setLoading] = useState(false);
  const DEFAULT_LABEL = t('combobox.client-combo-box.Select client');

  useEffect(() => {
    setLoading(true);
    fetchAvailableOrganisationsList().then((res) => {
      setClients(res);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!clients || clients.length == 0 || !primaryOrganisation) return;

    setClientsWithoutPrimary((prevClients) =>
      prevClients.filter((x) => x.id != primaryOrganisation?.id)
    );
  }, [clients, primaryOrganisation]);

  useEffect(() => {
    if (defaultValue) {
      const defaultOrg = clients.find((org) => org.id === defaultValue.id);
      if (defaultOrg) {
        onChange(null, defaultOrg);
      }
    }
  }, [defaultValue, clients]);

  const fetchAvailableOrganisationsList = async () => {
    const orgs = await API.get<any, OrganisationDto[]>(apiRoutes.orgs);
    const orgsWithoutSuperOrg = excludeSuperOrganisation
      ? orgs.filter((o) => o.level >= 1)
      : orgs;
    return sortByParentLevelOne(orgsWithoutSuperOrg);
  };

  return (
    <LocalComboBox
      {...rest}
      onChange={onChange}
      options={primaryOrganisation ? clientsWithoutPrimary ?? [] : clients}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      renderOption={renderOrgOption}
      loading={loading}
      customLabel={customLabel || DEFAULT_LABEL}
    />
  );
}

ClientComboBox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  customLabel: PropTypes.string,
  excludeSuperOrganisation: PropTypes.bool,
  primaryOrganisation: PropTypes.object,
};

export default ClientComboBox;
