// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  RadioGroup,
  Radio,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';
import { getReportWidgetData } from '../../../services/WidgetDataService';
import CircularProgressCentral from '../../common/CircularProgressCentral';
import TimeServiceProvider from '../../../services/TimeService';
import { WidgetCode } from '../widgetCodes';
import NoDataText from '../../common/NoDataText';
import WidgetTitle from '../../help-button/widget-title';
import LifeSavingRulesBarChart from '../../charts/life-saving-rules/LifeSavingRulesBarChart';
import LifeSavingRulesPieChart from '../../charts/life-saving-rules/LifeSavingRulesPieChart';
import LifeSavingRulesRadarChart from '../../charts/life-saving-rules/LifeSavingRulesRadarChart';
import { getSettings } from '../../../helpers/data-helper';
import LifeSavingRuleDialog from './LifeSavingRuleDialog';
import { filters } from '../../../signals/filterService';
import { useTranslation } from 'react-i18next';
import { useWidgetTranslations } from '../useTranslatedWidgetsDefaultSettings';

const styles = {
  chartTypeForm: {
    display: 'flex',
    alignItems: 'center',
  },
  chartTypeLabel: {
    marginRight: 16,
  },
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
};

const timeService = TimeServiceProvider();

const parseLifeSavingRules = (data) => {
  return data.map((item) => ({
    ...item,
    always: JSON.parse(item.always),
    never: JSON.parse(item.never),
  }));
};

const LifeSavingRulesWidget = ({ classes, settings }) => {
  const { LifeSavingRulesDefaultSettings } = useWidgetTranslations();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [dataMappedByCode, setDataMappedByCode] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRule, setSelectedRule] = useState(null);

  const CHART_TYPES = {
    Bar: t('widgets.life_saving_rules.LifeSavingRulesWidget.Bar'),
    Pie: t('widgets.life_saving_rules.LifeSavingRulesWidget.Pie'),
    Radar: t('widgets.life_saving_rules.LifeSavingRulesWidget.Radar'),
  };

  const [chartType, setChartType] = useState(CHART_TYPES.Bar);

  const loadData = async () => {
    setIsLoading(true);
    const response = await getReportWidgetData(WidgetCode.lifeSavingRules);

    const parsedData = parseLifeSavingRules(response);
    setData(parsedData);

    const mappedByCode = parsedData.reduce((result, next) => {
      result[next.code] = next;
      return result;
    }, {});
    setDataMappedByCode(mappedByCode);

    setIsLoading(false);
  };

  useEffect(() => {
    timeService.subscribe(loadData);
    loadData();

    return () => {
      timeService.unsubscribe(loadData);
    };
  }, []);

  useEffect(() => {
    loadData();
  }, [filters.value]);

  const handleChartClick = (ruleCode) => {
    const rule = dataMappedByCode[ruleCode];
    if (rule) {
      setSelectedRule(rule);
    }
  };

  const renderChart = () => {
    switch (chartType) {
      case CHART_TYPES.Bar:
        return (
          <LifeSavingRulesBarChart
            data={data}
            onClick={handleChartClick}
            axisY={axisY}
          />
        );
      case CHART_TYPES.Pie:
        return (
          <LifeSavingRulesPieChart data={data} onClick={handleChartClick} />
        );
      case CHART_TYPES.Radar:
        return (
          <LifeSavingRulesRadarChart data={data} onClick={handleChartClick} />
        );
      default:
        return null;
    }
  };

  const { title, info, axisY, noDataLabel } = getSettings(
    settings,
    LifeSavingRulesDefaultSettings
  );

  const hasData = data.length > 0;

  return isLoading ? (
    <CircularProgressCentral />
  ) : (
    <Card variant="outlined" className={classes.card}>
      <CardHeader
        title={<WidgetTitle title={title} info={info} />}
        titleTypographyProps={{ variant: 'subtitle1' }}
        action={
          <div className={classes.chartTypeForm}>
            <div className={classes.chartTypeLabel}>
              {t('widgets.life_saving_rules.LifeSavingRulesWidget.Chart type')}:
            </div>
            <RadioGroup
              value={chartType}
              onChange={(e) => setChartType(e.target.value)}
              row
            >
              {Object.keys(CHART_TYPES).map((chartTypeName) => (
                <FormControlLabel
                  key={chartTypeName}
                  value={chartTypeName}
                  control={<Radio color="primary" />}
                  label={chartTypeName}
                  labelPlacement="end"
                />
              ))}
            </RadioGroup>
          </div>
        }
      />
      <CardContent className={classes.cardContent}>
        {hasData ? renderChart() : <NoDataText label={noDataLabel} />}
      </CardContent>
      <LifeSavingRuleDialog
        rule={selectedRule}
        onClose={() => setSelectedRule(null)}
      />
    </Card>
  );
};

export default withStyles(styles)(LifeSavingRulesWidget);
