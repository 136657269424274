import React, { FC } from 'react';
import {
  IconButton,
  Grid,
  withStyles,
  createStyles,
  WithStyles,
} from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const styles = createStyles({
  itemContainer: {
    border: '#ccc solid 1px',
    marginTop: 5,
    alignItems: 'center',
    borderRadius: 5,
  },
  itemColumn: {
    paddingLeft: 14,
    overflowWrap: 'break-word',
  },
});

interface Props extends WithStyles<typeof styles> {
  alias: string;
  onRemove: () => void;
}

const AliasItem: FC<Props> = ({ alias, onRemove, classes }) => {
  return (
    <Grid container className={classes.itemContainer}>
      <Grid item xs={10} className={classes.itemColumn}>
        {alias}
      </Grid>
      <Grid item xs={2} className={classes.itemColumn}>
        <IconButton color="primary" onClick={onRemove}>
          <RemoveCircleOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AliasItem);
