import React, { useState, useEffect, FC } from 'react';
import {
  withStyles,
  createStyles,
  WithStyles,
  Tabs,
  Tab,
} from '@material-ui/core';
import UserManagement from './user-management/UserManagement';
import OrganisationManagement from './organisation-management/OrganisationManagement';
import UserPreferenceService from '../services/UserPreferenceService';
import TopicManagement from './configure-topics/TopicManagement';
import useAuthorization from '../hooks/useAuthorisationManager';
import { ROLES } from '../constants';
import { useTranslation } from 'react-i18next';

const userPreferenceService = new UserPreferenceService();

const styles = createStyles({
  tabPanel: {
    padding: '0 1em',
  },
});

interface Props extends WithStyles<typeof styles> {}

const GeneralManagement: FC<Props> = ({ classes }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<number>(0);
  const { hasRole } = useAuthorization();
  const isSuperUser = hasRole(ROLES.SuperUser);

  useEffect(() => {
    const savedTab = userPreferenceService.get('GeneralManagementTab');
    if (savedTab !== null) {
      setTab(parseInt(savedTab, 10));
    }
  }, []);

  useEffect(() => {
    userPreferenceService.save('GeneralManagementTab', tab.toString());
  }, [tab]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <Tabs
        value={tab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label={t('GeneralManagement.Users')} />
        <Tab label={t('GeneralManagement.Organisations')} />
        {isSuperUser && <Tab label={t('GeneralManagement.Topics')} />}
      </Tabs>
      <div className={classes.tabPanel}>
        {tab === 0 && <UserManagement />}
        {tab === 1 && <OrganisationManagement />}
        {tab === 2 && isSuperUser && <TopicManagement />}
      </div>
    </>
  );
};

export default withStyles(styles)(GeneralManagement);
