export const METRIC_CODES = {
  Incidents: 'incidents',
  NearMisses: 'nearMisses',
  Observations: 'observations',
};

export const METRIC_TITLES = {
  Incidents: 'Incidents',
  NearMisses: 'Near Misses',
  Observations: 'Observations',
};

export const METRIC_COLORS = {
  Incidents: 'red',
  NearMisses: 'orange',
  Observations: 'blue',
  IncidentsMuted: '#FC8B73',
  NearMissesMuted: '#FCD870',
  ObservationsMuted: '#7AB4FD',
};
