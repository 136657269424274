import React, { FC, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { TargetChartDataDtoBLL } from '../../api/apiDtos';
import HighchartsReact, {
  HighchartsReactProps,
} from 'highcharts-react-official';
import { createStyles } from '@material-ui/styles';
import Highcharts from 'highcharts';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((_) =>
  createStyles({
    targetSetterContainer: {
      paddingBottom: 20,
    },
    targetSetterContent: {
      padding: 10,
      display: 'flex',
      alignItems: 'center',
    },
    targetValueInput: {
      width: 100,
      marginLeft: 10,
    },
    barLabel: {
      position: 'fixed',
      transform: 'translateX(-50%)',
      paddingTop: 5,
      color: 'grey',
    },
    targetValueButton: {
      position: 'fixed',
      transform: 'translate(-50%, -50%)',
      minWidth: 22,
      color: 'white',
      borderStyle: 'solid',
      borderWidth: 1,
      boxShadow:
        '3px 3px 3px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      '&:hover': {
        filter: 'brightness(95%)',
      },
    },
    targetLabel: {
      position: 'absolute',
      bottom: '100%',
      color: 'black',
      textTransform: 'none',
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    chartFlex: {
      display: 'flex',
      '& > div': {
        flex: 1,
        alignSelf: 'stretch',
      },
    },
  })
);

const getAxisYdomainMax = (targetsData: TargetChartDataFiltered[]): number => {
  let axisYmax = Math.max(
    ...targetsData.map((target) =>
      Math.max(target.value, target.current, target.previous)
    )
  );
  // when it equals to 0 the line of axis X is displayed in the center
  return axisYmax ? axisYmax : 4;
};

const createHighchartsData = (
  specificChartData: TargetChartDataFiltered | null
): any[] => {
  if (!specificChartData) return [];

  const { previous, current } = specificChartData;
  const previousData = {
    name: 'Previous',
    data: [previous],
  };
  const currentData = {
    name: 'Current',
    data: [current],
  };
  return [previousData, currentData];
};

const createChartOptions = (
  category: string,
  targetLevel: number,
  targetId: string,
  axisY: any,
  axisYunit: any,
  maxY: number,
  chartData: TargetChartDataFiltered[]
): Highcharts.Options => ({
  chart: {
    events: {
      render: function () {
        const chart = this;
        const yAxis = chart.yAxis[0]; // Assuming the plot line is on the first yAxis
        const plotLineValue = targetLevel; // The y-axis value where your plot line is
        const plotLinePosition = yAxis.toPixels(plotLineValue, false);

        // Find the input box and position it
        const inputBox = document.getElementById(targetId);

        // Calculate position - you may need to adjust this based on your specific layout
        const chartOffset = chart.container.getBoundingClientRect();
        const topPosition =
          chartOffset.top +
          plotLinePosition +
          window.pageYOffset -
          inputBox.offsetHeight / 2; // Center vertically
        const leftPosition =
          chartOffset.left +
          chart.plotLeft +
          chart.plotWidth / 2 -
          inputBox.offsetWidth / 2;

        // Apply styles directly to the input box
        inputBox.style.position = 'absolute';
        inputBox.style.width = '50px'; // Set the width as desired
        inputBox.style.border = '1px solid red'; // Match the border color to your plot line color
        inputBox.style.borderRadius = '0.5em'; // Match the border color to your plot line color
        inputBox.style.backgroundColor = 'red'; // Makes the input background see-through
        inputBox.style.color = 'white'; // Match the text color to your plot line color
        inputBox.style.textAlign = 'center';

        // Set the calculated top and left positions
        inputBox.style.top = `${topPosition}px`;
        inputBox.style.left = `${leftPosition}px`;

        // Make sure it's visible
        inputBox.style.display = 'block';
      },
    },
    type: 'column',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false, // Hide the legends
  },
  xAxis: {
    categories: [category],
  },
  yAxis: {
    labels: {
      enabled: category === 'Incidents',
    },
    title: {
      text: category === 'Incidents' ? `${axisY}, ${axisYunit}` : '',
    },
    tickInterval: 1,
    max: maxY,
    plotLines: [
      {
        value: targetLevel,
        color: 'red',
        dashStyle: 'Dash',
        width: 2,
        label: {
          style: {
            color: 'red',
          },
        },
        zIndex: 5,
      },
    ],
  },
  series: createHighchartsData(
    chartData.find((x) => x.metricTitle === category) ?? null
  ),
});

type TargetChartDataFiltered = {
  id?: string;
  metricTitle?: string | null;
  metricCode?: string | null;
  isPositive?: boolean;
  goal?: TargetChartDataDtoBLL;
  current?: number;
  previous?: number;
  value?: number;
};

type TargetValues = {
  incidents: number;
  nearMisses: number;
  observations: number;
};

interface Props extends HighchartsReactProps {
  targetsData: TargetChartDataFiltered[];
  onUpdateTargetValue: (targetId: string, value: any) => void;
  isPercentages: Function;
  axisY: string;
}

const TargetsChart: FC<Props> = (props) => {
  const { t } = useTranslation();

  const {
    targetsData,
    onUpdateTargetValue,
    isPercentages,
    axisY = t('charts.TargetsChart.Metric Values'),
    ...highChartsProps
  } = props;
  const classes = useStyles();

  const formatTargetsData = () => {
    return targetsData.reduce((acc, curr) => {
      const { metricCode, value } = curr;
      acc[metricCode] = value;
      return acc;
    }, {} as TargetValues);
  };

  // const prevTargetValues = formatTargetsData();
  const [targetValues, setTargetValues] = useState<TargetValues | null>(
    formatTargetsData
  );

  const maxY = getAxisYdomainMax(targetsData);
  const axisYunit = isPercentages ? '%' : t('charts.TargetsChart.numbers');

  const incidentChart = createChartOptions(
    'Incidents',
    targetValues['incidents'],
    'incidentsTarget',
    axisY,
    axisYunit,
    maxY,
    targetsData
  );

  const nearMissesChart = createChartOptions(
    'Near Misses',
    targetValues['nearMisses'],
    'nearMissesTarget',
    axisY,
    axisYunit,
    maxY,
    targetsData
  );
  const observationsChart = createChartOptions(
    'Observations',
    targetValues['observations'],
    'observationsTarget',
    axisY,
    axisYunit,
    maxY,
    targetsData
  );

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const chartComponent2Ref = useRef<HighchartsReact.RefObject>(null);
  const chartComponent3Ref = useRef<HighchartsReact.RefObject>(null);

  return (
    <>
      <style>
        {`
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }
  `}
      </style>
      <div className={classes.chartFlex}>
        <div>
          <HighchartsReact
            highcharts={Highcharts}
            options={incidentChart}
            ref={chartComponentRef}
            {...highChartsProps}
          />
        </div>
        <div>
          <HighchartsReact
            highcharts={Highcharts}
            options={nearMissesChart}
            ref={chartComponent2Ref}
            {...highChartsProps}
          />
        </div>
        <div>
          <HighchartsReact
            highcharts={Highcharts}
            options={observationsChart}
            ref={chartComponent3Ref}
            {...highChartsProps}
          />
        </div>
      </div>
      <input
        type="number"
        id="incidentsTarget"
        style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}
        value={targetValues['incidents']}
        min={0}
        max={maxY}
        onChange={(evt) => {
          evt.persist(); //remove the synthetic event from the pool and allow references to the event to be retained by user code
          setTargetValues(
            (prevTargetValues) =>
              evt.target.valueAsNumber <= maxY && {
                ...prevTargetValues,
                incidents: evt.target.valueAsNumber,
              }
          );
        }}
        onKeyDown={(evt) =>
          evt.key === 'Enter' &&
          targetValues['incidents'] <= maxY &&
          onUpdateTargetValue(
            targetsData.find((x) => x.metricCode === 'incidents')?.id ?? '',
            targetValues['incidents']
          )
        }
      />
      <input
        type="number"
        id="nearMissesTarget"
        style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}
        value={targetValues['nearMisses']}
        min={0}
        max={maxY}
        onChange={(evt) => {
          evt.persist(); //remove the synthetic event from the pool and allow references to the event to be retained by user code
          setTargetValues(
            (prevTargetValues) =>
              evt.target.valueAsNumber <= maxY && {
                ...prevTargetValues,
                nearMisses: evt.target.valueAsNumber,
              }
          );
        }}
        onKeyDown={(evt) =>
          evt.key === 'Enter' &&
          targetValues['nearMisses'] <= maxY &&
          onUpdateTargetValue(
            targetsData.find((x) => x.metricCode === 'nearMisses')?.id ?? '',
            targetValues['nearMisses']
          )
        }
      />
      <input
        type="number"
        id="observationsTarget"
        style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}
        value={targetValues['observations']}
        min={0}
        max={maxY}
        onChange={(evt) => {
          evt.persist(); //remove the synthetic event from the pool and allow references to the event to be retained by user code
          setTargetValues(
            (prevTargetValues) =>
              evt.target.valueAsNumber <= maxY && {
                ...prevTargetValues,
                observations: evt.target.valueAsNumber,
              }
          );
        }}
        onKeyDown={(evt) =>
          evt.key === 'Enter' &&
          targetValues['observations'] <= maxY &&
          onUpdateTargetValue(
            targetsData.find((x) => x.metricCode === 'observations')?.id ?? '',
            targetValues['observations']
          )
        }
      />
    </>
  );
};

export default TargetsChart;
