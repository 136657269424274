export const OTHERS = 'Others';

export const ROUTES = {
  WidgetsAndFilters: '/widgets-and-filters',
  DashboardLayout: '/dashboardlayout',
  Licences: '/licences',
  Actions: '/actions',
  UsersAndOrganisations: '/users-and-organisations',
  UserProfile: '/user-profile',
  Upload: '/upload',
  Trackers: '/trackers',
  SpecificAlert: '/trackers/:alertId',
  Dashboard: '/',
  About: '/about',
  Facilities: '/facilities',
  Aliases: '/aliases',
  SuperUserPanel: '/super-user-panel',
  Personalise: '/personalise',
  ActivityLogs: '/activity-logs',
}as const;

export type Routes = typeof ROUTES[keyof typeof ROUTES]

export const LAYOUT = {
  HeaderHeight: 64,
};

export const PERMISSIONS = {
  DashboardConfig: {
    CreateUpdate: 'dashboardConfig.createUpdate',
  },
  File: {
    Any: 'file.any',
  },
  FilterConfig: {
    CreateUpdate: 'filterConfig.createUpdate',
  },
  Organisation: {
    Read: 'org.read',
    Update: 'org.update',
  },
  User: {
    Read: 'user.read',
    Update: 'user.update',
  },
  WidgetConfig: {
    CreateUpdate: 'widgetConfig.createUpdate',
  },
  Facility: {
    Create: 'facility.create',
    Read: 'facility.read',
    Update: 'facility.update',
    Delete: 'facility.delete',
  },
  Report: {
    Update: 'report.update',
  },
  AliasMap: {
    Read: 'aliasMap.read',
    CreateUpdate: 'aliasMap.createUpdate',
    Delete: 'aliasMap.delete',
  },
  ThemeConfig: {
    CreateUpdate: 'themeConfig.createUpdate',
  },
  ActivityLog: {
    Read: 'activityLog.read',
  },
} as const;

type NestedStrings<T> = {
  [P in keyof T]: T[P] extends string ? T[P] : NestedStrings<T[P]>
}[keyof T];
export type Permissions = NestedStrings<typeof PERMISSIONS>;

export const ROLES = {
  SuperUser: 'Super User',
  Admin: 'Admin',
  Contributor: 'Contributor',
  Reader: 'Reader',
} as const;

type RolesKeys = keyof typeof ROLES
export type Roles = typeof ROLES[RolesKeys] 

export const CLASSIFICATION_TYPES = {
  Hazard: 1,
  Topic: 2,
  Severity: 4,
  RootCause: 8,
  FieldVerification: 16,
  Observation: 32,
} as const;

type ClassificationKeys = keyof typeof CLASSIFICATION_TYPES;
export type ClassificationEnums = typeof CLASSIFICATION_TYPES[ClassificationKeys] & number

export const CLASSIFICATION_TYPE_OPTIONS = [
  { value: CLASSIFICATION_TYPES.Hazard, name: 'Hazard' },
  { value: CLASSIFICATION_TYPES.Topic, name: 'Topic' },
  { value: CLASSIFICATION_TYPES.Severity, name: 'Severity' },
  { value: CLASSIFICATION_TYPES.RootCause, name: 'Root Cause' },
];

export const DEFAULT_THEME_CONFIG = {
  navBarColour: '#0095FD',
  textColour: '#FFF',
  activeLinkFill: '#0095FD',
  inactiveLinkFill: '#FFF',
};

export const ACTIVITY_LEVELS = {
  Information: 0,
  Warning: 1,
  Error: 2,
};

export const ADVANCED_SEARCH_PREFIX = '__';

export const AlertTypes = {
  create: 'Create',
  edit: 'Edit',
  delete: 'Delete'
}

export const AZURE_OPENAI_REPORTING_FUNCTION_URL = `https://cometsignals-reporting.azurewebsites.net/api/csig_narrative_reporting`
