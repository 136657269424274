// @ts-nocheck
import React, { useState, useEffect } from 'react';
import MUIDatatable from 'mui-datatables';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import AddFacilityDialog from './AddFacilityDialog';
import UpdateFacilityDialog from './UpdateFacilityDialog';
import DeleteFacilityDialog from './DeleteFacilityDialog';
import {
  withStyles,
  Card,
  CardHeader,
  CardContent,
  TextField,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { PERMISSIONS } from '../../constants';
import useAuthorization from '../../hooks/useAuthorisationManager';
import { useTranslation } from 'react-i18next';

const styles = {
  actionsColumn: {
    display: 'flex',
    alignItems: 'center',
  },
  cardHeaderAction: {
    margin: 0,
  },
};

let searchTimer = null;
const SEARCH_TIMEOUT_MS = 1000;

const FacilityManagement = ({ classes }) => {
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();
  const [organisations, setOrganisations] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('');
  const [sortDesc, setSortDesc] = useState(false);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState('');

  const loadFacilities = (page, rowsPerPage, sortBy, sortDesc, search) => {
    const url = `${ApiRoutes.getFacilitiesWithPagination}?page=${page}&rowsPerPage=${rowsPerPage}&sortBy=${sortBy}&sortDesc=${sortDesc}&search=${search}`;
    api.get(url).then((response) => {
      const { data, total } = response;
      setFacilities(data);
      setCount(total);
    });
  };

  const loadFacilitiesWithCurrentSettings = () =>
    loadFacilities(page, rowsPerPage, sortBy, sortDesc, search);

  useEffect(() => {
    const loadOrganisations = () => {
      api.get(ApiRoutes.orgs).then((response) => {
        setOrganisations(response);
      });
    };
    loadFacilitiesWithCurrentSettings();
    loadOrganisations();
  }, []);

  const canAdd = hasPermission(PERMISSIONS.Facility.Create);
  const canUpdate = hasPermission(PERMISSIONS.Facility.Update);
  const canDelete = hasPermission(PERMISSIONS.Facility.Delete);

  const columns = [
    { label: t('facilities.FacilityManagement.ID'), name: 'localId' },
    { label: t('facilities.FacilityManagement.Name'), name: 'name' },
    { label: t('facilities.FacilityManagement.Postcode'), name: 'postcode' },
    {
      label: t('facilities.FacilityManagement.Organisation'),
      name: 'organisation.name',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const facility = facilities[dataIndex];
          return facility ? facility.organisation.name : null;
        },
      },
    },
    {
      name: '',
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const facility = facilities[dataIndex];
          return (
            facility && (
              <div className={classes.actionsColumn}>
                {canUpdate && (
                  <UpdateFacilityDialog
                    facility={facility}
                    organisations={organisations}
                    onUpdate={loadFacilitiesWithCurrentSettings}
                  />
                )}
                {canDelete && (
                  <DeleteFacilityDialog
                    facility={facility}
                    onDelete={loadFacilitiesWithCurrentSettings}
                  />
                )}
              </div>
            )
          );
        },
      },
    },
  ];

  const tableChangeHandler = (action, tableState) => {
    const {
      page: newPage,
      rowsPerPage: newRowsPerPage,
      sortOrder,
      searchText,
    } = tableState;
    const newSortBy = sortOrder.name ? sortOrder.name : '';
    const newSortDesc = sortOrder.direction === 'desc';
    const newSearch = searchText ? searchText : '';

    if (['changePage', 'changeRowsPerPage', 'sort'].includes(action)) {
      loadFacilities(
        newPage,
        newRowsPerPage,
        newSortBy,
        newSortDesc,
        newSearch
      );
      setPage(newPage);
      setRowsPerPage(newRowsPerPage);
      setSortBy(newSortBy);
      setSortDesc(newSortDesc);
    } else if (action === 'search') {
      clearTimeout(searchTimer);
      searchTimer = setTimeout(() => {
        loadFacilities(
          newPage,
          newRowsPerPage,
          newSortBy,
          newSortDesc,
          newSearch
        );
      }, SEARCH_TIMEOUT_MS);
      setSearch(newSearch);
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={t('facilities.FacilityManagement.Manage Facilities')}
        classes={{ action: classes.cardHeaderAction }}
        action={
          canAdd && (
            <AddFacilityDialog
              organisations={organisations}
              onAdd={loadFacilitiesWithCurrentSettings}
            />
          )
        }
      />
      <CardContent>
        <MUIDatatable
          data={facilities}
          columns={columns}
          options={{
            selectableRows: 'none',
            print: false,
            search: true,
            searchAlwaysOpen: true,
            filter: false,
            download: false,
            viewColumns: false,
            serverSide: true,
            count,
            onTableChange: tableChangeHandler,
            customSearchRender: (searchText, handleSearch) => (
              <TextField
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ width: 600 }}
                InputProps={{
                  startAdornment: (
                    <SearchIcon color="disabled" style={{ padding: 12 }} />
                  ),
                  endAdornment: search ? (
                    <IconButton onClick={() => handleSearch('')}>
                      <ClearIcon />
                    </IconButton>
                  ) : null,
                }}
              />
            ),
          }}
        />
      </CardContent>
    </Card>
  );
};

FacilityManagement.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FacilityManagement);
