import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import PropTypes from 'prop-types';
import { METRIC_CODES } from './constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  header: {
    padding: '0 5px 0 60px',
  },
  content: {
    border: '1px solid lightgrey',
    borderRadius: '0.5em',
    position: 'relative',
  },
  label: {
    position: 'absolute',
    left: 10,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  inner: {
    padding: '0 5px 0 60px',
    height: 50,
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  metricTitle: {
    fontSize: 20,
    margin: '5px 0',
  },
  trendContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    margin: 'auto',
  },
  trendText: {
    color: 'black',
    fontSize: 18,
    marginRight: 5,
  },
  badTrend: {
    color: 'red',
  },
  goodTrend: {
    color: 'green',
  },
  neutralTrend: {
    color: '#566573',
  },
});

const ArrowIcon = ({ target }) => {
  const { current, previous } = target;
  return current > previous ? (
    <TrendingUpIcon />
  ) : current < previous ? (
    <TrendingDownIcon />
  ) : (
    <TrendingFlatIcon />
  );
};

const TargetTrend = ({ target }) => {
  const { current, previous, isPositive, metricCode } = target;
  const classes = useStyles();

  const getText = () => {
    if (previous !== 0) {
      const variance = Math.floor((current / previous - 1) * 100);
      return `${variance}%`;
    } else if (current === 0) {
      return '0%';
    } else {
      return '';
    }
  };

  const getTrendClass = () => {
    if (metricCode === METRIC_CODES.Observations) {
      return classes.neutralTrend;
    }
    if (current > previous) {
      return isPositive ? classes.goodTrend : classes.badTrend;
    } else if (current < previous) {
      return isPositive ? classes.badTrend : classes.goodTrend;
    } else {
      return classes.neutralTrend;
    }
  };

  const trendClass = getTrendClass();
  const text = getText();
  return (
    <div className={`${classes.trendContainer} ${trendClass}`}>
      <span className={classes.trendText}>{text}</span>
      <ArrowIcon target={target} />
    </div>
  );
};

const TargetTrends = ({ targetsData }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.header}>
        {targetsData.map((target) => (
          <Grid
            key={`trend-title-${target.id}`}
            item
            xs={4}
            className={classes.column}
          >
            <h2 className={classes.metricTitle}>{target.metricTitle}</h2>
          </Grid>
        ))}
      </Grid>
      <div className={classes.content}>
        <span className={classes.label}>
          {t('tracker.TargetTrends.Trends')}
        </span>
        <Grid container className={classes.inner}>
          {targetsData.map((target) => (
            <Grid
              key={`trend-${target.id}`}
              item
              xs={4}
              className={classes.column}
            >
              <TargetTrend target={target} />
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

TargetTrends.propTypes = {
  targetsData: PropTypes.array.isRequired,
};

export default TargetTrends;
