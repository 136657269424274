//@ts-nocheck
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Drawer,
  makeStyles,
  Button,
  FormControl,
  FormLabel,
  IconButton,
} from '@material-ui/core';
import {
  Remove as RemoveIcon,
  Refresh as RefreshIcon,
  ChevronLeft,
  ChevronRight,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import FilterAutocompleteSelect from './FilterAutocompleteSelect';
import FilterNumberInput from './FilterNumberInput';
import FilterChips from './FilterChips';
import FilterSwitch from './FilterSwitch';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import TimeServiceProvider from '../../services/TimeService';
import moment from 'moment';
import clsx from 'clsx';
import FilterDrawerSection from './FilterDrawerSection';
import SaveTrackerDialog from './SaveTrackerDialog';
import { LAYOUT } from '../../constants';
import { isObjectEmpty } from '../../helpers/data-helper';
import { appendObject } from '../../helpers/form-data-helper';
// import {
//   TopicFilterContext,
//   useTopicFilters,
// } from '../../context/TopicFilterContextProvider';

import { useDateUpdate } from '../../context/DateUpdateContextProvider';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  GenerateFilterParams,
  ClearFilters,
  HasFilters,
  Filter,
  FilterTags,
  filters,
} from '../../signals/filterService';
import { FilterTypes, FilterVariants } from '../../signals/filterService';
import { FilterSectionDataDto } from '../../api/apiDtos';
import DateSelector from '../../components/filters/DateSelector';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  drawerContainer: {
    position: 'relative', // Create a positioning context for the toggle button
  },
  toggleButton: {
    position: 'fixed',
    left: '270px',
    top: '75px',
    zIndex: 1200,
    color: '#fff',
    background: '#d94936',
    '&:hover': {
      color: '#fff',
      background: '#d94936',
    },
  },
  toggleButtonCollapsed: {
    position: 'fixed',
    left: '1px',
    top: '75px',
    zIndex: 1200,
    color: '#fff',
    background: '#d94936',
    '&:hover': {
      color: '#fff',
      background: '#d94936',
    },
  },
  drawerPaper: {
    overflowY: 'auto', // for vertical scrolling
    overflowX: 'hidden',
    background: '#424C55',
    top: LAYOUT.HeaderHeight,
    bottom: 0,
    width: (props) => props.width,
    maxWidth: '287px',
    height: 'unset',
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F1F3F4',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(193, 193, 193)',
      borderRadius: 100,
    },
  },
  drawerPaperCollapsed: {
    overflowY: 'auto', // Allow for scrolling
    overflowX: 'hidden',
  },
  drawerHeader: {
    padding: '8px 14px',
  },
  drawerActions: {
    paddingLeft: 10,
    paddingRight: 20,
  },
  sectionBody: {
    width: '100%',
    padding: '5px 24px',
  },
  separator: {
    margin: '0 5px',
    fontSize: 15,
  },
  numberInputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rangeContainer: {
    marginTop: 8,
  },
  numInputLabel: {
    marginBottom: 10,
  },
  spinner: {
    marginTopTop: '15px',
    marginRight: '60px',
  },
  inactiveButton: {
    marginBottom: '.5em',
    height: '35px',
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  headerButton: {
    marginBottom: '.5em',
    height: '35px',
  },
});

const sortByOrder = (a, b) => a.order - b.order;

const FilterDrawer = ({ width, onCreateTracker, onToggle }) => {
  const timeService = TimeServiceProvider();
  const [timeDomain, setTimeDomain] = useState(timeService.domain);
  const [isOpen, setIsOpen] = useState(true);
  const [expandedSection, setExpandedSection] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [filterData, setFilterData] = useState({});
  const [filterConfig, setFilterConfig] = useState({ sections: [] });
  const { t } = useTranslation();
  //const [topicFilters] = useTopicFilters();
  const [dateUpdate] = useDateUpdate();

  const classes = useStyles({ width });

  useEffect(() => {
    timeService.subscribe(updateTimeDomain);
    getFilterData();
    getAssociatedReportFilterData();
    const loadFilterConfiguration = () => {
      api.get(ApiRoutes.getOwnFilterConfig).then(
        (response) => {
          const config = response;
          if (config) {
            config.sections.sort(sortByOrder);
            config.sections.forEach((s) => s.settings.sort(sortByOrder));
            const firstEnabledSection = config.sections.find(
              (s) => s.isEnabled
            );
            if (firstEnabledSection) {
              setExpandedSection(firstEnabledSection.typeId);
            }
            setFilterConfig(config);
          }
        },
        (err) => {
          console.log(
            t('filters.FilterDrawer.failed getting filter config: ') + err
          );
        }
      );
    };
    loadFilterConfiguration();
    return () => {
      timeService.unsubscribe(updateTimeDomain);
    };
  }, []);

  const processFilterData = (data: FilterSectionDataDto) => {
    Object.entries(data.options).forEach(([filterTag, rawOptions]) => {
      data.options[filterTag] = rawOptions.map((o) =>
        generateFilterOption(o, filterTag)
      );
    });
  };

  useEffect(() => {
    if (dateUpdate.length) {
      getFilterData();
    }
  }, [dateUpdate]);

  useEffect(() => {
    timeService.setTime(timeDomain);
  }, [timeDomain]);

  useEffect(
    function updateSelectedValuesWhenFiltersChange() {
      getFilterData();
    },
    [filters.value]
  );

  const getSectionTotalCount = (sectionTypeId) => {
    const sectionData = filterData[sectionTypeId];
    return sectionData ? sectionData.totalCount : 0;
  };

  const handleSectionChange = (sectionTypeId) => (event, newExpanded) => {
    setExpandedSection(newExpanded ? sectionTypeId : false);
  };
  const updateTimeDomain = () => {
    setTimeDomain(timeService.domain);
  };
  const handleDomainChange = (newTimeDomain: (number | Moment)[]) => {
    timeService.setTime(newTimeDomain);
    setTimeDomain(newTimeDomain);
  };

  const getFilterData = () => {
    setIsLoading(true);
    const filters = GenerateFilterParams(FilterTypes.report);
    const formData = new FormData();
    const [dateFrom, dateTo] = timeService.domain;

    formData.append('dateFrom', moment(dateFrom).toJSON());
    formData.append('dateTo', moment(dateTo).toJSON());
    appendObject(formData, 'filters', isObjectEmpty(filters) ? {} : filters);

    // if (topicFilters.enum != null) {
    //   formData.append('topicFilters', JSON.stringify(topicFilters));
    // } else {
    //   formData.append('topicFilters', '');
    // }
    api
      .post<string, FilterSectionDataDto>(ApiRoutes.getReportFilters, formData)
      .then(
        (res) => {
          // this is the data you need to change in the back end to incorporate the new filter parameter
          if (res) {
            processFilterData(res);
            setFilterData((prevState) => ({
              ...prevState,
              [res.sectionTypeId]: res,
            }));
          }
          setIsLoading(false);
        },
        (err) => {
          console.log(
            t('filters.FilterDrawer.FAILED_GETTING_TYPE', {
              FilterTypesReport: FilterTypes.report,
            }),
            err
          );
        }
      );
  };

  //TODO: delete

  const getRangeFilter = (setting, minTag, maxTag) => {
    return (
      <FormControl key={setting.typeCode} className={classes.rangeContainer}>
        <FormLabel className={classes.numInputLabel} style={{ color: 'white' }}>
          {setting.title}
        </FormLabel>
        <div
          className={classes.numberInputContainer}
          style={{ color: 'white' }}
        >
          <FilterNumberInput
            label={t('filters.FilterDrawer.Min')}
            filterName={t('filters.FilterDrawer.Min')` ${setting.title}`}
            filterTag={minTag}
          />
          <RemoveIcon className={classes.separator} />
          <FilterNumberInput
            label={t('filters.FilterDrawer.Max')}
            filterName={t('filters.FilterDrawer.Max')` ${setting.title}`}
            filterTag={maxTag}
          />
        </div>
      </FormControl>
    );
  };

  const getAssociatedReportFilterData = () =>
    getFilterData(
      FilterTypes.associatedReport,
      ApiRoutes.getAssociatedReportFilters
    );

  const generateFilterOption = (filterOption, tag): Filter => {
    return {
      name: filterOption.name,
      property: tag,
      value: filterOption.value,
      count: filterOption.count,
    };
  };

  //TODO: need to really determine what type of array is returned
  const getOptionsForFilter = (sectionTypeId, filterTag): any[] => {
    const sectionData = filterData[sectionTypeId];
    if (sectionData && sectionData.options) {
      // this is what is populated by the options filter
      const options = sectionData.options[filterTag];
      return options ? options : [];
    }
    return [];
  };

  const getDropDownFilter = (sectionTypeId, setting) => {
    const options = getOptionsForFilter(sectionTypeId, setting.typeCode);

    //remove 'None' from topics list
    let optionsWithoutNones = options.filter(
      (option) => option.name !== 'None'
    );

    return (
      <FilterAutocompleteSelect
        key={setting.typeCode}
        //this is the title for the filters that has an impact
        title={setting.title}
        //this is the actual filter options for the filters that has an impact
        _filters={optionsWithoutNones}
        filterTag={setting.typeCode}
      />
    );
  };

  const getSwitchFilter = (setting) => {
    return (
      <>
        <FilterSwitch
          key={setting.typeCode}
          label={setting.title}
          filterProperty={setting.typeCode}
        />
      </>
    );
  };

  const onClickToggle = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    if (onToggle) {
      onToggle(newState);
    }
  };
  const Header = () => {
    return (
      <div className={classes.drawerHeader}>
        <div className={classes.drawerActions}>
          <SaveTrackerDialog onCreateTracker={onCreateTracker} />
          <Button
            color="primary"
            variant="contained"
            startIcon={<RefreshIcon />}
            onClick={() => {
              if (!HasFilters()) {
                return;
              } else {
                ClearFilters();
              }
            }}
            fullWidth
            className={
              filters.value && filters.value.length
                ? `${classes.headerButton}`
                : `${classes.headerButton} ${classes.inactiveButton}`
            }
          >
            {t('filters.FilterDrawer.Reset Filters')}
          </Button>
        </div>
      </div>
    );
  };

  const renderFilter = (section, setting) => {
    switch (setting.variant) {
      case FilterVariants.Select:
        return getDropDownFilter(section.typeId, setting);
      case FilterVariants.Switch:
        return getSwitchFilter(setting);
      case FilterVariants.Range:
        // todo: refactor range filters
        return getRangeFilter(
          setting,
          FilterTags.associatedReport.minValue2,
          FilterTags.associatedReport.maxValue2
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.drawerContainer}>
      <Drawer
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerPaperCollapsed]: !isOpen,
          }),
        }}
        variant="persistent"
        anchor="left"
        open={isOpen}
      >
        {Header()}
        <DateSelector
          domain={timeDomain}
          domainChangeCallback={handleDomainChange}
          hideStandardRanges={true}
          textColor={'#fff'}
        />
        <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          <FilterChips maxItems={6} />
        </div>
        {filterConfig &&
          filterConfig.sections
            .filter((sec) => sec.isEnabled)
            .map((sec) => (
              <FilterDrawerSection
                key={sec.typeId}
                isExpanded={expandedSection === sec.typeId}
                onChange={handleSectionChange(sec.typeId)}
                title={
                  <>
                    <span style={{ paddingLeft: '10px', fontSize: '15px' }}>
                      {sec.title}
                    </span>
                    {isLoading && (
                      <CircularProgress
                        color="secondary"
                        className={classes.spinner}
                        size={20}
                      />
                    )}
                    <span>{getSectionTotalCount(sec.typeId)} results</span>
                  </>
                }
              >
                <div className={classes.sectionBody}>
                  {sec.settings
                    .filter((s) => s.isEnabled)
                    .map((setting) => renderFilter(sec, setting))}
                </div>
              </FilterDrawerSection>
            ))}
        <img
          style={{ height: '45px', paddingTop: '5px', paddingBottom: '5px' }}
          src="../images/CometLogo.svg"
        ></img>
      </Drawer>
      <IconButton
        color="primary"
        variant="contained"
        size="small"
        className={
          isOpen ? classes.toggleButton : classes.toggleButtonCollapsed
        }
        onClick={onClickToggle}
      >
        {isOpen ? <ChevronLeft /> : <ChevronRight />}
      </IconButton>
    </div>
  );
};

FilterDrawer.propTypes = {
  width: PropTypes.number.isRequired,
  onCreateTracker: PropTypes.func,
  onToggle: PropTypes.func,
};

export default FilterDrawer;
