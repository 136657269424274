// @ts-nocheck
import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';

const styles = {
  trendingUp: { color: '#C03736' },
  trendingDown: { color: '#23df31' },
};

class TrendIcon extends Component {
  render() {
    const rounder = 100;
    const { classes } = this.props;
    const curr = Math.round(this.props.current * rounder);
    const previous = Math.round(this.props.previous * rounder);
    return curr > previous ? (
      <TrendingUpIcon
        classes={{ colorAction: classes.trendingUp }}
        color="action"
      />
    ) : curr === previous ? (
      <TrendingFlatIcon />
    ) : (
      <TrendingDownIcon
        classes={{ colorAction: classes.trendingDown }}
        color="action"
      />
    );
  }
}

TrendIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  current: PropTypes.number.isRequired,
  previous: PropTypes.number.isRequired,
};

export default withStyles(styles)(TrendIcon);
