import React, { useState, useEffect, FC } from 'react';
import moment from 'moment';
import MUIDatatable from 'mui-datatables';
import UserPreferenceService from '../../services/UserPreferenceService';
import DeleteActionDialog from './DeleteActionDialog';
import { ActionDto } from '../../api/apiDtos';
import { useTranslation } from 'react-i18next';

const userPreferenceService = new UserPreferenceService();

interface Props {
  actions: ActionDto[];
  onDelete: () => void;
}

const ActionsList: FC<Props> = ({ actions, onDelete }) => {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const savedRows = userPreferenceService.get('ActionsListRows');
    if (savedRows !== null) {
      setRowsPerPage(savedRows);
    }
    const savedPage = userPreferenceService.get('ActionsListPage');
    if (savedPage !== null) {
      setPage(savedPage);
    }
  }, []);

  useEffect(() => {
    userPreferenceService.save('ActionsListRows', rowsPerPage);
  }, [rowsPerPage]);

  useEffect(() => {
    userPreferenceService.save('ActionsListPage', page);
  }, [page]);

  const columns = [
    {
      label: t('Actions.ActionsList.Date'),
      name: 'date',
      options: {
        customBodyRenderLite: (dataIndex: number) => {
          const action = actions[dataIndex];
          return action ? moment(action.date).format('DD MMM YY') : '';
        },
      },
    },
    {
      label: t('Actions.ActionsList.Title'),
      name: 'title',
      options: { sort: false },
    },
    {
      label: t('Actions.ActionsList.Description'),
      name: 'description',
      options: { sort: false },
    },
    {
      label: t('Actions.ActionsList.Organisation'),
      name: 'organisation',
      options: { sort: false },
    },
    {
      label: t('Actions.ActionsList.Facility'),
      name: 'facility',
      options: { sort: false },
    },
    {
      label: '',
      name: '',
      options: {
        customBodyRenderLite: (dataIndex: number) => {
          const action = actions[dataIndex];
          return action ? (
            <DeleteActionDialog action={action} onDelete={onDelete} />
          ) : (
            ''
          );
        },
        sort: false,
      },
    },
  ];

  return (
    <MUIDatatable
      data={actions}
      columns={columns}
      options={{
        selectableRows: 'none',
        print: false,
        search: false,
        filter: false,
        download: false,
        viewColumns: false,
        rowsPerPageOptions: [5, 10, 25],
        rowsPerPage: rowsPerPage,
        onChangeRowsPerPage: (numberOfRows: number) =>
          setRowsPerPage(numberOfRows),
        page: page,
        onChangePage: (currentPage: number) => setPage(currentPage),
      }}
      title={''}
    />
  );
};

export default ActionsList;
