import { signal } from '@preact/signals-react';
import { effect } from '@preact/signals-react';
import { ADVANCED_SEARCH_PREFIX } from '../constants';
import { trimStart } from '../helpers/data-helper';

export type Filter = {
  count?: number,
  name: string,
  property: string,
  value: string
}

type PartialFilter = Partial<Filter>;

type FilterParams = Record<string, string>

export const filters = signal<Array<Filter>>([]);

export const FilterTypes = {
  report: 'report',
  associatedReport: 'associatedReport',
};

export const FilterTags = {
  report: {
    organisation: 'ReportOrganisationId',
    reportType: 'ReportTypeId',
    affectedParty: 'ReportAffectedPartyId',
    topic: 'ReportTopicId',
    facility: 'ReportFacilityId',
    eventType: 'ReportEventTypeId',
    hazard: 'ReportHazardId',
    sifRiddor: 'ReportIsSifRiddor',
    rootCause: 'ReportRootCauseId',
    lifeSavingRule: 'ReportLifeSavingRuleId',
    additionalField1: 'ReportAdditionalField1',
    additionalField2: 'ReportAdditionalField2',
    search: 'ReportSearch',
    section: 'ReportFilterSection',
    cluster: 'Cluster filter'
  },
  associatedReport: {
    section: 'AssociatedReportFilterSection',
    status: 'AssociatedReportStatusId',
    value1: 'AssociatedReportValue1',
    minValue1: 'AssociatedReportMinValue1',
    maxValue1: 'AssociatedReportMaxValue1',
    value2: 'AssociatedReportValue2',
    minValue2: 'AssociatedReportMinValue2',
    maxValue2: 'AssociatedReportMaxValue2'
  }
};

export const FilterVariants = {
  Undefined: 0,
  Select: 1,
  Switch: 2,
  Range: 3,
};

export const ConstructFilter = (name: string, property: string, value: string, count = 0): Filter => {
  return {
    name,
    property,
    value,
    count,
  };
};

export const GetActiveFilters = () => filters.value;

export const GetActiveFiltersForFilterTag = (filterTag: string) =>
  filters.value[`${filterTag}`];

export const BulkAddFilters = (newFilters: Array<Filter>) => {
  // Using a Set for efficient lookup
  const existingFilterSet = new Set(filters.value.map(f => `${f.name}-${f.value}`));

  // Filtering out duplicates in one pass
  const nonDuplicates = newFilters.filter(nf => !existingFilterSet.has(`${nf.name}-${nf.value}`));

  // Checking and updating filters
  if (nonDuplicates.length > 0) {
    filters.value = filters.peek().concat(nonDuplicates);
  }
};

export const AddFilter = (newFilter: Filter) => {
  const existingFilter = filters.value.find(
    (f) => f.name === newFilter.name && f.value === newFilter.value
  );

  if (existingFilter) {
    return; //filter already exists, don't duplicate
  }

  filters.value = [...filters.peek(), newFilter];
};

export const RemoveFilter = (filterToRemove: Filter | PartialFilter) => {
  filters.value = filters.peek().filter(f =>
    (!filterToRemove.name || f.name !== filterToRemove.name) &&
    (!filterToRemove.value || f.value !== filterToRemove.value)
  );
};

export const RemoveFiltersByName = (name: string) => {
  if (!name) return;

  filters.value = filters.peek().filter(f => f.name !== name);
}

export const ClearFilters = () => {
  filters.value = [];
  
};

export const SetFilters = (newFilters: Array<Filter>) => {
  filters.value = newFilters;
};

export const BulkRemoveFilters = (filtersToRemove: Array<Filter>) => {
  filters.value = filters.value.filter(f => !filtersToRemove.some(x => x.value === f.value));
};

export const UpdateFilter = (filterToUpdate: Filter) => {
  filters.value = filters.value.map((filter) => {
    if (filter.property === filterToUpdate.property) {
      return filterToUpdate;
    } else {
      return filter;
    }
  });
};

export const FormatSearchFilterValue = (value: any) =>
  trimStart(value, ADVANCED_SEARCH_PREFIX);

export const IsFilterActive = (filter: Filter) => {
  return filters.value.some((activeFilter) => {
    return activeFilter.name === filter.name &&
      activeFilter.value === filter.value;
  });
};

export const FormatFilter = (filter: Filter) =>
  IsSearchFilter(filter.property)
    ? `Search: ${FormatSearchFilterValue(filter.value)}`
    : filter.name;

export const IsSearchFilter = (filterName: string) => {
  return filterName === FilterTags.report.search;
};

export const GenerateFilterParams = (type: string) => {

  const base: FilterParams = {};

  const filterParams = filterByType(filters.value, type).reduce((base, filter) => {
    const res = processFilterValue(base, filter);
    return res;
  }, base)


  return filterParams
};

export const GetParams = () => {
  const base: any = {}  
  return filters.value.reduce((base, filter) => {
    const { property, value, name } = filter;
    const item = base[property];
    if (item) {
      item.ids = `${item.ids}|${value}`;
      item.names = `${item.names}|${name}`;
    } else {
      base[property] = { ids: value, names: name };
    }
    return base;
  }, base);
};

export const GetTrackerParams = () => {
  const params = GetParams();
  const trackerParams = Object.keys(params).map((k) => {
    return {
      typeCode: k,
      ids: params[k].ids,
      names: params[k].names,
    };
  });

  return trackerParams;
};

export const HasFilters = () => {
  return filters.value && filters.value.length > 0;
};

const filterByType = (filters: Filter[], type: string) => {
  var res = filters.filter((f) =>
    f.property.toLowerCase().startsWith(type.toLowerCase())
  );
  return res;
}

const processFilterValue = (base: FilterParams , filter: Filter) => {
  if (filter.property === 'reportClusterFilterByIds') {
    base[filter.property] = filter.value;
  } else {
    base[filter.property] = base[filter.property]
      ? base[filter.property] + `|${filter.value}`
      : filter.value;
  }
  return base;
}
