import React, { FC, useState } from 'react';
import { Button, TextField, createStyles, Theme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import moment from 'moment';
import ApiRoutes from '../../services/ApiRoutes';
import API from '../../services/ApiInstance';
import LocalComboBox from '../combobox/local-combo-box';
import { ActionDto, FacilityDto, OrganisationDto } from '../../api/apiDtos';
import { WithStyles, withStyles } from '@material-ui/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useTranslation } from 'react-i18next';

const styles = (theme: Theme) =>
  createStyles({
    field: {
      marginBottom: '1em',
    },
  });

interface Props extends WithStyles<typeof styles> {
  organisations: OrganisationDto[];
  facilities: FacilityDto[];
  handleClose: () => void;
  onCreate: () => void;
}

const ActionsForm: FC<Props> = ({
  classes,
  organisations,
  facilities,
  handleClose,
  onCreate,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(moment());
  const [description, setDescription] = useState('');
  const [organisation, setOrganisation] = useState<OrganisationDto | null>(
    null
  );
  const [facility, setFacility] = useState<FacilityDto | null>(null);
  const [title, setTitle] = useState('');

  const handleDescChange = (desc: string) => setDescription(desc);
  const handleTitleChange = (title: string) => setTitle(title);
  const handleDateChange = (date: MaterialUiPickersDate) =>
    setDate(moment(date));

  const isFormValid = () => {
    return title && description && date;
  };

  const submitAction = () => {
    const action: ActionDto = {
      date: date.toJSON(),
      description: description,
      organisationId: organisation ? organisation.id : null,
      facilityId: facility ? facility.id : null,
      title: title,
    };
    API.post<ActionDto, any>(ApiRoutes.createAction, action).then(() => {
      onCreate();
      handleClose();
    });
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>{t('Actions.ActionsForm.Add New Action')}</DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            label={t('Actions.ActionsForm.Date')}
            value={date}
            format="DD MMM YYYY"
            className={classes.field}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>
        <TextField
          value={title}
          required
          variant="outlined"
          label={t('Actions.ActionsForm.Date')}
          type="text"
          fullWidth
          onChange={(evt) => handleTitleChange(evt.target.value)}
          className={classes.field}
        />
        <TextField
          value={description}
          required
          variant="outlined"
          label={t('Actions.ActionsForm.Description')}
          type="text"
          fullWidth
          onChange={(evt) => handleDescChange(evt.target.value)}
          className={classes.field}
          multiline
          minRows={2}
          maxRows={4}
        />
        <LocalComboBox
          options={facilities}
          value={facility}
          getOptionLabel={(option: FacilityDto) => option.name}
          onChange={(e: any, value: FacilityDto) => setFacility(value)}
          customLabel={t('Actions.ActionsForm.Facility')}
          className={classes.field}
        />
        <LocalComboBox
          options={organisations}
          value={organisation}
          getOptionLabel={(option: OrganisationDto) => option.name}
          onChange={(e: any, value: OrganisationDto) => setOrganisation(value)}
          customLabel={t('Actions.ActionsForm.Organisation')}
          className={classes.field}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isFormValid()}
          onClick={submitAction}
          color="primary"
          variant="outlined"
        >
          {t('Actions.ActionsForm.Create Action')}
        </Button>
        <Button onClick={handleClose} color="primary" variant="contained">
          {t('Global.Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ActionsForm);
