export const getBackgroundColor = (isOver, canDrop) => {
  if (isOver) {
    if (canDrop) {
      return 'rgb(188,251,255)';
    } else if (!canDrop) {
      return 'rgb(255,188,188)';
    }
  } else {
    return '';
  }
};
