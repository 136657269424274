import React from 'react';
import HelpButton from './help-button';
import { withStyles } from '@material-ui/core';

const styles = {
  root: {
    position: 'relative',
    paddingRight: '44px',
    display: 'inline-block',
  },
  infoIcon: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
  },
};

function WidgetTitle(props) {
  const { title, info, classes } = props;
  return (
    <div className={classes.root}>
      {title}
      {info && (
        <HelpButton title={title} info={info} className={classes.infoIcon} />
      )}
    </div>
  );
}

export default withStyles(styles)(WidgetTitle);
