// @ts-nocheck
import React from 'react';
import DashboardModal from '../../dashboard/DashboardModal';
import { Grid } from '@material-ui/core';
import './style.css';
import PropTypes from 'prop-types';

const isNestedList = (item) => item.Title && item.Items;

const RuleItem = ({ item }) => {
  return isNestedList(item) ? (
    <li>
      {item.Title}
      <ul className="lsr-nested-listing">
        {item.Items.map((i, idx) => (
          <RuleItem key={idx} item={i} />
        ))}
      </ul>
    </li>
  ) : (
    <li>{item}</li>
  );
};

const RulesListViewer = ({ rulesList, color }) => {
  return (
    rulesList && (
      <Grid item xs={6} style={{ color }} className="lsr-listing-viewer">
        <div className="lsr-listing-title">{rulesList.Title}</div>
        <ul className="lsr-listing">
          {rulesList.Items.map((item, idx) => (
            <RuleItem key={idx} item={item} />
          ))}
        </ul>
      </Grid>
    )
  );
};

const LifeSavingRuleDialog = ({ rule, onClose }) => {
  return (
    rule && (
      <DashboardModal
        title={rule.name}
        open={true}
        handleClose={onClose}
        width="md"
      >
        <div className="lsr-action">{rule.action}</div>
        <div className="lsr-description">{rule.description}</div>
        <Grid container>
          <RulesListViewer rulesList={rule.always} color="green" />
          <RulesListViewer rulesList={rule.never} color="red" />
        </Grid>
      </DashboardModal>
    )
  );
};

LifeSavingRuleDialog.propTypes = {
  rule: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default LifeSavingRuleDialog;
