import React, { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import ApiRoutes from '../../services/ApiRoutes';
import API from '../../services/ApiInstance';
import { ReportDataDto } from '../../api/apiDtos';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '400px',
    borderRadius: '10px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    '& $customTableCell': {
      padding: '5px',
    },
    '& $fixedCell': {
      width: '25%',
    },
    smallTableBox: {
      border: '1px solid #ccc',
      borderRadius: '10px',
      padding: '15px',
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    centerContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  fixedCell: {},
  aiOutputTableRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  customTableCell: {
    gridColumn: 'auto',
  },
  sidepanelTitle: {
    fontSize: '25px',
    paddingBottom: '10px',
  },
  sidepanel: {
    width: '100%',
    height: '100%',
  },
  viewAllLink: {
    cursor: 'pointer',
    color: 'blue',
  },
});

function formatDateString(dateString: string | number | Date) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function MapClickedPopup({ point, onClose }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [reportData, setReportData] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const getReportById = async (reportId) => {
    try {
      const report = await API.get<any, ReportDataDto>(
        `${ApiRoutes.getreportbyid}?Id=${reportId}`
      );
      setReportData(report);
    } catch (error) {
      console.error('Result Data From Back end failed:', error);
    }
  };
  useEffect(() => {
    if (!point) return;
    const reportId = point.firstReport.id;
    getReportById(reportId);
  }, [point, localStorage.getItem('selectedLanguage')]);

  useEffect(() => {
    setReportData(null);
  }, [onClose]);

  return (
    <div className={classes.tableContainer}>
      <div style={{ position: 'relative' }}>
        <IconButton
          style={{ position: 'absolute', top: -8, right: 0 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        <Table className={classes.sidepanel}>
          <TableCell colSpan={2} className={classes.customTableCell}>
            <div className={classes.sidepanelTitle}>
              {t('map.MapClickedPopup.Report Details:')}
            </div>
          </TableCell>
          <TableBody>
            {reportData ? (
              reportData.localId ? (
                <TableRow>
                  <TableCell
                    className={`${classes.fixedCell} ${classes.customTableCell}`}
                  >
                    {t('map.MapClickedPopup.Id')}
                  </TableCell>
                  <TableCell className={classes.customTableCell}>
                    <div>{reportData.localId}</div>
                  </TableCell>
                </TableRow>
              ) : null
            ) : (
              <TableRow>
                <TableCell
                  className={`${classes.fixedCell} ${classes.customTableCell}`}
                >
                  {t('map.MapClickedPopup.Id')}
                </TableCell>
                <TableCell className={classes.customTableCell}>
                  {t('map.MapClickedPopup.Loading...')}
                </TableCell>
              </TableRow>
            )}
            {reportData ? (
              reportData.reportType ? (
                <TableRow>
                  <TableCell
                    className={`${classes.fixedCell} ${classes.customTableCell}`}
                  >
                    {t('map.MapClickedPopup.Report Type')}
                  </TableCell>
                  <TableCell className={classes.customTableCell}>
                    <div>{reportData.reportType}</div>
                  </TableCell>
                </TableRow>
              ) : null
            ) : (
              <TableRow>
                <TableCell
                  className={`${classes.fixedCell} ${classes.customTableCell}`}
                >
                  {t('map.MapClickedPopup.Report Type')}
                </TableCell>
                <TableCell className={classes.customTableCell}>
                  {t('map.MapClickedPopup.Loading...')}
                </TableCell>
              </TableRow>
            )}
            {reportData ? (
              reportData.dateOccurred ? (
                <TableRow>
                  <TableCell
                    className={`${classes.fixedCell} ${classes.customTableCell}`}
                  >
                    {t('map.MapClickedPopup.Date Occured')}
                  </TableCell>
                  <TableCell className={classes.customTableCell}>
                    <div>{formatDateString(reportData.dateOccurred)}</div>
                  </TableCell>
                </TableRow>
              ) : null
            ) : (
              <TableRow>
                <TableCell
                  className={`${classes.fixedCell} ${classes.customTableCell}`}
                >
                  {t('map.MapClickedPopup.Date Occured')}
                </TableCell>
                <TableCell className={classes.customTableCell}>
                  {t('map.MapClickedPopup.Loading...')}
                </TableCell>
              </TableRow>
            )}
            {reportData ? (
              point.location ? (
                <TableRow>
                  <TableCell
                    className={`${classes.fixedCell} ${classes.customTableCell}`}
                  >
                    {t('map.MapClickedPopup.GPS Location')}
                  </TableCell>
                  <TableCell className={classes.customTableCell}>
                    <div>{point.location.join(', ')}</div>
                  </TableCell>
                </TableRow>
              ) : null
            ) : (
              <TableRow>
                <TableCell
                  className={`${classes.fixedCell} ${classes.customTableCell}`}
                >
                  {t('map.MapClickedPopup.GPS Location')}
                </TableCell>
                <TableCell className={classes.customTableCell}>
                  {t('map.MapClickedPopup.Loading...')}
                </TableCell>
              </TableRow>
            )}
            {reportData ? (
              reportData.subOrganisation ? (
                <TableRow>
                  <TableCell
                    className={`${classes.fixedCell} ${classes.customTableCell}`}
                  >
                    {t('map.MapClickedPopup.Sub organisation')}
                  </TableCell>
                  <TableCell className={classes.customTableCell}>
                    <div>{reportData.subOrganisation}</div>
                  </TableCell>
                </TableRow>
              ) : null
            ) : (
              <TableRow>
                <TableCell
                  className={`${classes.fixedCell} ${classes.customTableCell}`}
                >
                  {t('map.MapClickedPopup.Sub organisation')}
                </TableCell>
                <TableCell className={classes.customTableCell}>
                  {t('map.MapClickedPopup.Loading...')}
                </TableCell>
              </TableRow>
            )}
            {reportData ? (
              reportData.organisation ? (
                <TableRow>
                  <TableCell
                    className={`${classes.fixedCell} ${classes.customTableCell}`}
                  >
                    {t('map.MapClickedPopup.organisation')}
                  </TableCell>
                  <TableCell className={classes.customTableCell}>
                    <div>{reportData.organisation}</div>
                  </TableCell>
                </TableRow>
              ) : null
            ) : (
              <TableRow>
                <TableCell
                  className={`${classes.fixedCell} ${classes.customTableCell}`}
                >
                  {t('map.MapClickedPopup.organisation')}
                </TableCell>
                <TableCell className={classes.customTableCell}>
                  {t('map.MapClickedPopup.Loading...')}
                </TableCell>
              </TableRow>
            )}

            {reportData ? (
              reportData.affectedParty ? (
                <TableRow>
                  <TableCell
                    className={`${classes.fixedCell} ${classes.customTableCell}`}
                  >
                    {t('map.MapClickedPopup.Affected Party')}
                  </TableCell>
                  <TableCell className={classes.customTableCell}>
                    <div>{reportData.affectedParty}</div>
                  </TableCell>
                </TableRow>
              ) : null
            ) : (
              <TableRow>
                <TableCell
                  className={`${classes.fixedCell} ${classes.customTableCell}`}
                >
                  {t('map.MapClickedPopup.Affected Party')}
                </TableCell>
                <TableCell className={classes.customTableCell}>
                  {t('map.MapClickedPopup.Loading...')}
                </TableCell>
              </TableRow>
            )}
            {reportData ? (
              reportData.area ? (
                <TableRow>
                  <TableCell
                    className={`${classes.fixedCell} ${classes.customTableCell}`}
                  >
                    {t('map.MapClickedPopup.Area')}
                  </TableCell>
                  <TableCell className={classes.customTableCell}>
                    <div>{reportData.area}</div>
                  </TableCell>
                </TableRow>
              ) : null
            ) : (
              <TableRow>
                <TableCell
                  className={`${classes.fixedCell} ${classes.customTableCell}`}
                >
                  {t('map.MapClickedPopup.Area')}
                </TableCell>
                <TableCell className={classes.customTableCell}>
                  {t('map.MapClickedPopup.Loading...')}
                </TableCell>
              </TableRow>
            )}

            {reportData ? (
              reportData.eventType ? (
                <TableRow>
                  <TableCell
                    className={`${classes.fixedCell} ${classes.customTableCell}`}
                  >
                    {t('map.MapClickedPopup.Sub Type')}
                  </TableCell>
                  <TableCell className={classes.customTableCell}>
                    <div>{reportData.eventType}</div>
                  </TableCell>
                </TableRow>
              ) : null
            ) : (
              <TableRow>
                <TableCell
                  className={`${classes.fixedCell} ${classes.customTableCell}`}
                >
                  {t('map.MapClickedPopup.Sub Type')}
                </TableCell>
                <TableCell className={classes.customTableCell}>
                  {t('map.MapClickedPopup.Loading...')}
                </TableCell>
              </TableRow>
            )}
            {reportData ? (
              reportData.description ? (
                <TableRow>
                  <TableCell
                    className={`${classes.fixedCell} ${classes.customTableCell}`}
                  >
                    {t('map.MapClickedPopup.Description')}
                  </TableCell>
                  <TableCell className={classes.customTableCell}>
                    <div>
                      {showFullDescription
                        ? reportData.description
                        : `${reportData.description.substring(0, 40)}`}
                      <span
                        className={classes.viewAllLink}
                        onClick={toggleDescription}
                      >
                        {showFullDescription
                          ? t('map.MapClickedPopup.Show Less')
                          : t('map.MapClickedPopup.View All')}
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              ) : null
            ) : (
              <TableRow>
                <TableCell
                  className={`${classes.fixedCell} ${classes.customTableCell}`}
                >
                  {t('map.MapClickedPopup.Description')}
                </TableCell>
                <TableCell className={classes.customTableCell}>
                  {t('map.MapClickedPopup.Loading...')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

MapClickedPopup.propTypes = {
  point: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MapClickedPopup;
