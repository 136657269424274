// @ts-nocheck
import React, { FC } from 'react';
import Colours from '../../../services/ColourService';
import RootCauseBreakdownsBaseChart from './RootCauseBreakdownsBaseChart';
import NoDataText from '../../common/NoDataText';
import { RootCauseClassificationAggregationDto } from '../../../types/apiResponsesInterfaces';

export interface ChartShape {
  key: string;
  stack: string;
  colour: string;
  stroke: string;
}

// const createChartShape = (
//   data: RootCauseClassificationAggregationDto[]
// ): ChartShape[] => {
//   const stackKey = 'a';
//   const chartShape: ChartShape[] = [];
//   const coloursGenerator = Colours.materialColors();
//   let colour: string;

//   data.forEach((reportsBucket) => {
//     Object.keys(reportsBucket.subcategoryCounts!).forEach((key) => {
//       colour = coloursGenerator.next();
//       chartShape.push({
//         key,
//         stack: stackKey,
//         colour,
//         stroke: colour,
//       });
//     });
//   });

//   return chartShape;
// };

const createChartData = (data: RootCauseClassificationAggregationDto[]) => {
  return data.map((reportsBucket) => {
    const chartPoint: any = {
      levelOne: reportsBucket.levelOne!,
    };

    for (const [key, value] of Object.entries(
      reportsBucket.subcategoryCounts!
    )) {
      chartPoint[key] = value;
    }

    return chartPoint;
  }, []);
};

interface Props {
  data: RootCauseClassificationAggregationDto[];
  axisY: string;
  noDataLabel: string;
}

const RootCauseBreakdownsBySubcategoryChart: FC<Props> = ({
  data,
  axisY,
  noDataLabel,
}) => {
  const hasData =
    data &&
    data.some((d) => {
      return Object.values(d.subcategoryCounts!).some((count) => count > 0);
    });

  if (!hasData) {
    return <NoDataText label={noDataLabel} />;
  }

  // const chartShape = createChartShape(data);
  const chartData = createChartData(data);

  return <RootCauseBreakdownsBaseChart data={chartData} />;
};

export default RootCauseBreakdownsBySubcategoryChart;
