const emailRegexPattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
const usernameRegexPattern = /^[\w-.]*$/;

export default class ValidationService {
  validate(...requirements: any[]) {
    return requirements.every(requirement => requirement());
  }

  static validators = {
    match: (val1: any, val2: any) => () => val1 === val2,
    required: (val: any) => () => val !== null && val !== undefined && val !== '',
    length: (str: string, min: number, max: number) => () =>
      (min ? str.length >= min : true) && (max ? str.length <= max : true),
    email: (email: string) => () => emailRegexPattern.test(email),
    arrayNotEmpty: (arr: any[]) => () => arr && arr.length > 0,
    username: (username: string) => () => !!username && usernameRegexPattern.test(username)
  };
}
