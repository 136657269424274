import React, { FC, useEffect, useState } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, Tooltip } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import HelpDialog from './dashboard/HelpDialog';
import SearchBox from './search/SearchBox';
import SavedSearchCard from './dashboard/SavedSearchCard';
import DashboardModal from './dashboard/DashboardModal';
import { trackAction } from '../services/analytics/AnalyticsService';
import API from '../services/ApiInstance';
import ApiRoutes from '../services/ApiRoutes';
import { ROUTES } from '../constants';
import { toast } from 'react-toastify';
import ReactWordcloud from 'react-wordcloud';
import { getReportWidgetData } from '../services/WidgetDataService';
import { WidgetCode } from './widgets/widgetCodes';
import CircularProgressCentral from './common/CircularProgressCentral';
import {
  HasFilters,
  GenerateFilterParams,
  FilterTypes,
  filters,
} from '../signals/filterService';
import { SavedSearchDto, ThemeConfigDto } from '../api/originalMappings';
import { logDataFetchError, logResponseParseError } from '../services/utils';
import { WordcloudDtoSchema } from '../api/zodSchemas';
import { NotificationPanel } from './notification-panel/NotificationPanel';
import CloudIcon from '@material-ui/icons/CloudOutlined';
import CloudOffIcon from '@material-ui/icons/CloudOffOutlined';
import Spacer from './common/Spacer';
import useAuthorization from '../hooks/useAuthorisationManager';
import LanguageSwitcher from '../services/languageService/languageSwitcher';
import { useTranslation } from 'react-i18next';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  loggedIn: boolean;
  logoutCallback: (e: any) => void;
  themeConfig: ThemeConfigDto;
}

const styles = (theme: { zIndex: { drawer: number } }) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: 0,
      color: 'white',
    },
    grow: {
      flexGrow: 1,
      whiteSpace: 'nowrap',
      display: 'inherit',
      justifyContent: 'center',
    },
    menuItem: {
      display: 'flex',
      height: 36,
      alignItems: 'center',
      padding: 0,
    },
    menuLink: {
      textDecoration: 'none',
      padding: '0 16px',
      lineHeight: '36px',
      display: 'block',
      width: '100%',
    },
    activeMenuButton: {
      background: 'rgba(1,87,155 ,0.45)',
    },
    navIcon: {
      marginRight: 5,
    },
    iconButton: {
      margin: '0 5px',
      color: '#7B7B7B !important',
    },
    progressIcon: {
      position: 'absolute',
      marginLeft: '49%',
      marginTop: 380,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    logo: {
      maxHeight: 40,
      marginRight: 35,
      marginLeft: 10,
    },
    button: {
      margin: '0 5px',
      padding: '7px 11px',
    },
  });

const Header: FC<Props> = ({
  classes,
  location,
  loggedIn,
  logoutCallback,
  themeConfig,
}) => {
  const { hasAccess } = useAuthorization();
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showWordcloud, setShowWordcloud] = useState(false);
  const [wordcloudLoading, setWordCloudLoading] = useState(true);
  const [savedSearches, setSavedSearches] = useState<Array<SavedSearchDto>>([]);
  const [wordcloud, setWordcloud] = useState<{ text: string; value: any }[]>(
    []
  );
  const { t } = useTranslation();
  const [hasFilters, setHasFilter] = useState(false);
  const [filtersChanged, setFiltersChanged] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(
    function getWordcloudWhenFiltersChange() {
      setWordCloudLoading(true);
      getReportWidgetData(WidgetCode.wordCloudData)
        .then((res) => {
          if (!res) return {};
          //here zod is used to do a runtime check whether the parsed res from api is the
          //correct format
          const schemaMatch = WordcloudDtoSchema.safeParse(res);
          if (schemaMatch.success) {
            const wordcloudDataFormat = Object.keys(res).map((key) => {
              return { text: key, value: res[key] };
            });
            setWordcloud(wordcloudDataFormat);
          } else {
            //@ts-ignore
            logResponseParseError(schemaMatch.error);
          }
        })
        .catch((err) => logDataFetchError(err, 'Wordcloud'))
        .finally(() => {
          setWordCloudLoading(false);
          setFiltersChanged(false);
        });
    },
    [filtersChanged]
  );

  useEffect(
    function checkIfFiltersExist() {
      updateFiltersState();
    },
    [filters.value]
  );

  const updateFiltersState = () => {
    setFiltersChanged(true);
    setHasFilter(HasFilters());
  };

  const showMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const hideMenu = () => {
    setAnchorEl(null);
  };

  const handleHelpDialogClose = () => {};

  const setSearchModal = (_showSearchModal: boolean) => () =>
    setShowSearchModal(_showSearchModal);

  const saveSearch = () => {
    trackAction('saved search', 'save');
    API.post(ApiRoutes.saveSearch, GenerateFilterParams(FilterTypes.report))
      .then((_) => {
        toast.success('Search saved');
        getSearches();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteSearch = (search: string) => {
    API.del(ApiRoutes.deleteSearch, search).then((_) => {
      toast.success(t('header.Search deleted'));
      getSearches();
    });
  };

  const getSearches = () => {
    API.get<any, SavedSearchDto[]>(ApiRoutes.getSavedSearches)
      .then((res) => setSavedSearches(res))
      .catch((err) => {
        console.error(err);
      });
  };

  const UserMenu = () => {
    const openSearch = setSearchModal(true);

    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={hideMenu}
      >
        <MenuItem
          key="SavedSearches"
          className={classes.menuItem}
          onClick={() => {
            openSearch();
            hideMenu();
          }}
        >
          <span className={classes.menuLink}>{t('header.Saved Searches')}</span>
        </MenuItem>
        <MenuItem
          key="SaveSearch"
          className={classes.menuItem}
          onClick={() => {
            saveSearch();
            hideMenu();
          }}
          disabled={!hasFilters}
        >
          <span className={classes.menuLink}>{t('header.Save Search')}</span>
        </MenuItem>
        {hasAccess(ROUTES.UsersAndOrganisations) && (
          <MenuItem onClick={hideMenu} className={classes.menuItem}>
            <NavLink
              color="primary"
              className={classes.menuLink}
              activeClassName={classes.activeMenuButton}
              to={ROUTES.UsersAndOrganisations}
            >
              {t('header.Users & Organisations')}
            </NavLink>
          </MenuItem>
        )}
        {hasAccess(ROUTES.Upload) && (
          <MenuItem onClick={hideMenu} className={classes.menuItem}>
            <NavLink
              color="primary"
              className={classes.menuLink}
              activeClassName={classes.activeMenuButton}
              to={ROUTES.Upload}
            >
              {t('header.Upload data')}
            </NavLink>
          </MenuItem>
        )}
        {hasAccess(ROUTES.Facilities) && (
          <MenuItem onClick={hideMenu} className={classes.menuItem}>
            <NavLink
              to={ROUTES.Facilities}
              color="primary"
              className={classes.menuLink}
              activeClassName={classes.activeMenuButton}
            >
              {t('header.Facilities')}
            </NavLink>
          </MenuItem>
        )}
        <MenuItem onClick={hideMenu} className={classes.menuItem}>
          <NavLink
            to={ROUTES.Actions}
            color="primary"
            className={classes.menuLink}
            activeClassName={classes.activeMenuButton}
          >
            {t('header.Actions')}
          </NavLink>
        </MenuItem>
        {hasAccess(ROUTES.Aliases) && (
          <MenuItem onClick={hideMenu} className={classes.menuItem}>
            <NavLink
              to={ROUTES.Aliases}
              color="primary"
              className={classes.menuLink}
              activeClassName={classes.activeMenuButton}
            >
              {t('header.Report Type Aliases')}
            </NavLink>
          </MenuItem>
        )}
        <MenuItem onClick={hideMenu} className={classes.menuItem}>
          <NavLink
            color="primary"
            className={classes.menuLink}
            activeClassName={classes.activeMenuButton}
            to={ROUTES.Licences}
          >
            {t('header.Licensing')}
          </NavLink>
        </MenuItem>
        {hasAccess(ROUTES.DashboardLayout) && (
          <MenuItem onClick={hideMenu} className={classes.menuItem}>
            <NavLink
              to={ROUTES.DashboardLayout}
              color="primary"
              className={classes.menuLink}
              activeClassName={classes.activeMenuButton}
            >
              {t('header.Dashboard Customisation')}
            </NavLink>
          </MenuItem>
        )}
        {hasAccess(ROUTES.WidgetsAndFilters) && (
          <MenuItem onClick={hideMenu} className={classes.menuItem}>
            <NavLink
              to={ROUTES.WidgetsAndFilters}
              color="primary"
              className={classes.menuLink}
              activeClassName={classes.activeMenuButton}
            >
              {t('header.Widgets & Filters')}
            </NavLink>
          </MenuItem>
        )}
        <MenuItem onClick={hideMenu} className={classes.menuItem}>
          <NavLink
            color="primary"
            className={classes.menuLink}
            activeClassName={classes.activeMenuButton}
            to={ROUTES.UserProfile}
          >
            {t('header.Profile')}
          </NavLink>
        </MenuItem>
        {hasAccess(ROUTES.SuperUserPanel) && (
          <MenuItem onClick={hideMenu} className={classes.menuItem}>
            <NavLink
              to={ROUTES.SuperUserPanel}
              color="primary"
              className={classes.menuLink}
              activeClassName={classes.activeMenuButton}
            >
              {t('header.Super User Panel')}
            </NavLink>
          </MenuItem>
        )}
        {hasAccess(ROUTES.ActivityLogs) && (
          <MenuItem onClick={hideMenu} className={classes.menuItem}>
            <NavLink
              to={ROUTES.ActivityLogs}
              color="primary"
              className={classes.menuLink}
              activeClassName={classes.activeMenuButton}
            >
              {t('header.Activity Logs')}
            </NavLink>
          </MenuItem>
        )}
        <MenuItem onClick={hideMenu} className={classes.menuItem}>
          <NavLink
            color="primary"
            className={classes.menuLink}
            activeClassName={classes.activeMenuButton}
            to={ROUTES.About}
          >
            {t('header.About')}
          </NavLink>
        </MenuItem>
        {loggedIn && (
          <MenuItem
            className={classes.menuItem}
            onClick={(e) => {
              logoutCallback(e);
              hideMenu();
            }}
          >
            <span className={classes.menuLink}>{t('header.Logout')}</span>
          </MenuItem>
        )}
      </Menu>
    );
  };

  const activeStyle = {
    color: '#d94936',
  };

  // const inactiveStyle = {
  //   color: '#d3d3d3',
  // };

  const isActive = () => showWordcloud;

  const hasWordcloudData = wordcloud.length;

  return (
    <>
      {showWordcloud && (
        <Dialog
          onClose={() => setShowWordcloud(false)}
          aria-labelledby="wordcloud"
          open={showWordcloud}
          className="wordcloud-dialog"
        >
          {wordcloudLoading ? (
            <div
              style={{
                width: '800px',
                height: '600px',
                display: 'flex',
                placeContent: 'center',
              }}
            >
              <CircularProgressCentral />
            </div>
          ) : (
            <ReactWordcloud
              options={{
                enableTooltip: false,
                deterministic: true,
                fontSizes: [11, 100],
                fontStyle: 'normal',
                fontWeight: 'normal',
                padding: 1,
                rotations: 1,
                rotationAngles: [0, 90],
                scale: 'sqrt',
                spiral: 'archimedean',
                transitionDuration: 1000,
              }}
              size={[800, 600]}
              words={wordcloud ? wordcloud : []}
            />
          )}
        </Dialog>
      )}
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{ backgroundColor: 'white' }}
      >
        <Toolbar>
          <img className={classes.logo} src="images/logos/logo.png" />
          <LanguageSwitcher />
          <Spacer></Spacer>
          <SearchBox />
          <div className={classes.grow}></div>

          <Tooltip
            title={
              hasWordcloudData
                ? t('header.word_cloud_tool_tip')
                : t('header.No_Word_Cloud_Data')
            }
          >
            {!hasWordcloudData ? (
              <CloudOffIcon style={activeStyle} />
            ) : (
              <CloudIcon
                style={activeStyle}
                onClick={() => setShowWordcloud(!showWordcloud)}
              />
            )}
          </Tooltip>
          <NotificationPanel />

          <Tooltip title={t('header.User Menu')}>
            <AccountCircleIcon
              onClick={showMenu}
              className={classes.iconButton}
            />
          </Tooltip>
          {UserMenu()}
        </Toolbar>
      </AppBar>
      <HelpDialog
        type="general"
        open={helpDialogOpen}
        onClose={handleHelpDialogClose}
      />
      <DashboardModal
        handleClose={setSearchModal(false)}
        title={t('header.My Saved Searches')}
        open={showSearchModal}
      >
        <SavedSearchCard searches={savedSearches} onDelete={deleteSearch} />
      </DashboardModal>
    </>
  );
};

export default withRouter(withStyles(styles)(Header));
