import jsPDF from 'jspdf';
import { toPng } from 'html-to-image';

export const PAGE_ORIENTATIONS = {
  portrait: 'portrait',
  landscape: 'landscape'
} as const;

export type OrientationKeys = keyof typeof PAGE_ORIENTATIONS

const getSize = (element: HTMLElement, pageWidth: number, pageHeight: number) => {
  let width = element.offsetWidth;
  let height = element.offsetHeight;

  if (width > pageWidth) {
    const ratio = pageWidth / width;
    height = height * ratio;
    width = width * ratio;
  } 

  if (height > pageHeight) {
    const ratio = pageHeight / height;
    height = height * ratio;
    width = width * ratio;
  }

  return { width, height };
};

const getPosition = (width: number, pageWidth: number) => {
  const x = width < pageWidth ? (pageWidth - width) / 2 : 0;
  return { x, y: 0 };
};

const addExtension = (fileName: string) => fileName.endsWith('.pdf') ? fileName : `${fileName}.pdf`;

export const generateOnePagePdf = async (element: HTMLElement, fileName: string, orientation: OrientationKeys = "portrait") => {
  const doc = new jsPDF({ orientation, unit: 'px' });
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();   

  const { width, height } = getSize(element, pageWidth, pageHeight);
  const { x, y } = getPosition(width, pageWidth);

  const imgData = await toPng(element);

  doc.addImage(imgData, 'PNG', x, y, width, height);
  
  doc.save(addExtension(fileName));
};