//@ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Button,
  Typography,
  createStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import DashboardModal from '../../dashboard/DashboardModal';
import { getSettings } from '../../../helpers/data-helper';
import WidgetTitle from '../../help-button/widget-title';
import PreventiveActionsTable from './PreventiveActionsTable';
// import {
//   PreventiveActionsGuidanceDefaultSettings,
//   WidgetSettings,
// } from '../'';
import NoDataText from '../../common/NoDataText';
import TimeServiceProvider from '../../../services/TimeService';
import { WidgetCode } from '../widgetCodes';
import { getReportWidgetData } from '../../../services/WidgetDataService';
import CircularProgressCentral from '../../common/CircularProgressCentral';
import { WithStyles } from '@material-ui/styles';
import {
  PossibleReportWidgetDataDto,
  RootCausePreventiveActionsDto,
} from '../../../api/originalMappings';
import { RootCausePreventiveActionsDtoSchema } from '../../../api/zodSchemas';
import { logResponseParseError } from '../../../services/utils';
import { useTranslation } from 'react-i18next';
import { useWidgetTranslations } from '../useTranslatedWidgetsDefaultSettings';

const styles = createStyles({
  seeAllButton: {
    width: '100%',
  },
  header: {
    padding: 16,
  },
  root: {
    background: 'white',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    overflowY: 'auto',
  },
  footer: {
    padding: 8,
  },
});

const timeService = TimeServiceProvider();

const parsePreventiveActions = (data: RootCausePreventiveActionsDto[]) => {
  return data.map((rootCauseInfo) => {
    const preventiveAction = rootCauseInfo.preventiveAction;
    return {
      ...rootCauseInfo,
      preventiveAction: {
        description: JSON.parse(preventiveAction.description),
        shortTermActivity: JSON.parse(preventiveAction.shortTermActivity),
        longTermActivity: JSON.parse(preventiveAction.longTermActivity),
      },
    };
  });
};

interface Props extends WithStyles<typeof styles> {
  settings: WidgetSettings;
  limit: any;
  initialData: RootCausePreventiveActionsDto[];
}

const PreventiveActionsGuidance: React.FC<Props> = ({
  settings,
  classes,
  limit,
  initialData,
}) => {
  const { PreventiveActionsGuidanceDefaultSettings } = useWidgetTranslations();
  const { t } = useTranslation();
  const [data, setData] = useState<RootCausePreventiveActionsDto[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  settings = getSettings(settings, PreventiveActionsGuidanceDefaultSettings);
  const { title, subtitle, info, noDataLabel } = settings;

  const hasData = data.length > 0;

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const getData = () => {
    setIsLoading(true);
    getReportWidgetData(WidgetCode.preventiveActionsGuidance).then(
      (res: PossibleReportWidgetDataDto) => {
        //here zod is used to do a runtime check whether the parsed res from api is the
        //correct format
        const schemaMatch = RootCausePreventiveActionsDtoSchema.safeParse(res);
        if (schemaMatch.success) {
          const parsedData = parsePreventiveActions(schemaMatch.data);
          setData(parsedData);
          setIsLoading(false);
        } else {
          logResponseParseError(schemaMatch.error);
        }
      }
    );
  };

  useEffect(
    function assignPreventiveActions() {
      if (initialData) {
        const parsedData = parsePreventiveActions(initialData);
        setData(parsedData);
        setIsLoading(false);
        return;
      } else {
        timeService.subscribe(getData);
        getData();

        return () => {
          timeService.unsubscribe(getData);
        };
      }
    },
    [initialData]
  );

  return isLoading ? (
    <CircularProgressCentral />
  ) : (
    <div className={classes.root}>
      <div className={classes.header}>
        <WidgetTitle title={title} info={info} />
        <Typography color="textSecondary" variant="h6">
          {subtitle}
        </Typography>
      </div>
      <div className={classes.content}>
        {hasData ? (
          <PreventiveActionsTable
            data={data}
            limit={limit}
            settings={settings}
          />
        ) : (
          <NoDataText label={noDataLabel} />
        )}
      </div>
      <div className={classes.footer}>
        {hasData && (
          <Button onClick={toggleShowModal} className={classes.seeAllButton}>
            {t(
              'widgets.preventive_actions_guidance.PreventiveActionsGuidance.SEE ALL'
            )}
          </Button>
        )}
      </div>
      {showModal && (
        <DashboardModal
          title={title}
          open={showModal}
          handleClose={toggleShowModal}
        >
          <PreventiveActionsTable data={data} settings={settings} />
        </DashboardModal>
      )}
    </div>
  );
};

PreventiveActionsGuidance.propTypes = {
  classes: PropTypes.object.isRequired,
  limit: PropTypes.number,
  initialData: PropTypes.array,
};

export default withStyles(styles)(PreventiveActionsGuidance);
