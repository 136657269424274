import moment, {unitOfTime} from 'moment';
import { GenericObject } from '../../helpers/form-data-helper';
import { AssociatedReportDto } from '../../api/originalMappings';

const months3 = 7948800000;
const months12 = 31536000000;

//TODO: seems like associatedReports have not been used in the app from POC's that Paul Nicholas had gone through with
// therefore the type is of 'any' for now
export default function AssociatedReportsDateCostChartData(associatedReports:AssociatedReportDto[], domain: (number | moment.Moment)[]) {
  return GetItemsDateCostChartData(associatedReports, domain, 'date', 'value1'); 
}

export function GetItemsDateCostChartData(items: any, domain: (number | moment.Moment)[], datePropName='date', valuePropName='value1') {
  const [startDate, endDate] = domain;
  const range = (new Date(endDate as number)).valueOf() - (new Date(startDate as number)).valueOf();
  let dateKeyFormat: string | undefined, dateUnit: unitOfTime.StartOf;

  if (range <= months3) {
    dateKeyFormat = 'Y MMM D';
    dateUnit = 'day';
  } else if (range <= months12) {
    dateKeyFormat = 'Y Wo';
    dateUnit = 'week';
  } else {
    dateKeyFormat = 'MMM YYYY';
    dateUnit = 'month';
  }

  const chunkBucket: any = {};
  const firstDate = moment(startDate).startOf(dateUnit);
  const lastDate = moment(endDate).startOf(dateUnit);
  let chunkTracker = firstDate;
  while (chunkTracker <= lastDate) {
    chunkBucket[chunkTracker.format(dateKeyFormat)] = 0;
    chunkTracker.add(1, `${dateUnit}s`);
  }
  items.forEach((c: GenericObject) => {
    const key = moment(c[datePropName]).format(dateKeyFormat);
    if (chunkBucket[key] !== undefined) {
      chunkBucket[key] += c[valuePropName];
    }
  });
  const chartData = Object.keys(chunkBucket)
    .map(key => ({
      cost: chunkBucket[key],
      date: key
    }))
    .sort((a, b) => moment(a.date).valueOf() > moment(b.date).valueOf() ? -1 : 1);
  return chartData;
}
