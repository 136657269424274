import React from 'react';
import PropTypes from 'prop-types';
import RootCauseBreakdownsBaseChart from './RootCauseBreakdownsBaseChart';
import NoDataText from '../../common/NoDataText';

// const createChartShape = (data) => {
//   const stackKey = 'a';
//   const chartShape = [];

//   data.forEach((reportsBucket) => {
//     Object.keys(reportsBucket.organisationCounts).forEach((key) => {
//       if (!chartShape.some((cs) => cs.key === key)) {
//         chartShape.push({
//           key,
//           stack: stackKey,
//         });
//       }
//     });
//   });

//   return chartShape;
// };

// const applyColours = (chartShape) => {
//   const coloursGenerator = Colours.materialColors();
//   const palette = chartShape.reduce((palette, c) => {
//     palette[c.key] = coloursGenerator.next();
//     return palette;
//   }, {});

//   chartShape.forEach((cs) => {
//     cs.colour = palette[cs.key];
//     cs.stroke = palette[cs.key];
//   });
// };

const createChartData = (data) => {
  return data.map((reportsBucket) => {
    const chartPoint = {
      levelOne: reportsBucket.levelOne,
    };

    for (const [key, value] of Object.entries(
      reportsBucket.organisationCounts
    )) {
      chartPoint[key] = value;
    }

    return chartPoint;
  }, []);
};

const RootCauseBreakdownsByOrganisationChart = ({ data, noDataLabel }) => {
  const hasData =
    data &&
    data.some((d) => {
      return Object.values(d.organisationCounts).some((count) => count > 0);
    });

  if (!hasData) {
    return <NoDataText label={noDataLabel} />;
  }

  const chartData = createChartData(data);

  return <RootCauseBreakdownsBaseChart data={chartData} />;
};

RootCauseBreakdownsByOrganisationChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default RootCauseBreakdownsByOrganisationChart;
