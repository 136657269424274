import moment from 'moment';

const defaultDomain = (function () {
  const latestDate = moment();
  const earliestDate = moment().subtract(1, 'years');
  return [
    earliestDate,
    latestDate.valueOf(),
  ];
})();

export class TimeService {
  callbacks = [];
  domain = [...defaultDomain];

  resetDomain() {
    this.setTime([...defaultDomain]);
  };

  subscribe(callback: any) {
    if (callback && !this.callbacks.includes(callback)) {
      this.callbacks.push(callback);
    }
  }

  unsubscribe(callback: any) {
    if (callback) {
      this.callbacks = this.callbacks.filter(c => c !== callback);
    }
  }

  setTime(timeDomain: (number | moment.Moment)[]) {
    this.domain = timeDomain;
    this.callbacks.forEach(callback => callback(timeDomain));
  }

  getPrevDomain() {
    const [earliestDate, latestDate] = this.domain;
    const daysDiff = moment(latestDate).diff(earliestDate, 'days');    
    const prevDomainEnd = moment(earliestDate).subtract(1, 'days');
    const prevDomainStart = moment(prevDomainEnd).subtract(daysDiff, 'days');
    return [
      prevDomainStart,
      prevDomainEnd
    ];
  }
}

const TimeServiceProvider = (function () {
  let _timeService: TimeService;
  return function (): TimeService {
    if (!_timeService) {
      _timeService = new TimeService();
    }
    return _timeService;
  };
})();

export default TimeServiceProvider;
