//@ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import MapWidgetView from '../map/MapWidgetView';

import {
  CardContent,
  CardHeader,
  Card,
  FormControlLabel,
  FormControl,
  Switch,
  InputBase,
} from '@material-ui/core';

import WidgetTitle from '../help-button/widget-title';
import { getSettings } from '../../helpers/data-helper';
import TimeServiceProvider from '../../services/TimeService';
import { WidgetCode } from './widgetCodes';
import { getReportWidgetData } from '../../services/WidgetDataService';
import CircularProgressCentral from '../common/CircularProgressCentral';
import { Button } from '@material-ui/core';
import { useRef } from 'react';
import { MapWidgetDtoSchema } from '../../api/zodSchemas';
import { logDataFetchError, logResponseParseError } from '../../services/utils';
import {
  AddFilter,
  filters,
  RemoveFilter,
  UpdateFilter,
} from '../../signals/filterService';
import { Filter } from '../../signals/filterService';
import { FilterTags } from '../../signals/filterService';
import { ClusterFilterListenerContext } from '../../context/ClusterFilterListenerContextProvider';
import { ClusterSelectionFilterContext } from '../../context/ClusterSelectionFilterContextProvider';
import { makeStyles } from '@material-ui/core/styles';
import UserPreferenceService from '../../services/UserPreferenceService';
import { useTranslation } from 'react-i18next';
import { useWidgetTranslations } from './useTranslatedWidgetsDefaultSettings';

const userPreferenceService = new UserPreferenceService();

const useStyles = makeStyles({
  clusterInputBox: {
    background: 'white',
    color: 'black',
    position: 'absolute',
    left: '631px',
    top: '97px',

    borderRadius: '20px',
    paddingLeft: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'transform 0.2s ease-in-out',
    ':hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
    },
    '::placeholder': {
      color: 'rgba(255, 255, 255, 0.7)',
      fontWeight: 'bold',
    },

    fontFamily: '"Comic Sans MS", cursive, sans-serif',
    fontSize: '16px',
  },
});

const timeService = TimeServiceProvider();
function MapWidget({ columns, settings }) {
  const { MapWidgetDefaultSettings } = useWidgetTranslations();
  const { t } = useTranslation();
  const classes = useStyles();
  const [clusterSelectionFilter, setClusterSelectionFilter] = useContext(
    ClusterSelectionFilterContext
  );
  const [clusterFilterListener, setClusterFilterListener] = useContext(
    ClusterFilterListenerContext
  );
  const mapWidgetRef = useRef(null);
  const [filterByCluster, setFilterByCluster] = useState(false);
  const [points, setPoints] = useState([]);
  const [customFilterNames, setCustomFilterNames] = useState(false);
  const [showInputPopUp, setShowInputPopUp] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [clearHistory, setClearHistory] = useState(true);
  const clusterFilterRef = useRef(null);
  const customClusterNameRef = useRef(null);
  const [showHeatMap, setHeatMap] = useState();

  const {
    title,
    info,
    typesLabel: modalPrefixTitle,
    reportedTooltip,
  } = getSettings(settings, MapWidgetDefaultSettings);

  const handleClearHistory = () => {
    if (mapWidgetRef.current) {
      mapWidgetRef.current.clearPointHistory();
    }
  };

  const getData = () => {
    setIsLoading(true);
    getReportWidgetData(WidgetCode.reportsMap)
      .then((res) => {
        //here zod is used to do a runtime check whether the parsed res from api is the
        //correct format
        const schemaMatch = MapWidgetDtoSchema.safeParse(res);
        if (schemaMatch.success) {
          setPoints(schemaMatch.data.locations);
        } else {
          const err: any = (schemaMatch as any).error; //make ts happy for now
          logResponseParseError(err);
        }
      })
      .catch((err) => logDataFetchError(err, 'MapWidget'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    timeService.subscribe(getData);
    getData();

    return () => {
      timeService.unsubscribe(getData);
    };
  }, []);

  useEffect(
    function getDataWhenFiltersChange() {
      getData();
    },
    [filters.value]
  );

  useEffect(() => {
    const savedHeatSwitchValue = userPreferenceService.get(
      'MapWidgetShowHeatMap'
    );
    if (savedHeatSwitchValue !== null) {
      setHeatMap(savedHeatSwitchValue);
    }
  }, []);

  useEffect(() => {
    userPreferenceService.save('MapWidgetShowHeatMap', showHeatMap);
  }, [showHeatMap]);

  const handleClick = () => {
    setClearHistory(false);
    setTimeout(() => {
      setClearHistory(true);
    }, 1);
  };

  useEffect(() => {
    if (clusterFilterListener === true) {
      clusterFilterRef.current = clusterSelectionFilter;
      applyClusterFilter();
    } else {
      setClusterFilterListener(false);
    }
  }, [clusterFilterListener]);

  const applyClusterFilter = () => {
    const clusterFilterValue: any[] = clusterFilterRef.current;
    let name = '';
    if (
      (inputValue == '' && customClusterNameRef.current == '') ||
      customClusterNameRef.current == null ||
      customClusterNameRef.current == ''
    ) {
      name =
        FilterTags.report.cluster +
        ' count: ' +
        clusterFilterValue.flat().length;
    } else {
      name = `${customClusterNameRef.current}`;
    }

    const clusterFilter: Filter = {
      name: name,
      property: 'reportClusterFilterByIds',
      value: clusterFilterValue.flat().join('|'),
      count: clusterFilterValue.flat().length,
    };

    RemoveFilter(clusterFilter);
    AddFilter(clusterFilter);

    setClusterFilterListener(false);
    setCustomFilterNames(false);
    setInputValue(null);
    setShowInputPopUp(false);
    customClusterNameRef.current = null;
  };

  function filterReports() {
    if (
      Array.isArray(clusterSelectionFilter) &&
      clusterSelectionFilter.length > 0
    ) {
      setClusterFilterListener(true);
    } else {
      setClusterFilterListener(false);
    }
  }

  const handleSetCustomClusterName = () => {
    if (inputValue != '') {
      customClusterNameRef.current = inputValue;
    } else {
      customClusterNameRef.current = '';
    }
    setShowInputPopUp(false);
  };

  useEffect(() => {
    if (customFilterNames === true) {
      setShowInputPopUp(true);
    } else if (customFilterNames === false) {
      customClusterNameRef.current = '';
      setInputValue('');
      setShowInputPopUp(false);
    }
  }, [customFilterNames]);

  const renderPopup = () => {
    return (
      <Card variant="outlined">
        <div className={classes.clusterInputBox}>
          <InputBase
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={t('widgets.MapWidget.Filter name')}
          />
          <Button onClick={handleSetCustomClusterName}>Ok</Button>
        </div>
      </Card>
    );
  };

  return isLoading ? (
    <CircularProgressCentral />
  ) : (
    <Card variant="outlined" className="full-size">
      <CardHeader
        title={<WidgetTitle title={title} info={info} />}
        titleTypographyProps={{
          variant: 'subtitle1',
        }}
        action={
          <FormControl component="fieldset">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button onClick={handleClick} style={{ marginRight: '10px' }}>
                {t('widgets.MapWidget.Clear History')}
              </Button>
              {filterByCluster && (
                <FormControlLabel
                  value="filterByCluster"
                  control={
                    <Switch
                      color="primary"
                      checked={customFilterNames}
                      onChange={() => setCustomFilterNames(!customFilterNames)}
                    />
                  }
                  label={t('widgets.MapWidget.Set custom filter name')}
                  labelPlacement="end"
                />
              )}
              <FormControlLabel
                value="filterByCluster"
                control={
                  <Switch
                    color="primary"
                    checked={filterByCluster}
                    onChange={() => setFilterByCluster(!filterByCluster)}
                  />
                }
                label={t('widgets.MapWidget.Select Clusters')}
                labelPlacement="end"
              />
              {filterByCluster && (
                <Button onClick={filterReports} style={{ marginRight: '10px' }}>
                  {t('widgets.MapWidget.Filter Reports')}
                </Button>
              )}
            </div>
          </FormControl>
        }
      />

      <CardContent className="full-size">
        <MapWidgetView
          ref={mapWidgetRef}
          className="full-size"
          points={points}
          columns={columns}
          clearHistory={clearHistory}
          filterByCluster={filterByCluster}
          modalPrefixTitle={modalPrefixTitle}
          reportedTooltip={reportedTooltip}
        />
      </CardContent>
      {showInputPopUp && renderPopup()}
    </Card>
  );
}

export default MapWidget;
