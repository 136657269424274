const materialColours = [
  '#536DFE',
  '#D50000',
  '#607D8B',
  '#4CAF50',
  '#BBDEFB',
  '#FF9800',
  '#D81B60',
  '#FFAB91',
  '#03A9F4',
  '#FF80AB',
  '#7CB342',
  '#26A69A',
  '#FF5722',
  '#00BCD4',
  '#CDDC39',
  '#795548',
  '#FFC107',
  '#9E9E9E',
];

const ColourIterator = {
  [Symbol.iterator]() {
    let step = 0;
    const iterator = {
      next() {
        if (step + 1 >= materialColours.length) {
          step = 0;
        }
        return materialColours[step++];
      },
    };
    return iterator;
  },
};

export default class Colours {
  static materialColors() {
    return ColourIterator[Symbol.iterator]();
  }
}
