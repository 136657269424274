import React, { createContext, useState, ReactNode } from 'react';
import { AlertDto } from '../api/apiDtos';

interface AlertsConfigContextProps {
  alertsConfig: string;
  setAlertsConfig: React.Dispatch<React.SetStateAction<string>>;
  selectedAlert: AlertDto;
  setSelectedAlert: React.Dispatch<React.SetStateAction<AlertDto>>;
  callAlerts: boolean;
  CallingAlerts: () => void;
}

export const AlertsConfigContext = createContext<
  AlertsConfigContextProps | undefined
>(undefined);

interface AlertsConfigProviderProps {
  children: ReactNode;
}

export const AlertsConfigProvider: React.FC<AlertsConfigProviderProps> = ({
  children,
}) => {
  const [alertsConfig, setAlertsConfig] = useState<string>('');
  const [callAlerts, setCallAlerts] = useState<boolean>(false);
  const [selectedAlert, setSelectedAlert] = useState<AlertDto>(null);

  function CallingAlerts() {
    setCallAlerts(true);
    setCallAlerts(false);
  }

  return (
    <AlertsConfigContext.Provider
      value={{
        alertsConfig,
        setAlertsConfig,
        selectedAlert,
        setSelectedAlert,
        callAlerts,
        CallingAlerts,
      }}
    >
      {children}
    </AlertsConfigContext.Provider>
  );
};

export default AlertsConfigProvider;
