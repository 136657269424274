import React, { FC, useState } from 'react';
import {
  withStyles,
  TextField,
  WithStyles,
  createStyles,
} from '@material-ui/core';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import { toast } from 'react-toastify';
import AddEntityDialog from '../dialog/AddEntityDialog';
import LocalComboBox from '../combobox/local-combo-box';
import { useTranslation } from 'react-i18next';

const styles = createStyles({
  formControl: {
    marginBottom: 10,
  },
});

interface Props extends WithStyles<typeof styles> {
  names: string[];
  entityType: number;
  onAdd: () => void;
}

const AddAliasDialog: FC<Props> = ({ names, entityType, classes, onAdd }) => {
  const [name, setName] = useState(names.length > 0 ? names[0] : '');
  const [alias, setAlias] = useState('');
  const { t } = useTranslation();

  const resetForm = () => {
    setAlias('');
  };

  const validate = () => {
    if (!name || !alias) {
      return { valid: false };
    }
    if (name.toLowerCase() === alias.toLowerCase()) {
      return {
        valid: false,
        text: t('Alias.AddAliasDialog.Name and Alias must be different'),
      };
    }
    return { valid: true };
  };

  const addAlias = async () => {
    const aliasMap = {
      entityType,
      name,
      aliases: [alias],
    };
    await api.post(ApiRoutes.saveAlias, aliasMap);
    toast.success(t('Alias.AddAliasDialog.Alias added'));
    onAdd();
  };

  return (
    <AddEntityDialog
      buttonName={t('Alias.AddAliasDialog.Add Alias')}
      title={t('Alias.AddAliasDialog.Add Alias')}
      validateFunc={validate}
      addFunc={addAlias}
      onClose={() => resetForm()}
    >
      <LocalComboBox
        options={names}
        value={name}
        onChange={(_: any, value: string) => setName(value)}
        customLabel={t('Alias.AddAliasDialog.Name *')}
        className={classes.formControl}
      />
      <TextField
        value={alias}
        required
        variant="outlined"
        label={t('Alias.AddAliasDialog.Alias')}
        fullWidth
        onChange={(e) => setAlias(e.target.value.trim())}
        className={classes.formControl}
      />
    </AddEntityDialog>
  );
};

export default withStyles(styles)(AddAliasDialog);
