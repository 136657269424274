import jsPDF from 'jspdf';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type Msg = {
  collating_data_complete: {
    report_count: number;
    complete: boolean;
  };
  processing_reports: boolean;
  reasonings_complete: boolean;
  narrative_report: string;
};

export type NarrativeReportReq = {
  dateFrom: string;
  dateTo: string;
  orgName: string;
  classifications: Array<{
    name: string;
    type: 'ROOT_CAUSE' | 'TOPIC' | 'HAZARD';
  }>;
};

type CurrentStatus = keyof Msg | 'not_started' | 'complete';

const functionKey = process.env.REACT_APP_OPENAI_REPORTING_FUNCTION_KEY;

const useNarrativeReport = (
  url: string | null,
  payload: NarrativeReportReq
): { triggerReportNarrative: typeof triggerReportNarrative; error: Error } => {
  const { t } = useTranslation();

  const triggerReportNarrative = async () => {
    try {
      const response = await showStartingSnackbar(
        () =>
          fetch(url!, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-functions-key': `${functionKey}`,
            },
            body: JSON.stringify(payload),
          }),
        {
          info: t('useNarrativeReport.Starting report process...'),
          success: t('useNarrativeReport.Report generation started'),
        }
      );

      if (response.ok) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        let currentStatus: CurrentStatus = 'collating_data_complete';
        let reportCount = 0;
        let done = false;
        let narrativeReport = '';

        while (!done) {
          switch (currentStatus as CurrentStatus) {
            case 'collating_data_complete': {
              const [_, count, isDone, newStatus] = await showSnackbar(
                () =>
                  new Promise((res) => {
                    reader.read().then(({ value, done: streamDone }) => {
                      if (streamDone) done = true;

                      const text = decoder.decode(value, { stream: true });

                      const newMessage = JSON.parse(JSON.parse(text)) as Msg; //have to json.parse twice because the event stream is an encoded json string and JS is not smart enough to detect it from the first json.parse even though its a string, so yeah... you can thank brendan eich

                      const newStatus = parse(newMessage);
                      const reportCount =
                        newMessage.collating_data_complete.report_count;

                      if (newStatus === getNextStage('collating_data_complete'))
                        res(['', reportCount, false]);
                    });
                  }),
                {
                  info: t('useNarrativeReport.Collating data...'),
                  success: t('useNarrativeReport.Collated data'),
                },
                'collating_data_complete'
              );

              currentStatus = newStatus;
              done = isDone;
              reportCount = count;

              break;
            }
            case 'processing_reports': {
              const [_, __, isDone, newStatus] = await showSnackbar(
                () =>
                  new Promise(async (res) => {
                    const { value, done: streamDone } = await reader.read();
                    if (streamDone) done = true;

                    const text = decoder.decode(value, { stream: true });

                    const newMessage = JSON.parse(JSON.parse(text)) as Msg; //have to json.parse twice because the event stream is a decoded json string and JS is not smart enough to detect it from the first json.parse even though its a string, so yeah... you can thank brendan eich

                    const reportCount =
                      newMessage.collating_data_complete.report_count;

                    currentStatus = parse(newMessage);
                    if (currentStatus === getNextStage('processing_reports'))
                      res(['', reportCount, false]);
                  }),
                {
                  info: t('useNarrativeReport.Processing reports...', {
                    reportCount: reportCount,
                  }),
                  success: t('useNarrativeReport.Finished processing reports'),
                },
                'processing_reports'
              );

              currentStatus = newStatus;
              done = isDone;

              break;
            }
            case 'reasonings_complete': {
              const [_, __, isDone, newStatus] = await showSnackbar(
                () =>
                  new Promise(async (res) => {
                    const { value, done: streamDone } = await reader.read();
                    if (streamDone) done = true;

                    const text = decoder.decode(value, { stream: true });

                    const newMessage = JSON.parse(JSON.parse(text)) as Msg; //have to json.parse twice because the event stream is a decoded json string and JS is not smart enough to detect it from the first json.parse even though its a string, so yeah... you can thank brendan eich

                    const reportCount =
                      newMessage.collating_data_complete.report_count;
                    currentStatus = parse(newMessage);
                    if (currentStatus === getNextStage('reasonings_complete'))
                      res(['', reportCount, false]);
                  }),
                {
                  info: t('useNarrativeReport.Building a narrative...'),
                  success: t(
                    'useNarrativeReport.Finished building a narrative'
                  ),
                },
                'reasonings_complete'
              );

              currentStatus = newStatus;
              done = isDone;

              break;
            }
            case 'narrative_report': {
              const [report, _, isDone, newStatus] = await showSnackbar(
                () =>
                  new Promise(async (res) => {
                    let reportContents = '';

                    while (true) {
                      const { value, done } = await reader.read();
                      if (done) break;

                      const text = decoder.decode(value, { stream: true });

                      let newMessage: Msg | null = null;

                      try {
                        newMessage = JSON.parse(JSON.parse(text)) as Msg; //have to json.parse twice because the event stream is a decoded json string and JS is not smart enough to detect it from the first json.parse even though its a string, so yeah... you can thank brendan eich

                        reportContents = newMessage?.narrative_report ?? '';
                      } catch (err) {
                        toast.error(
                          t(
                            'useNarrativeReport.Could not finish writing the report'
                          )
                        );
                        console.error(err, newMessage);
                        break;
                      }

                      currentStatus = parse(newMessage);
                    }

                    res([reportContents, 0, true]);
                  }),
                {
                  info: t('useNarrativeReport.Writing report...'),
                  success: t('useNarrativeReport.Finished writing report'),
                },
                'narrative_report'
              );

              currentStatus = newStatus;
              done = isDone;
              narrativeReport = report;

              break;
            }
            default:
              break;
          }
        }

        let elementHTML = document.createElement('span');
        elementHTML.textContent = narrativeReport;

        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        const doc = new jsPDF();
        // doc.text(narrativeReport, 10, 10);
        // doc.save(`${formattedDate}-COMETSIGNALS-REPORT.pdf`);
        doc.html(elementHTML, {
          callback: function (doc) {
            // Save the PDF
            doc.save(`${formattedDate}-COMETSIGNALS-REPORT.pdf`);
          },
          x: 15,
          y: 15,
          width: 170, //target width in the PDF document
          windowWidth: 650, //window width in CSS pixels
        });

        return { ok: true };
      } else {
        console.error('Response not OK:', response.statusText);
        return {
          ok: false,
          error: new Error(t('useNarrativeReport.Failed to get a response')),
        };
      }
    } catch (err) {
      console.error('Failed to fetch event stream:', err, url);
      return {
        ok: false,
        error: new Error(
          t('useNarrativeReport.Failed to connect to the event stream')
        ),
      };
    }
  };

  const parse = (msg: Msg | null): CurrentStatus => {
    if (msg.narrative_report != '') {
      return 'complete';
    } else if (msg.reasonings_complete) {
      return 'narrative_report';
    } else if (msg.processing_reports) {
      return 'reasonings_complete';
    } else if (msg.collating_data_complete.complete) {
      return 'processing_reports';
    } else {
      return 'collating_data_complete';
    }
  };

  const showSnackbar = (
    promiseFunc: () => Promise<[string, number, boolean]>,
    contents: { info: string; success: string },
    stage: CurrentStatus
  ): Promise<[string, number, boolean, CurrentStatus]> => {
    return new Promise((resolve) => {
      const { info, success } = contents;
      const x = toast.promise(promiseFunc, {
        pending: info,
        success: success,
        error: t('useNarrativeReport.Report generation failed') + '🤯',
      });

      x.then(([report, reportCount, done]) =>
        resolve([report, reportCount, done, getNextStage(stage)])
      );
    });
  };

  const showStartingSnackbar = (
    promiseFunc: () => Promise<Response>,
    contents: { info: string; success: string }
  ): Promise<Response> => {
    return new Promise((resolve) => {
      const { info, success } = contents;

      const x = toast.promise(promiseFunc, {
        pending: info,
        success: success,
        error: t('useNarrativeReport.Report generation failed') + '🤯',
      });

      x.then((res) => resolve(res));
    });
  };

  const getNextStage = (stage: CurrentStatus): CurrentStatus => {
    switch (stage) {
      case 'not_started':
        return 'collating_data_complete';
      case 'collating_data_complete':
        return 'processing_reports';
      case 'processing_reports':
        return 'reasonings_complete';
      case 'reasonings_complete':
        return 'narrative_report';
      case 'narrative_report':
        return 'complete';
    }
  };

  if (!url)
    return {
      triggerReportNarrative: null,
      error: new Error(t('useNarrativeReport.URL is null')),
    };

  return { triggerReportNarrative, error: null };
};
export default useNarrativeReport;
