// @ts-nocheck
import React, { useState } from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import ConfirmDialog from '../../dialog/ConfirmDialog';
import ActionViewer from './ActionViewer';
import NewActionForm from './NewActionForm';
import { useTranslation } from 'react-i18next';

const styles = {
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
};

const TrackerActionsDialog = ({
  open,
  onClose,
  actions,
  onSubmit,
  onRemove,
  classes,
}) => {
  const [actionIndexToRemove, setActionIndexToRemove] = useState(-1);
  const { t } = useTranslation();
  const onConfirmRemove = () => {
    onRemove(actionIndexToRemove);
    setActionIndexToRemove(-1);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <IconButton onClick={onClose} className={classes.closeIcon}>
        <Close />
      </IconButton>
      <DialogTitle>
        {t('actions.TrackerActionsDialog.Manage actions')}
      </DialogTitle>
      <DialogContent>
        <NewActionForm onSubmit={onSubmit} />
        {actions &&
          actions.length > 0 &&
          actions.map((action, index) => (
            <ActionViewer
              key={`av-${index}`}
              action={action}
              onRemove={() => setActionIndexToRemove(index)}
            />
          ))}
      </DialogContent>
      <ConfirmDialog
        open={actionIndexToRemove >= 0}
        onCancel={() => setActionIndexToRemove(-1)}
        onConfirm={onConfirmRemove}
        title={t('actions.TrackerActionsDialog.Delete action')}
        confirm="Delete"
      >
        <Typography variant="body1">
          {t(
            'actions.TrackerActionsDialog.Action will be deleted. Do you wish to continue?'
          )}
        </Typography>
      </ConfirmDialog>
    </Dialog>
  );
};

TrackerActionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  actions: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TrackerActionsDialog);
