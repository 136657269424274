import React, { useState, FC, useEffect } from 'react';
import {
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  CardContent,
  DialogActions,
  Button,
  makeStyles,
  Theme,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { AdditionalTopic, AdditionalTopicDto } from '../../api/apiDtos';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import { toast } from 'react-toastify';
import Spacer from '../common/Spacer';
import EditTopic from './EditTopic';
import NewTopic from './NewTopic';
import { Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmationCard: {
    maxWidth: 600,
    width: 600,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
  },
}));

const TopicManagement: FC<{}> = () => {
  const { t } = useTranslation();
  const [showConfirmPopUp, setShowConfirmPopUp] = useState<boolean>(false);
  const [topics, setTopics] = useState<AdditionalTopic[]>([]);
  const [selectedTopic, setSelectedTopic] = useState<AdditionalTopic>(null);
  const classes = useStyles();

  useEffect(() => {
    async function onInit() {
      await getTopics();
    }
    onInit();
  }, []);

  async function getTopics() {
    try {
      const result: AdditionalTopic[] = await api.get<
        string,
        AdditionalTopic[]
      >(ApiRoutes.getTopics);
      if (result?.length) {
        setTopics(result);
      } else {
        setTopics([]);
      }
    } catch (error) {
      toast.error(
        t('configure-topics.TopicManagement.Failed to fetch topics'),
        error
      );
    }
  }

  async function DeleteTopic(topic: AdditionalTopicDto) {
    if (topic) {
      await api
        .del(`${ApiRoutes.deleteTopic}`, topic)
        .then(async () => {
          await getTopics();
          toast.success(
            t('configure-topics.TopicManagement.Topic Successfully Deleted')
          );
          setSelectedTopic(null);
          setShowConfirmPopUp(false);
        })
        .catch((error) => {
          toast.error(error);
          setSelectedTopic(null);
          setShowConfirmPopUp(false);
        });
    }
  }

  const onTopicAdded = async () => {
    await getTopics();
    toast.success(
      t('configure-topics.TopicManagement.Topic added successfully')
    );
  };

  const onTopicEdited = async () => {
    await getTopics();
    toast.success(
      t('configure-topics.TopicManagement.Topic updated successfully')
    );
  };

  function RenderConfirmationPopUp() {
    return (
      <div className={classes.backdrop}>
        <div className={classes.confirmationCard}>
          <CardContent>
            {t(
              'configure-topics.TopicManagement.Are you sure you want to delete'
            )}{' '}
            <strong style={{ fontWeight: 'bold' }}>
              {selectedTopic?.name}
            </strong>
            ?
          </CardContent>
          <DialogActions>
            <Button
              onClick={() => DeleteTopic(selectedTopic ?? {})}
              color="primary"
              variant="outlined"
            >
              {t('configure-topics.TopicManagement.Create')}
            </Button>
            <Button
              onClick={() => setShowConfirmPopUp(false)}
              color="primary"
              variant="contained"
            >
              {t('Global.Cancel')}
            </Button>
          </DialogActions>
        </div>
      </div>
    );
  }

  return (
    <>
      {showConfirmPopUp && selectedTopic && RenderConfirmationPopUp()}
      <div className={classes.header}>
        <Spacer />
        <NewTopic onSuccess={onTopicAdded} />
      </div>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {t('configure-topics.TopicManagement.Name')}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topics.map((topic) => {
              return (
                <TableRow key={topic.id}>
                  <TableCell>{topic.name}</TableCell>
                  <TableCell>
                    <EditTopic topic={topic} onSuccess={onTopicEdited} />
                    <Tooltip
                      title={t('configure-topics.TopicManagement.Delete topic')}
                    >
                      <IconButton aria-label="EditTopic" color="primary">
                        <Delete
                          color="primary"
                          onClick={() => {
                            setShowConfirmPopUp(true);
                            setSelectedTopic(topic);
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};
export default TopicManagement;
