// @ts-nocheck
import React, { useState } from 'react';
import { withStyles, Button, TextField } from '@material-ui/core';
import { SaveAlt as SaveAltIcon } from '@material-ui/icons';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import DashboardModal from '../dashboard/DashboardModal';
import PropTypes from 'prop-types';
import { HasFilters, GetTrackerParams } from '../../signals/filterService';
import { useTranslation } from 'react-i18next';

const styles = {
  activatorButton: {
    height: '35px',
    marginBottom: '.5em',
  },
  topIndent: {
    marginTop: '1em',
  },
  inactiveButton: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  activeButton: { opacity: 1 },
};

const SaveTrackerDialog = ({ classes, onCreateTracker }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [trackerName, setTrackerName] = useState('');

  const hasFilters = HasFilters();

  const saveTracker = () => {
    const params = {
      name: trackerName,
      filters: GetTrackerParams(),
    };

    api.post(ApiRoutes.saveTracker, params).then(
      () => {
        if (onCreateTracker) {
          onCreateTracker();
        }
      },
      (err) => {
        console.log(t('filters.SaveTrackerDialog.error encountered:') + err);
      }
    );
  };

  return (
    <>
      <Button
        style={!hasFilters ? styles.inactiveButton : styles.activeButton}
        color="primary"
        variant="contained"
        startIcon={<SaveAltIcon />}
        onClick={() => {
          hasFilters ? setIsOpen(true) : setIsOpen(false);
        }}
        fullWidth
        className={classes.activatorButton}
      >
        {t('filters.SaveTrackerDialog.Save Tracker')}
      </Button>
      <DashboardModal
        width={'sm'}
        title={t('filters.SaveTrackerDialog.Create new tracker')}
        open={isOpen}
        handleClose={() => setIsOpen(false)}
      >
        <div>
          <div>
            <TextField
              variant="outlined"
              fullWidth
              label={t('filters.SaveTrackerDialog.Tracker Name')}
              name="trackerName"
              value={trackerName}
              onChange={(e) => setTrackerName(e.target.value)}
            ></TextField>
          </div>
          <div className={classes.topIndent}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<SaveAltIcon />}
              onClick={saveTracker}
              disabled={!trackerName}
            >
              {t('filters.SaveTrackerDialog.Save Tracker')}
            </Button>
          </div>
        </div>
      </DashboardModal>
    </>
  );
};

SaveTrackerDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onCreateTracker: PropTypes.func,
};

export default withStyles(styles)(SaveTrackerDialog);
