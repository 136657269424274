import React, { FC, useState } from 'react';
import API from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import { Tooltip, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../dialog/ConfirmDialog';
import { ActionDto } from '../../api/apiDtos';
import { useTranslation } from 'react-i18next';

interface Props {
  action: ActionDto;
  onDelete: () => void;
}

const DeleteActionDialog: FC<Props> = ({ action, onDelete }) => {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);

  const deleteAction = () => {
    API.del<ActionDto, any>(ApiRoutes.deleteAction, { id: action.id }).then(
      () => {
        onDelete();
        setShowDialog(false);
      }
    );
  };

  return (
    <>
      <Tooltip title={t('Actions.DeleteActionDialog.Title')}>
        <IconButton
          aria-label="DeleteAction"
          color="primary"
          onClick={() => setShowDialog(true)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmDialog
        open={showDialog}
        onCancel={() => setShowDialog(false)}
        onConfirm={deleteAction}
        title={t('Actions.DeleteActionDialog.Title')}
        confirm="Delete"
      >
        <Typography variant="body1">
          {t('Actions.DeleteActionDialog.ActionTitle', { title: action.title })}
          <br />
          {t('Actions.DeleteActionDialog.ActionBody')}
        </Typography>
      </ConfirmDialog>
    </>
  );
};

export default DeleteActionDialog;
