import React, { useEffect, useRef } from 'react';
import { Map, View } from 'ol';
import * as proj from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { defaults as defaultControls } from 'ol/control';
import { makeStyles } from '@material-ui/core';
import { createTileLayer, MARKER_STYLES } from './map-utils';

const styles = {
  mapContainer: {
    height: 400,
    width: '100%',
  },
};

const useStyles = makeStyles(styles);

function SinglePointMap(props) {
  const mapElement = useRef();
  const classes = useStyles();

  useEffect(() => {
    const loc = props.location || [0, 0];

    const initMap = (location) => {
      if (!location) {
        return;
      }

      const initialMap = createMap(location);
      drawPoint(initialMap, location);
    };

    initMap(loc);
  }, []);

  const createMap = (location) => {
    return new Map({
      target: mapElement.current,
      layers: [createTileLayer()],
      view: new View({
        center: location,
        zoom: 8,
        minZoom: 5,
        maxZoom: 13,
      }),
      controls: defaultControls(),
    });
  };

  const drawPoint = (map, location) => {
    const layer = new VectorLayer({
      source: new VectorSource({
        features: [],
        wrapX: false,
        noWrap: true,
      }),
      style: MARKER_STYLES.singlePoint,
    });

    const coordinate = proj.fromLonLat(location);
    const geometry = new Point(coordinate);
    const feature = new Feature({ geometry });

    layer.getSource().addFeature(feature);
    map.addLayer(layer);
    map.getView().setCenter(coordinate);
  };

  return <div ref={mapElement} className={classes.mapContainer} />;
}

export default SinglePointMap;
