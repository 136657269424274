import { Tile as TileLayer } from 'ol/layer';
import { OSM } from 'ol/source';
import { Circle, Fill, Stroke, Style } from 'ol/style';

export function createTileLayer() {
  return new TileLayer({
    source: new OSM({
      opaque: false,
      wrapX: false,
      noWrap: true,
    }),
  });
}

export const MARKER_STYLES = {
  common: new Style({
    image: new Circle({
      radius: 5,
      stroke: new Stroke({ color: '#000' }),
      fill: new Fill({ color: '#91DC9B' }),
    }),
  }),
  hover: new Style({
    image: new Circle({
      radius: 5,
      stroke: new Stroke({ color: '#000' }),
      fill: new Fill({ color: '#4897f7' }),
    }),
  }),
  clicked: new Style({
    image: new Circle({
      radius: 5,
      stroke: new Stroke({ color: '#000' }),
      fill: new Fill({ color: '#c025db' }),
    }),
    zIndex: 9000,
  }),
  incident: new Style({
    image: new Circle({
      radius: 5,
      stroke: new Stroke({ color: '#000' }),
      fill: new Fill({ color: '#FF5722' }),
    }),
  }),
  singlePoint: new Style({
    image: new Circle({
      radius: 8,
      stroke: new Stroke({ color: '#000' }),
      fill: new Fill({ color: '#91DC9B' }),
    }),
  }),
};

export function getLocationText(location) {
  if (location && Array.isArray(location) && location.length === 2) {
    return `[${location[0]}, ${location[1]}]`;
  }
}

export function getLocationTitle(report) {
  if (report) {
    return report.postcode || getLocationText(report.location);
  }
}
