import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const styles = {
  root: {
    margin: '0px 10px',
    minWidth: 115,
    '& .MuiSelect-select': {
      padding: '10px 32px 10px 14px',
    },
  },
};

export const PERIODS = {
  Day: 1,
  Week: 7,
  Month: 30,
};

const ChartDataPeriodSelector = ({ value, onChange, className, classes }) => {
  const { t } = useTranslation();
  return (
    <FormControl variant="outlined" className={`${classes.root} ${className}`}>
      <InputLabel id="period-selector-label">
        {t('tracker.ChartDataPeriodSelector.View')}
      </InputLabel>
      <Select
        value={value}
        onChange={(event) => onChange(event.target.value)}
        labelId="period-selector-label"
        label="Period"
      >
        <MenuItem value={PERIODS.Day}>
          {t('tracker.ChartDataPeriodSelector.Daily')}
        </MenuItem>
        <MenuItem value={PERIODS.Week}>
          {t('tracker.ChartDataPeriodSelector.Weekly')}
        </MenuItem>
        <MenuItem value={PERIODS.Month}>
          {t('tracker.ChartDataPeriodSelector.Monthly')}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

ChartDataPeriodSelector.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChartDataPeriodSelector);
