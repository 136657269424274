import React, { createContext, useState } from 'react';

export const ClusterFilterListenerContext = createContext();

export const ClusterFilterListenerProvider = ({ children }) => {
  const [clusterFilterListener, setClusterFilterListener] = useState(false);
  return (
    <ClusterFilterListenerContext.Provider
      value={[clusterFilterListener, setClusterFilterListener]}
    >
      {children}
    </ClusterFilterListenerContext.Provider>
  );
};
export default ClusterFilterListenerProvider;
