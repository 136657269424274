import React, { useState } from 'react';
import { Popover, IconButton, Typography, withStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const styles = {
  activatorButton: {
    color: '#7B7B7B',
  },
  content: {
    padding: 16,
    maxWidth: 400,
  },
};

const AdvancedSearchHelp = ({ classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        className={classes.activatorButton}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <HelpOutlineIcon fontSize="small" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography variant="body2" className={classes.content}>
          {t('header.search_tool_tip')}
        </Typography>
      </Popover>
    </>
  );
};

AdvancedSearchHelp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdvancedSearchHelp);
