// @ts-nocheck
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const styles = {
  root: {
    margin: '1em',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.25s',
    height: 40,
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
  },
  input: {
    paddingLeft: '2.5em',
    flex: 1,
    transition: 'all 0.25s',
    color: '#6c6c6c',
    height: '100%',
    '& input': {
      padding: 0,
    },
  },
  focused: {
    opacity: 1,
    color: 'black !important',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  iconButton: {
    padding: 10,
    margin: 2,
    color: '#6c6c6c',
  },
  clearButton: {
    position: 'absolute',
    zIndex: 999,
  },
};

const useStyles = makeStyles(styles);

function SearchBar(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [focusBar, setFocusBar] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { onSubmit, onChange } = props;

  const textChanged = (text) => {
    setSearchText(text);
    if (onChange) {
      onChange(text);
    }
  };

  const submitHandler = () => {
    if (onSubmit) {
      onSubmit(searchText);
    }
  };

  const keySubmitHandler = (e) => {
    if (e.keyCode === 13) {
      submitHandler();
    }
  };

  const mouseEnterHander = () => setFocusBar(true);
  const mouseLeaveHandler = () => setFocusBar(false);
  const changeHander = (e) => textChanged(e.target.value);

  return (
    <Paper className={classes.root}>
      <InputBase
        className={`${classes.input} ${focusBar ? classes.focused : ''}`}
        classes={{ focused: classes.focused }}
        placeholder={t('header.search')}
        value={searchText}
        onChange={changeHander}
        onKeyDown={keySubmitHandler}
        onMouseEnter={mouseEnterHander}
        onMouseLeave={mouseLeaveHandler}
      />

      {searchText && (
        <IconButton
          onClick={() => textChanged('')}
          color="default"
          className={classes.clearButton}
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      )}
      <IconButton className={classes.iconButton} onClick={submitHandler}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

SearchBar.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default SearchBar;
