// @ts-nocheck
import React, { useState } from 'react';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  IconButton,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import DashboardModal from '../../dashboard/DashboardModal';
import { useTranslation } from 'react-i18next';

const styles = {
  readMoreButton: {
    whiteSpace: 'nowrap',
    marginLeft: 10,
    color: '#0095FD',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tableRoot: {
    tableLayout: 'fixed',
  },
  firstColumn: {
    width: 250,
  },
  listingTitle: {
    marginBottom: 16,
  },
  listing: {
    paddingInlineStart: '40px',
    listStyleType: 'disc',
  },
  paragraph: {
    marginBottom: 16,
  },
};

const CAPTION_LIMIT = 150;

const ListingView = ({ listing, classes }) => {
  return (
    <>
      <p className={classes.listingTitle}>{listing.Title}</p>
      <ul className={classes.listing}>
        {listing.Items.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </ul>
    </>
  );
};

const ParagraphsView = ({ paragraphs, classes }) => {
  return (
    <>
      {paragraphs.map((paragraph, idx) => {
        return (
          <p key={idx} className={classes.paragraph}>
            {paragraph}
          </p>
        );
      })}
    </>
  );
};

const PreventiveActionsTable = ({ data, limit, settings, classes }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', text: '' });

  const openModal = (title, composedText) => {
    setModalContent({
      title,
      text: getText(composedText),
    });
    setShowModal(true);
  };

  const getCaption = (composedText) => {
    const wholeText = composedText.Listing
      ? composedText.Listing.Title
      : composedText.Paragraphs[0];

    return `${wholeText.substring(0, CAPTION_LIMIT)}...`;
  };

  const getText = (composedText) => {
    return composedText.Listing ? (
      <ListingView classes={classes} listing={composedText.Listing} />
    ) : (
      <ParagraphsView classes={classes} paragraphs={composedText.Paragraphs} />
    );
  };

  const {
    rootCauseType,
    shortTermRecommendedActivity,
    longTermRecommendedActivity,
    objectiveLabel,
  } = settings;

  limit = limit || data.length;

  return (
    <Table className={classes.tableRoot}>
      <TableHead>
        <TableRow>
          <TableCell component="th" className={classes.firstColumn}>
            {rootCauseType}
          </TableCell>
          <TableCell component="th">{shortTermRecommendedActivity}</TableCell>
          <TableCell component="th">{longTermRecommendedActivity}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.slice(0, limit).map((item) => {
          return (
            <TableRow key={item.label}>
              <TableCell className={classes.firstColumn}>
                {item.label}
                <IconButton
                  onClick={() =>
                    openModal(objectiveLabel, item.preventiveAction.description)
                  }
                >
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </TableCell>
              <TableCell>
                {getCaption(item.preventiveAction.shortTermActivity)}
                <span
                  className={classes.readMoreButton}
                  onClick={() =>
                    openModal(
                      shortTermRecommendedActivity,
                      item.preventiveAction.shortTermActivity
                    )
                  }
                >
                  {t(
                    'widgets.preventive_actions_guidance.PreventiveActionsTable.view all'
                  )}
                </span>
              </TableCell>
              <TableCell>
                {getCaption(item.preventiveAction.longTermActivity)}
                <span
                  className={classes.readMoreButton}
                  onClick={() =>
                    openModal(
                      longTermRecommendedActivity,
                      item.preventiveAction.longTermActivity
                    )
                  }
                >
                  {t(
                    'widgets.preventive_actions_guidance.PreventiveActionsTable.view all'
                  )}
                </span>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      {showModal && (
        <DashboardModal
          title={modalContent.title}
          open={showModal}
          handleClose={() => setShowModal(false)}
          width="md"
        >
          {modalContent.text}
        </DashboardModal>
      )}
    </Table>
  );
};

PreventiveActionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  limit: PropTypes.number,
};

export default withStyles(styles)(PreventiveActionsTable);
