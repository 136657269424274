// NewTopic.tsx

import React, { useState } from 'react';
import {
  withStyles,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  WithStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ValidationService from '../../services/authentication/ValidationService';
import ApiRoutes from '../../services/ApiRoutes';
import API from '../../services/ApiInstance';
import InfoDialog from '../dialog/InfoDialog';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const styles = {
  button: {
    marginBottom: '1.5em',
  },
  control: {
    marginBottom: '1em',
  },
};

interface NewTopicProps extends WithStyles<typeof styles> {
  onSuccess: () => void;
}

const NewTopic: React.FC<NewTopicProps> = ({ onSuccess, classes }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [validationErrorText, setValidationErrorText] = useState('');
  const [openValidationError, setOpenValidationError] = useState(false);
  const [loading, setLoading] = useState(false);
  const validator = new ValidationService();

  const FORM_ERROR_TEXT = t('configure-topics.NewTopic.FORM_ERROR_TEXT');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName('');
  };

  const submit = () => {
    const requirements = [ValidationService.validators.required(name)];

    const isValid = validator.validate(...requirements);

    if (!isValid) {
      setValidationErrorText(FORM_ERROR_TEXT);
      setOpenValidationError(true);
    } else {
      setLoading(true);
      const newTopic = { name };

      API.post(ApiRoutes.addTopic, newTopic)
        .then(() => {
          setLoading(false);
          handleClose();
          onSuccess();
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
          handleClose();
        });
    }
  };

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={handleClickOpen}
        className={classes.button}
      >
        <AddIcon />
        {t('configure-topics.NewTopic.Add new topic')}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>{t('configure-topics.NewTopic.New Topic')}</DialogTitle>
        <DialogContent>
          <TextField
            label={t('configure-topics.NewTopic.Name')}
            value={name}
            onChange={(event) => setName(event.target.value)}
            required
            autoFocus
            fullWidth
            variant="outlined"
            className={classes.control}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={submit}
            disabled={loading}
            variant="contained"
          >
            {t('configure-topics.NewTopic.Create')}
          </Button>
          <Button onClick={handleClose} color="primary" variant="outlined">
            {t('Global.Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      {openValidationError && (
        <InfoDialog
          open={openValidationError}
          onClose={() => setOpenValidationError(false)}
          text={validationErrorText}
        />
      )}
    </>
  );
};

export default withStyles(styles)(NewTopic);
