import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  ConstructFilter,
  AddFilter,
  UpdateFilter,
  IsFilterActive,
  RemoveFiltersByName,
  filters,
} from '../../signals/filterService';
import { useTranslation } from 'react-i18next';

export default function FilterNumberInput({
  label,
  filterName,
  filterTag,
  prefix,
}) {
  const { t } = useTranslation();
  const [number, setNumber] = useState('');
  const [canApply, setCanApply] = useState(false);
  const startAdornment = prefix && (
    <InputAdornment position="start">{prefix}</InputAdornment>
  );

  const handleChange = (event) => {
    setCanApply(true);
    const val = event.target.value >= 0 ? event.target.value : 0;
    setNumber(val);
  };

  const applyChange = () => {
    setCanApply(false);
    const filter = ConstructFilter(filterName, filterTag, number);
    if (filter.value === '' || filter.value === '0') {
      RemoveFiltersByName(filter.name);
      if (
        filter &&
        filterName === filter.name &&
        filterTag === filter.parameter
      )
        setNumber('');
    } else {
      if (IsFilterActive(filter)) {
        UpdateFilter(filter);
      } else {
        AddFilter(filter);
      }
    }
  };

  useEffect(
    function resetFilterWhenFiltersChange() {
      const filterRemoved = (filter) => {
        if (
          filter &&
          filterName === filter.name &&
          filterTag === filter.parameter
        )
          setNumber('');
      };

      filters.value.forEach(filterRemoved);
    },
    [filters.value]
  );

  return (
    <div>
      <TextField
        style={{ background: 'white', borderRadius: 5, color: 'white' }}
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        label={label}
        value={number}
        onChange={handleChange}
        variant="filled"
        InputProps={{
          startAdornment: startAdornment,
        }}
      />
      <Button
        style={{ color: 'white' }}
        size="small"
        disabled={!canApply}
        onClick={applyChange}
        aria-label="apply"
      >
        {t('filters.FilterNumberInput.Apply')}
      </Button>
    </div>
  );
}

FilterNumberInput.propTypes = {
  label: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired,
  filterTag: PropTypes.string.isRequired,
};
