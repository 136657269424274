import React, { FC } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts';
import Colours from '../../../services/ColourService';
import { ChartStyles } from '../ChartConstants';
import { LifeSavingRuleDto } from '../../../api/bllDtos';

interface Props {
  data: LifeSavingRuleDto[];
  axisY: string;
  onClick: (code: string) => void;
}

export interface Palette {
  [key: string]: string;
}

const LifeSavingRulesBarChart: FC<Props> = ({ data, axisY, onClick }) => {
  const chartData = data.map((item) => ({
    code: item.code,
    [item.name!]: item.count,
  }));

  const chartShape = data.map((item) => (item.name ? item.name : ''));

  const coloursGenerator = Colours.materialColors();
  const palette: Palette = chartShape.reduce((palette, c) => {
    palette[c] = coloursGenerator.next();
    return palette;
  }, {} as Palette);

  const onChartClick = (event: any) => {
    if (!event || !event.activePayload) {
      return;
    }
    const activeTooltipPayload = event.activePayload[event.activeTooltipIndex];
    if (activeTooltipPayload && activeTooltipPayload.payload) {
      onClick(activeTooltipPayload.payload.code);
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={chartData}
        onClick={onChartClick}
        margin={ChartStyles.barChartMargin}
      >
        <CartesianGrid strokeDasharray="3 3" fill="#F1F3F4" />
        <Tooltip
          contentStyle={ChartStyles.tooltipContentStyle}
          itemStyle={ChartStyles.tooltipItemStyle}
        />
        {chartShape.map((c) => (
          <Bar
            key={c}
            dataKey={c}
            stackId="A"
            fill={palette[c]}
            cursor="pointer"
          />
        ))}
        <YAxis
          type="number"
          label={{
            value: axisY,
            angle: -90,
            dx: -15,
            letterSpacing: 1,
          }}
        />
        <XAxis type="category" dataKey="name" />
        <Legend wrapperStyle={{ paddingBottom: 8 }} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default LifeSavingRulesBarChart;
