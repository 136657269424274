// @ts-nocheck
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ColumnChart from '../charts/ColumnChart';
import { widgetPropOptions } from '../widgets-manager-page/widgets-management-constants';
import { OTHERS } from '../../constants';
import FilteredReportsViewer from './FilteredReportsViewer';

const sortByCount = (a, b) => b.count - a.count;

function ReportsByBrandCard({ settings, data }) {
  const [selectedItem, setSelectedItem] = useState(null);

  const { axisX, axisY, organisationOrFacility } = settings;

  const source =
    organisationOrFacility === widgetPropOptions.organisation
      ? data.organisationChartData
      : data.facilityChartData;

  const chartData = source
    .sort(sortByCount)
    .map((item) => {
      const result = {
        id: item.id,
        name: item.name,
        count: item.count,
      };

      const top = item.reportTypes.slice(0, 3);
      top.forEach((element) => {
        result[element.name] = element.count;
      });

      if (item.reportTypes.length > 3) {
        const othersCount = item.reportTypes
          .slice(3)
          .reduce((sum, reportType) => sum + reportType.count, 0);
        if (result[OTHERS]) {
          result[OTHERS] = result[OTHERS] + othersCount;
        } else {
          result[OTHERS] = othersCount;
        }
      }

      return result;
    })
    .sort(sortByCount);

  const createReportFilters = () => {
    if (!selectedItem) {
      return {};
    }
    const filterTag =
      organisationOrFacility === widgetPropOptions.organisation
        ? FilterTags.report.organisation
        : FilterTags.report.facility;
    return { [filterTag]: selectedItem.id };
  };
  const reportFilters = createReportFilters();

  return (
    <>
      <ColumnChart
        chartData={chartData}
        chartShape={data.chartShape}
        header={axisY}
        label={axisX}
        onSelectItem={(item) => setSelectedItem(item)}
      />
      {selectedItem && (
        <FilteredReportsViewer
          open={true}
          handleClose={() => setSelectedItem(null)}
          filters={reportFilters}
          title={selectedItem.name}
        />
      )}
    </>
  );
}

ReportsByBrandCard.propTypes = {
  settings: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default ReportsByBrandCard;
