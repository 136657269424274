import React from 'react';
import { useDrop } from 'react-dnd';
import { CARD_TYPE } from './constants';
import { getBackgroundColor } from './shared';
import ValueMapper from './ValueMapper';

export const Card = ({
  children,
  title,
  name,
  itemised,
  onChangeValueMappingRules,
  valueMappingRules,
  isValueMapperDisabled,
  required,
  visible = true,
}) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: CARD_TYPE,
    drop: () => ({ name: name }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isCardMapped = children && children.length > 0;

  return (
    <div
      ref={drop}
      className="mapper-row"
      style={{
        backgroundColor: getBackgroundColor(isOver, canDrop),
        display: visible ? 'flex' : 'none',
        boxShadow: required && !isCardMapped ? '0 0 4px red' : null,
      }}
    >
      <div className="mapper-col">{children}</div>
      <div className="mapper-col">
        <div className="destination-label">
          {title}
          {itemised && (
            <ValueMapper
              propertyName={name}
              onChange={onChangeValueMappingRules}
              rules={valueMappingRules}
              disabled={isValueMapperDisabled}
            />
          )}
        </div>
      </div>
    </div>
  );
};
