// @ts-nocheck
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  Tooltip,
  TextField,
  Paper,
  Grid,
  Box,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import SettingsIcon from '@material-ui/icons/Settings';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { isObjectEmpty } from '../../../helpers/data-helper';
import ValueMappingRulesItem from './ValueMappingRulesItem';
import { Close } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const styles = {
  paper: {
    padding: 5,
    marginBottom: 5,
  },
  inputContainer: {
    paddingRight: 5,
  },
  addButton: {
    height: '100%',
  },
  activatorButton: {
    marginLeft: 5,
  },
};

const ValueMapper = ({ propertyName, onChange, rules, classes, disabled }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [mappedTo, setMappedTo] = useState('');
  const [source, setSource] = useState('');

  const handleAddClick = () => {
    if (source === mappedTo) {
      toast.info(
        t(
          'upload.upload_mapper.ValueMapper.Source and target values should differ'
        )
      );
      return;
    }
    const currentRules = rules ? { ...rules } : {};
    onChange(propertyName, {
      ...currentRules,
      [source]: mappedTo,
    });
    setMappedTo('');
    setSource('');
  };

  const handleRemoveClick = (source) => {
    const currentRules = { ...rules };
    delete currentRules[source];
    onChange(propertyName, currentRules);
  };

  const canAdd = () => !!mappedTo && !!source;

  return (
    <>
      <Tooltip
        title={t('upload.upload_mapper.ValueMapper.Set value mapping rules')}
      >
        <IconButton
          aria-label="ValueMappingRules"
          color="primary"
          size="small"
          className={classes.activatorButton}
          onClick={() => setOpen(true)}
          disabled={disabled}
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {t('upload.upload_mapper.ValueMapper.Set value mapping rules for')}{' '}
          {propertyName}
        </DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={5} className={classes.inputContainer}>
                <TextField
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                  fullWidth
                  label="Source value"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={5} className={classes.inputContainer}>
                <TextField
                  value={mappedTo}
                  onChange={(e) => setMappedTo(e.target.value)}
                  fullWidth
                  label={t('upload.upload_mapper.ValueMapper.Target value')}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  className={classes.addButton}
                  color="primary"
                  variant="outlined"
                  onClick={handleAddClick}
                  disabled={!canAdd()}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  {t('upload.upload_mapper.ValueMapper.Add')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {!isObjectEmpty(rules) && (
            <Paper className={classes.paper}>
              {Object.entries(rules).map(([mapFrom, mapTo]) => (
                <ValueMappingRulesItem
                  key={mapFrom}
                  source={mapFrom}
                  mappedTo={mapTo}
                  onRemove={() => handleRemoveClick(mapFrom)}
                />
              ))}
            </Paper>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

ValueMapper.propTypes = {
  propertyName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  rules: PropTypes.object,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

ValueMapper.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(ValueMapper);
