export const WidgetCode = {
  reportTable: 'ReportTable',
  reportTableExpanded: 'ReportTableExpanded',
  associatedReportTable: 'AssociatedReportTable',
  associatedReportTableExpanded: 'AssociatedReportTableExpanded',
  reportHistory: 'ReportHistory',
  costOverTime: 'CostOverTime',

  reportsByOrganisationAndTypes: 'ReportsByOrganisationAndTypes',
  reportsByOrganisations: 'ReportsByOrganisations',
  reportsByTypes: 'ReportsByTypes',

  reportsMap: 'ReportsMap',
  associatedReportsMap: 'AssociatedReportsMap',

  reportsByDates: 'ReportsByDates',
  reportsAveragesByClassification: 'ReportsAveragesByClassification',
  rootCauseBreakdowns: 'RootCauseBreakdowns',

  topicTrends: 'TopicTrends',
  fieldVerification: 'FieldVerification',
  observation: 'Observation',
  flaggedReports: 'FlaggedReports',
  priorityRootCauseClusters: 'PriorityRootCauseClusters',
  preventiveActionsGuidance: 'PreventiveActionsGuidance',
  rootCauseToTopicCorrelations: 'RootCauseToTopicCorrelations',
  priorityHazards: 'PriorityHazards',
  lifeSavingRules: 'LifeSavingRules',
  wordCloudData: 'WordCloudData',
} as const;

export type WidgetCodeType = (typeof WidgetCode)[keyof typeof WidgetCode];
