import { EntityEnums } from "../components/upload/upload-mapper/constants";
import { ClassificationEnums } from "../constants";

type AcceptableEnums = [EntityEnums, ClassificationEnums]
type AcceptableEnumsArray = AcceptableEnums[number][]

export type GenericObject = {
  [key: string]: any
}

const appendArray = (formData: FormData, name: string, array: any[]) => {
  if (!formData || !name || !array) {
    return;
  }
  for (let i = 0; i < array.length; i++) {
    formData.append(`${name}[${i}]`, array[i]);
  }
};

const appendFlagEnum = (formData: FormData, name: string, enumValues: AcceptableEnumsArray) => {
  if (!formData || !name || !enumValues) {
    return;
  }
  const newEnumValue = enumValues.reduce((prev, current) => prev | current, 0)
  formData.append(name, newEnumValue.toString());
};

const appendObject = (formData: FormData, name: string, object: GenericObject) => {
  if (!formData || !name || !object) {
    return;
  }
  Object.keys(object).forEach(key => {
    formData.append(`${name}.${key}`, object[key]);
  });
};

export { appendArray, appendFlagEnum, appendObject };