const createGuidID = (function () {
  const S4 = () => {
    const array = new Uint16Array(1);
    window.crypto.getRandomValues(array);
    return (array[0] & 0xfff).toString(16).padStart(3, '0');
  };
  return function () {
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-4' +
      S4().substr(0, 3) +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    ).toLowerCase();
  };
})();

export default class IDService {
  static Generate() {
    return createGuidID();
  }
}
