import React from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';

const styles = {
  progress: {
    display: 'block',
    margin: 'auto',
  },
};

function CircularProgressCentral({ classes }) {
  return <CircularProgress className={classes.progress} />;
}

export default withStyles(styles)(CircularProgressCentral);
