import React, { useState, useEffect, FC } from 'react';
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  WithStyles,
} from '@material-ui/core';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import { toast } from 'react-toastify';
import LocalComboBox from '../combobox/local-combo-box';
import ClassificationTypesSelector from './ClassificationTypesSelector';
import {
  ClassificationTypesRequestDto,
  OrganisationDto,
} from '../../api/apiDtos';
import { useTranslation } from 'react-i18next';

const styles = {
  actionsRoot: {
    paddingRight: 24,
  },
};

interface ClassificationRunDialogProps extends WithStyles<typeof styles> {}

const ClassificationRunDialog: FC<ClassificationRunDialogProps> = ({
  classes,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [topLevelOrganisations, setTopLevelOrganisations] = useState<
    OrganisationDto[]
  >([]);
  const [selectedClassificationTypes, setSelectedClassificationTypes] =
    useState<any[]>([]);
  const [selectedOrganisations, setSelectedOrganisations] = useState<
    OrganisationDto[]
  >([]);
  const [selectedAdditionalTopics, setSelectedAdditionalTopics] = useState(
    new Map()
  );

  useEffect(() => {
    api.get(ApiRoutes.orgsTopLevel).then((response: OrganisationDto[]) => {
      setTopLevelOrganisations(response);
    });
  }, []);

  const resetForm = () => {
    setSelectedClassificationTypes([]);
    setSelectedOrganisations([]);
    setSelectedAdditionalTopics(new Map());
  };

  const onOpen = () => {
    resetForm();
    setOpen(true);
  };

  const isFormValid = () =>
    (selectedClassificationTypes.length !== 0 ||
      selectedAdditionalTopics.size !== 0) &&
    selectedOrganisations.length !== 0;

  const structureData = () => {
    let structuredData = [];

    for (const [orgName, additionalTopics] of selectedAdditionalTopics) {
      structuredData.push({
        TopLevelOrganisationId: selectedOrganisations.find(
          (o: OrganisationDto) => o.name === orgName
        ).id,
        ClassificationTypes: selectedClassificationTypes,
        additionalOrganisationTopicIds: additionalTopics.map(
          (topic: any) => topic
        ),
      } as ClassificationTypesRequestDto);
    }

    return structuredData;
  };

  const run = async () => {
    if (!isFormValid()) {
      return;
    }

    setIsRunning(true);
    const data = structureData();
    try {
      await api.post(ApiRoutes.runClassification, data);
      toast.success(
        t('super_user.ClassificationRunDialog.Classification started')
      );
      setOpen(false);
    } finally {
      setIsRunning(false);
    }
  };

  return (
    <>
      <Button color="primary" variant="contained" onClick={onOpen}>
        {t('super_user.ClassificationRunDialog.Run classification')}
      </Button>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle>
          {t('super_user.ClassificationRunDialog.Set up classification run')}
        </DialogTitle>
        <DialogContent>
          <ClassificationTypesSelector
            orgs={selectedOrganisations}
            selectedClassificationTypes={selectedClassificationTypes}
            setSelectedClassificationTypes={setSelectedClassificationTypes}
            setSelectedAdditionalTopics={setSelectedAdditionalTopics}
          />
          <LocalComboBox
            value={selectedOrganisations}
            onChange={(event, value: OrganisationDto[]) =>
              setSelectedOrganisations(value)
            }
            options={topLevelOrganisations}
            getOptionLabel={(option: OrganisationDto) => option.name}
            getOptionSelected={(
              option: OrganisationDto,
              value: OrganisationDto
            ) => option.id === value.id}
            customLabel={t(
              'super_user.ClassificationRunDialog.Select organisations'
            )}
            multiple
          />
        </DialogContent>
        <DialogActions classes={{ root: classes.actionsRoot }}>
          <Button
            onClick={run}
            color="primary"
            variant="contained"
            disabled={isRunning || !isFormValid()}
          >
            {t('super_user.ClassificationRunDialog.Run')}
          </Button>
          <Button
            onClick={() => setOpen(false)}
            color="primary"
            variant="outlined"
          >
            {t('Global.Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(ClassificationRunDialog);
