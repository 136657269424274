import React from 'react';
import alertRead from './AlertRead.svg';
import alertUnread from './AlertUnread.svg';

export const AlertIcon: React.FC<{ type: 'read' | 'unread' }> = ({ type }) => {
  return (
    <img
      style={{ marginLeft: '1em' }}
      width={'40em'}
      src={type === 'read' ? alertRead : alertUnread}
    />
  );
};
