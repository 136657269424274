import React, { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getSettings } from '../../helpers/data-helper';
import ClassificationTrendsBase from './ClassificationTrendsBase';
import { WidgetCode, WidgetCodeType } from './widgetCodes';
import { getReportWidgetData } from '../../services/WidgetDataService';
import ApiRoutes from '../../services/ApiRoutes';
import API from '../../services/ApiInstance';
import {
  OrganisationDto,
  PossibleReportWidgetDataDto,
  UserDto,
} from '../../api/originalMappings';
import { useWidgetTranslations } from './useTranslatedWidgetsDefaultSettings';

interface Props {
  limit: number;
  settings: string;
}

const TopicTrends: FC<Props> = ({ limit, settings }) => {
  const { TopicTrendsDefaultSettings } = useWidgetTranslations();
  const {
    title,
    info,
    topic,
    reportsPercent,
    previousPeriodReportsPercent,
    currentPeriodReportsPercent,
    noDataLabel,
    defaultTopicsSource,
  } = getSettings(settings, TopicTrendsDefaultSettings);

  const fetchTrendsFunc = (
    widgetCode: WidgetCodeType
  ): Promise<PossibleReportWidgetDataDto> => {
    switch (widgetCode) {
      case 'FieldVerification':
        return getReportWidgetData(WidgetCode.fieldVerification);
      case 'Observation':
        return getReportWidgetData(WidgetCode.observation);
      default:
        //changed to defaulted field verification as it is now child of topic
        return getReportWidgetData(WidgetCode.fieldVerification);
    }
  };

  const [additionalTopicsOptions, setAdditionalTopicsOptions] = useState([]);

  useEffect(() => {
    API.post<unknown, UserDto>(ApiRoutes.userPersonalDetails).then(
      (response) => {
        const { primaryOrganisation } = response;

        API.get<unknown, OrganisationDto>(
          `${ApiRoutes.org}?Id=${primaryOrganisation.id}`
        ).then((response) => {
          const { additionalTopics } = response;
          setAdditionalTopicsOptions(
            additionalTopics.map((aT) => {
              return {
                key: aT.additionalTopicName,
                value: aT.customLabel,
              };
            })
          );
        });
      }
    );
  }, []);

  return (
    <ClassificationTrendsBase
      trendsFetchFunc={fetchTrendsFunc}
      limit={limit}
      title={title}
      info={info}
      noDataLabel={noDataLabel}
      classificationType={topic}
      previousPeriod={previousPeriodReportsPercent}
      currentPeriod={currentPeriodReportsPercent}
      reportsPercent={reportsPercent}
      widgetName="TopicTrends"
      defaultTopicsSource={defaultTopicsSource ? defaultTopicsSource : null}
      additionalTopicsOptions={additionalTopicsOptions}
    />
  );
};

export default TopicTrends;
