import React, { useState, useEffect, useRef, FC } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact, {
  HighchartsReactProps,
} from 'highcharts-react-official';
import {
  withStyles,
  LinearProgress,
  createStyles,
  WithStyles,
} from '@material-ui/core';
import { ReportClassificationAggregationDto } from '../../api/originalMappings';
import { useTranslation } from 'react-i18next';
// Include other necessary imports as per your original component setup
const styles = createStyles({
  barText: {
    fontSize: 15,
    fill: '#fff',
    lineHeight: 32,
    textShadow: '1px 1px 1px #000',
  },
  progress: {
    margin: '100px 0',
  },
});

interface Props extends WithStyles<typeof styles>, HighchartsReactProps {
  data: any[];
  showOrganisations: boolean;
}

const ReportsAveragesByClassificationChart: FC<Props> = ({
  data,
  settings,
  showOrganisations,
  classes,
  ...highChartsProps
}) => {
  const { t } = useTranslation();

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [allKeys, setAllKeys] = useState([]);
  const [classificationData, setClassificationData] = useState([]);
  const [organisationData, setOrganisationData] = useState([]);
  const [percentageItemsPostfix, setPercentageItemsPostfix] = useState(
    settings.percentageItemsPostfix
  );
  const [fractionalItemsPostfix, setFractionalItemsPostfix] = useState(
    settings.fractionalItemsPostfix
  );
  const [domain, setDomain] = useState([0, 0]);

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const FOURPS = [
    t('charts.ReportsAveragesByClassificationChart.Human Factors'),
    t('charts.ReportsAveragesByClassificationChart.Job Factors (Parts)'),
    t('charts.ReportsAveragesByClassificationChart.Unsafe Acts (Procedures)'),
    t(
      'charts.ReportsAveragesByClassificationChart.Unsafe Conditions (Positions)'
    ),
  ];

  useEffect(() => {
    processData(data);
    updateTooltipPostfixes(settings);
    // Adding dependencies to re-run these effects when `data` or `settings` props change
  }, [data, settings]);

  const updateTooltipPostfixes = (currentSettings) => {
    if (!currentSettings) return;

    const { percentageItemsPostfix, fractionalItemsPostfix } = currentSettings;

    if (percentageItemsPostfix)
      setPercentageItemsPostfix(percentageItemsPostfix);
    if (fractionalItemsPostfix)
      setFractionalItemsPostfix(fractionalItemsPostfix);
  };

  const processData = (incomingData: any[]) => {
    if (!incomingData) return;
    const newClassificationData = [];
    const newOrganisationData = [];
    let newAllKeys = [];
    let maxEndValue = 0;

    incomingData.forEach((a) => {
      const splitTotal = a.maxValuesSum;
      newOrganisationData.push({
        name: a.type,
        fullName: a.type,
        average: a.average,
        splitTotal,
        ...a.organisationValues,
      });

      newClassificationData.push({
        name: a.type,
        fullName: a.type,
        average: a.average,
        splitTotal,
        ...a.classificationValues,
      });

      newAllKeys = Array.from(
        new Set([
          ...newAllKeys,
          ...Object.keys(a.organisationValues),
          ...Object.keys(a.classificationValues),
        ])
      );

      maxEndValue = Math.max(maxEndValue, a.maxValue);
    });

    setClassificationData(newClassificationData);
    setOrganisationData(newOrganisationData);
    setAllKeys(newAllKeys);
    setDomain([0, maxEndValue + 0.05]); // Adjusted domain based on max value
    setIsLoadingData(false);
  };

  const createHighchartsData = (
    chartData: ReportClassificationAggregationDto[]
  ): any[] => {
    const keys = Array.from(new Set(chartData.map(Object.keys).flat())).filter(
      (x) =>
        x !== 'fullName' &&
        x !== 'name' &&
        x !== 'splitTotal' &&
        x !== 'average'
    );

    // Map over the keys array to create a new array of objects.
    const seriesDataKeys = keys.map((name) => ({
      name,
    }));

    const formatReportCounts = (
      chartData: ReportClassificationAggregationDto[],
      orgName: string
    ): any[] => {
      let newArr = [];
      chartData.forEach((data) => {
        newArr.push(data[orgName] ?? 0);
      });

      return newArr;
    };

    return seriesDataKeys.map((x) => ({
      ...x,
      data: formatReportCounts(chartData, x.name),
    }));
  };

  const chartData = showOrganisations ? organisationData : classificationData;

  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      polar: true,
      inverted: true,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    pane: {
      size: '100%',
      innerSize: '20%',
      endAngle: 270,
    },
    xAxis: {
      categories: FOURPS,
      lineWidth: 0,
    },
    yAxis: {
      title: {
        text: '',
      },
      lineWidth: 0,
      tickInterval: 5,
      reversedStacks: false,
      endOnTick: true,
      showLastLabel: true,
      gridLineWidth: 0,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.15,
        borderRadius: '50%',
      },
    },
    legend: {
      itemStyle: {
        fontSize: '10px',
      },
      y: 10,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      floating: false,
      backgroundColor: '#FFFFFF',
    },
    tooltip: {
      formatter: function () {
        //@ts-ignore
        const legendName = this.series.legendItem.label.textStr;
        const formattedValue = (value: number) =>
          Math.round(value * 1000) / 1000;
        return `<b>${legendName ?? 'Unknown'}</b>: ${formattedValue(
          this.point.percentage
        )}% ${percentageItemsPostfix}, (${formattedValue(
          this.y
        )}) ${fractionalItemsPostfix}`;
      },
    },
    series: createHighchartsData(chartData),
  };

  if (isLoadingData) {
    return <LinearProgress className={classes.progress} />;
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      ref={chartComponentRef}
      {...highChartsProps}
    />
  );
};

export default withStyles(styles)(ReportsAveragesByClassificationChart);
