import {z} from "zod"

export const ReportTypeCountDtoSchema = z.object({
  type: z.union([z.string(), z.null()]).optional(),
  count: z.union([z.number(), z.null()]).optional(),
})

export const ReportOrganisationCountDtoSchema = z.object({
  organisation: z.union([z.string(), z.null()]).optional(),
  count: z.union([z.number(), z.null()]).optional(),
})

export const ReportDateDtoSchema = z.object({
  date: z.union([z.string(), z.null()]).optional(),
  typeCounts: z.union([z.array(ReportTypeCountDtoSchema), z.null()]).optional(),
  organisationCounts: z.union([z.array(ReportOrganisationCountDtoSchema), z.null()]).optional(),
})

export const ChartDataDtoSchema = z.object({
  date: z.string().optional(),
  data: z.record(z.number()).optional()
})

export const ReportHistoryDtoSchema = z.object({
  chartData: z.union([z.array(ChartDataDtoSchema), z.null()]).optional(),
  total: z.number()
})


export const ReportDtoSchema = z.object({
  id: z.union([z.string(), z.null()]).optional(),
  organisation: z.union([z.string(), z.null()]).optional(),
  dateOccured: z.union([z.string(), z.null()]).optional(),
  reportType: z.union([z.string(), z.null()]).optional(),
  location: z.union([z.array(z.number()), z.null()]).optional(),
})

export const ReportLocationDtoSchema = z.object({
  location: z.union([z.array(z.number()), z.null()]).optional(),
  reportsCount: z.union([z.number(), z.null()]).optional(),
  firstReport: z.union([ReportDtoSchema,z.null()]).optional(),
})

export const MapWidgetDtoSchema = z.object({
  locations: z.union([z.array(ReportLocationDtoSchema),z.null()]).optional(),
})

export const RootCauseClassficationDtoSchema = z.object({
  levelOne: z.string().nullable().optional(),
  subcategoryCounts: z.record(z.number()).nullable().optional(),
  organisationCounts: z.record(z.number()).nullable().optional(),
})

export const RootCausePreventiveActionsDtoPreventiveActionDto = z.object({
  description: z.string().nullable().optional(),
  shortTermActivity: z.string().nullable().optional(),
  longTermActivity: z.string().nullable().optional(),
})

export const RootCausePreventiveActionsDtoSchema = z.array(z.object({
  label: z.string().nullable().optional(),
  count: z.number().optional(),
  preventiveAction: RootCausePreventiveActionsDtoPreventiveActionDto.optional(),
}))

export const WordcloudDtoSchema = z.record(z.string(), z.number())

export const ReportTypeDtoSchema = z.object({
  name: z.string().nullable().optional(),
  count: z.number().nullable().optional(),
});

export const ChartDataItemDtoSchema = z.object({
  id: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  reportTypes: z.array(ReportTypeDtoSchema).nullable().optional(),
  count: z.number().nullable().optional(),
});

export const ReportByTypeWidgetDtoSchema = z.object({
  organisationChartData: z.array(ChartDataItemDtoSchema).nullable().optional(),
  facilityChartData: z.array(ChartDataItemDtoSchema).nullable().optional(),
  chartShape: z.array(z.string()).nullable().optional(),
});

export const TopicDtoSchema = z.object({
  label: z.string(), // label is required and must be a string
  count: z.number(), // count is required and must be a number
});

// Define the Zod schema for RootCauseToTopicDto
export const RootCauseToTopicDtoSchema = z.array(
  z.object({
  label: z.string(), // label is required and must be a string
  count: z.number(), // count is required and must be a number
  topics: z.array(TopicDtoSchema), // topics is an array of TopicDto
}));

export const AssociatedReportDtoSchema = z.object({
  id: z.string().nullable().optional(),
  title: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
  value1: z.number().optional(), // Assuming float is handled as a number in JS
  value2: z.number().optional(), // Same assumption as above
  date: z.string().optional(), // For date-time, further validation can be added if needed
  status: z.string().nullable().optional(),
  statusId: z.string().nullable().optional(),
  value1Unit: z.string().nullable().optional(),
  value2Unit: z.string().nullable().optional(),
  facility: z.string().nullable().optional(),
  organisation: z.string().nullable().optional(),
  affectedParty: z.string().nullable().optional(),
  dateOccurred: z.string().optional(), // Also a date-time string
});
