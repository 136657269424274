// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import ClientComboBox from '../../combobox/client-combo-box';
import api from '../../../services/ApiInstance';
import ApiRoutes from '../../../services/ApiRoutes';
import LocalComboBox from '../../combobox/local-combo-box';
import { widgetPropTypes } from '../widgets-management-constants';
import { submitWidgetsConfiguration } from '../submit-data';
import { widgetManagementStyles } from '../widgets-management-styles';
import UserPreferenceService from '../../../services/UserPreferenceService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Spacer from '../../common/Spacer';

const userPreferenceService = new UserPreferenceService();
const useStyles = makeStyles(widgetManagementStyles);

// Probably each numberic prop should has its own min value
const NUMBERIC_PROP_MIN_VALUE = 1;

const RowTextItem = ({ name, label, value, onChange, disabled }) => {
  return (
    <TextField
      variant="outlined"
      fullWidth
      id={`rti-${name}`}
      label={label}
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

export default function WidgetsConfigurator() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [client, setClient] = useState(null);
  const [defaultClient, setDefaultClient] = useState(null);
  const [modified, setModified] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const [currentWidget, setCurrentWidget] = useState(null);

  useEffect(() => {
    const savedClient = userPreferenceService.get('WidgetConfigClient');
    if (savedClient !== null) {
      setDefaultClient(savedClient);
    }
  }, []);

  useEffect(() => {
    setModified(false);
    loadWidgetsConfiguration(client);
  }, [client]);

  const onClientChange = (event, value) => {
    setClient(value);
    userPreferenceService.save('WidgetConfigClient', value);
  };

  const loadWidgetsConfiguration = (client) => {
    if (!client) {
      resetFields();
      return;
    }
    setModified(false);
    api
      .get(ApiRoutes.getWidgetsConfiguration, {
        params: { clientId: client.id },
      })
      .then(
        (response) => {
          if (response) {
            const { widgetSettings } = response;
            setWidgets(widgetSettings);
            setCurrentWidget(widgetSettings[0]);
          }
        },
        (err) => {
          console.log('error encountered: ' + err);
        }
      );
  };

  const getMergedState = () => {
    return Object.assign(
      {},
      { widgetSettings: widgets },
      { clientId: client.id }
    );
  };

  const resetFields = () => {
    setWidgets([]);
    setCurrentWidget(null);
  };

  const widgetPropertyChange = (
    code,
    value,
    callback = (property, value) => (property.value = value)
  ) => {
    if (!code) {
      return;
    }

    let currentUsedProps = [...currentWidget.usedProps];
    const propToChange = currentUsedProps.find((p) => p.code === code);

    if (!propToChange) {
      return;
    }

    callback(propToChange, value);
    setCurrentWidget((prevState) => ({
      ...prevState,
      usedProps: currentUsedProps,
    }));

    widgets.find((w) => w.code === currentWidget.code).isModified = true;

    setModified(true);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    if (!value) {
      return;
    }
    widgetPropertyChange(name, value);
  };

  const handleNumbericPropChange = (e, min) => {
    const { name, value } = e.target;
    if (!value) {
      return;
    }
    widgetPropertyChange(
      name,
      value,
      (prop, val) => (prop.value = val > min ? val : min)
    );
  };

  const toggleEnable = (e) => {
    const { name, checked } = e.target;
    widgetPropertyChange(
      name,
      checked,
      (property, value) => (property.isEnabled = value)
    );
  };

  const dataSourceChange = (code, value) => {
    if (!value) {
      return;
    }
    widgetPropertyChange(code, value);
  };

  const renderGeneralAndLabelsProperties = (usedProps) => {
    return (
      <>
        {renderTextWidgetProperties(
          usedProps.filter((p) => p.typeName === widgetPropTypes.general)
        )}
        {renderTextWidgetProperties(
          usedProps.filter((p) => p.typeName === widgetPropTypes.label)
        )}
        {renderTextWidgetProperties(
          usedProps.filter((p) => p.typeName === widgetPropTypes.tooltip)
        )}
      </>
    );
  };

  const renderTextWidgetProperties = (usedProps, allowChangeEnable = false) => {
    const { code } = currentWidget;
    return (
      <>
        {usedProps &&
          usedProps.map((item, index) => {
            const key = `${code}-${item.code}-${index}`;
            return (
              <div key={key} className={classes.tableColumnPropertyRow}>
                <RowTextItem
                  label={item.name}
                  value={item.value}
                  name={item.code}
                  onChange={handleFieldChange}
                  disabled={!item.isEnabled}
                />
                {allowChangeEnable && (
                  <Switch
                    checked={item.isEnabled}
                    onChange={toggleEnable}
                    color="primary"
                    name={item.code}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                )}
              </div>
            );
          })}
      </>
    );
  };

  const DataSourceWidgetProperties = ({ currentWidget }) => {
    const [code] = useState(currentWidget?.code ? currentWidget.code : '');
    const [dataSourceProperties, setDataSourceProperties] = useState(
      currentWidget?.usedProps &&
        currentWidget.usedProps.filter(
          (p) => p.typeName === widgetPropTypes.dataSource
        )
    );

    useEffect(() => {
      if (!currentWidget) return;

      let defaultTopicsSource = Array.from(dataSourceProperties).find(
        (x) => x.code === 'defaultTopicsSource'
      );

      if (!defaultTopicsSource) return;

      let additionalTopics = client.additionalTopics;

      //boilerplater code -> renames 'customLabel' -> 'name' to abide by general code in LocalComboBox options prop
      additionalTopics = additionalTopics.map(({ customLabel, ...rest }) => ({
        name: customLabel,
        ...rest,
      }));
      additionalTopics.unshift({
        additionalTopicName: '',
        id: '00000000-0000-0000-0000-000000000000',
        name: t(
          'widgets_manager_page.widgets_configurator.widgets-configurator.Sector Topics'
        ),
      });

      defaultTopicsSource.options = additionalTopics || [];
      setDataSourceProperties(
        dataSourceProperties.map((x) =>
          x.code === 'defaultTopicsSource' ? defaultTopicsSource : x
        )
      );
    }, []);

    return (
      <>
        {code &&
          dataSourceProperties &&
          dataSourceProperties.map((prop, index) => {
            const key = `${code}-${prop.code}-${index}`;
            return (
              <div className={classes.dataSourceLabel} key={key}>
                <Typography variant="subtitle2" mt={1}>
                  {t(
                    'widgets_manager_page.widgets_configurator.widgets_configurator.Select'
                  )}
                  {` ${prop.name}`}
                </Typography>
                <br />
                <LocalComboBox
                  disabled={!prop.isEnabled}
                  options={prop.options.map((o) => o.name)}
                  value={prop.value}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => {
                    dataSourceChange(prop.code, value);
                  }}
                ></LocalComboBox>
              </div>
            );
          })}
      </>
    );
  };

  const renderNumbericWidgetProperties = () => {
    if (!currentWidget) {
      return null;
    }
    const { code, usedProps } = currentWidget;
    const numbericProperties =
      usedProps &&
      usedProps.filter((p) => p.typeName === widgetPropTypes.numberic);
    return (
      <>
        {code &&
          numbericProperties &&
          numbericProperties.map((prop, index) => {
            const key = `${code}-${prop.code}-${index}`;
            return (
              <div key={key} className={classes.row}>
                <TextField
                  type="number"
                  variant="outlined"
                  fullWidth
                  id={`rti-${prop.code}`}
                  label={prop.name}
                  name={prop.code}
                  value={prop.value}
                  disabled={!prop.isEnabled}
                  onChange={(e) =>
                    handleNumbericPropChange(e, NUMBERIC_PROP_MIN_VALUE)
                  }
                />
                <Switch
                  checked={prop.isEnabled}
                  onChange={toggleEnable}
                  color="primary"
                  name={prop.code}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            );
          })}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.combo}>
        <ClientComboBox
          name="client"
          onChange={onClientChange}
          value={client}
          defaultValue={defaultClient}
          style={{ maxWidth: 300 }}
        />
      </div>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item container xs={4} direction="column">
            <div className={classes.gridColumn}>
              <Typography className={classes.sectionHeader} variant="subtitle2">
                {t(
                  'widgets_manager_page.widgets_configurator.widgets_configurator.Select Widget'
                )}
              </Typography>
              <LocalComboBox
                disabled={!widgets || widgets.length === 0}
                options={widgets}
                value={currentWidget}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.code === value.code
                }
                customLabel={t(
                  'widgets_manager_page.widgets_configurator.widgets_configurator.Select Widget'
                )}
                onChange={(event, value) => setCurrentWidget(value)}
              ></LocalComboBox>
            </div>
          </Grid>
          <Grid item container xs={4} direction="column">
            <div className={classes.gridColumn}>
              <Typography className={classes.sectionHeader} variant="subtitle2">
                {(currentWidget && currentWidget.name) ||
                  t(
                    'widgets_manager_page.widgets_configurator.widgets_configurator.Select Widget'
                  )}{' '}
                {t(
                  'widgets_manager_page.widgets_configurator.widgets_configurator.properties'
                )}
              </Typography>
              {currentWidget &&
                currentWidget.usedProps &&
                currentWidget.usedProps.length > 0 &&
                renderGeneralAndLabelsProperties(currentWidget.usedProps)}
              <DataSourceWidgetProperties currentWidget={currentWidget} />
              {renderNumbericWidgetProperties()}
            </div>
          </Grid>
          <Grid item container xs={4} direction="column">
            <div className={classes.gridColumn}>
              <Typography className={classes.sectionHeader} variant="subtitle2">
                {t(
                  'widgets_manager_page.widgets_configurator.widgets_configurator.Data manager'
                )}
              </Typography>
              {currentWidget &&
                currentWidget.usedProps &&
                renderTextWidgetProperties(
                  currentWidget.usedProps.filter(
                    (p) => p.typeName === widgetPropTypes.tableColumn
                  ),
                  true
                )}
              {currentWidget &&
                currentWidget.usedProps &&
                renderTextWidgetProperties(
                  currentWidget.usedProps.filter(
                    (p) => p.typeName === widgetPropTypes.chart
                  )
                )}
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          submitWidgetsConfiguration(getMergedState(), () =>
            toast.success(
              t(
                'widgets_manager_page.widgets_configurator.widgets_configurator.Saved successfully'
              )
            )
          )
        }
        disabled={!client || !modified}
      >
        {t(
          'widgets_manager_page.widgets_configurator.widgets_configurator.Save configuration'
        )}
      </Button>
    </div>
  );
}
