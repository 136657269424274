import React, { useState } from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';

function CheckBoxGroup({
  value,
  onChange,
  options,
  nameSelector,
  valueSelector,
  ...rest
}) {
  const [isAllChecked, setIsAllChecked] = useState(false);

  const handleChange = (optionValue) => {
    const updatedValue = value.includes(optionValue)
      ? value.filter((x) => x !== optionValue)
      : value.concat([optionValue]);
    onChange(updatedValue);
    setIsAllChecked(updatedValue.length === options.length);
  };

  const onClickAll = () => {
    setIsAllChecked((prevState) => !prevState);
    if (value.length === options.length) {
      onChange([]);
    } else {
      onChange(options.map((option) => valueSelector(option)));
    }
  };

  return (
    <FormGroup row {...rest}>
      {options.length > 0 && (
        <FormControlLabel
          control={
            <Checkbox
              checked={isAllChecked}
              onChange={onClickAll}
              name="All"
              color="primary"
            />
          }
          label="All"
        />
      )}
      {options.map((option, index) => {
        const optionValue = valueSelector(option);
        const optionName = nameSelector(option);
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={value.includes(optionValue)}
                onChange={() => handleChange(optionValue)}
                name={optionName}
                color="primary"
              />
            }
            label={optionName}
          />
        );
      })}
    </FormGroup>
  );
}

CheckBoxGroup.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  nameSelector: PropTypes.func,
  valueSelector: PropTypes.func,
};

CheckBoxGroup.defaultProps = {
  nameSelector: (option) => option,
  valueSelector: (option) => option,
};

export default CheckBoxGroup;
