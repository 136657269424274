import React, { useState } from 'react';
import {
  withStyles,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Tooltip,
  IconButton,
  WithStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ApiRoutes from '../../services/ApiRoutes';
import API from '../../services/ApiInstance';
import InfoDialog from '../dialog/InfoDialog';
import ValidationService from '../../services/authentication/ValidationService';
import { AdditionalTopicDto } from '../../api/apiDtos';
import { useTranslation } from 'react-i18next';

const styles = {
  control: {
    marginBottom: '1em',
  },
};

interface EditTopicDialogProps extends WithStyles<typeof styles> {
  topic: any;
  onSuccess: () => void;
}

const EditTopicDialog: React.FC<EditTopicDialogProps> = ({
  topic,
  onSuccess,
  classes,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(topic.name);

  const [validationErrorText, setValidationErrorText] = useState('');
  const [openValidationError, setOpenValidationError] = useState(false);
  const [loading, setLoading] = useState(false);
  const validator = new ValidationService();

  const FORM_ERROR_TEXT = t('configure-topics.EditTopic.FORM_ERROR_TEXT');

  const handleClickOpen = () => {
    setName(topic.name);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submit = () => {
    const requirements = [ValidationService.validators.required(name)];

    const isValid = validator.validate(...requirements);

    if (!isValid) {
      setValidationErrorText(FORM_ERROR_TEXT);
      setOpenValidationError(true);
    } else {
      setLoading(true);
      const updatedTopic = { id: topic.id, name } as AdditionalTopicDto;
      API.put(ApiRoutes.updateTopic, updatedTopic)
        .then(() => {
          setLoading(false);
          handleClose();
          onSuccess();
        })
        .catch(() => {
          setLoading(false);
          handleClose();
        });
    }
  };

  return (
    <>
      <Tooltip title={t('configure-topics.EditTopic.Edit topic')}>
        <IconButton
          aria-label="EditTopic"
          color="primary"
          onClick={handleClickOpen}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>{t('configure-topics.EditTopic.Edit topic')}</DialogTitle>
        <DialogContent>
          <TextField
            label={t('configure-topics.EditTopic.Name')}
            value={name}
            onChange={(event) => setName(event.target.value)}
            required
            autoFocus
            fullWidth
            variant="outlined"
            className={classes.control}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={submit}
            disabled={loading}
            variant="contained"
          >
            {t('configure-topics.EditTopic.Update')}
          </Button>
          <Button onClick={handleClose} color="primary" variant="outlined">
            {t('Global.Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      {openValidationError && (
        <InfoDialog
          open={openValidationError}
          onClose={() => setOpenValidationError(false)}
          text={validationErrorText}
        />
      )}
    </>
  );
};

export default withStyles(styles)(EditTopicDialog);
