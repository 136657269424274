import axios, { AxiosError, AxiosInstance} from 'axios';
import { toast } from 'react-toastify';


const DEV_API_PORT = 50449;
const PROD_API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? `https://localhost:${DEV_API_PORT}/`
      : PROD_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});


api.interceptors.request.use(async (config) => {
  const token = await localStorage.getItem('accessToken');
  const userEmail = await localStorage.getItem('currentUserEmail')
  if (token && userEmail) {
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['UserEmail'] = `${userEmail}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

enum ErrorType {
  AxiosError,
  GenericError
}

const SERVER_MESSAGE_STATUS = 418;

const showErrorToast = (text: string) => toast.error(text, { autoClose: false });

const handleError = (errorType: ErrorType, error: AxiosError | Error) => {
  switch (errorType) {
    case ErrorType.AxiosError:
      const axiosError = error as AxiosError
      if (axiosError.response?.status === SERVER_MESSAGE_STATUS)
      {
        console.error("API error: " + axiosError.response.data);
      toast.error(axiosError.response.data);
      }
      else {
      console.error("HTTP error: " + axiosError.response?.data);
      toast.error(axiosError.response.data)
      }
      break;
    case ErrorType.GenericError:
      const genericError = error as Error
      console.error("Bad request: " + genericError.message);
      toast.error(axiosError.response.data)
      break;
  }
};

namespace API {

  export function getInstance(): AxiosInstance {
    return api
  }

  export async function post<Req, Res>(uri: string, parameter?: Req | null): Promise<Res> {
    if (parameter === null) {
      const error = new Error("Request parameter for POST method cannot be null or undefined");
      handleError(ErrorType.GenericError, error);
      throw error;
    }

    const result = parameter === undefined ? api.post(uri) : api.post(uri, parameter);

    try {
      const response = await result;
      return response.data as Res;
    } catch (error) {
      handleError(ErrorType.AxiosError, error);
      throw error;
    }
  };

  export async function put<Req, Res>(uri: string, parameter?: Req | null): Promise<Res> {
    if (parameter === null) {
      const error = new Error("Request parameter for PUT method cannot be null or undefined");
      handleError(ErrorType.GenericError, error);
      throw error;
    }

    try {
      const result = parameter === undefined ? api.get(uri) : api.put(uri, parameter);
      return (await result).data as Res;
    } catch (error) {
      handleError(ErrorType.AxiosError, error as AxiosError);
      throw error
    }
  };

  export async function deleteReport<Res>(uri: string): Promise<Res> {
    try {
      const result = api.put(uri);
      return (await result).data as Res;
    } catch (error) {
      handleError(ErrorType.AxiosError, error as AxiosError);
      throw error
    }
  };

  export async function get<Req, Res>(uri: string, parameter?: Req | null): Promise<Res> {
    if (parameter === null) {
      const error = new Error("Request parameter for POST method cannot be null or undefined");
      handleError(ErrorType.GenericError, error);
      throw error;
    }

    try {
      const result = parameter === undefined ? api.get(uri) : api.get(uri, parameter);
      return (await result).data as Res;
    } catch (error) {
      handleError(ErrorType.AxiosError, error as AxiosError);
      throw error
    }
  };

  export async function del<Req, Res>(uri: string, parameter?: Req): Promise<Res> {
    try {
      const result = api.delete(uri, { data: parameter });
      return (await result).data as Res;
    } catch (error) {
      handleError(ErrorType.AxiosError, error as AxiosError);
      throw error
    }
  };

  export async function downloadFile(url: string, saveAs: string) {
    let objectUrl, anchor;
    try {
      const response = await api({
        url,
        method: 'GET',
        responseType: 'blob'
      });
      objectUrl = window.URL.createObjectURL(new Blob([response.data]));
      anchor = document.createElement('a');
      anchor.href = objectUrl;
      anchor.download = saveAs;
      anchor.click();
    } catch (error: unknown) {
      if (!axios.isAxiosError(error)) {
        console.error("Error type is malformed. Is not an Axios Error type");
        return
      }

      if (error.response?.status === SERVER_MESSAGE_STATUS) {
        const text = await error.response.data.text();
        showErrorToast(text);
      } else {
        showErrorToast('error');
      }
    } finally {
      if (objectUrl)
        window.URL.revokeObjectURL(objectUrl);
      if (anchor)
        document.removeChild(anchor);
    }
  };
}

export default API;
