import React, { FC, useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import IDService from '../services/IdService';
import { generateOnePagePdf } from '../helpers/pdf-helper';

interface Props {
  fileName: string;
  targetElementId: string;
}

const PdfCreator: FC<Props> = ({ fileName = 'data.pdf', targetElementId }) => {
  const [elementId, setElementId] = useState(targetElementId);
  const [idAttr, setIdAttr] = useState('');

  useEffect(() => {
    if (!elementId) {
      setElementId(IDService.Generate());
      setIdAttr(elementId);
    }
  }, []);

  const handleClick = () => {
    let element = document.getElementById(elementId);
    if (targetElementId == null) {
      element = element!.parentElement;
    }

    generateOnePagePdf(element!, fileName);
  };

  return (
    <IconButton id={idAttr} onClick={handleClick}>
      <ScreenShareIcon />
    </IconButton>
  );
};

export default PdfCreator;
