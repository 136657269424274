import React from 'react';
import { useTranslation } from 'react-i18next';

export const useTranslatedConstants = () => {
  const { t, i18n } = useTranslation();

  const UNMAPPED_ITEMS_POOL = 'Parsed Headers';

  const getNewEntityItems = () => {
    const ENTITY_NAMES = {
      Reports: t('upload.upload_mapper.useTranslatedConstants.Reports'),
      Locations: t('upload.upload_mapper.useTranslatedConstants.Locations'),
      Facilities: t('upload.upload_mapper.useTranslatedConstants.Facilities'),
      AssociatedReports: t(
        'upload.upload_mapper.useTranslatedConstants.Associated reports'
      ),
      Organisations: t(
        'upload.upload_mapper.useTranslatedConstants.Organisations'
      ),
    };

    const ITEMS = [
      {
        id: 1,
        name: t('upload.upload_mapper.useTranslatedConstants.Item1'),
        card: UNMAPPED_ITEMS_POOL,
      },
      {
        id: 2,
        name: t('upload.upload_mapper.useTranslatedConstants.Item2'),
        card: UNMAPPED_ITEMS_POOL,
      },
      {
        id: 3,
        name: t('upload.upload_mapper.useTranslatedConstants.Item3'),
        card: UNMAPPED_ITEMS_POOL,
      },
      {
        id: 4,
        name: t('upload.upload_mapper.useTranslatedConstants.Item4'),
        card: 'Location',
      },
      {
        id: 5,
        name: t('upload.upload_mapper.useTranslatedConstants.Item5'),
        card: UNMAPPED_ITEMS_POOL,
      },
      {
        id: 6,
        name: t('upload.upload_mapper.useTranslatedConstants.Item6'),
        card: UNMAPPED_ITEMS_POOL,
      },
      {
        id: 7,
        name: t('upload.upload_mapper.useTranslatedConstants.Item7'),
        card: UNMAPPED_ITEMS_POOL,
      },
      {
        id: 8,
        name: t('upload.upload_mapper.useTranslatedConstants.Item8'),
        card: UNMAPPED_ITEMS_POOL,
      },
    ];

    const ENTITY_TYPES = {
      Reports: 1,
      Locations: 2,
      Facilities: 4,
      AssociatedReports: 8,
      Organisations: 16,
      ReportType: 32,
    } as const;

    return { ENTITY_NAMES, ITEMS, ENTITY_TYPES };
  };

  return {
    getNewEntityItems,
  };
};

const withTranslatedConstants = (Component) => {
  return (props) => {
    const { getNewEntityItems } = useTranslatedConstants();
    const { ENTITY_NAMES, ENTITY_TYPES, ITEMS } = getNewEntityItems();

    return (
      <Component
        ENTITY_NAMES={ENTITY_NAMES}
        ITEMS={ITEMS}
        ENTITY_TYPES={ENTITY_TYPES}
        {...props}
      />
    );
  };
};

export default withTranslatedConstants;
