export const widgetManagementStyles = {
  root: {
    margin: '1em 1em 1em 1em',
  },
  paper: {
    padding: '1em',
    textAlign: 'center',
    minHeight: '500px',
    height: 'auto',
  },
  sectionHeader: {
    padding: '13px 25px',
    background: '#F1F3F4',
  },
  combo: {
    marginBottom: '1em',
  },
  row: {
    marginTop: '1em',
    display: 'flex',
    alignItems: 'center',
  },
  filterTitle: {
    flexGrow: 1,
  },
  filterToggle: {
    margin: '0 20px',
  },
  label: {
    verticalAlign: 'middle',
    marginRight: '1em',
  },
  text: {
    marginTop: '1em',
  },
  gridColumn: {
    margin: '1em',
  },
  dataSourceLabel: {
    textAlign: 'left',
    marginTop: '1em',
  },
  tableColumnPropertyRow: {
    marginTop: '1em',
    display: 'flex',
    alignItems: 'center',
  },
  filterRow: {
    display: 'flex',
    alignItems: 'center',
  },
  draggableFilter: {
    padding: '.5em',
  },
  filterSection: {
    marginBottom: '1em',
  },
  filterSectionHeader: {
    padding: '1em',
    display: 'flex',
    alignItems: 'center',
  },
  filterSectionContent: {
    padding: '0 1.5em',
  },
  dragIcon: {
    margin: '0 10px',
  },
};
