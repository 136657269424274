import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TopicFilterProvider from './context/TopicFilterContextProvider';
import DateUpdateProvider from './context/DateUpdateContextProvider';
import ClusterSelectionFilterProvider from './context/ClusterSelectionFilterContextProvider';
import ClusterFilterListenerProvider from './context/ClusterFilterListenerContextProvider';
import AlertsConfigProvider from './context/AlertConfigContextProvider';
import { Auth0Provider } from '@auth0/auth0-react';
import './services/i18n';

const baseUrl =
  document.getElementsByTagName('base')[0].getAttribute('href') || '';
const rootElement = document.getElementById('root') as HTMLElement;
const connectionName =
  process.env.NODE_ENV === 'development'
    ? 'comet-signals-dev'
    : process.env.REACT_APP_CONNECTION_NAME;

ReactDOM.render(
  <Auth0Provider
    domain="cometsignals.uk.auth0.com"
    clientId="yzrV66PZwYq4Ldnz8FNRVKxHEBzuG75f"
    authorizationParams={{
      redirect_uri: window.location.origin,
      connection: connectionName,
    }}
  >
    <BrowserRouter basename={baseUrl}>
      <DndProvider backend={HTML5Backend}>
        <AlertsConfigProvider>
          <ClusterFilterListenerProvider>
            <ClusterSelectionFilterProvider>
              <TopicFilterProvider>
                <DateUpdateProvider>
                  <App />
                </DateUpdateProvider>
              </TopicFilterProvider>
            </ClusterSelectionFilterProvider>
          </ClusterFilterListenerProvider>
        </AlertsConfigProvider>
      </DndProvider>
    </BrowserRouter>
  </Auth0Provider>,
  rootElement
);
