// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import api from '../../services/ApiInstance';
import SinglePointMap from '../map/SinglePointMap';

const styles = {
  row: {
    borderBottom: '1px solid #CCC',
    marginBottom: 10,
  },
  title: {
    color: '#444',
    fontWeight: 500,
  },
};

const useStyles = makeStyles(styles);

function DataTableDetails(props) {
  const classes = useStyles();
  const { record, columns, url } = props;
  const { id, location } = record;
  const [extras, setExtras] = useState([]);

  const DataRow = (props) => {
    const { title, value } = props;
    return (
      <Grid container item xs={12} className={classes.row}>
        <Grid item xs={2} className={classes.title}>
          {title}
        </Grid>
        <Grid item xs={10}>
          {formatValue(title, value)}
        </Grid>
      </Grid>
    );
  };

  const formatValue = (column, value) => {
    if (column.toLowerCase().includes('date')) {
      return moment(value).format('D/MMM/YY');
    }
    return value;
  };

  useEffect(() => {
    if (url && id) {
      loadExtras(url, id);
    }
  }, []);

  const loadExtras = (url, id) => {
    api.get(url, { params: { id: id } }).then(
      (response) => {
        const { data } = response;
        if (data) {
          setExtras(data);
        }
      },
      (err) => {
        console.log('error encountered: ' + err);
      }
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      {location && (
        <Grid item xs={12}>
          <SinglePointMap location={location} />
        </Grid>
      )}
      {columns.map((c, i) => (
        <DataRow key={`row-${i}`} title={c.label} value={record[c.name]} />
      ))}
      {extras &&
        Object.keys(extras).map((key, i) => (
          <DataRow key={`row-extra-${i}`} title={key} value={extras[key]} />
        ))}
    </Grid>
  );
}

DataTableDetails.propTypes = {
  record: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
};

export default DataTableDetails;
