// AdditionalTopicsComboBox.tsx

import React, { useState, useEffect } from 'react';
import { TextField, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CustomLabelComponent } from '../organisation-management/CustomLabelComponent';
import { OrganisationAdditionalTopicsDto, OrganisationDto } from '../../api/apiDtos';

const SELECT_ITEM = 'Select item';

interface AdditionalTopicsComboBoxProps {
  customLabel?: string;
  topicsAndLabels: OrganisationAdditionalTopicsDto[];
  selectedTopics: any[];
  setAdditionalTopics: (topics: any) => void;
  [x: string]: any;
  organisation: OrganisationDto;
}

const AdditionalTopicsComboBox: React.FC<AdditionalTopicsComboBoxProps> = ({
  organisation,
  customLabel,
  topicsAndLabels,
  selectedTopics,
  setAdditionalTopics,
  options,
  ...childProps
}) => {
  const [_topicsAndLabels, _setTopicsAndLabels] = useState<OrganisationAdditionalTopicsDto[]>(
    topicsAndLabels ? topicsAndLabels : []
  );
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    if (selectedTopics) {
      setSelectedTags(selectedTopics);
    }
  }, []);

  useEffect(() => {
    //get diff between selectedTags and _topicsAndLabels
    const diff = selectedTags.filter((tag) => !_topicsAndLabels.some((item) => item.additionalTopicName === tag));

    if(diff?.length){
      const newTopicsAndLabels = diff.map((tag) => ({
        customLabel: '',
        additionalTopicName: tag,
        organisationId: organisation?.id ?? '',
        id: '00000000-0000-0000-0000-000000000000',
        additionalTopic: undefined,
      }));
      _setTopicsAndLabels([..._topicsAndLabels, ...newTopicsAndLabels]);
    }
  }, [selectedTags]);

  useEffect(() => {
    if (setAdditionalTopics) setAdditionalTopics(_topicsAndLabels);
  }, [_topicsAndLabels]);

  const handleDelete = (option: string) => {
    const newSelectedTags = selectedTags.filter((tag) => tag !== option);
    setSelectedTags(newSelectedTags);
    const updatedTopicsAndLabels = _topicsAndLabels.filter((x: any) => x.additionalTopicName !== option);
    _setTopicsAndLabels(updatedTopicsAndLabels);
  };

  return (
    <Autocomplete
      options={options}
      {...childProps}
      value={selectedTags}
      onChange={(_, newValue) => setSelectedTags(newValue)}
      renderTags={(value, _) =>
        value.map((option, index) => (
          <Chip
            key={option}
            label={
              <CustomLabelComponent
                option={option}
                organisation={organisation}
                setTopicsAndLabels={_setTopicsAndLabels}
                topicsAndLabels={_topicsAndLabels}
              />
            }
            onDelete={() => handleDelete(option)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={customLabel || SELECT_ITEM}
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
};

export default AdditionalTopicsComboBox;
