import React, { useState, useEffect, FC } from 'react';
import {
  Chip,
  createStyles,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import MUIDatatable from 'mui-datatables';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import Spacer from '../common/Spacer';
import { ENTITY_TYPES } from '../upload/upload-mapper/constants';
import AddAliasDialog from './AddAliasDialog';
import DeleteAliasDialog from './DeleteAliasDialog';
import EditAliasDialog from './EditAliasDialog';
import { PERMISSIONS } from '../../constants';
import { AliasMapDto } from '../../api/apiDtos';
import useAuthorization from '../../hooks/useAuthorisationManager';
import { useTranslation } from 'react-i18next';

const styles = createStyles({
  root: {
    padding: 16,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0',
  },
  chip: {
    marginRight: 2,
  },
});

interface Props extends WithStyles<typeof styles> {}

const AliasManagement: FC<Props> = ({ classes }) => {
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();
  const [reportTypeAliases, setReportTypeAliases] = useState<AliasMapDto[]>([]);
  const [reportTypeNames, setReportTypeNames] = useState<string[]>([]);

  const loadReportTypeNames = async () => {
    const url = `${ApiRoutes.getNamesForAlias}?entityType=${ENTITY_TYPES.ReportType}`;
    const response = await api.get<any, string[]>(url);
    setReportTypeNames(response);
  };

  const loadReportTypeAliases = async () => {
    const url = `${ApiRoutes.getAliases}?entityType=${ENTITY_TYPES.ReportType}`;
    const response = await api.get<any, AliasMapDto[]>(url);
    setReportTypeAliases(response);
  };

  useEffect(() => {
    loadReportTypeAliases();
    loadReportTypeNames();
  }, []);

  const canAdd = hasPermission(PERMISSIONS.AliasMap.CreateUpdate);
  const canEdit = hasPermission(PERMISSIONS.AliasMap.CreateUpdate);
  const canDelete = hasPermission(PERMISSIONS.AliasMap.Delete);

  const columns = [
    { label: t('Alias.AliasManagement.Name'), name: 'name' },
    {
      label: t('Alias.AliasManagement.Aliases'),
      name: 'aliases',
      options: {
        customBodyRenderLite: (dataIndex: number) => {
          const aliasMap = reportTypeAliases[dataIndex];
          return (
            aliasMap && (
              <>
                {aliasMap.aliases?.map((alias) => (
                  <Chip
                    key={alias}
                    label={alias}
                    color="primary"
                    size="small"
                    className={classes.chip}
                  />
                ))}
              </>
            )
          );
        },
        sort: false,
      },
    },
    {
      label: '',
      name: '',
      options: {
        customBodyRenderLite: (dataIndex: number) => {
          const aliasMap = reportTypeAliases[dataIndex];
          return (
            aliasMap && (
              <>
                {canEdit && (
                  <EditAliasDialog
                    aliasMap={aliasMap}
                    onEdit={loadReportTypeAliases}
                  />
                )}
                {canDelete && (
                  <DeleteAliasDialog
                    aliasMap={aliasMap}
                    onDelete={loadReportTypeAliases}
                  />
                )}
              </>
            )
          );
        },
        sort: false,
      },
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5">
          {t('Alias.AliasManagement.Report Type Aliases')}
        </Typography>
        <Spacer />
        {canAdd && (
          <AddAliasDialog
            entityType={ENTITY_TYPES.ReportType}
            names={reportTypeNames}
            onAdd={loadReportTypeAliases}
          />
        )}
      </div>
      <MUIDatatable
        data={reportTypeAliases}
        columns={columns}
        options={{
          selectableRows: 'none',
          print: false,
          search: false,
          filter: false,
          download: false,
          viewColumns: false,
        }}
        title={''}
      />
    </div>
  );
};

export default withStyles(styles)(AliasManagement);
