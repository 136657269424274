import React, { createContext, useState } from 'react';

export const ClusterSelectionFilterContext = createContext();

export const ClusterSelectionFilterProvider = ({ children }) => {
  const [clusterSelectionFilter, setClusterSelectionFilter] = useState([]);

  return (
    <ClusterSelectionFilterContext.Provider
      value={[clusterSelectionFilter, setClusterSelectionFilter]}
    >
      {children}
    </ClusterSelectionFilterContext.Provider>
  );
};
export default ClusterSelectionFilterProvider;
