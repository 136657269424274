import React, { useState, useEffect, useContext } from 'react';
import { Chip, Button, makeStyles } from '@material-ui/core';
import {
  GetActiveFilters,
  RemoveFilter,
  FormatFilter,
  filters,
  Filter,
} from '../../signals/filterService';

const useStyles = makeStyles((_) => ({
  chip: {
    margin: '0 6px 6px 0',
    maxWidth: '100%',
  },
  chipLabel: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  smallChip: {
    height: 'unset',
    minHeight: 24,
    padding: '3px 0',
  },
  base: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingTop: '1em',
  },
}));

export default function FilterChips({ maxItems }) {
  const [activeFilters, setActiveFilters] = useState<Array<Filter>>([]);
  const classes = useStyles();
  maxItems = maxItems || 3;

  useEffect(
    function setActiveFiltersWhenFiltersChange() {
      setActiveFilters(GetActiveFilters());
    },
    [filters.value]
  );

  var handleDelete = (filter: Filter) => (event: any) => {
    RemoveFilter(filter);
  };

  return activeFilters.length > 0 ? (
    <div className={classes.base}>
      {activeFilters.slice(0, maxItems).map((filter, idx) => (
        <Chip
          key={`${filter.name}-${idx}`}
          label={FormatFilter(filter)}
          color="primary"
          size="small"
          onDelete={handleDelete(filter)}
          classes={{
            root: classes.chip,
            label: classes.chipLabel,
            sizeSmall: classes.smallChip,
          }}
        />
      ))}
      {activeFilters.length > maxItems ? (
        <Button color="primary" size="small">
          + {activeFilters.length - maxItems}
        </Button>
      ) : null}
    </div>
  ) : null;
}
