// @ts-nocheck
import React, { useState, useEffect, useRef, useContext } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Map from 'ol/Map';
import View from 'ol/View';
import * as proj from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { defaults as defaultControls } from 'ol/control';
import Overlay from 'ol/Overlay';
import IDService from '../../services/IdService';
import MapClickedPopup from './MapClickedPopup';
import { createTileLayer } from './map-utils';
import Cluster from 'ol/source/Cluster';
import { Style, Circle as CircleStyle, Fill, Stroke } from 'ol/style';
import { Text } from 'ol/style';
import { ClusterSelectionFilterContext } from '../../context/ClusterSelectionFilterContextProvider';
import MapClusterSelectionPopUp from '../map/MapClusterSelectionPopUp';

const styles = {
  root: {
    flexGrow: 1,
    flexShrink: 0,
    minHeight: 400,
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
  },
  olPopup: {
    padding: '0.5em',
    cursor: 'pointer',
    color: '#fff',
    background: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    borderRadius: 4,
  },
  mapAndPanelContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  sizeOfSidePanel: {
    width: '32%',
    height: '80%',
    position: 'fixed',
    right: 10,
    bottom: 10,
    padding: '10px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    background:
      'linear-gradient(to bottom, rgba(245, 245, 245, 0.9), rgba(220, 220, 220, 0.99))',
  },
  mapContainer: {
    width: '100%',
  },
};

const MapWidgetView = React.forwardRef(
  ({ classes, points, clearHistory, filterByCluster }, ref) => {
    const [clusterSelectionFilter, setClusterSelectionFilter] = useContext(
      ClusterSelectionFilterContext
    );
    const recentlySelectedPoint = useRef(null);
    const [clickedPointIds, setClickedPointIds] = useState(new Set());
    const [clickedFeatures, setClickedFeatures] = useState([]);
    const [globalReportIds, setGlobalReportIds] = useState([]);
    const [globalFeatures, setGlobalFeatures] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [dataForMapPopUp, setDataforMapPopUp] = useState(null);
    const popUpIdReference = useRef(IDService.Generate());
    const mapContainerRef = useRef(null);
    const recentlySelectedPointId = useRef(null);
    const popUpRef = useRef(null);
    const mapRef = useRef(null);
    const clusterOverLayerRef = useRef(null);
    const clusterLayerRef = useRef(null);
    const filterByClusterRef = useRef(null);
    const globalIdsRef = useRef(null);
    const recentlySelectedGlobalFeatures = useRef(null);

    useEffect(() => {
      const coordinates = points.map((p) => p.location);
      const center = proj.fromLonLat(findCenterPoint(coordinates));
      popUpRef.current = new Overlay({
        element: document.getElementById(popUpIdReference),
        autoPan: false,
        offset: [0, 10],
      });

      mapRef.current = new Map({
        layers: [createTileLayer()],
        overlays: [popUpRef.current],
        target: mapContainerRef.current,
        view: new View({
          center: center,
          zoom: 3.5,
          minZoom: 3,
          maxZoom: 30,
        }),
        controls: defaultControls(),
      });
      const handleMapClick = (e) => {
        const features = [];
        mapRef.current.forEachFeatureAtPixel(e.pixel, (feature) => {
          features.push(feature);
        });
        displayReport(features);
      };
      mapRef.current.on('singleclick', handleMapClick);
      drawCluster();
      return () => {
        mapRef.current.un('singleclick', handleMapClick);
      };
    }, []);

    function findCenterPoint(points) {
      if (points.length === 0) {
        return [0, 0];
      }
      let longitude = 0;
      let latitude = 0;
      points.forEach((p) => {
        longitude += p[0];
        latitude += p[1];
      });
      const center = [longitude / points.length, latitude / points.length];
      return center;
    }

    function drawCluster() {
      const clusterSource = new Cluster({
        source: new VectorSource({
          features: [],
          wrapX: false,
          noWrap: true,
        }),
        distance: 30,
      });
      points.forEach((point) => {
        point.id = point.id || IDService.Generate();
        const coordinate = point.location;
        const geometry = new Point(proj.fromLonLat(coordinate));
        const feature = new Feature({ geometry });
        feature.setId(point.firstReport.id);
        clusterSource.getSource().addFeature(feature);
      });

      const clusterLayer = new VectorLayer({
        source: clusterSource,
        style: clusterStyling,
      });

      const clusterOverLayer = new VectorLayer({
        source: clusterSource,
        style: clusterStyling,
      });

      clusterLayerRef.current = clusterLayer;
      clusterOverLayerRef.current = clusterOverLayer;

      mapRef.current.addLayer(clusterOverLayerRef.current);
      if (clearHistory === true || clusterLayerRef.current != null) {
        mapRef.current.removeLayer(clusterLayerRef.current);
      }
      mapRef.current.addLayer(clusterLayerRef.current);
      mapRef.current.removeLayer(clusterOverLayerRef.current);
    }

    function clusterStyling(feature) {
      let color;
      const features = feature.get('features');
      const size = feature.get('features').length;
      let isClicked;
      if (filterByCluster === true) {
        let allFeaturesMatch = true;
        let someFeaturesMatch = false;
        for (const clusterFeature of features) {
          const isFeatureInGlobal = globalFeatures.some((e) =>
            e.some((i) => i.id_ === clusterFeature.id_)
          );
          if (isFeatureInGlobal) {
            someFeaturesMatch = true;
          } else {
            allFeaturesMatch = false;
          }
          if (someFeaturesMatch && !allFeaturesMatch) {
            break;
          }
        }
        if (allFeaturesMatch) {
          color = 'rgba(41, 182, 246, 1)'; // blue
        } else if (someFeaturesMatch) {
          color = 'rgba(0, 128, 0, 1)'; // green
        } else {
          color = 'rgba(255, 235, 59, 1)'; // yellow
        }
      } else if (filterByCluster === false) {
        isClicked = features.some((feature) =>
          clickedFeatures.some((e) => e[0].id_ === feature.id_)
        );
        if (isClicked && size > 1) {
          color = 'rgba(92,211,253,1)';
        } else if (size === 1 && isClicked) {
          color = 'rgba(140, 16, 118, 1)';
        } else if (size > 20) {
          color = 'rgba(255, 51, 51, 1)';
        } else if (size > 15) {
          color = 'rgba(255, 153, 51, 1)';
        } else {
          color = 'rgba(51, 204, 51, 1)';
        }
      }
      let textStyle = null;
      if (size > 1) {
        textStyle = new Text({
          text: size.toString(),
          fill: new Fill({ color: '#000' }),
          font: '16px, Arial, sans-serif',
          stroke: new Stroke({ color: '#000000', width: 1 }),
        });
      }
      const MAX_RADIUS = 20;
      const radius = size === 1 ? 8 : Math.min(12 + size, MAX_RADIUS);
      return new Style({
        image: new CircleStyle({
          radius: radius,
          fill: new Fill({ color: color }),
          stroke: new Stroke({ color: '#000000', width: 2 }),
        }),
        text: textStyle,
      });
    }

    function displayReport(features) {
      handleClose();
      const selectedFeatures = features[0].get('features');
      if (filterByClusterRef.current === true) {
        if (selectedFeatures && selectedFeatures.map) {
          const pointsToSet = selectedFeatures.map((p) => p.id_);
          globalIdsRef.current = pointsToSet;
          recentlySelectedGlobalFeatures.current = selectedFeatures;
          setClicked(true);
        }
      } else if (filterByClusterRef.current === false) {
        if (selectedFeatures && selectedFeatures.length > 200) {
          let clusterCenter = [0, 0];
          selectedFeatures.forEach((feature) => {
            const coords = feature.getGeometry().getCoordinates();
            clusterCenter[0] += coords[0];
            clusterCenter[1] += coords[1];
          });
          clusterCenter = [
            clusterCenter[0] / selectedFeatures.length,
            clusterCenter[1] / selectedFeatures.length,
          ];
          const view = mapRef.current.getView();
          const currentZoom = view.getZoom();
          const newZoom = currentZoom + 3;
          view.animate({
            center: clusterCenter,
            zoom: newZoom,
            duration: 500,
          });
        } else if (selectedFeatures && selectedFeatures.length < 200) {
          let allCoordinates = selectedFeatures.map((feature) =>
            feature.getGeometry().getCoordinates()
          );
          let clusterCenter = [0, 0];
          allCoordinates.forEach((coords) => {
            clusterCenter[0] += coords[0]; // this is basically the sum of all X th coordinates
            clusterCenter[1] += coords[1]; // same but for the y coordinates
          });
          clusterCenter = [
            clusterCenter[0] / allCoordinates.length, // average X coordinates
            clusterCenter[1] / allCoordinates.length, // average Y coordinates
          ];
          let extent = [
            allCoordinates[0][0], // min X value
            allCoordinates[0][1], // min Y val
            allCoordinates[0][0], // max X val
            allCoordinates[0][1], // max Y val
          ];
          // this is so we can calculat the extent in minX, minY, maxX, maxY - which allows us to auto zoom in on multiple points
          allCoordinates.forEach((coords) => {
            extent[0] = Math.min(extent[0], coords[0]);
            extent[1] = Math.min(extent[1], coords[1]);
            extent[2] = Math.max(extent[2], coords[0]);
            extent[3] = Math.max(extent[3], coords[1]);
          });

          if (selectedFeatures.length === 1) {
            const selectedPoint = points.find(
              (p) => p.firstReport.id === selectedFeatures[0].id_
            );
            recentlySelectedPointId.current = selectedFeatures.id_;
            recentlySelectedPoint.current = selectedFeatures;

            const views = mapRef.current.getView();
            const currentZooms = views.getZoom();
            let zoom;
            if (currentZooms > 20) {
              zoom = currentZooms;
            } else if (currentZooms < 12) {
              zoom = currentZooms + 4;
            } else {
              zoom = currentZooms;
            }
            views.animate({
              center: clusterCenter,
              zoom: zoom,
              duration: 500,
            });
            setDataforMapPopUp(selectedPoint);
            setShowModal(true);
            return;
          }
          const view = mapRef.current.getView();
          view.fit(extent, {
            center: clusterCenter,
            padding: [150, 150, 150, 150],
            duration: 500,
          });
        }
      }
    }

    useEffect(() => {
      if (filterByCluster == true && clicked === true) {
        const flatGlobalReportIds = globalReportIds.flat();
        const isDeselection = globalIdsRef.current.some((id) =>
          flatGlobalReportIds.includes(id)
        );
        if (isDeselection) {
          const newGlobalReportIds = globalReportIds
            .map((e) => e.filter((id) => !globalIdsRef.current.includes(id)))
            .filter((group) => group.length > 0);

          const newGlobalFeatures = globalFeatures
            .map((e) => e.filter((x) => !globalIdsRef.current.includes(x.id_)))
            .filter((y) => y.length > 0);

          setGlobalReportIds(newGlobalReportIds);
          setGlobalFeatures(newGlobalFeatures);
        } else {
          setGlobalReportIds((prevState) => [
            ...prevState,
            globalIdsRef.current,
          ]);
          setGlobalFeatures([
            ...globalFeatures,
            recentlySelectedGlobalFeatures.current,
          ]);
        }
        setClicked(false);
      }
    }, [globalIdsRef.current, recentlySelectedGlobalFeatures.current, clicked]);

    useEffect(() => {
      if (filterByCluster === false) {
        if (recentlySelectedPoint.current) {
          if (!clickedPointIds.has(recentlySelectedPoint.current)) {
            setClickedPointIds(
              new Set([...clickedPointIds, recentlySelectedPoint.current])
            );
            setClickedFeatures([
              ...clickedFeatures,
              recentlySelectedPoint.current,
            ]);
          }
        }
      }
    }, [recentlySelectedPoint.current, clicked]);

    useEffect(() => {
      drawCluster();
    }, [clickedFeatures, globalFeatures]);

    useEffect(() => {
      setClusterSelectionFilter(globalReportIds);
    }, [globalReportIds]);

    useEffect(() => {
      drawCluster();
      filterByClusterRef.current = filterByCluster;
    }, [filterByCluster]);

    useEffect(() => {
      resetMap();
    }, [clearHistory]);

    useEffect(() => {
      if (filterByCluster === true) {
        setShowModal(false);
      } else {
        setShowModal(true);
      }
    }, [filterByCluster]);

    function handleClose() {
      setShowModal(false);
      setDataforMapPopUp(null);
    }

    function resetMap() {
      if (clearHistory === true) {
        setShowModal(false);
        setDataforMapPopUp(null);
        setClickedPointIds(new Set());
        setClickedFeatures([]);
        recentlySelectedPointId.current = null;
        recentlySelectedPoint.current = null;
        globalIdsRef.current = null;
        recentlySelectedGlobalFeatures.current = null;
        setGlobalReportIds([]);
        setGlobalFeatures([]);
        drawCluster();
      }
    }

    return (
      <div className={classes.root}>
        <div className={classes.mapAndPanelContainer}>
          <div ref={mapContainerRef} className={classes.mapContainer} />
          {showModal && dataForMapPopUp && (
            <div className={classes.sizeOfSidePanel}>
              <MapClickedPopup point={dataForMapPopUp} onClose={handleClose} />
            </div>
          )}
          {filterByCluster && <MapClusterSelectionPopUp />}
        </div>
      </div>
    );
  }
);

MapWidgetView.displayName = 'MapWidgetView';

export default withStyles(styles)(MapWidgetView);
