import apiRoutes from './ApiRoutes';
import API from './ApiInstance';

export const sortByLevel = (a, b) => a.level - b.level;

export const sortByParentLevelOne = (orgs) => {
  const grouped = orgs.reduce((grouped, org) => {
    const group = grouped[org.parentLevelOne];
    if (group) {
      group.push(org);
    } else {
      grouped[org.parentLevelOne] = [org];
    }
    return grouped;
  }, {});
  const result = [];
  Object.keys(grouped).forEach((parentLevelOne) => {
    result.push(...grouped[parentLevelOne].sort(sortByLevel));
  });
  return result;
};

export const fetchAvailableOrganisationsList = () =>
  API.get(apiRoutes.orgs).then((response) => {
    // const orgs = excludeSuperOrganisation ? response.data.filter(o => o.level >= 1) : response.data;
    return sortByParentLevelOne(response);
    // setClients(sortByParentLevelOne(orgs));
  });
