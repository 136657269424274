export const widgetPropTypes = {
  general: 'General',
  label: 'Label',
  tableColumn: 'TableColumn',
  chart: 'Chart',
  dataSource: 'DataSource',
  tooltip: 'Tooltip',
  numberic: 'Numberic',
};

export const widgetPropOptions = {
  reports: 'Reports',
  associatedReports: 'AssociatedReports',
  organisation: 'Organisation',
  facility: 'Facility',
};

export const widgetPropCodes = {
  reportTypeAggregationThreshold: 'reportTypeAggregationThreshold',
  organisationAggregationThreshold: 'organisationAggregationThreshold',
};
