import React, { Component, FC, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import DateFnsUtils from '@date-io/moment';
import { Tab, Tabs, WithStyles, withStyles } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Spacer from '../common/Spacer';
import { DateUpdateContext } from '../../context/DateUpdateContextProvider';
import { KeyboardDatePicker } from '@material-ui/pickers';
import TimeServiceProvider from '../../services/TimeService';
import { useTranslation } from 'react-i18next';

const styles = {
  controlBar: {
    marginLeft: '20px',
  },
  tabRoot: {
    minWidth: 50,
  },
  datePicker: {
    width: 100,
    marginRight: '1.5em',
    fontFamily: 'Roboto',
  },
  nudgeLeft: {
    paddingLeft: '1em',
  },
  dateBoxes: {
    color: '#000000',
    background: '#fff',
    borderRadius: '8px',
    height: '33px',
    width: '231px',
  },
};

interface Props extends WithStyles<typeof styles> {
  domainChangeCallback: Function;
  domain: any[];
  hideStandardRanges: boolean;
  textColor: string;
}

// const detectRangeIndex = (domain): number | boolean => {
//   if (!domain) return false;
//   var difference = moment(domain[1]).diff(moment(domain[0]), 'weeks');

//   // find index for preset range
//   switch (difference) {
//     case 52:
//       return 0;
//     case 13:
//       return 1;
//     case 4:
//       return 2;
//     case 1:
//       return 3;
//     default:
//       // completely unset tabs as it is seems it is custom range
//       return false;
//   }
// };

const DateSelector: FC<Props> = ({
  classes,
  domainChangeCallback,
  domain,
  hideStandardRanges,
}) => {
  const { t } = useTranslation();
  const timeService = TimeServiceProvider();
  const [dateUpdate, setDateUpdate] = useContext(DateUpdateContext);
  const [localDomain, setLocalDomain] = useState(domain);

  const handleSetTimeRangeChange = (event, value) => {
    const ranges = [52, 13, 4, 1];
    const latestDate = moment().subtract(1, 'months').endOf('month');
    const earliestDate = moment()
      .subtract(ranges[value], 'weeks')
      .startOf('month');
    var newDomain = [earliestDate.valueOf(), latestDate.valueOf()];
    domainChangeCallback(newDomain.map((date) => date.valueOf()));
    setLocalDomain(newDomain);
    setDateUpdate(newDomain);
  };

  const DatePickers = () => {
    if (!DateUpdateContext) return null;

    const handleTimeChange = (from, to) => {
      const newDomain = [...localDomain];
      if (from) newDomain[0] = from;
      if (to) newDomain[1] = to;
      setDateUpdate(newDomain);
      timeService.setTime(localDomain);
      setLocalDomain(newDomain);
      domainChangeCallback(newDomain.map((date) => date.valueOf()));
    };

    return (
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div
            style={{ color: '#fff', paddingBottom: '4px', fontSize: '15px' }}
          >
            {t('filters.DateSelector.From')}
          </div>
          <KeyboardDatePicker
            InputProps={{
              className: classes.dateBoxes,
            }}
            value={localDomain[0]}
            format="DD/MM/YYYY"
            onChange={(date) => {
              if (date && moment(date, 'DD/MM/YYYY', true).isValid()) {
                handleTimeChange(date, null);
              }
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div
            style={{
              color: '#fff',
              fontSize: '15px',
              paddingBottom: '4px',
              paddingTop: '8px',
            }}
          >
            {t('filters.DateSelector.To')}
          </div>
          <KeyboardDatePicker
            InputProps={{
              className: classes.dateBoxes,
            }}
            value={localDomain[1]}
            format="DD/MM/YYYY"
            onChange={(date) => {
              if (date && moment(date, 'DD/MM/YYYY', true).isValid()) {
                handleTimeChange(null, date);
              }
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  };

  return (
    <div className={classes.controlBar}>
      <Spacer />
      {!hideStandardRanges && (
        <Tabs
          value={localDomain}
          onChange={handleSetTimeRangeChange}
          indicatorColor="primary"
        >
          <Tab
            label={t('filters.DateSelector.1 year')}
            classes={{ root: classes.tabRoot }}
          />
          <Tab
            label={t('filters.DateSelector.3 months')}
            classes={{ root: classes.tabRoot }}
          />
        </Tabs>
      )}
      {DatePickers()}
    </div>
  );
};

export default withStyles(styles)(DateSelector);
