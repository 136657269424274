import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const styles = {
  root: {
    minWidth: 100,
    '& .MuiSelect-select': {},
  },
};

export const PERIODS = {
  Month: 1,
  Quarter: 2,
  Year: 3,
};

const TrackerPeriodSelector = ({ value, onChange, className, classes }) => {
  const { t } = useTranslation();
  return (
    <FormControl variant="outlined" className={`${classes.root} ${className}`}>
      <InputLabel id="period-selector-label">
        {t('tracker.TrackerPeriodSelector.Period')}
      </InputLabel>
      <Select
        value={value}
        onChange={(event) => onChange(event.target.value)}
        labelId="period-selector-label"
        label="Period"
      >
        <MenuItem value={PERIODS.Month}>
          {t('tracker.TrackerPeriodSelector.Month')}
        </MenuItem>
        <MenuItem value={PERIODS.Quarter}>
          {t('tracker.TrackerPeriodSelector.Quarter')}
        </MenuItem>
        <MenuItem value={PERIODS.Year}>
          {t('tracker.TrackerPeriodSelector.Year')}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

TrackerPeriodSelector.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TrackerPeriodSelector);
