// @ts-nocheck
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Close as CloseIcon } from '@material-ui/icons';

const styles = {
  dialogContent: {
    marginBottom: '1em',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    margin: '3px 5px',
  },
};

const useStyles = makeStyles(styles);

function HelpButton(props) {
  const classes = useStyles();
  const { title, info, className } = props;
  const [isModalOpen, setModal] = useState(false);
  const onClose = () => setModal(false);

  return (
    <div className={className}>
      <IconButton onClick={() => setModal(true)}>
        <InfoOutlined fontSize="small" />
      </IconButton>

      <Dialog open={isModalOpen} onClose={onClose} fullWidth>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>{info}</DialogContent>
      </Dialog>
    </div>
  );
}

HelpButton.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

export default HelpButton;
