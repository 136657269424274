export default class UserPreferenceService {  
  save = (key: string, value: any) => {
    if (value){      
      localStorage.setItem(prependPath(key), JSON.stringify(value));
    }else{
      console.log(key + " not present")
    }
  };

  get = (key: string) => {    
    const userPreferencesCache = localStorage.getItem(prependPath(key));
    if (userPreferencesCache != 'undefined' || userPreferencesCache !== null){
      return JSON.parse(`${userPreferencesCache}`);
    }else{
      return null;
    }
  };
}

// make key more unique by prepending a current path
const prependPath = (key: string) => {
  return `${window.location.pathname}_${key}`;
};
