// @ts-nocheck
import React from 'react';
import { IconButton, Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const styles = {
  ruleContainer: {
    border: '#ccc solid 1px',
    marginTop: 5,
    alignItems: 'center',
    borderRadius: 5,
  },
  ruleColumn: {
    paddingLeft: 14,
    overflowWrap: 'break-word',
  },
};

const ValueMappingRulesItem = ({ source, mappedTo, onRemove, classes }) => {
  return (
    <Grid container className={classes.ruleContainer}>
      <Grid item xs={5} className={classes.ruleColumn}>
        {source}
      </Grid>
      <Grid item xs={5} className={classes.ruleColumn}>
        {mappedTo}
      </Grid>
      <Grid item xs={2} className={classes.ruleColumn}>
        <IconButton color="primary" onClick={onRemove}>
          <RemoveCircleOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

ValueMappingRulesItem.propTypes = {
  source: PropTypes.string.isRequired,
  mappedTo: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ValueMappingRulesItem);
