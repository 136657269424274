// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  Radio,
  RadioGroup,
  CardContent,
  CardHeader,
  Card,
  CardActions,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';
import ReportsAveragesByClassificationChart from '../charts/ReportsAveragesByClassificationChart';
import { getSettings } from '../../helpers/data-helper';
// import { ReportAveragesByClassificationDefaultSettings } from './'';
import WidgetTitle from '../help-button/widget-title';
import TimeServiceProvider from '../../services/TimeService';
import { WidgetCode } from './widgetCodes';
import { getReportWidgetData } from '../../services/WidgetDataService';
import CircularProgressCentral from '../common/CircularProgressCentral';
import PropType from 'prop-types';
import UserPreferenceService from '../../services/UserPreferenceService';
import { filters } from '../../signals/filterService';
import { useWidgetTranslations } from './useTranslatedWidgetsDefaultSettings';

const styles = {
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
};

const chartTypes = {
  organisations: 'organisations',
  types: 'types',
};

const userPreferenceService = new UserPreferenceService();
const timeService = TimeServiceProvider();

function ReportsAveragesByClassification({ settings, classes }) {
  const { ReportAveragesByClassificationDefaultSettings } =
    useWidgetTranslations();
  const [chartType, setChartType] = useState(chartTypes.types);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    setIsLoading(true);
    getReportWidgetData(WidgetCode.reportsAveragesByClassification).then(
      (res) => {
        setData(res);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    timeService.subscribe(getData);
    getData();

    return () => {
      timeService.unsubscribe(getData);
    };
  }, []);

  useEffect(() => {
    const savedChartType = userPreferenceService.get(
      'ReportsAveragesByClassificationChartType'
    );
    if (savedChartType !== null) {
      setChartType(savedChartType);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [filters.value]);

  useEffect(() => {
    userPreferenceService.save(
      'ReportsAveragesByClassificationChartType',
      chartType
    );
  }, [chartType]);

  const widgetSettings = getSettings(
    settings,
    ReportAveragesByClassificationDefaultSettings
  );
  const { info, title, organisationsLabel, typesLabel } = widgetSettings;

  const handleChartChange = (event) => {
    setChartType(event.target.value);
  };

  return isLoading || !data ? (
    <CircularProgressCentral />
  ) : (
    <Card variant="outlined" className={classes.card}>
      <CardHeader
        title={<WidgetTitle title={title} info={info} />}
        titleTypographyProps={{
          variant: 'subtitle1',
        }}
        action={
          <>
            <RadioGroup value={chartType} onChange={handleChartChange} row>
              <FormControlLabel
                value={chartTypes.organisations}
                control={<Radio color="primary" />}
                label={organisationsLabel}
                labelPlacement="end"
              />
              <FormControlLabel
                value={chartTypes.types}
                control={<Radio color="primary" />}
                label={typesLabel}
                labelPlacement="end"
              />
            </RadioGroup>
          </>
        }
      />
      <CardContent className={classes.cardContent}>
        <ReportsAveragesByClassificationChart
          data={data}
          showOrganisations={chartType === chartTypes.organisations}
          settings={widgetSettings}
        />
      </CardContent>
      <CardActions />
    </Card>
  );
}

ReportsAveragesByClassification.propTypes = {
  classes: PropType.object.isRequired,
  settings: PropType.object,
};

export default withStyles(styles)(ReportsAveragesByClassification);
