import React from 'react';
import { useDrop } from 'react-dnd';
import { getBackgroundColor } from './shared';
import { UNMAPPED_ITEMS_POOL, EXTRA_ITEMS_POOL, CARD_TYPE } from './constants';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { makeStyles } from '@material-ui/core';

const styles = {
  icon: {
    marginRight: '0.2em',
    color: '#6c6c6c',
  },
};

const useStyles = makeStyles(styles);

const ItemsPool = ({ children }, dropName, title, width = 30) => {
  const classes = useStyles();
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: CARD_TYPE,
    drop: () => ({ name: dropName }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      className="items-pool mapper-container-border"
      style={{
        backgroundColor: getBackgroundColor(isOver, canDrop),
        width: `${width}em`,
      }}
    >
      <div className="items-pool-header">
        <ClearAllIcon className={classes.icon} />
        {title}
      </div>
      {children}
    </div>
  );
};

export const HeadersPool = ({ children }) =>
  ItemsPool({ children }, UNMAPPED_ITEMS_POOL, 'FileHeaders');

export const ExtrasPool = ({ children }) =>
  ItemsPool({ children }, EXTRA_ITEMS_POOL, 'Extras Headers', 25);
