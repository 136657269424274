import { useAuth0 } from '@auth0/auth0-react';
import useLoggedInUser from '../useLoggedInUser';
import {
  Permissions,
  Roles,
  ROUTES,
  PERMISSIONS,
  ROLES,
  Routes,
} from '../constants';

const useAuthorization = () => {
  const { isAuthenticated,  } = useAuth0();
  const { currentUser, setCurrentUser } = useLoggedInUser();

  function hasAccess(route: Routes) {
    switch (route) {
      case ROUTES.DashboardLayout:
        return hasPermission(PERMISSIONS.DashboardConfig.CreateUpdate);
      case ROUTES.UsersAndOrganisations:
        return hasPermissions([
          PERMISSIONS.User.Read,
          PERMISSIONS.Organisation.Read,
        ]);
      case ROUTES.Upload:
        return hasPermission(PERMISSIONS.File.Any);
      case ROUTES.WidgetsAndFilters:
        return hasPermissions([
          PERMISSIONS.WidgetConfig.CreateUpdate,
          PERMISSIONS.FilterConfig.CreateUpdate,
        ]);
      case ROUTES.Facilities:
        return hasPermissions([
          PERMISSIONS.Facility.Create,
          PERMISSIONS.Facility.Read,
          PERMISSIONS.Facility.Update,
          PERMISSIONS.Facility.Delete,
        ]);
      case ROUTES.Aliases:
        return hasPermission(PERMISSIONS.AliasMap.CreateUpdate);
      case ROUTES.SuperUserPanel:
        return hasRole(ROLES.SuperUser);
      case ROUTES.Personalise:
        return hasPermissions([
          PERMISSIONS.ThemeConfig.CreateUpdate,
          PERMISSIONS.Organisation.Update,
        ]);
      case ROUTES.ActivityLogs:
        return hasPermission(PERMISSIONS.ActivityLog.Read);
      case ROUTES.UserProfile:
      case ROUTES.Trackers:
      case ROUTES.Actions:
      case ROUTES.Dashboard:
      case ROUTES.Licences:
      case ROUTES.About:
        return true;
      default:
        console.log(`Unknown route: ${route}`);
        return false;
    }
  }

  const hasPermission = (permissionToCheck: Permissions): boolean => {
    return (
      isAuthenticated &&
      currentUser.userRole.permissions.some(
        (rolePermission) => rolePermission.name === permissionToCheck
      )
    );
  };

  const hasPermissions = (permissionsToCheck: Permissions[]): boolean => {
    return (
      isAuthenticated &&
      permissionsToCheck.every((permission) =>
        currentUser.userRole.permissions.some(
          (rolePermission) => rolePermission.name === permission
        )
      )
    );
  };

  const hasRole = (role: Roles): boolean => {
    return (
      isAuthenticated &&
      currentUser.userRole.name.toLowerCase().includes(role.toLowerCase())
    );
  };

  return { hasPermission, hasPermissions, hasRole, hasAccess };
};

export default useAuthorization;
