import React from 'react';
import { Grid, Switch, Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const CHART_DATA_FORMATS = {
  Actual: 'Actuals',
  Average: 'Averages',
};

const styles = {
  root: {
    display: 'flex',
    padding: '0 10px',
    marginLeft: '10px',
  },
  formatSwitcher: {
    '& .MuiSwitch-track': {
      backgroundColor: '#d94936 !important',
    },
    '& .MuiSwitch-thumb': {
      color: '#d94936',
    },
  },
};

const ChartDataVisualisationTypeToggle = ({ value, onChange, classes }) => {
  const { t } = useTranslation();
  const toggleDataValueType = () => {
    const newFormat =
      value === CHART_DATA_FORMATS.Actual
        ? CHART_DATA_FORMATS.Average
        : CHART_DATA_FORMATS.Actual;
    onChange(newFormat);
  };

  const checked = value === CHART_DATA_FORMATS.Average;

  return (
    <Paper variant="outlined" className={classes.root}>
      <Grid container alignItems="center">
        <Grid item>
          {t('tracker.ChartDataVisualisationTypeToggle.Actuals')}
        </Grid>
        <Grid item>
          <Switch
            className={classes.formatSwitcher}
            checked={checked}
            onChange={toggleDataValueType}
            color="primary"
          />
        </Grid>
        <Grid item>
          {t('tracker.ChartDataVisualisationTypeToggle.Averages')}
        </Grid>
      </Grid>
    </Paper>
  );
};

ChartDataVisualisationTypeToggle.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChartDataVisualisationTypeToggle);
