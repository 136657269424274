// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
} from '@material-ui/core';
import { getSettings } from '../../helpers/data-helper';
import WidgetTitle from '../help-button/widget-title';
import DashboardModal from '../dashboard/DashboardModal';
import NoDataText from '../common/NoDataText';
import PropTypes from 'prop-types';
import TimeServiceProvider from '../../services/TimeService';
import { WidgetCode } from './widgetCodes';
import { getReportWidgetData } from '../../services/WidgetDataService';
import CircularProgressCentral from '../common/CircularProgressCentral';
import { filters } from '../../signals/filterService';
import { RootCauseToTopicDto } from '../../api/originalMappings';
import { RootCauseToTopicDtoSchema } from '../../api/zodSchemas';
import { logResponseParseError, logDataFetchError } from '../../services/utils';
import { useTranslation } from 'react-i18next';
import { useWidgetTranslations } from './useTranslatedWidgetsDefaultSettings';

const styles = {
  viewAllButton: {
    width: '100%',
  },
  header: {
    padding: 16,
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
    background: 'white',
  },
  content: {
    overflowY: 'auto',
  },
  footer: {
    padding: 8,
  },
};

const timeService = TimeServiceProvider();

function TableView({ data, limit, settings }) {
  const { RootCauseToTopicCorrelationsDefaultSettings } =
    useWidgetTranslations();
  const { rootCauseType, topic } = settings;
  limit = limit || data.length;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell component="th">{rootCauseType}</TableCell>
          <TableCell component="th">{topic}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.slice(0, limit).map((item) => {
          return (
            <TableRow key={item.label}>
              <TableCell>{item.label}</TableCell>
              <TableCell>
                {item.topics.map((topic) => topic.label).join(', ')}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

function RootCauseToTopicCorrelations({ limit, settings, classes }) {
  const { RootCauseToTopicCorrelationsDefaultSettings } =
    useWidgetTranslations();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState<RootCauseToTopicDto[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    setIsLoading(true);
    getReportWidgetData(WidgetCode.rootCauseToTopicCorrelations)
      .then((res) => {
        if (!res) return {};
        //here zod is used to do a runtime check whether the parsed res from api is the
        //correct format
        const schemaMatch = RootCauseToTopicDtoSchema.safeParse(res);
        if (schemaMatch.success) {
          setData(schemaMatch.data);
        } else {
          logResponseParseError(schemaMatch.error);
        }
      })
      .catch((err) =>
        logDataFetchError(err, 'Reports by Organisation And Types')
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(function initialiseTimeServiceListener() {
    timeService.subscribe(getData);
    getData();

    return () => {
      timeService.unsubscribe(getData);
    };
  }, []);

  useEffect(
    function getDataWhenFiltersChange() {
      getData();
    },
    [filters.value]
  );

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  settings = getSettings(settings, RootCauseToTopicCorrelationsDefaultSettings);
  const { title, info, noDataLabel } = settings;

  const hasData = data.length > 0;

  return isLoading ? (
    <CircularProgressCentral />
  ) : (
    <div className={classes.root}>
      <div className={classes.header}>
        <WidgetTitle title={title} info={info} />
      </div>
      <div className={classes.content}>
        {hasData ? (
          <TableView data={data} limit={limit} settings={settings} />
        ) : (
          <NoDataText label={noDataLabel} />
        )}
      </div>
      <div className={classes.footer}>
        {hasData && (
          <Button onClick={toggleShowModal} className={classes.viewAllButton}>
            {t('widgets.RootCauseToTopicCorrelations.VIEW ALL')}
          </Button>
        )}
      </div>
      {showModal && (
        <DashboardModal
          title={title}
          open={showModal}
          handleClose={toggleShowModal}
        >
          <TableView data={data} settings={settings} />
        </DashboardModal>
      )}
    </div>
  );
}

RootCauseToTopicCorrelations.propTypes = {
  classes: PropTypes.object.isRequired,
  limit: PropTypes.number,
};

export default withStyles(styles)(RootCauseToTopicCorrelations);
