import React, { FC } from 'react';
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import Colours from '../../../services/ColourService';
import { ChartStyles } from '../ChartConstants';
import { Palette } from './LifeSavingRulesBarChart';
import { LifeSavingRuleDto } from '../../../api/bllDtos';

interface Props {
  data: LifeSavingRuleDto[];
  onClick: (code: string) => void;
}

const LifeSavingRulesPieChart: FC<Props> = ({ data, onClick }) => {
  const coloursGenerator = Colours.materialColors();
  const palette: Palette = data.reduce((palette, item) => {
    palette[item.code!] = coloursGenerator.next();
    return palette;
  }, {} as Palette);

  const onPieClick = (event: { code: string }) => {
    if (event) {
      onClick(event.code);
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Tooltip
          itemStyle={{ color: 'white' }}
          contentStyle={ChartStyles.tooltipContentStyle}
        />
        <Pie
          data={data}
          dataKey="count"
          label
          onClick={onPieClick}
          cursor="pointer"
        >
          {data.map((item) => (
            <Cell key={item.code} fill={palette[item.code!]} />
          ))}
        </Pie>
        <Legend wrapperStyle={{ paddingBottom: 8 }} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default LifeSavingRulesPieChart;
