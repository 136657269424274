import Highcharts from 'highcharts';
import HighchartsReact, {
  HighchartsReactProps,
  HighchartsReactRefObject,
} from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import React, { FC, MutableRefObject } from 'react';

HC_more(Highcharts);

export type BubbleSeriesData = {
  name: string;
  data: {
    name: string;
    value: number;
    color: string;
  }[];
}[];

export const createBubbleChartOptions = (
  data: BubbleSeriesData,
  titleText: string,
  categories: string[]
) => ({
  chart: {
    type: 'packedbubble',
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    categories: categories,
  },
  title: {
    text: titleText,
  },
  series: data,
  tooltip: {
    shared: true,
    useHTML: true,
    pointFormat: '{point.value}%',
  },
  plotOptions: {
    packedbubble: {
      useSimulation: true,
      minSize: '20%',
      maxSize: '120%',
      dataLabels: {
        formatter: function () {
          if (this.point.value >= 15) {
            // Adjust the threshold value as needed
            return this.point.name;
          } else {
            return ''; // Hide labels for bubbles with a value below the threshold
          }
        },
        enabled: false,
        format: '{point.name}',
        style: {
          color: 'black',
          textOutline: 'none',
          fontWeight: 'normal',
          fontSize: '11px',
        },
      },
    },
  },
});

export const getColorByValueChange = (value1: number, value2: number) => {
  if (value1 < value2) {
    return 'red'; // Increased value, set color to red
  } else if (value1 == value2) {
    return 'grey'; // Increased value, set color to red
  } else {
    return 'green'; // Decreased or unchanged value, set color to green
  }
};

interface Props extends HighchartsReactProps {
  title: string;
  categories: string[];
  data: any[];
  chartRef: MutableRefObject<HighchartsReactRefObject>;
}

export const PackedBubbleChart: FC<Props> = ({
  title,
  data,
  categories,
  chartRef,
  ...highChartsProps
}) => {
  const chartOptions = createBubbleChartOptions(data, title, categories);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      ref={chartRef}
      {...highChartsProps}
    />
  );
};
