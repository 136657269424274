import React, { useState, useEffect, FC } from 'react';
import MUIDatatable, { MUIDataTableState } from 'mui-datatables';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import {
  withStyles,
  Card,
  CardHeader,
  CardContent,
  TextField,
  IconButton,
  createStyles,
  WithStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import clsx from 'clsx';
import { ACTIVITY_LEVELS } from '../../constants';
import {
  ActivityLogDto,
  PaginationDtoOfActivityLogDto,
} from '../../api/apiDtos';
import { useTranslation } from 'react-i18next';

const styles = createStyles({
  description: {
    whiteSpace: 'break-spaces',
  },
  errorLog: {
    background: 'rgb(253, 237, 237)',
  },
});

let searchTimer: number | NodeJS.Timeout = 0;
const SEARCH_TIMEOUT_MS = 1000;

const DATE_TIME_FORMAT = 'DD MMM YYYY hh:mm:ss';

interface Props extends WithStyles<typeof styles> {}

const ActivityLogs: FC<Props> = ({ classes }) => {
  const { t } = useTranslation();
  const [logs, setLogs] = useState<ActivityLogDto[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('');
  const [sortDesc, setSortDesc] = useState(false);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState('');

  const loadActivityLogs = async (
    page: number,
    rowsPerPage: number,
    sortBy: string,
    sortDesc: boolean,
    search: string
  ) => {
    const url = `${ApiRoutes.getActivityLogs}?page=${page}&rowsPerPage=${rowsPerPage}&sortBy=${sortBy}&sortDesc=${sortDesc}&search=${search}`;
    const response = await api.get<any, PaginationDtoOfActivityLogDto>(url);
    const { data, total } = response;
    if (data) setLogs(data);
    if (total) setCount(total);
  };

  useEffect(() => {
    loadActivityLogs(page, rowsPerPage, sortBy, sortDesc, search);
  }, []);

  const columns = [
    {
      label: t('activity-logs.ActivityLogs.Event'),
      name: 'activityType',
      options: { sort: false },
    },
    {
      label: t('activity-logs.ActivityLogs.User'),
      name: 'user',
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex: number) => {
          const log = logs[dataIndex];
          return log && log.user ? log.user : 'System';
        },
      },
    },
    {
      label: t('activity-logs.ActivityLogs.Date and Time'),
      name: 'timeCompleted',
      options: {
        customBodyRenderLite: (dataIndex: number) => {
          const log = logs[dataIndex];
          return log
            ? moment(log.timeCompleted).format(DATE_TIME_FORMAT)
            : null;
        },
      },
    },
    {
      label: t('activity-logs.ActivityLogs.Description'),
      name: 'description',
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex: number) => {
          const log = logs[dataIndex];
          return log ? (
            <div className={classes.description}>{log.description}</div>
          ) : null;
        },
      },
    },
  ];

  const tableChangeHandler = (
    action: string,
    tableState: MUIDataTableState
  ) => {
    const {
      page: newPage,
      rowsPerPage: newRowsPerPage,
      sortOrder,
      searchText,
    } = tableState;
    const newSortBy = sortOrder.name ? sortOrder.name : '';
    const newSortDesc = sortOrder.direction === 'desc';
    const newSearch = searchText ? searchText : '';

    if (['changePage', 'changeRowsPerPage', 'sort'].includes(action)) {
      loadActivityLogs(
        newPage,
        newRowsPerPage,
        newSortBy,
        newSortDesc,
        newSearch
      );
      setPage(newPage);
      setRowsPerPage(newRowsPerPage);
      setSortBy(newSortBy);
      setSortDesc(newSortDesc);
    } else if (action === 'search') {
      clearTimeout(searchTimer);
      searchTimer = setTimeout(() => {
        loadActivityLogs(
          newPage,
          newRowsPerPage,
          newSortBy,
          newSortDesc,
          newSearch
        );
      }, SEARCH_TIMEOUT_MS);
      setSearch(newSearch);
    }
  };

  const customSearchRender = (
    searchText: string,
    handleSearch: (text: string) => void
  ) => (
    <TextField
      value={search}
      onChange={(e) => handleSearch(e.target.value)}
      style={{ width: 600 }}
      InputProps={{
        startAdornment: <SearchIcon color="disabled" style={{ padding: 12 }} />,
        endAdornment: search ? (
          <IconButton onClick={() => handleSearch('')}>
            <ClearIcon />
          </IconButton>
        ) : null,
      }}
    />
  );

  const setRowProps = (row: any, dataIndex: number, _: any) => {
    const log = logs[dataIndex];
    return {
      className: clsx({
        [classes.errorLog]: log && log.level === ACTIVITY_LEVELS.Error,
      }),
    };
  };

  return (
    <Card>
      <CardHeader title={t('activity-logs.ActivityLogs.Activity Logs')} />
      <CardContent>
        <MUIDatatable
          data={logs}
          columns={columns}
          options={{
            selectableRows: 'none',
            print: false,
            search: true,
            searchAlwaysOpen: true,
            filter: false,
            download: false,
            viewColumns: false,
            serverSide: true,
            count,
            onTableChange: tableChangeHandler,
            customSearchRender,
            setRowProps,
          }}
          title={''}
        />
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ActivityLogs);
