// @ts-nocheck
import React from 'react';
import DataTableWidget from './DataTableWidget';
import PropTypes from 'prop-types';
import { getAssociatedReportsWithPagination } from '../../services/WidgetDataService';
import { useTableColumns } from '../../context/TableColumnsContext';
import { useWidgetTranslations } from './useTranslatedWidgetsDefaultSettings';

function AssociatedReportsTableWidget({ settings }) {
  const { AssociatedReportTableDefaultSettings } = useWidgetTranslations();

  const { associatedReportTableColumns, associatedReportTableColumnsExpanded } =
    useTableColumns();

  return (
    <DataTableWidget
      columns={associatedReportTableColumns}
      columnsExpanded={associatedReportTableColumnsExpanded}
      settings={settings}
      defaultSettings={AssociatedReportTableDefaultSettings}
      dataFetchFunc={getAssociatedReportsWithPagination}
    />
  );
}

AssociatedReportsTableWidget.propTypes = {
  settings: PropTypes.object,
};

export default AssociatedReportsTableWidget;
