import { AlertDto, UserDtoBLL } from "./apiDtos";

export interface AlertCreateDto {
  name?: string | null;
  /** @format uuid */
  parentClassificationId?: string;
  /** @format uuid */
  subClassificationId?: string;
  /** @format uuid */
  level2ClassificationId?: string | null;
  typeOfChange?: AlertTypeOfChange;
  period?: AlertPeriod;
  alertUsersIds?: string[] | null;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  minimumValue?: number;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  maximumValue?: number;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  percentageValue?: number;
  isActive?: boolean;
}

export enum AlertPeriod {
  Day = 1,
  Week = 2,
  Month = 3,
  Quarter = 4,
  Year = 5,
}

export enum AlertTypeOfChange {
  // 'Rate Of Change' = 1,
  // 'Value Change' = 2,
  // 'Volume Change' = 3,
  // 'Standard Deviation Change' = 4,
  'Percentage Change' = 5,
  // 'Outliers Change' = 6,
  'Range Change' = 7,
  // 'Mode Change' = 8,
  // 'Median Change' = 9,
}

export enum AlertTypeOfReport {
  'Incident' = 1,
  'Near Miss' = 2,
  'Observation' = 3,
}


export interface CostOverTimeAssociatedReportDto {
  /** @format uuid */
  id?: string;
  /** @format float */
  value1?: number;
  /** @format date-time */
  date?: string;
}

export interface RootCausePreventiveActionsDto {
  label?: string | null;
  /** @format int32 */
  count?: number;
  preventiveAction?: RootCausePreventiveActionsDtoPreventiveActionDto;
}

export interface RootCausePreventiveActionsDtoPreventiveActionDto {
  description?: string | null;
  shortTermActivity?: string | null;
  longTermActivity?: string | null;
}

export interface TargetChartDataDto {
  /** @format int32 */
  month?: number;
  /** @format int32 */
  quarter?: number;
  /** @format int32 */
  year?: number;
}

export interface TargetDto {
  /** @format uuid */
  id?: string;
  metricTitle?: string | null;
  metricCode?: string | null;
  isPositive?: boolean;
  goal?: TargetChartDataDto;
  current?: TargetChartDataDto;
  previous?: TargetChartDataDto;
}

export interface TrackerChartDto {
  chartShape?: string[] | null;
  percentagesChartData?: Record<string, Record<string, number>>;
  totalCountsChartData?: Record<string, Record<string, number>>;
  targetsData?: TargetDto[] | null;
}

export interface OrganisationInfo {
  /** @format uuid */
  id?: string;
  name?: string | null;
  isActive?: boolean;
}

export interface SavedSearchDto {
  /** @format uuid */
  id?: string;
  searchFilters?: SearchFilterDto[] | null;
}

export interface SearchFilterDto {
  property?: string | null;
  selectValue?: Record<string, string | null>;
  textValue?: string | null;
}

export interface UserDto {
  /** @format uuid */
  id?: string;
  username?: string | null;
  email?: string | null;
  phone?: string | null;
  isActive?: boolean | null;
  role?: UserRoleEnum;
  roleName?: string | null;
  /** @format int32 */
  roleLevel?: number;
  organisations?: OrganisationInfo[] | null;
  primaryOrganisation?: OrganisationInfo;
}

export interface UserLoginDto {
  usernameOrEmail?: string | null;
}

/** @format int32 */
export enum ActivityLevels {
  Information = 0,
  Warning = 1,
  Error = 2,
}

/** @format int32 */
export enum ClassificationType {
  None = 0,
  Hazard = 1,
  Topic = 2,
  Severity = 4,
  RootCause = 8,
  FieldVerification = 16,
  Observation = 32,
  All = None | Hazard | Topic | Severity | RootCause | FieldVerification | Observation,
}

/** @format int32 */
export enum EntityType {
  None = 0,
  Report = 1,
  Location = 2,
  Facility = 4,
  AssociatedReport = 8,
  Organisation = 16,
  ReportType = 32,
}

/** @format int32 */
export enum FilterVariants {
  Undefined = 0,
  Select = 1,
  Switch = 2,
  Range = 3,
}

/** @format int32 */
export enum UserRoleEnum {
  SuperUser = 0,
  Admin = 1,
  Contributor = 2,
  Reader = 3,
}

export interface ActionDto {
  /** @format uuid */
  id?: string | null;
  /** @format date-time */
  date?: string;
  dateText?: string | null;
  title?: string | null;
  description?: string | null;
  /** @format uuid */
  organisationId?: string | null;
  organisation?: string | null;
  /** @format uuid */
  facilityId?: string | null;
  facility?: string | null;
}

export interface ActivityLogDto {
  user?: string | null;
  organisation?: string | null;
  activityType?: string | null;
  level?: ActivityLevels;
  description?: string | null;
  /** @format date-time */
  timeCompleted?: string;
  /** @format uuid */
  relatedEntityId?: string | null;
}

export interface AliasMapDto {
  /** @format uuid */
  id?: string | null;
  entityType?: EntityType;
  name?: string | null;
  aliases?: string[] | null;
}

export interface AssociatedReportDto {
  id?: string | null;
  title?: string | null;
  description?: string | null;
  /** @format float */
  value1?: number;
  /** @format float */
  value2?: number;
  /** @format date-time */
  date?: string;
  status?: string | null;
  statusId?: string | null;
  value1Unit?: string | null;
  value2Unit?: string | null;
  facility?: string | null;
  organisation?: string | null;
  affectedParty?: string | null;
  /** @format date-time */
  dateOccurred?: string;
}

export interface ClassificationDto {
  label?: string | null;
  /** @format int32 */
  level?: number;
  preventiveAction?: PreventiveActionDto;
}

export interface ClassificationTypesRequestDto {
  classificationTypes?: ClassificationType[] | null;
  /** @format uuid */
  topLevelOrganisationId?: string | null;
}

export interface DashboardConfigurationDto {
  layout?: DashboardLayoutItemDto[] | null;
  widgets?: WidgetDto[] | null;
  /** @format uuid */
  clientId?: string;
}

export interface DashboardLayoutItemDto {
  i?: string | null;
  /** @format int32 */
  x?: number;
  /** @format int32 */
  y?: number;
  /** @format int32 */
  w?: number;
  /** @format int32 */
  h?: number;
}

export interface FacilityDto {
  /** @format uuid */
  id?: string;
  name?: string | null;
  localId?: string | null;
  postcode?: string | null;
  organisation?: NamedDto;
  hasDependentReports?: boolean;
}

export interface FilterConfigDto {
  /** @format uuid */
  clientId?: string;
  sections?: FilterSectionDto[] | null;
}

export interface FilterDto {
  typeCode?: string | null;
  typeName?: string | null;
  ids?: string | null;
  names?: string | null;
}

export interface FilterOptionDto {
  value?: any;
  name?: string | null;
  /** @format int32 */
  count?: number;
}

export interface FilterSectionDataDto {
  /** @format uuid */
  sectionTypeId?: string;
  options?: Record<string, FilterOptionDto[] | null>;
  /** @format int32 */
  totalCount?: number;
}

export interface AlertUserDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  userId?: string;
  user?: UserDtoBLL;
  /** @format uuid */
  alertId?: string;
  alert?: AlertDto;
}

export interface FilterSectionDto {
  title?: string | null;
  isEnabled?: boolean;
  /** @format int32 */
  order?: number;
  canBeDisabled?: boolean;
  /** @format uuid */
  typeId?: string;
  typeName?: string | null;
  settings?: FilterSettingDto[] | null;
}

export interface FilterSettingDto {
  /** @format uuid */
  typeId?: string;
  typeCode?: string | null;
  typeName?: string | null;
  canBeDisabled?: boolean;
  isEnabled?: boolean;
  title?: string | null;
  /** @format int32 */
  order?: number;
  variant?: FilterVariants;
}

export interface MappingRulesDto {
  source?: string | null;
  mappedTo?: string | null;
}

export interface NamedDto {
  /** @format uuid */
  id?: string;
  name?: string | null;
}

export interface OrganisationAdditionalTopicsDto {
  /** @format uuid */
  id?: string;
  additionalTopicName?: string | null;
  customLabel?: string | null;
}

export interface OrganisationDto {
  /** @format uuid */
  id?: string;
  name?: string | null;
  /** @format uuid */
  parentId?: string | null;
  parent?: OrganisationDto;
  /** @format uuid */
  industrySectorId?: string | null;
  industrySector?: NamedDto;
  /** @format uuid */
  locationId?: string | null;
  location?: NamedDto;
  isActive?: boolean;
  parentLevelOne?: string | null;
  /** @format int32 */
  level?: number;
  additionalTopics?: OrganisationAdditionalTopicsDto[] | null;
}

export interface PreventiveActionDto {
  description?: string | null;
  shortTermActivity?: string | null;
  longTermActivity?: string | null;
}

export interface ReportColumnDto {
  name?: string | null;
  title?: string | null;
  sort?: boolean;
  isDateTime?: boolean;
}

export interface ReportDataDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  organisationId?: string;
  organisation?: string | null;
  subOrganisation?: string | null;
  /** @format uuid */
  facilityId?: string;
  facility?: string | null;
  postcode?: string | null;
  localId?: string | null;
  /** @format date-time */
  dateOccurred?: string;
  affectedParty?: string | null;
  reportType?: string | null;
  description?: string | null;
  eventType?: string | null;
  area?: string | null;
  location?: number[] | null;
  rootCauseTrends?: string[] | null;
  hazardTrends?: string[] | null;
  topicTrends?: string[] | null;
  rootCauseClassifications?: ClassificationDto[] | null;
  topicClassifications?: ClassificationDto[] | null;
  hazardClassifications?: ClassificationDto[] | null;
  fieldVerificationClassifications?: ClassificationDto[] | null;
}

export interface AnnotationDto {
  key?: string | null;
  date?: string | null;
  title?: string | null;
}

export interface ChartDataDto {
  date?: string | Date;
  data?: { [key: string]: number };
}

export interface ReportHistoryDto {
  chartData?: ChartDataDto[] | null;
  total?: number
}

export interface DateDomainDto {
  current?: string[] | null;
  previous?: string[] | null;
}

export interface LifeSavingRuleDto {
  code?: string | null;
  name?: string | null;
  action?: string | null;
  description?: string | null;
  always?: string | null;
  never?: string | null;
  count?: number | null;
}

export interface MapWidgetDto {
  locations?: ReportLocationDto[] | null;
}

export interface ReportDto {
  id?: string;
  organisation?: string | null;
  dateOccurred?: string | null;
  reportType?: string | null;
  location?: number[] | null;
}

export interface ReportLocationDto {
  location?: number[] | null;
  reportsCount?: number | null;
  firstReport?: ReportDto | null;
}

export interface LocationDto {
  latitude?: number | null;
  longitude?: number | null;
}


export interface ReportByTypeWidgetDto {
  organisationChartData?: ChartDataItemDto[] | null;
  facilityChartData?: ChartDataItemDto[] | null;
  chartShape?: string[] | null;
}

export interface ChartDataItemDto {
  id?: string | null;
  name?: string | null;
  reportTypes?: ReportTypeDto[] | null;
  count?: number | null;
}

export interface ReportTypeDto {
  name?: string | null;
  count?: number | null;
}

export interface ReportClassificationAggregationDto {
  type?: string | null;
  organisationValues?: { [key: string]: number } | null;
  classificationValues?: { [key: string]: number } | null;
  maxValue?: number | null;
  maxValuesSum?: number | null;
}

export interface ReportTypeCountDto {
  type?: string | null;
  count?: number | null;
}

export interface ReportOrganisationCountDto {
  organisation?: string | null;
  count?: number | null;
}

export interface ReportDateAggregationDto {
  date?: string | null;
  typeCounts?: ReportTypeCountDto[] | null;
  organisationCounts?: ReportOrganisationCountDto[] | null;
}

export interface RootCauseClassificationAggregationDto {
  levelOne?: string | null;
  subcategoryCounts?: { [key: string]: number } | null;
  organisationCounts?: { [key: string]: number } | null;
}

export interface RootCauseToTopicDto {
  label: string;
  count: number;
  topics: TopicDto[];
}

export interface TopicDto {
  label: string;
  count: number;
}

export interface TrackerDateDomainsDto {
  month: DateDomainDto;
  quarter: DateDomainDto;
  year: DateDomainDto;
}

export interface TrendDto {
  organisation: string;
  trends: Trend;
}

interface Trend {
  prev: number;
  current: number;
}

export type Trends = { [key:string]: TrendDto};

export type PossibleReportWidgetDataDto = 
    ReportHistoryDto 
  | ReportByTypeWidgetDto
  | MapWidgetDto
  | ReportDateAggregationDto[]
  | ReportClassificationAggregationDto[]
  | RootCauseClassificationAggregationDto[]
  | RootCausePreventiveActionsDto[]
  | RootCauseToTopicDto[]
  | LifeSavingRuleDto[]
  | Trends

export interface TargetMetricDto {
  typeCode?: string | null;
  code?: string | null;
  title?: string | null;
  name?: string | null;
  isPositive?: boolean;
}

export interface ThemeConfigDto {
  /** @format uuid */
  organisationId?: string | null;
  navBarColour?: string | null;
  textColour?: string | null;
  activeLinkFill?: string | null;
  inactiveLinkFill?: string | null;
}

export interface TrackerDto {
  /** @format uuid */
  id?: string | null;
  name?: string | null;
  /** @format date-time */
  dateCreated?: string;
  /** @format date-time */
  dateUpdated?: string | null;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  hasAlert?: boolean;
  filters?: FilterDto[] | null;
  actions?: ActionDto[] | null;
  targets?: TargetDto[] | null;
}

export interface WidgetConfigDto {
  /** @format uuid */
  clientId?: string;
  widgetSettings?: WidgetSettingDto[] | null;
}

export interface WidgetDto {
  title?: string | null;
  code?: string | null;
  mappedTo?: string | null;
  /** @format int32 */
  order?: number | null;
}

export interface WidgetSettingDto {
  code?: string | null;
  name?: string | null;
  isModified?: boolean;
  usedProps?: WidgetUsedPropDto[] | null;
}

export interface WidgetUsedPropDto {
  typeName?: string | null;
  code?: string | null;
  name?: string | null;
  isEnabled?: boolean;
  value?: string | null;
  options?: NamedDto[] | null;
}

export interface SqlDataLayerModelsPermission {
  /** @format uuid */
  id?: string;
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string;
  rolePermissions?: SqlDataLayerModelsRolePermission[] | null;
}

export interface SqlDataLayerModelsRolePermission {
  /** @format uuid */
  userRoleId?: string;
  userRole?: SqlDataLayerModelsUserRole;
  /** @format uuid */
  permissionId?: string;
  permission?: SqlDataLayerModelsPermission;
}

export interface SqlDataLayerModelsUserRole {
  /** @format uuid */
  id?: string;
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string;
  roleEnum?: UserRoleEnum;
  /** @format int32 */
  level?: number;
  rolePermissions?: SqlDataLayerModelsRolePermission[] | null;
  permissions?: SqlDataLayerModelsPermission[] | null;
}
