// @ts-nocheck
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const styles = {
  closeButton: {
    position: 'absolute',
    right: 0,
    margin: '3px 5px',
  },
};

const titles = {
  general: 'General Info',
};

const HelpDialog = (props) => {
  const { open, onClose, type, classes } = props;
  const { t } = useTranslation();
  const GeneralContent = () => (
    <>
      <Typography variant="subtitle1">
        {t('dashboard.HelpDialog.How do I use this application')}
      </Typography>
      <Typography align="justify" paragraph variant="body2">
        {t(
          'dashboard.HelpDialog.Use this application by clicking on buttons and looking at the screen.'
        )}
      </Typography>
    </>
  );

  const content = useMemo(
    () => ({
      general: <GeneralContent />,
    }),
    []
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
      <DialogTitle>
        {titles[type] === undefined ? '' : titles[type]}
      </DialogTitle>
      <DialogContent>{content[type]}</DialogContent>
    </Dialog>
  );
};

HelpDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(HelpDialog);
