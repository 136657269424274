import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, FormControlLabel } from '@material-ui/core';
import {
  ConstructFilter,
  RemoveFilter,
  AddFilter,
} from '../../signals/filterService';

function FilterSwitch({ label, filterProperty }) {
  const [checked, setChecked] = useState(false);
  const filter = ConstructFilter(label, filterProperty, '');

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      AddFilter(filter, filterProperty);
    } else {
      RemoveFilter(filter, filterProperty);
    }
  };

  return (
    <FormControlLabel
      control={
        <Switch checked={checked} onChange={handleChange} color="primary" />
      }
      style={{ color: '#fff' }}
      label={label}
    />
  );
}

FilterSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  filterProperty: PropTypes.string.isRequired,
};

export default FilterSwitch;
