// const u = process.env.REACT_APP_ANALYTICS_ENDPOINT;
// const siteId = process.env.REACT_APP_ANALYTICS_ID;
// const hasAnalytics = !!(u && siteId);
// const _paq = window._paq || [];
// window._paq = _paq;
// /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
// if (hasAnalytics) {
//   window._paq.push(['trackPageView']);
//   window._paq.push(['enableLinkTracking']);
//   window._paq.push(['setTrackerUrl', u + 'matomo.php']);
//   window._paq.push(['setSiteId', siteId]);
//   const d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
//   g.type = 'text/javascript'; g.async = true; g.defer = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
// }

// const noOp = () => { };

// const trackAction = hasAnalytics ? (area, action) => {
//   window._paq.push(['trackEvent', area, action]);
// } : noOp;
// const trackPage = hasAnalytics ? pageName => {
//   window._paq.push(['setDocumentTitle', pageName]);
//   window._paq.push(['trackPageView']);
// } : noOp;

// export { trackAction, trackPage };

declare global {
  interface Window {
    _paq: any[];
  }
}

const u: string | undefined = process.env.REACT_APP_ANALYTICS_ENDPOINT;
const siteId: string | undefined = process.env.REACT_APP_ANALYTICS_ID;
const hasAnalytics: boolean = !!(u && siteId);
window._paq = window._paq || [];

/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
if (hasAnalytics) {
  window._paq.push(['trackPageView']);
  window._paq.push(['enableLinkTracking']);
  window._paq.push(['setTrackerUrl', u + 'matomo.php']);
  window._paq.push(['setSiteId', siteId]);
  const d: Document = document, g: HTMLScriptElement = d.createElement('script'), s: HTMLScriptElement = d.getElementsByTagName('script')[0];
  g.type = 'text/javascript'; g.async = true; g.defer = true; g.src = u + 'matomo.js'; s.parentNode?.insertBefore(g, s);
}

const noOp = () => { };

const trackAction: (area: string, action: string) => void = hasAnalytics
  ? (area, action) => {
      window._paq.push(['trackEvent', area, action]);
    }
  : noOp;
  
const trackPage: (pageName: string) => void = hasAnalytics
  ? pageName => {
      window._paq.push(['setDocumentTitle', pageName]);
      window._paq.push(['trackPageView']);
    }
  : noOp;

export { trackAction, trackPage };
