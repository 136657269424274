// @ts-nocheck
import React from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { Button } from '@material-ui/core';
import LocalComboBox from '../combobox/local-combo-box';
import api from '../../services/ApiInstance';
import ApiRoutes from '../../services/ApiRoutes';
import './styles.css';
import ClientComboBox from '../combobox/client-combo-box';
import PropTypes from 'prop-types';
import UserPreferenceService from '../../services/UserPreferenceService';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

const userPreferenceService = new UserPreferenceService();
const ResponsiveReactGridLayout = WidthProvider(Responsive);

class DashboardLayoutConfigurator extends React.PureComponent {
  static defaultProps = {
    className: 'layout',
    cols: { lg: 3, md: 3, sm: 3, xs: 3, xxs: 3 },
    rowHeight: 200,
    onLayoutChange: function () {},
    onWidgetUpdate: function () {},
    widgets: [],
    layout: [],
    counter: 0,
  };

  constructor(props) {
    super(props);

    const { layout, widgets } = this.props;
    const counter = this.getCounter(layout);

    this.state = {
      widgets: widgets,
      layout: layout,
      currentLayout: {},
      counter: counter,
      client: null,
      defaultClient: null,
    };

    this.onAddItem = this.onAddItem.bind(this);
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.loadDashboardConfiguration =
      this.loadDashboardConfiguration.bind(this);
  }

  componentDidMount() {
    const savedClient = userPreferenceService.get('LayoutConfigClient');
    if (savedClient !== null) {
      this.setState({ defaultClient: savedClient });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.client !== prevState.client) {
      this.onClientChange();
    }
  }

  onClientChange() {
    const { client } = this.state;
    this.loadDashboardConfiguration(client);
    userPreferenceService.save('LayoutConfigClient', client);
  }

  getCounter(layout) {
    let counter = 0;
    if (!layout || layout.length < 1) {
      return 0;
    }
    const layoutKeys = layout.map((item) => parseInt(item.i));
    const maxKey = Math.max(...layoutKeys);
    counter = maxKey >= 0 ? maxKey + 1 : 0;
    return counter;
  }

  updateWidgets(key, value) {
    this.setState({
      widgets: this.state.widgets.map((item) => {
        if (item.mappedTo === key) {
          item.mappedTo = '';
        }

        if (value) {
          if (item.title === value.title) {
            item.mappedTo = key;
          }
        }
        return item;
      }),
    });
    this.props.onWidgetUpdate(this.state.widgets);
  }

  createElement(el) {
    const availableNames = this.state.widgets.filter(
      (item) => !item.mappedTo || item.mappedTo === el.i
    );
    const mapped = this.state.currentLayout[el.i];
    return (
      <div
        key={el.i}
        data-grid={el}
        className="grid-widget-item configurator-layout-item resizable-layout-item"
      >
        <LocalComboBox
          className="grid-layout-combobox"
          options={availableNames}
          defaultValue={mapped}
          getOptionLabel={(option) => option.title}
          onChange={(event, value) => this.updateWidgets(el.i, value)}
        />
        <span className="close" onClick={this.onRemoveItem.bind(this, el.i)} />
      </div>
    );
  }

  onAddItem() {
    const { counter, layout } = this.state;
    //TODO: implement simple O(N) algoritm to find first empty cell (x,y) for a new adding item
    const totalWidth = Object.values(layout).reduce(
      (total, { w }) => total + w,
      0
    );
    const totalHeight = Object.values(layout).reduce(
      (total, { h }) => total + h,
      0
    );

    this.setState({
      layout: this.state.layout.concat({
        i: counter.toString(), //should be unique
        x: totalWidth % 3,
        y: Math.floor(totalHeight / 3),
        w: 1,
        h: 1,
      }),
      counter: counter + 1,
    });
  }

  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange(breakpoint, cols) {
    this.setState({
      breakpoint: breakpoint,
      cols: cols,
    });
  }

  onLayoutChange(layout) {
    this.props.onLayoutChange(layout);
    this.setState({ layout: layout });
  }

  onRemoveItem(i) {
    this.setState({
      layout: this.state.layout.filter((item) => item.i !== i),
      widgets: this.state.widgets.map((item) => {
        if (item.mappedTo === i) {
          item.mappedTo = '';
        }
        return item;
      }),
    });
  }

  drawGrid() {
    return this.state.layout.map((el) => this.createElement(el));
  }

  stringifyAvailableWidgetNames() {
    return this.state.widgets.map((item, index) => {
      return (
        <div className="layoutItem" key={index}>
          {item.mappedTo ? (
            <span className="text font-weight-bold">{item.title}</span>
          ) : (
            <span className="text">{item.title}</span>
          )}
        </div>
      );
    });
  }

  loadDashboardConfiguration(client) {
    this.setState({
      widgets: [],
      layout: [],
      counter: 0,
    });

    if (!client) {
      return;
    }

    api
      .get(ApiRoutes.getDashboardConfiguration, {
        params: { clientId: client.id },
      })
      .then(
        (response) => {
          if (response) {
            const { layout, widgets } = response;
            this.setState({
              widgets: widgets,
              layout: layout,
              currentLayout: widgets
                .filter((w) => w.mappedTo)
                .reduce((currentLayout, widget) => {
                  currentLayout[widget.mappedTo] = widget;
                  return currentLayout;
                }, {}),
              counter: this.getCounter(layout),
            });
          }
        },
        (err) => {
          console.log('error encountered: ' + err);
        }
      );
  }

  saveDashboardConfiguration = () => {
    const config = {
      widgets: this.state.widgets,
      layout: this.state.layout,
      clientId: this.state.client.id,
    };
    api.post(ApiRoutes.saveDashboardConfiguration, config).then(
      () => {
        toast.success(
          this.props.t(
            'gridlayout.dashboard-layout-configurator.Saved successfully'
          )
        );
      },
      (err) => {
        console.log('error encountered: ' + err);
      }
    );
  };

  render() {
    const { client, defaultClient } = this.state;
    return (
      <div className="row">
        <div className="column-left">
          <div className="left-panel">
            <div>
              <ClientComboBox
                value={client}
                defaultValue={defaultClient}
                onChange={(event, value) => this.setState({ client: value })}
                style={{ maxWidth: 300 }}
              />
            </div>
            <div className="layout-json widget-names top-indent">
              <div className="widget-names-header">
                {this.props.t(
                  'gridlayout.dashboard-layout-configurator.widgets'
                )}
              </div>
              <div>{this.stringifyAvailableWidgetNames()}</div>
            </div>

            <div className="top-indent">
              <Button
                color="primary"
                variant="outlined"
                onClick={this.onAddItem}
                className="grid-layout-button"
                disabled={!client}
              >
                {this.props.t(
                  'gridlayout.dashboard-layout-configurator.Add layout Item'
                )}
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={this.saveDashboardConfiguration}
                className="grid-layout-button"
                disabled={!client}
              >
                {this.props.t(
                  'gridlayout.dashboard-layout-configurator.Save configuration'
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="column-right">
          <ResponsiveReactGridLayout
            {...this.props}
            onLayoutChange={this.onLayoutChange}
            onBreakpointChange={this.onBreakpointChange}
          >
            {this.drawGrid()}
          </ResponsiveReactGridLayout>
        </div>
      </div>
    );
  }
}

DashboardLayoutConfigurator.propTypes = {
  layout: PropTypes.array,
  widgets: PropTypes.array,
  onWidgetUpdate: PropTypes.func,
  onLayoutChange: PropTypes.func,
};

export default withTranslation()(DashboardLayoutConfigurator);
