import React, { useContext } from 'react';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { useTrackerStyles } from './styles';
import { formatDateCommon } from '../../helpers/date-helper';
import { AlertDto, AlertUserDto } from '../../api/apiDtos';
import {
  NotificationsNone as NotificationsNoneIcon,
  NotificationsOff as NotificationsOffIcon,
  EditOutlined,
  DeleteOutline,
} from '@material-ui/icons';
import { AlertsConfigContext } from '../../context/AlertConfigContextProvider';
import { AlertTypes } from '../../constants';
import { selectedAlert } from '../../signals/selectedAlert';
import useLoggedInUser from '../../useLoggedInUser';
import { useTranslation } from 'react-i18next';

interface AlertItemProps {
  item: AlertDto;
  onClick: (event: any) => void;
  itemSelected: (item: AlertDto) => void;
}

export const AlertItem: React.FC<AlertItemProps> = ({
  onClick,
  item,
  itemSelected,
}) => {
  const { t } = useTranslation();
  const classes = useTrackerStyles();
  const { currentUser, setCurrentUser } = useLoggedInUser();
  const { setAlertsConfig } = useContext(AlertsConfigContext);
  const alertUser: AlertUserDto | undefined = item.alertUsers.find(
    (x) => x.userId === currentUser.id
  );

  if (!item) {
    return <></>;
  }
  const className = classes.itemWrapper;
  const handleIconClick = (e) => {
    e.stopPropagation();
    onClick(e);
  };

  const handleEditClick = (e, item: AlertDto) => {
    e.stopPropagation();
    selectedAlert.value = item;
    setAlertsConfig(AlertTypes.edit);
    itemSelected(item);
  };

  const handleDeleteClick = (e, item: AlertDto) => {
    e.stopPropagation();
    selectedAlert.value = item;
    setAlertsConfig(AlertTypes.delete);
    itemSelected(item);
  };

  return (
    <div className={className}>
      <Grid direction="row" container>
        <Grid item xs={11}>
          <Typography variant="subtitle1">{item.name}</Typography>
          <Typography variant="subtitle1">
            {item.level2Classification?.label ??
              item.subClassification?.label ??
              ''}
          </Typography>
          <Typography variant="body1">
            {t('tracker.AlertItem.Created')}:{' '}
            {formatDateCommon(item.createdDate)}
          </Typography>
          <Typography variant="body1">
            {t('tracker.AlertItem.Last updated')}:{' '}
            {formatDateCommon(item.updatedDate || item.createdDate)}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {alertUser && !alertUser.muted ? (
            <Tooltip title={t('tracker.AlertItem.Mute Notification')}>
              <NotificationsNoneIcon
                color="secondary"
                onClick={handleIconClick}
              />
            </Tooltip>
          ) : (
            <Tooltip title={t('tracker.AlertItem.Unmute Notification')}>
              <NotificationsOffIcon
                color="secondary"
                onClick={handleIconClick}
              />
            </Tooltip>
          )}
        </Grid>
        <Grid
          style={{ cursor: 'pointer' }}
          item
          xs={12}
          container
          justifyContent="flex-end"
        >
          <Tooltip title={t('tracker.AlertItem.Edit Alert')}>
            <EditOutlined
              color="primary"
              onClick={(event) => {
                handleEditClick(event, item);
              }}
            />
          </Tooltip>
          <Tooltip title={t('tracker.AlertItem.Delete Alert')}>
            <DeleteOutline
              color="primary"
              onClick={(event) => {
                handleDeleteClick(event, item);
              }}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
};
