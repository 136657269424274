// @ts-nocheck
import { makeStyles } from '@material-ui/core';

export const trackerStyles = {
  root: {
    margin: '-4em 1em 1em 1em',
  },
  paper: {
    minHeight: '40em',
    height: '100%',
  },
  text: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  itemWrapper: {
    margin: '0em',
    width: '100%',
  },
  ml1: {
    marginLeft: '1em',
  },
  mr1: {
    marginRight: '1em',
  },
  mr05: {
    marginRight: '.5em',
  },
  mb1: {
    marginBottom: '1em',
  },
  itemsPaper: {
    marginTop: '1em',
    padding: '1em',
  },
  buttonPaper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 1em 1em 0',
  },
  button: {
    marginTop: '1em',
    marginLeft: '1em',
  },
  modified: {
    borderLeft: '0.5em solid orange',
    marginLeft: '-1em',
    paddingLeft: '0.5em',
  },
  paperTrackerViewerDetails: {
    marginLeft: '1em',
    marginRight: '0em',
    height: '100%',
    padding: '0 1em 1em 1em',
  },
  chartWrapper: {
    marginTop: '1em',
    padding: '1em',
  },
  trackerHeader: {
    display: 'flex',
    alignItems: 'center',

    minHeight: 56,
    paddingTop: 10,
  },
  preventiveActions: {
    height: 550,
    marginTop: '1em',
  },
  redText: {
    color: 'red',
  },
  alignCenter: {
    height: '18vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  periodSelector: {
    marginRight: 10,
    background: 'white',
    borderRadius: '5px',
  },
  periodSelectorInput: {
    '& .MuiOutlinedInput-input': {
      padding: '10px !important',
      width: 100,
    },
  },

  iconCircleButton: {
    background: '#D94936',
    width: '35px',
    height: '35px',
  },
  iconStyle: {
    color: 'white',
  },
  trackerHeaderTitle: {
    paddingLeft: '360px',
    top: '60px',
    position: 'absolute',
  },
  gridButtonLayout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 10,
  },
};

export const useTrackerStyles = makeStyles(trackerStyles);
