import React, { useEffect, useState, useRef } from 'react';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import API from '../../services/ApiInstance';
import apiRoutes from '../../services/ApiRoutes';
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import UploadMapper from './upload-mapper/upload-mapper';
import withTranslatedConstants from './upload-mapper/useTranslatedConstants';
import { UNMAPPED_ITEMS_POOL } from './upload-mapper/constants';
import ClientComboBox from '../combobox/client-combo-box';
import SendIcon from '@material-ui/icons/Send';
import UserPreferenceService from '../../services/UserPreferenceService';
import FileDropzone from './FileDropzone';
import UploadResultViewer from './UploadResultViewer';
import EntitiesSelector from './EntitiesSelector';
import InfoDialog from '../dialog/InfoDialog';
import { appendFlagEnum } from '../../helpers/form-data-helper';
import { useTranslation } from 'react-i18next';

const userPreferenceService = new UserPreferenceService();
const acceptedExtensions = ['csv', 'xlsx', 'xls'];
const acceptedMimeTypes =
  'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const TranslateSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#d94936',
    },
    '&$checked + $track': {
      backgroundColor: '#d94936',
    },
  },
  checked: {},
  track: {},
})(Switch);

function buildFileSelector(callback) {
  const acceptedExtensionsAsString = acceptedExtensions
    .map((ext) => `.${ext}`)
    .join(',');
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  fileSelector.setAttribute('accept', acceptedExtensionsAsString);
  fileSelector.onchange = callback;
  fileSelector.onclick = function () {
    this.value = null;
  };
  return fileSelector;
}

const styles = {
  paper: {
    margin: '0 0 1em 0',
    padding: '1em',
    textAlign: 'center',
    minHeight: '500px',
    height: 'auto',
  },
  translateSwitch: {
    marginLeft: '6em',
    marginTop: '0.5em',
    display: 'flex',
    justifyContent: 'center',
  },
  uploadText: {
    fontSize: '0.9em',
    textAlign: 'center',
    marginBottom: '2em',
    marginTop: '0.8em',
  },
  uploadErrorText: {
    fontSize: '0.9em',
    textAlign: 'center',
    marginBottom: '4em',
    marginTop: '0.8em',
    color: 'red',
  },
  uploadimg: {
    marginTop: '1.5em',
  },
  header: {
    marginBottom: '0.5em',
    textAlign: 'center',
  },
  subheader: {
    marginBottom: '0.5em',
    fontSize: '1em',
    textAlign: 'center',
  },
  table: {
    display: 'table',
    width: '70%',
    margin: 'auto',
  },
  row: {
    display: 'table-row',
  },
  cellCenter: {
    display: 'table-cell',
    textAlign: 'center',
  },
  cellLeft: {
    display: 'table-cell',
    textAlign: 'left',
  },
  spinner: {
    marginBottom: '100px',
    marginTop: '100px',
  },
  uploadButton: {
    margin: '0 10px',
    minWidth: 120,
  },
  buttonIcon: {
    marginRight: 5,
  },
  titleText: {
    fontSize: '1.2em',
    marginBottom: '1em',
    textAlign: 'center',
    color: '#666',
  },
  fileName: {
    fontWeight: 500,
  },
  fileDropZone: {
    margin: 'auto',
    marginBottom: 10,
  },
};

const ReportUpload = (props) => {
  const { t, i18n } = useTranslation();
  const { ENTITY_NAMES, ITEMS, ENTITY_TYPES, classes } = props;
  const mapperRef = useRef(null);
  const [mapperItems, setMapperItems] = useState(ITEMS);
  // const [isUploading, setIsUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();

  const fileSelectorCallback = (e) => {
    const file = e.target.files[0];
    parseHeaders(file);
  };
  const [selectedClient, setSelectedClient] = useState(null);
  const [state, setState] = useState({
    isUploading: false,
    isUploadingError: false,
    fileSelector: buildFileSelector(fileSelectorCallback),
    fileName: '',
    errorMessage: '',
    shouldBeTranslated: false,
    uploadResult: null,
    reportFile: null,
    showMapper: false,
    mapperItems: ITEMS,
    client: null,
    defaultClient: null,
    selectedEntityTypes: [ENTITY_TYPES.Reports],
    isMappingErrorVisible: false,
  });

  const FILE_IS_EMPTY = t('upload.ReportUpload.File is empty');

  useEffect(() => {
    const savedClient = userPreferenceService.get('UploadClient');
    if (savedClient !== null) {
      setState((prevState) => ({ ...prevState, defaultClient: savedClient }));
    }
  }, []);

  const onSelectFile = (e) => {
    e.preventDefault();
    //setIsUploading(false);
    setState({
      ...state,
      isUploading: false,
      isUploadingError: false,
    });
    state.fileSelector.click();
  };

  const handleUploadAnother = () => {
    hideMapperAndSaveItems();
  };

  const handleTranslate = (event) => {
    setState({
      ...state,
      shouldBeTranslated: event.currentTarget.checked,
    });
  };

  const isFileAccepted = (filename) =>
    acceptedExtensions.includes(getFileExtension(filename));

  const getFileExtension = (filename) =>
    filename ? filename.split('.').pop().toLowerCase() : '';

  const onDropFile = (files) => {
    const file = files[0];
    if (file && file.name && !isFileAccepted(file.name)) {
      return;
    }
    parseHeaders(file);
  };

  const parseHeaders = (file) => {
    if (!file) {
      return;
    }

    if (!isFileAccepted(file.name)) {
      // setIsUploading(false);
      setState({
        ...state,
        isUploading: false,
        fileName: file.name,
        errorMessage: t(
          'upload.ReportUpload.Incorrect file type. Please select a CSV or an Excel file.'
        ),
        isUploadingError: true,
      });
      return;
    }
    setUploadedFile(file);

    //setIsUploading(false);
    setState({
      ...state,
      isUploading: true,
      reportFile: file,
    });

    const formData = new FormData();
    formData.append('file', file);

    formData.append('clientId', selectedClient ? selectedClient.id : null);

    API.post(apiRoutes.fileParseHeaders, formData).then(
      (response) => {
        if (response === FILE_IS_EMPTY) {
          // setIsUploading(false);
          setState({
            ...state,
            errorMessage:
              t('upload.ReportUpload.Error encountered') + ' : ' + response,
            fileName: `${file.name}`,
            isUploading: false,
            isUploadingError: true,
          });
        } else {
          const items = response.map((item, index) => {
            return {
              id: index,
              name: item.name,
              card: item.mappedTo ? item.mappedTo : UNMAPPED_ITEMS_POOL,
            };
          });
          setMapperItems(items);
          // setIsUploading(false);
          setState({ ...state, mapperItems: items });

          setState({
            ...state,
            fileName: `${file.name}`,
            isUploading: false,
            isUploadingError: false,
            showMapper: true,
            mapperItems: items,
          });
        }
      },
      (err) => {
        // setIsUploading(false);
        setState({
          ...state,
          errorMessage:
            t('upload.ReportUpload.Error encountered') + ' : ' + err,
          fileName: `${file.name}`,
          isUploading: false,
          isUploadingError: true,
        });
        console.log('Error encountered: ' + err);
      }
    );
  };

  const getRules = () => {
    return mapperRef.current.getRules();
  };

  const addRulesToFormData = (formdata, rules, name) => {
    for (let i = 0; i < rules.length; i++) {
      addSingleRuleToFormData(formdata, rules[i], name + '[' + i + ']');
    }
  };

  const addSingleRuleToFormData = (formdata, rule, name) => {
    for (let key in rule) {
      formdata.append(name + '.' + key, rule[key]);
    }
  };

  const fileProcessMappedItems = () => {
    const { reportFile, client, shouldBeTranslated, selectedEntityTypes } =
      state;

    if (!uploadedFile) return;

    if (selectedEntityTypes.length === 0) {
      showMappingError(
        t('upload.ReportUpload.Please, select at least one entity')
      );
      return;
    }

    // if (!mapperRef.current.isValid()) {
    //   showMappingError(
    //     t('upload.ReportUpload.Please, map all the required properties')
    //   );
    //   return;
    // }
    setMapperItems(mapperRef.current.getItems());
    //setIsUploading(true);

    //console.log(mapperRef.current);
    const rules = getRules();

    if (!rules) return;

    const formData = new FormData();
    formData.append('file', uploadedFile);

    addRulesToFormData(formData, rules, 'rules');
    formData.append('clientId', selectedClient ? selectedClient.id : null);
    formData.append('shouldBeTranslated', shouldBeTranslated);

    const valueMappingRules = mapperRef.current.getValueMappingRules();
    formData.append(
      'valueMappingRulesString',
      JSON.stringify(valueMappingRules)
    );

    appendFlagEnum(formData, 'entityTypes', selectedEntityTypes);

    API.post(apiRoutes.fileProcessMapped, formData).then(
      (response) => {
        if (response === FILE_IS_EMPTY) {
          // setIsUploading(false);
          setState({
            ...state,
            errorMessage:
              t('upload.ReportUpload.Error encountered') + ' : ' + response,
            fileName: `${reportFile.name}`,
            isUploading: false,
            isUploadingError: true,
          });
        } else {
          const uploadResult = response;
          // setIsUploading(false);
          setState({
            ...state,
            fileName: `${reportFile.name}`,
            isUploading: false,
            isUploadingError: false,
            uploadResult,
            showMapper: false,
          });
        }
      },
      (err) => {
        // setIsUploading(false);
        setState({
          ...state,
          errorMessage:
            t('upload.ReportUpload.Error encountered') + ' : ' + err,
          fileName: `${reportFile.name}`,
          isUploading: false,

          isUploadingError: true,
        });
        console.log('Error encountered: ' + err);
      }
    );
  };

  const showMappingError = (text) => {
    setState({
      ...state,
      errorMessage: text,
      isMappingErrorVisible: true,
    });
  };

  const spinner = () => {
    return (
      <CircularProgress
        color="inherit"
        className={classes.spinner}
        size={100}
      />
    );
  };

  const fileProcessSpinner = () => {
    const { fileName } = state;
    return (
      <>
        {fileName && (
          <div className={classes.titleText}>
            {t('upload.ReportUpload.File')}
            <span className={classes.fileName}>{fileName}</span>
            {t('upload.ReportUpload.is processing')}
          </div>
        )}
        <CircularProgress
          color="primary"
          className={classes.spinner}
          size={150}
        />
      </>
    );
  };

  const uploadFileStage = () => {
    const { isUploading } = state;
    return (
      <div>
        <Typography variant="subtitle1" className={classes.header}>
          {t('upload.ReportUpload.File Upload')}
        </Typography>
        <Typography variant="body1" className={classes.subheader}>
          {t(
            'upload.ReportUpload.Please upload your HSE file in CSV or Excel format'
          )}
        </Typography>
        {!isUploading && (
          <FileDropzone
            accept={acceptedMimeTypes}
            onDrop={onDropFile}
            className={classes.fileDropZone}
          />
        )}
        {isUploading && spinner()}
        <div>
          <Button
            color="primary"
            variant="contained"
            className={classes.uploadButton}
            disabled={isUploading}
            onClick={onSelectFile}
          >
            {t('upload.ReportUpload.Select file')}
          </Button>
        </div>
        <div style={{ marginTop: '0.5em' }}>
          <Button
            color="primary"
            variant="outlined"
            className={classes.uploadButton}
            onClick={() => setState({ ...state, showMapper: true })}
          >
            {t('upload.ReportUpload.Show mapper')}
          </Button>
        </div>
      </div>
    );
  };

  const uploadFileStageError = () => {
    const { isUploading, fileName, errorMessage } = state;
    return (
      <div>
        <Typography variant="subtitle1" className={classes.header}>
          {t('upload.ReportUpload.File Upload')}
        </Typography>
        <Typography variant="body1" className={classes.subheader}>
          {t(
            'upload.ReportUpload.Please upload your HSE file in CSV or Excel format'
          )}
        </Typography>
        <img
          src="../images/UploadError.png"
          width="226"
          height="219"
          alt="Upload error"
          className={classes.uploadimg}
        />
        <Typography className={classes.uploadText}>{fileName}</Typography>
        <Typography className={classes.uploadErrorText}>
          {errorMessage}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          className={classes.uploadButton}
          disabled={!isUploading}
          onClick={handleUploadAnother}
        >
          {t('upload.ReportUpload.CANCEL')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.uploadButton}
          onClick={onSelectFile}
        >
          {t('upload.ReportUpload.SELECT FILE')}
        </Button>
      </div>
    );
  };

  const uploadFileResultStage = () => {
    const { uploadResult } = state;
    return uploadResult ? (
      <UploadResultViewer uploadResult={uploadResult} />
    ) : null;
  };

  const renderMapper = () => {
    const {
      mapperItems,
      shouldBeTranslated,
      client,
      reportFile,
      selectedEntityTypes,
    } = state;
    return (
      <>
        <EntitiesSelector
          value={selectedEntityTypes}
          onChange={(value) =>
            setState({ ...state, selectedEntityTypes: value })
          }
        />
        <UploadMapper
          ref={mapperRef}
          initialItems={mapperItems}
          client={selectedClient}
          selectedEntityTypes={selectedEntityTypes}
        />
        <div style={{ marginTop: '1em' }}>
          <FormGroup row className={classes.translateSwitch}>
            <FormControlLabel
              control={
                <TranslateSwitch
                  checked={shouldBeTranslated}
                  size="small"
                  onChange={handleTranslate}
                />
              }
              label={t('upload.ReportUpload.Translate')}
            />
            <Button
              color="primary"
              variant="outlined"
              className={classes.uploadButton}
              onClick={hideMapperAndSaveItems}
            >
              {t('upload.ReportUpload.Hide mapper')}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              endIcon={<SendIcon />}
              className={classes.uploadButton}
              disabled={!selectedClient || !uploadedFile}
              onClick={fileProcessMappedItems}
            >
              {t('upload.ReportUpload.Process mapped items')}
            </Button>
          </FormGroup>
        </div>
      </>
    );
  };

  const renderFileSelector = () => {
    const { isUploadingError } = state;
    return (
      <Paper elevation={0} square={true}>
        {!isUploadingError && uploadFileStage()}
        {isUploadingError && uploadFileStageError()}
        {uploadFileResultStage()}
      </Paper>
    );
  };

  const hideMapperAndSaveItems = () => {
    setState({
      ...state,
      showMapper: false,
    });
  };

  const {
    isReportChecked,
    showMapper,
    client,
    defaultClient,
    isUploading,
    errorMessage,
    isMappingErrorVisible,
  } = state;

  return (
    <>
      <div className={classes.table} style={{ margin: '1em auto' }}>
        <div className={classes.row}>
          <div className={classes.cellCenter}>
            <ClientComboBox
              value={selectedClient}
              defaultValue={defaultClient}
              onChange={(event, value) => setSelectedClient(value)}
              style={{ maxWidth: 300 }}
              excludeSuperOrganisation
            />
          </div>
        </div>
      </div>
      <div className={classes.table}>
        <div className={classes.row}>
          <div className={classes.cellCenter}>
            <Paper className={classes.paper}>
              {isUploading
                ? fileProcessSpinner()
                : showMapper
                ? renderMapper()
                : renderFileSelector()}
            </Paper>
          </div>
        </div>
      </div>

      <div className={classes.table} style={{ marginTop: '1em' }}>
        <div className={classes.row}>
          <div className={classes.cellLeft}>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleUploadAnother}
              // disabled={!isReportChecked}
            >
              <KeyboardBackspaceIcon className={classes.buttonIcon} />
              {t('upload.ReportUpload.UPLOAD ANOTHER FILE')}
            </Button>
          </div>
        </div>
      </div>
      <InfoDialog
        open={isMappingErrorVisible}
        onClose={() => setState({ ...state, isMappingErrorVisible: false })}
        text={errorMessage}
      />
    </>
  );
};

ReportUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  ENTITY_NAMES: PropTypes.object.isRequired,
  ITEMS: PropTypes.array.isRequired,
  ENTITY_TYPES: PropTypes.object.isRequired,
};

export default withTranslatedConstants(withStyles(styles)(ReportUpload));
