import React, { FC } from 'react';
import { DialogTitle, Theme, makeStyles } from '@material-ui/core';
import AlertsForm from './AlertsForm';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '20px',
  },
}));

interface Props {
  Type: string;
}

const AlertsView: FC<Props> = ({ Type }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle>
        {Type} {t('Alerts.AlertsView.Alert')}
      </DialogTitle>
      <div className={classes.container}>
        <AlertsForm Type={Type} />
      </div>
    </>
  );
};

export default AlertsView;
