import React, { useEffect, useState } from 'react';
import CheckBoxGroup from '../common/CheckBoxGroup';
import PropTypes from 'prop-types';
import { Card, CardHeader, withStyles } from '@material-ui/core';
import { useTranslatedConstants } from './upload-mapper/useTranslatedConstants';
import { useTranslation } from 'react-i18next';

const styles = {
  container: {
    marginBottom: 10,
    paddingBottom: 10,
  },
  content: {
    justifyContent: 'center',
  },
};

function EntitiesSelector({ value, onChange, classes }) {
  const { t } = useTranslation();
  const { getNewEntityItems } = useTranslatedConstants();
  const [entityNames, setEntityNames] = useState({
    Reports: '',
    Locations: '',
    Facilities: '',
    AssociatedReports: '',
    Organisations: '',
  });
  const [entityTypes, setEntityTypes] = useState({
    Reports: '',
    Locations: '',
    Facilities: '',
    AssociatedReports: '',
    Organisations: '',
    ReportType: '',
  });

  useEffect(() => {
    const results = getNewEntityItems();
    const { ENTITY_TYPES, ENTITY_NAMES, ITEMS } = results;
    if (results) {
      setEntityNames(ENTITY_NAMES);
      setEntityTypes(ENTITY_TYPES);
    }
  }, [localStorage.getItem('selectedLanguage')]);

  const ENTITIES = [
    { type: entityTypes.Reports, name: entityNames.Reports },
    { type: entityTypes.Locations, name: entityNames.Locations },
    { type: entityTypes.Facilities, name: entityNames.Facilities },
    {
      type: entityTypes.AssociatedReports,
      name: entityNames.AssociatedReports,
    },
    { type: entityTypes.Organisations, name: entityNames.Organisations },
  ];

  return (
    <Card variant="outlined" className={classes.container}>
      <CardHeader
        title={t('upload.EntitiesSelector.Select entities to create or update')}
      />
      <CheckBoxGroup
        value={value}
        onChange={onChange}
        options={ENTITIES}
        nameSelector={(option) => option.name}
        valueSelector={(option) => option.type}
        className={classes.content}
      />
    </Card>
  );
}

EntitiesSelector.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EntitiesSelector);
