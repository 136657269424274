// EditOrganisationDialog.tsx

import React, { useEffect, useState } from 'react';
import {
  withStyles,
  FormControlLabel,
  Switch,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Tooltip,
  IconButton,
  WithStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ApiRoutes from '../../services/ApiRoutes';
import API from '../../services/ApiInstance';
import InfoDialog from '../dialog/InfoDialog';
import LocalComboBox from '../combobox/local-combo-box';
import ValidationService from '../../services/authentication/ValidationService';
import useAuthorization from '../../hooks/useAuthorisationManager';
import { ROLES } from '../../constants';
import AdditionalTopicsComboBox from '../combobox/additional-topics-combo-box';
import api from '../../services/ApiInstance';
import {
  AdditionalTopicDto,
  OrganisationAdditionalTopics,
  OrganisationAdditionalTopicsDto,
  OrganisationDto,
} from '../../api/apiDtos';
import { useTranslation } from 'react-i18next';

const styles = {
  control: {
    marginBottom: '1em',
  },
};

interface EditOrganisationDialogProps extends WithStyles<typeof styles> {
  organisation: OrganisationDto;
  industrySectors: Array<any>;
  locations: Array<any>;
  onSuccess: () => void;
}

const EditOrganisationDialog: React.FC<EditOrganisationDialogProps> = ({
  organisation,
  industrySectors,
  locations,
  onSuccess,
  classes,
}) => {
  const { hasRole } = useAuthorization();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(organisation.name);
  const [industrySector, setIndustrySector] = useState(
    organisation.industrySector
  );
  const [location, setLocation] = useState(organisation.location);
  const [isActive, setIsActive] = useState(organisation.isActive);
  const [additionalTopics, setAdditionalTopics] = useState<
    OrganisationAdditionalTopicsDto[]
  >(organisation.additionalTopics);
  const [topicsIsOpen, setTopicsIsOpen] = useState(false);
  const [validationErrorText, setValidationErrorText] = useState('');
  const [openValidationError, setOpenValidationError] = useState(false);
  const [loading, setLoading] = useState(false);
  const validator = new ValidationService();
  const isSuperUser = hasRole(ROLES.SuperUser);
  const industrySectorRequired = isSuperUser && organisation.level === 1;
  const [configuredTopics, setConfiguredTopics] = useState<
    AdditionalTopicDto[]
  >([]);

  const FORM_ERROR_TEXT = t(
    'organisation-management.EditOrganisationDialog.FORM_ERROR_TEXT'
  );

  const handleClickOpen = () => {
    setName(organisation.name);
    setIsActive(organisation.isActive);
    setIndustrySector(organisation.industrySector);
    setLocation(organisation.location);
    setAdditionalTopics(organisation.additionalTopics);
    setOpen(true);
  };

  useEffect(() => {
    async function onInit() {
      await getTopics();
    }
    onInit();
  }, []);

  async function getTopics() {
    try {
      const result = await api.get<unknown, AdditionalTopicDto[]>(
        ApiRoutes.getTopics
      );
      if (result?.length) {
        setConfiguredTopics(result);
      } else {
        setConfiguredTopics([]);
      }
    } catch (error) {
      console.error('Failed to fetch topics: ', error);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const submit = () => {
    const requirements = [ValidationService.validators.required(name)];
    if (industrySectorRequired) {
      requirements.push(ValidationService.validators.required(industrySector));
    }
    const isValid = validator.validate(...requirements);

    if (!isValid) {
      setValidationErrorText(FORM_ERROR_TEXT);
      setOpenValidationError(true);
    } else {
      setLoading(true);
      const updatedOrg = {
        id: organisation.id,
        name,
        isActive,
      } as OrganisationDto;
      if (industrySectorRequired) {
        updatedOrg.industrySectorId = industrySector.id;
      }
      if (location) {
        updatedOrg.locationId = location.id;
      }

      if (additionalTopics.length !== 0) {
        updatedOrg.additionalTopics = Object.entries(additionalTopics).map(
          ([key, value]) =>
            ({
              Id: value.id,
              OrganisationId: updatedOrg.id,
              AdditionalTopicName: value.additionalTopicName,
              CustomLabel: value.customLabel,
              additionalTopicId: value.additionalTopicId,
              additionalTopic: value.additionalTopic,
            }) as OrganisationAdditionalTopics
        );
      }
      API.post(ApiRoutes.updateOrganisation, updatedOrg)
        .then(() => {
          setLoading(false);
          handleClose();
          onSuccess();
        })
        .catch(() => {
          setLoading(false);
          handleClose();
        });
    }
  };

  return (
    <>
      <Tooltip
        title={t(
          'organisation-management.EditOrganisationDialog.Edit organisation'
        )}
      >
        <IconButton
          aria-label="EditOrg"
          color="primary"
          onClick={handleClickOpen}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>
          {t(
            'organisation-management.EditOrganisationDialog.Edit organisation'
          )}
        </DialogTitle>
        <DialogContent>
          <TextField
            label={t('organisation-management.EditOrganisationDialog.Name')}
            value={name}
            onChange={(event) => setName(event.target.value)}
            required
            autoFocus
            fullWidth
            variant="outlined"
            className={classes.control}
          />
          {industrySectorRequired && (
            <LocalComboBox
              options={industrySectors}
              value={industrySector}
              onChange={(event, value) => setIndustrySector(value)}
              getOptionLabel={(sector) => sector.name}
              customLabel={t(
                'organisation-management.EditOrganisationDialog.Industry sector'
              )}
              className={classes.control}
            />
          )}
          {isSuperUser && (
            <AdditionalTopicsComboBox
              customLabel={t(
                'organisation-management.EditOrganisationDialog.Additional Topics'
              )}
              organisation={organisation}
              options={configuredTopics.map((item) => item.name) || []}
              open={topicsIsOpen}
              onOpen={() => setTopicsIsOpen(true)}
              onClose={() => setTopicsIsOpen(false)}
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) => option === value}
              selectedTopics={
                additionalTopics && additionalTopics instanceof Array
                  ? additionalTopics.map((item) => item.additionalTopicName)
                  : []
              }
              topicsAndLabels={additionalTopics ? additionalTopics : []}
              setAdditionalTopics={setAdditionalTopics}
              className={classes.control}
              multiple
            />
          )}
          <LocalComboBox
            options={locations}
            value={location}
            onChange={(event, value) => setLocation(value)}
            getOptionLabel={(loc) => loc.name}
            customLabel={t(
              'organisation-management.EditOrganisationDialog.Location'
            )}
            className={classes.control}
          />
          <FormControlLabel
            control={
              <Switch
                checked={isActive}
                onChange={(event) => setIsActive(event.target.checked)}
                color="primary"
              />
            }
            label={t(
              'organisation-management.EditOrganisationDialog.Is Active'
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={submit}
            disabled={loading}
            variant="contained"
          >
            {t('organisation-management.EditOrganisationDialog.Update')}
          </Button>
          <Button onClick={handleClose} color="primary" variant="outlined">
            {t('Global.Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      {openValidationError && (
        <InfoDialog
          open={openValidationError}
          onClose={() => setOpenValidationError(false)}
          text={validationErrorText}
        />
      )}
    </>
  );
};

export default withStyles(styles)(EditOrganisationDialog);
