// @ts-nocheck
import React, { useState } from 'react';
import MUIDatatable from 'mui-datatables';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Button,
  withStyles,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import API from '../../services/ApiInstance';
import apiRoutes from '../../services/ApiRoutes';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const STATUS_CODES = {
  success: 0,
  warning: 1,
  error: 2,
};

const DATE_FORMAT = 'DD-MMM-yy HH:mm';

const styles = {
  logContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logMessage: {
    marginLeft: 5,
  },
  actionsBar: {
    display: 'flex',
    justifyContent: 'end',
  },
  cardRoot: {
    margin: '6px 0',
  },
  metadataTitle: {
    textAlign: 'left',
    fontWeight: 500,
    padding: '4px 0',
  },
  metadataValue: {
    textAlign: 'left',
  },
};

const getStatusIcon = (code) => {
  switch (code) {
    case STATUS_CODES.error:
      return <ErrorIcon fontSize="small" style={{ color: 'red' }} />;
    case STATUS_CODES.warning:
      return <WarningIcon fontSize="small" style={{ color: 'orange' }} />;
    case STATUS_CODES.success:
      return <CheckCircleIcon fontSize="small" style={{ color: 'green' }} />;
    default:
      return null;
  }
};

const UploadResultViewer = ({ uploadResult, classes }) => {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const renderLog = (log, key) => {
    return (
      <p key={key} className={classes.logContainer}>
        {getStatusIcon(log.code)}
        <span className={classes.logMessage}>{log.message}</span>
      </p>
    );
  };

  const exportFile = () => {
    const url = `${apiRoutes.downloadFileUploadReport}?fileIngestionId=${uploadResult.fileIngestionId}`;
    API.downloadFile(url, uploadResult.reportFileDisplayName);
  };

  const data = Object.keys(uploadResult.details).map((row) => ({
    row,
    logs: uploadResult.details[row],
  }));

  const columns = [
    {
      label: t('upload.UploadResultViewer.Row'),
      name: 'row',
      options: {
        sortCompare: (order) => {
          return ({ data: val1 }, { data: val2 }) => {
            val1 = parseInt(val1);
            val2 = parseInt(val2);
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        },
      },
    },
    {
      label: t('upload.UploadResultViewer.Logs'),
      name: 'logs',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const item = data[dataIndex];
          return (
            <>
              {item.logs.map((log, idx) => renderLog(log, `${log.row}-${idx}`))}
            </>
          );
        },
        sort: false,
      },
    },
  ];

  return (
    <>
      <Typography variant="h4">
        {t('upload.UploadResultViewer.File upload report')}
      </Typography>
      <div className={classes.actionsBar}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<GetAppIcon />}
          onClick={exportFile}
        >
          {t('upload.UploadResultViewer.Export')}
        </Button>
      </div>
      <Card variant="outlined" className={classes.cardRoot}>
        <CardContent>
          <Grid container>
            <Grid item xs={3} className={classes.metadataTitle}>
              {t('upload.UploadResultViewer.File name')}:
            </Grid>
            <Grid item xs={9} className={classes.metadataValue}>
              {uploadResult.fileName}
            </Grid>
            <Grid item xs={3} className={classes.metadataTitle}>
              {t('upload.UploadResultViewer.Organisation')}:
            </Grid>
            <Grid item xs={9} className={classes.metadataValue}>
              {uploadResult.clientName}
            </Grid>
            <Grid item xs={3} className={classes.metadataTitle}>
              {t('upload.UploadResultViewer.Date and time')} (UTC):
            </Grid>
            <Grid item xs={9} className={classes.metadataValue}>
              {moment(uploadResult.dateTimeUtc).format(DATE_FORMAT)}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card variant="outlined" className={classes.cardRoot}>
        <CardHeader title="Summary" />
        <CardContent>
          {uploadResult.summary.map((log, idx) =>
            renderLog(log, `${log.row}-${idx}`)
          )}
        </CardContent>
      </Card>
      <Card variant="outlined" className={classes.cardRoot}>
        <CardHeader title="Details" />
        <MUIDatatable
          data={data}
          columns={columns}
          options={{
            selectableRows: 'none',
            print: false,
            search: false,
            filter: false,
            viewColumns: false,
            download: false,
            rowsPerPageOptions: [25, 50, 100],
            rowsPerPage: rowsPerPage,
            page: page,
            onChangeRowsPerPage: (numberOfRows) => setRowsPerPage(numberOfRows),
            onChangePage: (currentPage) => setPage(currentPage),
          }}
        />
      </Card>
    </>
  );
};

UploadResultViewer.propTypes = {
  uploadResult: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadResultViewer);
