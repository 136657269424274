// @ts-nocheck
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FiltersConfigurator from './filters-configurator/filters-configurator';
import { Paper } from '@material-ui/core';
import WidgetsConfigurator from './widgets-configurator/widgets-configurator';
import UserPreferenceService from '../../services/UserPreferenceService';
import { useTranslation } from 'react-i18next';

const userPreferenceService = new UserPreferenceService();

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`mdl-tabpanel-${index}`}
      aria-labelledby={`mdl-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function tabProps(index) {
  return {
    id: `mdl-tab-${index}`,
    'aria-controls': `mdl-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    width: '100%',
    textAlign: 'center',
  },
}));

export default function WidgetsManagement() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    const savedTab = userPreferenceService.get('ConfiguratorsMainTab');
    if (savedTab !== null) {
      setValue(savedTab);
    }
  }, []);

  useEffect(() => {
    userPreferenceService.save('ConfiguratorsMainTab', value);
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Paper position="static">
        <div className={classes.header}>
          <Typography variant="subtitle2">
            {t(
              'widgets_manager_page.widgets_management.Select configuration option from the navigation below'
            )}
          </Typography>
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="manage-display-labels"
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            label={t(
              'widgets_manager_page.widgets_management.Customise Widgets'
            )}
            {...tabProps(0)}
          />
          <Tab
            label={t(
              'widgets_manager_page.widgets_management.Customise Filters'
            )}
            {...tabProps(1)}
          />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <WidgetsConfigurator />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FiltersConfigurator />
      </TabPanel>
    </div>
  );
}
