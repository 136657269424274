import React, { FC } from 'react';
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { ChartStyles } from '../ChartConstants';
import { LifeSavingRuleDto } from '../../../api/bllDtos';

interface CustomRadarTooltipProps {
  active: any;
  payload: any;
}

// Is this component even being used? the props werent set originally so it would never go inside the if block
const CustomRadarTooltip: FC<CustomRadarTooltipProps> = ({
  active,
  payload,
}) => {
  if (active && payload && payload.length) {
    const rule = payload[0].payload;
    return (
      <div style={ChartStyles.tooltipContentStyle}>
        <p style={{ padding: 12 }}>{`${rule.name} : ${rule.count}`}</p>
      </div>
    );
  }
  return null;
};

interface Props {
  data: LifeSavingRuleDto[];
  onClick: (code: string) => void;
}

const LifeSavingRulesRadarChart: FC<Props> = ({ data, onClick }) => {
  const onChartClick = (event: any) => {
    if (!event || !event.activePayload) {
      return;
    }
    const activeTooltipPayload = event.activePayload[0];
    if (activeTooltipPayload && activeTooltipPayload.payload) {
      onClick(activeTooltipPayload.payload.code);
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart data={data} onClick={onChartClick}>
        <PolarGrid style={{ cursor: 'pointer' }} />
        <Tooltip
          content={
            <CustomRadarTooltip active={undefined} payload={undefined} />
          }
        />
        <PolarAngleAxis dataKey="name" />
        <PolarRadiusAxis angle={90} />
        <Radar
          dataKey="count"
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.4}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default LifeSavingRulesRadarChart;
